<template>
  <li :class="['accordion-item', classes]" :styles="customStyles">
    <div :class="['accordion-item--trigger', triggerClasses]" @click="onClick">
      <slot name="accordion-trigger"></slot>
      <div class="accordion-item--icon-container">
        <font-awesome-icon :icon="visible ? iconOpen : iconClose" />
      </div>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion-item--content" v-if="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  name: "AccordionItem",
  data() {
    return {
      index: null,
      active: ""
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary",
      validator: type => {
        return ["primary", "secondary", "tertiary"].includes(type);
      }
    },
    accordionIndex: {
      type: Number,
      required: true,
      default: 0
    },
    iconClose: {
      type: String,
      default: "chevron-down"
    },
    iconOpen: {
      type: String,
      default: "chevron-up"
    },
    activeIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    classes() {
      return {
        ...this.customClass,
        "accordion-item--primary": this.type === "primary",
        "accordion-item--secondary": this.type === "secondary",
        "accordion-item--tertiary": this.type === "tertiary"
      };
    },
    triggerClasses() {
      return {
        "accordion-item--trigger-primary": this.type === "primary",
        "accordion-item--trigger-secondary": this.type === "secondary",
        "accordion-item--trigger-tertiary": this.type === "tertiary"
      };
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    visible() {
      return this.index === this.activeIndex;
    }
  },
  methods: {
    onClick() {
      this.visible ? (this.active = null) : (this.active = this.index);

      this.$emit("onToggle", { index: this.index, visible: this.visible });
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.heigth = "";
    }
  },
  created() {
    this.index = this.accordionIndex;
  }
};
</script>

<style lang="css" scoped src="./AccordionItem.css"></style>
