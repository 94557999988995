<template>
  <div :class="['tooltip', customClass]">
    <slot name="content"></slot>
    <span
      class="tooltip--text"
      :class="classes"
      :style="customStyles"
      v-html="tooltip"
    >
      {{ tooltip }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary",
      required: false
    },
    size: {
      type: String,
      default: "medium",
      required: false
    },
    tooltip: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: "top"
    },
    bgColor: {
      type: String,
      default: "#9ca3af"
    }
  },
  computed: {
    classes() {
      return {
        ...this.customClass,
        "tooltip--primary": this.type === "primary",
        "tooltip--top": this.position === "top",
        "tooltip--left": this.position === "left",
        "tooltip--right": this.position === "right",
        "tooltip--bottom": this.position === "bottom",
        "tooltip--small": this.size === "small",
        "tooltip--medium": this.size === "medium",
        "tooltip--large": this.size === "large"
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor
      };
    }
  }
};
</script>

<style lang="css" scoped src="./Tooltip.css"></style>
