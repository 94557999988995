// import httpClient from "./httpClient";
import axios from "axios";

const AUTOCOMPLETE =
  process.env.VUE_APP_DIGITAL_STORE_BASE_URL + "/vehicle/autocomplete";

// declare an request's cancelToken (globally)
let cancelToken = null;
function autocomplete(word) {
  // cancel previous request if exists
  if (cancelToken) {
    cancelToken.cancel();
  }
  // creates a new token for upcomming request (overwrite the previous one)
  cancelToken = axios.CancelToken.source();
  try {
    return axios.get(AUTOCOMPLETE + "?q=" + word, {
      cancelToken: cancelToken.token,
      headers: {
        companyId: 1
      }
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      cancelToken.cancel("Request Canceled");
    } else {
      // handle error
    }
  }
}

export { autocomplete };
