const RETAKESTATUS = {
  FINALIZED: 1,
  EXPIRED: 2,
  Q1_ESTIMATED: 3,
  Q1_UPDATED: 4,
  Q1_ERROR: 5,
  Q2_PENDING: 6,
  Q2_REFUSED: 7
};

export { RETAKESTATUS };
