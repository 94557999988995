var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isTestDrive)?_c('RightSidebar',{attrs:{"title":"Marcar Test Drive","description":""},on:{"onClose":_vm.closeTestDrive},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"interested--price-model-details"},[_c('PriceModelDetailsRightSidebar',{attrs:{"size":"medium","product-title":_vm.vehicleDetails.model,"product-description":_vm.vehicleDetails.commercialDescription,"finance-taeg":'TAEG: ' + _vm.vehicleDetails.taeg.toFixed(2) + '%',"modality":_vm.vehicleDetails.monthlyPrice.toFixed(2) + '€/mês',"price-label":_vm.getPriceFormatted(_vm.vehicleDetails.pricePvp, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }),"icon-name":"info-circle","tooltip-description":"Loren Ipsum"}})],1),_c('div',{staticClass:"mt-4"},[_c('Label',{attrs:{"color":"tertiary"}},[_vm._v("Insira os seus dados pessoais")])],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{attrs:{"type":"primary","inputType":"text","label":"Nome *","bgColor":"#F5F7F9"},on:{"onChange":value => {
            _vm.myPersonalName = value;
          }}}),(_vm.errorName)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errorName))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{attrs:{"type":"primary","inputType":"text","label":"Email *","bgColor":"#F5F7F9"},on:{"onChange":value => {
            _vm.myPersonalEmail = value;
          }}}),(_vm.errorEmail)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errorEmail))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{attrs:{"type":"primary","inputType":"text","label":"Telémovel *","bgColor":"#F5F7F9"},on:{"onChange":value => {
            _vm.myPersonalPhone = value;
          }}}),(_vm.errorPhone)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errorPhone))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('PrivacyPolicy',{attrs:{"privacyPolicyLinkURL":"/politica-de-privacidade","textMarketing":_vm.$t('privacyPolicy.textMarketing'),"createAccount":true},on:{"acceptedPrivacyPolicy":_vm.acceptedPrivacyPolicy,"acceptedEmailMarketing":_vm.acceptedEmailMarketing,"acceptedCreateAccount":_vm.acceptedCreateAccount,"onClickPolicyLink":_vm.onOpenPrivacyPolicy}}),(_vm.errorPrivacyPolicy)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errorPrivacyPolicy))]):_vm._e()],1),(_vm.isCreateAccount)?_c('div',{staticClass:"mt-4"},[_c('InputForm',{attrs:{"type":"primary","inputType":"password","label":"Palavra-passe *","bgColor":"#F5F7F9"},on:{"onChange":value => {
            _vm.myPersonalPassword = value;
          }}}),(_vm.errorPassword)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errorPassword))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('Label',{attrs:{"color":"tertiary"}},[_vm._v("Escolha um concessionário para intermediar o negócio.")]),_c('Link',{staticClass:"concessionario",attrs:{"color":"secondary","size":"small","label":"Porque preciso de um concessionário?"}})],1),_c('div',{staticClass:"mt-4"},[_c('Select',{attrs:{"id":"district-test-drive","options":_vm.districtList},on:{"onChange":_vm.onChangeDistrict}})],1),_c('div',{staticClass:"mt-4"},[_c('Select',{attrs:{"id":"dealer-test-drive","options":_vm.concessionList},on:{"onChange":_vm.onChangeDealer}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('Button',{attrs:{"label":"anterior","uppercase":"","type":"primary"},on:{"onClick":_vm.previous}}),_c('Button',{staticClass:"reserve",attrs:{"label":"marcar test drive","uppercase":"","type":"primary","fill":true,"disabled":_vm.buttonEnabled},on:{"onClick":_vm.clickTestDrive}})]},proxy:true}],null,false,763033274)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }