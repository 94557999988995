var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isDisabled},[_c('div',{class:['select', _vm.customClass, _vm.classes],style:(_vm.customStyles),attrs:{"id":_vm.id}},[_c('div',{staticClass:"selectWrapper"},[_c('select',{staticClass:"selectNative js-selectNative",attrs:{"aria-labelledby":"jobLabel"}},[_vm._l((_vm.preparedOptions),function(option,index){return _c('option',{key:index,attrs:{"disabled":option.selected},domProps:{"value":option.value,"selected":option.selected,"innerHTML":_vm._s(option.text)}})}),_c('div',{staticClass:"select--icon-wrapper"},[(
              _vm.clearable &&
                _vm.optionChecked &&
                _vm.optionChecked !== _vm.preparedOptions[0].value
            )?_c('Icon',{staticClass:"select--clear-icon cursor-pointer",attrs:{"color":"secondary","name":"times","size":"x-small"},nativeOn:{"click":function($event){return _vm.clearSelect.apply(null, arguments)}}}):_vm._e(),_c('Icon',{staticClass:"select--dropdown-icon ml-1 cursor-pointer",attrs:{"color":"primary","name":_vm.dropdownIcon,"size":"x-small"},nativeOn:{"click":function($event){return _vm.onClickSelectHandler.apply(null, arguments)}}})],1)],2),_c('div',{staticClass:"selectCustom js-selectCustom",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"selectCustom-trigger"},[_c('div',{staticClass:"trigger-text overflow-ellipsis overflow-hidden whitespace-nowrap mr-5"},[_vm._v(" "+_vm._s(_vm.getDefaultSelected)+" ")]),_c('div',{staticClass:"select--icon-wrapper"},[(
                _vm.clearable &&
                  _vm.optionChecked &&
                  _vm.optionChecked !== _vm.preparedOptions[0].value
              )?_c('Icon',{staticClass:"select--clear-icon select--clear-icon cursor-pointer",attrs:{"color":_vm.type,"name":"times","size":"x-small"},nativeOn:{"click":function($event){return _vm.clearSelect.apply(null, arguments)}}}):_vm._e(),_c('Icon',{staticClass:"select--dropdown-icon ml-1 cursor-pointer",attrs:{"color":_vm.type,"name":_vm.dropdownIcon,"size":"x-small"}})],1)]),_c('div',{staticClass:"selectCustom-options",style:({ 'background-color': _vm.optionsBgColor })},_vm._l((_vm.preparedOptions),function(option,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!option.disabled),expression:"!option.disabled"}],key:index,staticClass:"selectCustom-option",attrs:{"data-value":option.value},domProps:{"innerHTML":_vm._s(option.text)}})}),0)])]),(_vm.showIndicator)?_c('span',{staticClass:"indicator"},[_vm._v("Campo Obrigatório")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }