<template>
  <div :class="['dropdown--menu-item', customClass]" :style="customStyles">
    <div class="country-picker">
      <vue-country-code
        ref="country_list"
        @onSelect="onSelect"
        :preferredCountries="['PT']"
        :dropdownOptions="{ disabledDialCode: true }"
      >
      </vue-country-code>
      <div v-on:click.stop="openCountryList">
        <InputForm
          inputType="text"
          label="Nacionalidade"
          :inputValue="countryName"
          :key="countryName"
          :isDisabled="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { InputForm } from "../../molecules";

export default {
  data() {
    return {
      countryName: "Portugal"
    };
  },
  components: {
    InputForm
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: "sgsdfsdf"
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  methods: {
    onSelect({ name }) {
      this.countryName = name;
      this.$emit("onCountry", name);
    },
    openCountryList() {
      this.$refs.country_list.toggleDropdown();
    }
  }
};
</script>
<style lang="css" scoped src="./CountryPicker.css"></style>
