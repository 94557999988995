<template>
  <div class="pagination">
    <div class="pagination--previous">
      <Button
        @onClick="previousPage"
        :label="previousButtonLabel"
        uppercase
        fill
        type="secondary"
        :disabled="!getPrev"
        :class="Previous"
        icon="chevron-left"
        iconPosition="left"
        iconPrefix="fas"
      ></Button>
    </div>
    <div class="pagination--results">
      <Label size="x-small" align="center"
        >{{ getCurrentPage }} -
        {{
          getTotal >= 20 ? (getCurrentPage - 1) * 20 + recent.length : getTotal
        }}
        de {{ getTotal }} Resultados
      </Label>
    </div>
    <div class="pagination--next">
      <Button
        @onClick="nextPage"
        :label="nextButtonLabel"
        uppercase
        fill
        type="secondary"
        :class="Next"
        :disabled="!getNext"
        icon="chevron-right"
        iconPosition="right"
        iconPrefix="fas"
      ></Button>
    </div>
  </div>
</template>

<script>
import { Button, Label } from "../../atoms";
import { mapGetters } from "vuex";

export default {
  components: {
    Button,
    Label
  },
  props: {
    previousButtonLabel: {
      type: String,
      default: "Anterior"
    },
    nextButtonLabel: {
      type: String,
      default: "Seguinte"
    },
    used: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async changePage(offset = 1) {
      await this.$store.commit(
        "SearchModule/pagination/setCurrent",
        this.getCurrentPage + offset
      );
      if (this.used) {
        await this.$store.dispatch("SearchModule/fetchUsed", {
          quickSearchItem: [],
          dynamic: false
        });
      } else {
        await this.$store.dispatch("SearchModule/fetchRecent", {
          quickSearchItem: [],
          dynamic: false
        });
      }
      window.scrollTo(0, 0);
    },
    previousPage() {
      this.changePage(-1);
    },
    nextPage() {
      this.changePage();
    }
  },
  computed: {
    ...mapGetters({
      getCurrentPage: "SearchModule/pagination/getCurrent",
      getPages: "SearchModule/pagination/getPages",
      getTotal: "SearchModule/pagination/getResults",
      getNext: "SearchModule/pagination/getNext",
      getPrev: "SearchModule/pagination/getPrev",
      recent: "SearchModule/getRecent"
    }),
    Previous() {
      return {
        "pagination--cursor-locked": this.prev === 0
      };
    },
    Next() {
      return {
        "pagination--cursor-locked": this.next === 0
      };
    }
  }
};
</script>

<style lang="css" src="./Pagination.css" scoped></style>
