<template>
  <div class="warranty-card">
    <div class="warranty-card--emoji">
      <img
        :src="
          $t('resources.images.detailPage.warranty') ||
            require(`../../../assets/images/warranty-images/warranty-white.png`)
        "
        width="70px"
        height="50px"
      />
    </div>
    <div class="warranty-card--content">
      <Label color="secondary" size="small">{{ info }}</Label>
      <div class="warranty-card--content-list">
        <div
          class="warranty-card--content-list-item"
          v-for="(item, index) in infoList"
          :key="index"
        >
          <Label color="secondary" size="small">{{ item }}</Label>
        </div>
      </div>
      <div class="warranty-card--content-disclaimer">
        <Label color="secondary" size="x-small">{{ disclaimerInfo }}</Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  components: {
    Label
  },
  props: {
    info: {
      type: String,
      default: ""
    },
    infoList: {
      type: Array[String],
      default: []
    },
    disclaimerInfo: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="css" scoped src="./WarrantyCard.css"></style>
