<template>
  <div id="app">
    <BaseAuth v-if="prodBaseAuth" @onValidate="clearBaseAuth" />
    <Navbar id="navbar-page">
      <template v-slot:left>
        <router-link to="/">
          <div style="width: 180px; height: auto">
            <img :src="appLogo" />
          </div>
        </router-link>
      </template>
      <template v-slot:center>
        <LinkList
          @onClick="closeMobileMenu"
          :list="list"
          bold="medium"
          display="horizontal"
          size="small"
          :uppercase="true"
        />
      </template>
      <template v-slot:right-1>
        <div
          class="actions-area flex flex-row justify-center sm:justify-start items-center"
        >
          <a @click="openCompare" class="icon--wrapper">
            <div>
              <span class="notify-badge" v-if="compareCount > 0">
                {{ compareCount }}
              </span>

              <div
                @click="openCompare"
                class="navbar--compare-icon"
                :style="{
                  backgroundImage: `url(${compareIcon})`
                }"
              />
            </div>
          </a>

          <a class="icon--wrapper">
            <div>
              <span class="notify-badge" v-if="favoritesCount > 0">
                {{ favoritesCount }}
              </span>

              <div
                @click="openFavoritePanel"
                class="navbar--favorite-icon"
                :style="{
                  backgroundImage: `url(${favoriteIcon})`
                }"
              />
            </div>
          </a>
        </div>
      </template>
      <template v-slot:right-2>
        <div
          class="session-area flex flex-row justify-center sm:justify-start items-center"
        >
          <a class="usericon--wrapper">
            <div
              class="account-image"
              :style="{
                backgroundImage: `url(${userIcon})`
              }"
            />
          </a>
          <Link
            v-show="!getToken || isGuest"
            :label="$t('navbar.session.login')"
            href="#"
            size="small"
            bold="medium"
            color="secondary"
            @onClick="openLoginForm"
            customClass="session--link"
          />
          <SessionDropdown
            v-show="getToken && !isGuest"
            :title="getWelcomeUser"
            :menuList="personalList"
            @onLogout="onLogoutHandler"
          />
        </div>
      </template>
    </Navbar>
    <transition name="fade">
      <router-view :key="$route.path" />
    </transition>
    <Footer
      id="footer-page"
      :copyright-message="footerMessage"
      :footer-list="footerList"
      :subfooterList="[
        { title: 'Termos e Condições', url: '/termos-e-condicoes' },
        { title: 'Política de Cookies', url: '/politica-de-cookies' },
        { title: 'Política de Privacidade', url: '/politica-de-privacidade' }
      ]"
    />
    <RegisterForm
      v-if="renderComponent"
      v-show="openPopup"
      :passwordSent="passwordSent"
      @close="closeAuthenticationForm"
      @onRegister="onRegisterHandler"
      @onLogin="onLoginHandler"
      @onLoginMyHyundai="onLoginMyHyundaiHandler"
      @onFacebookLogin="onFacebookLoginHandler"
      @onGoogleLogin="onGoogleLoginHandler"
      @onRecoverPassword="onRecoverPasswordHandler"
      :isLogin="getIsOpenSigninPopup"
    />
    <VerificationAccount
      v-if="renderComponent"
      v-show="getIsOpenVerificationAccount"
      :email="user.email"
      :verifiedAccount="verifiedAccount"
      @onSendCode="onSendCodeHandler"
      @onResendCode="onResendCodeHandler"
      @close="closeVerificationAccountHandler"
    ></VerificationAccount>
    <ReservePanel
      :contact="reserveContact"
      :back="reserveBack"
      :vehicle="reserveVehicle"
      :key="panelKey"
    />
    <ComparePanel />
    <FavoritePanel />
    <Spinner :loading="isLoading" />
  </div>
</template>

<script>
import {
  Navbar,
  LinkList,
  Link,
  SessionDropdown,
  Footer,
  RegisterForm,
  Spinner,
  VerificationAccount
} from "./components";

import BaseAuth from "./components/BaseAuth";
import ReservePanel from "./components/sidebars/reserve/ReservePanel.vue";

import { EventBus } from "./utils/eventbus";

import { getPriceFormatted } from "./utils/currency.js";
import ComparePanel from "./components/sidebars/compare/ComparePanel.vue";
import FavoritePanel from "./components/sidebars/favorites/FavoritePanel.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

import SocialLogin from "hellojs/dist/hello.all.js";

export default {
  created() {
    if (parseInt(process.env.VUE_APP_DISPLAY_CHAT)) {
      const d = document;
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.id = "zsiqscript";
      s.defer = true;
      s.src = "https://salesiq.zoho.com/widget";
      const tn = d.getElementsByTagName("script")[0];
      tn.parentNode.insertBefore(s, tn);
    }

    this.$store.dispatch("ConfigurationModule/loadConfiguration");
  },
  components: {
    BaseAuth,
    Navbar,
    LinkList,
    Link,
    SessionDropdown,
    Footer,
    RegisterForm,
    ComparePanel,
    FavoritePanel,
    Spinner,
    VerificationAccount,
    ReservePanel
  },
  computed: {
    ...mapState([
      "isOpenSignupPopup",
      "isOpenSigninPopup",
      "isOpenVerificationAccount"
    ]),
    ...mapGetters([
      "getToken",
      "getUserInfo",
      "getWelcomeUser",
      "getLoadingAuthentication",
      "isGuest",
      "getIsOpenPopup",
      "getIsOpenSigninPopup",
      "getIsOpenVerificationAccount",
      "getUserObj",
      "getVerifiedAccount",
      "getUserStatus",
      "getUserName",
      "getUserEmail",
      "getUserPhoneNumber",
      "getModell"
    ]),
    ...mapGetters({
      isLoading: "spinnerModule/isLoading",
      compareCount: "CompareModule/count",
      compareList: "CompareModule/list",
      favoritesCount: "FavoritesModule/count",
      favoritesList: "FavoritesModule/list",
      reserveStatus: "getReserveStatus",
      reserveContact: "getReserveContact",
      reserveBack: "getReserveBack",
      reserveVehicle: "getReserveVehicle",
      panelKey: "getReservePanelKey"
    }),
    favoriteIcon() {
      return (
        this.$t("resources.images.navbar.favorite") ||
        require("./assets/images/heart.svg")
      );
    },
    compareIcon() {
      return (
        this.$t("resources.images.navbar.compare") ||
        require("./assets/images/compare.svg")
      );
    },
    userIcon() {
      return (
        this.$t("resources.images.navbar.avatar") ||
        require("./assets/images/account_circle.svg")
      );
    },
    appLogo() {
      return (
        this.$t("resources.images.navbar.applogo") ||
        require("./assets/images/logotipo Clicktobuy-positivo.svg")
      );
    },
    openPopup() {
      if (this.getIsOpenPopup) {
        this.forceRerender();
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
      return this.getIsOpenPopup;
    }
  },
  methods: {
    onWindowResize() {
      this.$store.commit("setReponsivenessStatus", {
        windowWidth: window.innerWidth
      });
    },
    clearBaseAuth(e) {
      if (e) {
        this.prodBaseAuth = false;
        document.body.classList.remove("no-scroll");
      }
    },
    ...mapMutations({
      openComparePanel: "CompareModule/openPanel",
      openFavoritesPanel: "FavoritesModule/openPanel"
    }),
    getPriceFormatted,
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    openLoginForm() {
      this.$store.state.isOpenSigninPopup = true;
    },
    closeAuthenticationForm() {
      this.$store.state.isOpenSignupPopup = false;
      this.$store.state.isOpenSigninPopup = false;
    },
    closeVerificationAccountHandler() {
      this.$store.commit("setOpenVerificationAccount", false);
      // ATTENTION: this does not belong here!
      // The method onSendCodeHandler was created with no regard to the
      // EventBus architecture used in MultiStepForm, in particular the
      // PersonalFormStep validations.
      // !!!Massive refactor needed!!!
      if (this.$store.state.isOpenRetakePanel && this.getVerifiedAccount) {
        const userInfo = {
          name: this.getUserName,
          email: this.getUserEmail,
          phoneNumber: this.getUserPhoneNumber
        };
        this.$store.commit("spinnerModule/setLoading", true, { root: true });
        this.$store
          .dispatch("storeValuation", userInfo)
          .then(result => {
            result;
            EventBus.$emit("valid", 2);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eec.retomaQ1",
              model: this.getModell
            });
            this.$emit("onSubmit");
            this.requestValuationFootprint(result);
            this.$store.dispatch("storeGetAllReturns");
          })
          .catch(error => {
            error;
            this.requestValuationFootprint(error);
          })
          .finally(
            () => this.$store.commit("spinnerModule/setLoading", false),
            { root: true }
          );
      }
    },
    async onRegisterHandler(user) {
      //call vuex to register backend
      const logged = await this.$store.dispatch("storeRegisterToken", user);

      this.closeAuthenticationForm();
      if (logged) {
        this.$store.commit("setOpenVerificationAccount", true);
      }
      this.forceRerender();
    },
    onLogoutHandler() {
      this.$store.dispatch("storeLogout");
    },
    onLoginHandler(userObj) {
      this.$store.dispatch("storeLogin", userObj);
      this.closeAuthenticationForm();
      this.forceRerender();
    },
    onLoginMyHyundaiHandler(userObj) {
      this.$store.dispatch("storeLogin", userObj);
      this.closeAuthenticationForm();
    },
    onFacebookLoginHandler() {
      SocialLogin("facebook").login();
    },
    onGoogleLoginHandler() {
      SocialLogin("google").login();
    },
    closeMobileMenu() {
      this.$store.state.isOpenNavbar = false;
      let menu = document.querySelector(".mobile-menu");
      menu.classList.remove("active");
    },
    openCompare() {
      this.closeMobileMenu();
      this.openComparePanel();
    },
    openFavoritePanel() {
      this.closeMobileMenu();
      if (!this.getToken || this.isGuest) {
        this.$store.state.isOpenSigninPopup = true;
      } else {
        this.openFavoritesPanel();
      }
    },
    setupSocialLogins() {
      SocialLogin.init(
        {
          facebook: process.env.VUE_APP_FACEBOOK_APP_ID,
          google: process.env.VUE_APP_GOOGLE_WEB_CLIENT_ID
        },
        { redirect_uri: window.location.href }
      );
    },
    onRecoverPasswordHandler(email) {
      this.$store.commit("spinnerModule/setLoading", true, { root: true });
      this.$store
        .dispatch("recoverPassword", email)
        .then(data => {
          const { statusText } = data;
          if (statusText === "OK") {
            this.passwordSent = true;
          }
        })
        .catch(err => {
          this.passwordSent = false;
          console.log("err: ", err);
        })
        .finally(() => {
          this.$store.commit("spinnerModule/setLoading", false, { root: true });
        });
    },
    onSendCodeHandler(code) {
      this.forceRerender();
      this.verifiedAccount = false;

      const obj = {
        payload: {
          confirmationCode: code
        },
        userObj: this.getUserObj
      };
      this.$store
        .dispatch("verificationAccount", obj)
        .then(data => {
          if (data.data.data === "OK") {
            this.verifiedAccount = true;
            this.forceRerender();
            this.$store.dispatch("storeLogin", this.getUserObj);
            setTimeout(() => {
              this.verifiedAccount = false;
            }, 2000);
          } else {
            this.verifiedAccount = false;
          }
        })
        .catch(err => {
          this.verifiedAccount = false;
          this.$toast.open({
            message: this.$t("validators.invalidConfirmationCode"),
            dismissible: true,
            type: "error"
          });
          console.log("err: ", err);
        });
    },
    onResendCodeHandler() {
      this.$store.dispatch("resendActivationCode");
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onWindowResize);
    });

    this.setupSocialLogins();
    if (!this.isGuest) this.$store.dispatch("storeUserInfo");
    this.$store.dispatch("CompareModule/loadVehiclesFromStorage");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  data() {
    return {
      prodBaseAuth: process.env.VUE_APP_DS_BASE_AUTH === "true",
      renderComponent: true,
      list: [
        {
          label: "Viaturas novas",
          url: "/carros-novos"
        },
        {
          label: "Viaturas usadas",
          url: "/carros-usados"
        },
        {
          label: "Avaliar Retoma",
          url: "/retoma-carros"
        },
        {
          label: "Como funciona",
          url: "/como-funciona"
        }
      ],
      footerMessage: `© Copyright ${new Date().getFullYear()} Hyundai Portugal. Todos os direitos reservados.`,
      footerList: [
        [
          {
            title: "Pesquisar",
            url: "/carros-novos",
            list: [
              {
                label: "Ver Viaturas",
                url: "#"
              },
              {
                label: "Configurar Hyundai",
                url: "#"
              },
              {
                label: "Encontrar Concessionário",
                url: "#"
              }
            ]
          }
        ],
        [
          {
            title: "Carros usados",
            url: "/carros-usados",
            list: [
              {
                label: "Ver Viaturas",
                url: "#"
              },
              {
                label: "Configurar Hyundai",
                url: "#"
              },
              {
                label: "Encontrar Concessionário",
                url: "#"
              }
            ]
          }
        ],
        [
          {
            title: "Retoma",
            url: "/retoma-carros",
            list: [
              {
                label: "Valorizar Retoma",
                url: "/retoma-carros"
              },
              {
                label: "Como Funciona",
                url: "/retoma-carros#details"
              }
            ]
          }
        ],
        [
          {
            title: "Como funciona",
            url: "/como-funciona",
            list: [
              {
                label: "Como funciona a Hyundai Click to Buy",
                url: "/como-funciona"
              },
              {
                label: "Financiamento",
                url: "#"
              },
              {
                label: "Inspeção das Viaturas",
                url: "#"
              }
            ]
          }
        ]
      ],
      personalList: [
        {
          label: "Dados Pessoais",
          url: "/pessoal/dados-pessoais"
        },
        {
          label: "Minhas Reservas",
          url: "/pessoal/reserva"
        },
        {
          label: "Minhas Retomas",
          url: "/pessoal/retoma"
        },
        {
          label: "Pesquisas Guardadas",
          url: "/pessoal/pesquisas-guardadas"
        },
        {
          label: "Terminar Sessão",
          url: "/pessoal/logout"
        }
      ],
      isRegisterFormOpen: false,
      user: {},
      userObj: {},
      passwordSent: false,
      verificationAccountOpened: false,
      verifiedAccount: false
    };
  }
};
</script>
<style scoped>
#app {
  background: white;
  font-family: "Hyundai Sans Head";
}

@media (max-width: 768px) {
  .navbar--compare-icon,
  .navbar--favorite-icon {
    height: 19px !important;
  }
  .navbar--compare-icon {
    @apply -mr-96;
  }
}

.compare-balance {
  @apply mr-4;
  @apply w-7;
  @apply h-7;
  margin: 0 auto;
  margin-top: 8px;
}

.favorite-navbar {
  @apply my-3;
  @apply md:my-0;
  @apply md:ml-3;
}

.compare-balance:hover,
.favorite-navbar:hover {
  @apply cursor-pointer;
}

/* RightSidebar */

.right-sidebar .right-sidebar--content {
  grid-template-columns: repeat(auto-fill, 231px);
  grid-gap: 5px;
  margin-bottom: 10px;
}

.right-sidebar--content a {
  height: 252px;
  width: 231px;
}

.right-sidebar--content a :deep(.search-vehicle-result-card) {
  height: 270px;
  width: 231px;
}

.right-sidebar--content a :deep(.image--wrapper) {
  height: 120px;
  background-color: white;
}

.right-sidebar--content
  a
  :deep(.card--content)
  .image--wrapper
  .block--content
  .image {
  height: 120px;
  width: 100%;
}

.right-sidebar--content a :deep(.container--wrapper) {
  margin-bottom: 15px;
}

.right-sidebar--content a :deep(.info--wrapper) {
  height: 252px;
  @apply px-5;
  @apply pt-8;
  @apply pb-4;
}

.right-sidebar--content a :deep(.title--content) .label {
  font-size: 14px;
  line-height: 16px;
}

.right-sidebar--content a :deep(.title--content) {
  width: 50%;
}

.right-sidebar--content a :deep(.title--content) .price-model-description {
  font-size: 10px;
  line-height: 15px;
  margin-top: 15px;
}

.right-sidebar--content a :deep(.finance--container) .label {
  font-size: 16px;
  line-height: 16px;
}

.right-sidebar--content a :deep(.finance--wrapper) .label {
  font-size: 10px;
  line-height: 10px;
  margin-top: 10px;
}

.right-sidebar--content a :deep(.finance--container) .finance-model-taeg {
  font-size: 10px;
  line-height: 10px;
}

.right-sidebar--content
  a
  :deep(.block--content)
  .search-vehicle-result-card-info-label {
  font-size: 12px;
  line-height: 12px;
}

.right-sidebar--content a :deep(.checkbox--checkmark) {
  @apply w-5;
  @apply h-5;
}

.right-sidebar--content a :deep(.checkbox--container) {
  @apply w-5;
  @apply h-5;
}

.right-sidebar--content a :deep(.toggle--button-icon) {
  @apply w-5;
  @apply h-5;
}

.right-sidebar--content a :deep(.compare-car--wrapper) {
  right: 10px;
  top: 35px;
}

.right-sidebar--content
  a
  :deep(.checkbox--container)
  .checkbox--checkmark.checkbox--secondary::after {
  top: 1px;
  left: 6px;
}

:deep(.right-sidebar--content) .toggle--button-compare-icon {
  @apply w-4;
  @apply h-4;
  top: 35px;
  right: 16px;
}

:deep(.right-sidebar--content) .toggle--button-compare {
  @apply w-4;
  @apply h-4;
  top: 35px;
  right: 16px;
}

.right-sidebar--content a {
  margin-bottom: 18px;
}

.button-compare {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 280px;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.notify-badge {
  position: absolute;
  /* right: -20px; */
  top: -6px;
  background: #00aad2;
  text-align: center;
  border-radius: 50%;
  color: white;
  padding: 1px;
  font-size: 12px;
  margin-left: 22px;
  width: 16px;
  height: 16px;
}

.account-image {
  width: 40px;
  height: 40px;
}

.session--link {
  @apply ml-2.5;
}

.navbar--favorite-icon,
.navbar--compare-icon {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.icon--wrapper {
  display: grid;
  place-items: center;
  height: 44px;
  width: 44px;
  @apply mr-4;
  @apply sm:mr-5;
}

.icon--wrapper ~ .icon--wrapper {
  @apply mr-7;
  @apply sm:mr-5;
}

.usericon--wrapper {
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
}

.usericon--wrapper > div {
  position: relative;
}

.icon--wrapper > div {
  position: relative;
}
</style>
