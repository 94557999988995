import httpClient from "./httpClient";

const SEARCH = "/search/v2";
const SAVE_SEARCH = "/search/saveV2";

function search(
  payload = {},
  page = 1,
  sort = "price",
  orderBy = "asc",
  nElem = 20,
  showReservation = 1
) {
  return httpClient.post(
    `${SEARCH}?numberElements=${nElem}&page=${page}&showReservation=${showReservation}`,
    payload,
    {
      params: {
        sort,
        orderBy
      }
    }
  );
}

function saveSearch(search) {
  return httpClient.post(SAVE_SEARCH, {
    ...search
  });
}

function deleteSearch(id) {
  return httpClient.delete(`${SAVE_SEARCH}?id=${id}`);
}

function getSearches() {
  return httpClient.get(SAVE_SEARCH);
}
function getSimilar() {
  return httpClient.post(SEARCH + "?showReservation=0", {
    headers: { Authorization: "" }
  });
}

export { search, getSearches, saveSearch, deleteSearch, getSimilar };
