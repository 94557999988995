<template>
  <div
    :class="['container--wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="">
      <div class="flex">
        <div class="car--image">
          <img
            :src="
              this.$t('resources.images.pricemodeldetails.cardetail') ||
                '../../../assets/images/detail_car.png'
            "
            alt=""
          />
        </div>
        <div class="title--content">
          <Label
            color="primary"
            bold="bold"
            :size="titleSize"
            :customClass="size === 'medium' ? 'title--content-title' : ''"
          >
            {{ productTitle }}
          </Label>
          <Label
            class="price-model-description"
            color="tertiary"
            :size="smallSize"
            customClass="title--content-subtitle"
          >
            {{ productDescription }}
          </Label>
        </div>
      </div>
    </div>
    <div class="finance--container">
      <Label
        class="finance-model-price"
        color="secondary"
        bold="bold"
        :size="smallSize"
        align="right"
        :customClass="size === 'medium' ? 'finance--container-title' : ''"
      >
        {{ priceLabel }}
      </Label>
      <div class="finance--wrapper">
        <Label
          color="secondary"
          class="modality--margin"
          :size="smallSize"
          align="right"
          customClass="finance--container-subtitle"
        >
          {{ modality }}</Label
        >
        <Tooltip :tooltip="tooltipDescription">
          <template v-slot:content>
            <Icon
              class="icon--margin"
              :name="iconName"
              size="x-small"
              color="gray"
            ></Icon>
          </template>
        </Tooltip>
      </div>
      <Label
        class="taeg--margin"
        color="secondary"
        bold="semibold"
        :size="smallSize"
        align="right"
        :customClass="
          size === 'medium'
            ? 'finance--container-subtitle'
            : 'finance-model-taeg'
        "
      >
        {{ financeTaeg }}
      </Label>
    </div>
  </div>
</template>

<script>
import { Label, Tooltip, Icon } from "../../atoms";

export default {
  name: "PriceModelDetailsRightSidebar",
  components: { Label, Tooltip, Icon },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: "../../../assets/images/detail_car.png"
    },
    size: {
      type: String,
      default: "small",
      validator: value => {
        return ["small", "medium"].indexOf(value) !== -1;
      }
    },
    productTitle: {
      type: String,
      default: ""
    },
    productDescription: {
      type: String,
      default: ""
    },
    priceLabel: {
      type: String,
      default: ""
    },
    financeTaeg: {
      type: String,
      default: ""
    },
    modality: {
      type: String,
      default: ""
    },
    iconName: {
      type: String,
      default: ""
    },
    tooltipDescription: {
      type: String,
      default: ""
    }
  },
  computed: {
    classes() {
      return {
        ...this.customClass
      };
    },
    titleSize() {
      return this.size === "medium"
        ? "medium"
        : this.size === "small"
        ? "small"
        : "";
    },

    smallSize() {
      return this.size === "medium"
        ? "small"
        : this.size === "small"
        ? "x-small"
        : "";
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>

<style lang="css" scoped src="./PriceModelDetailsRightSidebar.css"></style>
