<template>
  <SavedCard
    @onClick="onSearch"
    @onDelete="onDelete"
    labelFooterButton="Pesquisar Viaturas"
  >
    <template v-slot:header>
      <div class="saved-search--title">
        <Label size="medium" color="primary">{{ search.title }}</Label>
      </div>
      <div class="saved-search--date">
        <Icon color="tertiary" name="calendar-alt" iconPrefix="fas"></Icon>
        <Label class="date" color="tertiary" size="small"
          >Guardada na {{ search.createTime }}</Label
        >
      </div>
    </template>
    <template v-slot:footer>
      <div class="saved-search--tags-tags">
        <div v-for="(tag, index) in search.tags" :key="index">
          <Tag
            customClass="tag"
            type="primary"
            :uppercase="true"
            :label="tag.value"
          ></Tag>
        </div>
      </div>
    </template>
  </SavedCard>
</template>

<script>
import { SavedCard } from "../../organisms";
import { Label, Icon, Tag } from "../../atoms";
export default {
  components: {
    SavedCard,
    Label,
    Icon,
    Tag
  },
  props: {
    search: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onSearch() {
      this.$emit("onSearch", this.search.tags);
    },
    onDelete() {
      this.$emit("onDelete");
    }
  }
};
</script>

<style lang="css" scoped src="./SavedSearch.css"></style>
