<template>
  <div
    :class="['vehicle-specifications', classes, customClass]"
    :styles="customStyles"
  >
    <div class="vehicle-specifications--title">
      <Label bold="normal" color="primary" size="medium">{{ title }}</Label>
    </div>
    <div class="vehicle-specifications--list">
      <div class="vehicle-specifications--left">
        <Specification
          class="vehicle-specifications--item"
          v-for="(item, index) in itemsHead"
          :key="index"
          :title="
            $t(`pages.detailsPage.details.vehicleSpecifications.${item.title}`)
          "
          :specification="parseDesc(item.title, item.specification)"
          :checked="item.checked"
        ></Specification>
      </div>
      <div class="vehicle-specifications--right">
        <Specification
          class="vehicle-specifications--item"
          v-for="(item, index) in itemsTail"
          :key="index"
          :title="
            $t(`pages.detailsPage.details.vehicleSpecifications.${item.title}`)
          "
          :specification="parseDesc(item.title, item.specification)"
          :checked="item.checked"
        ></Specification>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { Specification } from "../../molecules";

const SPECS_WITH_UNITS = ["power", "consumption"];
export default {
  name: "VehicleSpecifications",
  components: {
    Label,
    Specification
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    specificationsList: {
      type: Array[Object],
      default: () => {
        return [];
      }
    }
  },
  computed: {
    itemsLength() {
      return Number((this.specificationsList.length / 2).toFixed(0));
    },
    itemsHead() {
      return this.specificationsList
        ? this.specificationsList.slice(0, this.itemsLength)
        : [];
    },
    itemsTail() {
      return this.specificationsList
        ? this.specificationsList.slice(this.itemsLength)
        : [];
    },
    classes() {
      return {};
    },
    customStyles() {
      return { ...this.styles };
    }
  },
  methods: {
    parseDesc(title, desc) {
      var obj = {};
      obj[title] = desc;
      if (title.toUpperCase() === "ORIGIN")
        return parseInt(desc)
          ? this.$t(`pages.detailsPage.details.vehicleSpecifications.imported`)
          : this.$t(`pages.detailsPage.details.vehicleSpecifications.national`);
      return SPECS_WITH_UNITS.includes(title)
        ? this.$t(
            `pages.detailsPage.details.vehicleSpecifications.units.${title}`,
            obj
          )
        : desc;
    }
  }
};
</script>

<style lang="css" scoped src="./VehicleSpecifications.css"></style>
