<template>
  <div>
    <FormStep :step="3" :validate="validate" />
    <PriceDetails :vehicle="selectedVehicle" />
    <div class="pt-4 book-vehicle--title">
      <Label class="reserve--header" size="medium" color="primary"
        >Dados de Faturação</Label
      >
    </div>
    <div class="mt-2">
      <CpInput
        ref="nif"
        :label="$t('placeholder.nif')"
        :value="getReceipt.nif"
        :validator="validateNif"
      />
    </div>
    <div class="mt-4">
      <CpInput
        ref="address"
        :label="$t('placeholder.address')"
        :value="getReceipt.address"
        :validator="validateAddress"
      />
    </div>
    <div class="mt-4">
      <div class="postal-code-location">
        <div class="postal-code">
          <CpZipCode
            ref="zipCode"
            :value="form.zipCode"
            @onChange="handleZipCode"
          />
        </div>
        <div class="ml-4 location">
          <CpInput
            ref="location"
            :label="$t('placeholder.location')"
            :value="getReceipt.location"
            :validator="validateLocation"
          />
        </div>
      </div>
    </div>
    <div class="pt-4 book-vehicle--title">
      <Label class="reserve--header" size="medium" color="primary"
        >Escolha um concessionário para intermediar o negócio</Label
      >
    </div>
    <ConcessionPicker
      ref="dealer"
      @onDealer="onDealer"
      showIsland
      v-if="selectedVehicle.conditionId === 1"
    />
    <ConcessionPicker
      ref="dealer"
      @onDealer="onDealer"
      isUsed
      :districtProp="selectedVehicle.dealerDistrict"
      :dealerId="selectedVehicle.dealerId"
      showIsland
      v-else
    />
    <div class="flex flex-col mt-4">
      <Label color="tertiary" bold="thin" size="small" v-if="errs.dealer">{{
        errs.dealer
      }}</Label>
      <Label color="tertiary" bold="thin" size="small" v-if="errs.district">{{
        errs.district
      }}</Label>
    </div>
  </div>
</template>

<script>
import { FormStep } from "../../../molecules";
import { PriceDetails, ConcessionPicker } from "../../../organisms";
import { Label } from "../../../atoms";
import CpInput from "../../../refactor/CpInput.vue";
import CpZipCode from "../../../refactor/CpZipCode.vue";
import { mapGetters } from "vuex";
import LABEL_TYPE from "../../../../utils/labelType";
import formvalidator from "../../../../utils/formValidator";
import { FOOTPRINT_TYPES, registerEvent } from "../../../../api/footprint";

export default {
  name: "PriceFormStep",
  components: {
    FormStep,
    PriceDetails,
    Label,
    ConcessionPicker,
    CpInput,
    CpZipCode
  },
  props: {
    selectedVehicle: {
      type: Object
    }
  },
  data() {
    return {
      errs: {},
      form: {
        ...this.getReceipt
      },
      dealer: {
        district: "",
        dealer: ""
      }
    };
  },
  methods: {
    handleZipCode(e) {
      this.form.zipCode = e;
      this.setReceipt({ zipCode: e });
    },
    setReceipt(newValue) {
      this.$store.commit("setReceipt", { ...this.getReceipt, ...newValue });
    },
    validateAddress(e) {
      this.form.address = e;
      this.setReceipt({ address: e });
      if (!e) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.required")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateLocation(e) {
      this.form.location = e;
      this.setReceipt({ location: e });
      if (!e) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.required")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateNif(e) {
      this.form.nif = e;
      this.setReceipt({ nif: e });
      const re = /^\d{9}$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.nif")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validate() {
      const refs = [
        {
          ref: "nif",
          value: this.form.nif
        },
        {
          ref: "address",
          value: this.form.address
        },
        {
          ref: "location",
          value: this.form.location
        },
        {
          ref: "zipCode",
          nested: true,
          value: this.form.zipCode
        }
      ];

      const [dealerErr, districtErr] = this.$refs.dealer.triggerRefs({
        dealer: this.form.dealerCode,
        district: this.form.district
      });

      const errs = formvalidator(refs, this.$refs);
      if (errs.length > 0 || !dealerErr || !districtErr) {
        return false;
      }

      this.$store.dispatch("storeReceipt", this.form);

      const { dealerCode, district } = this.dealer;
      this.$store.dispatch("updateDealer", {
        dealerCode,
        district
      });

      const result = {
        info: { ...this.form },
        vin: this.getSelectedVehicle?.vin
      };
      registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_3, result);

      return true;
    },
    onDealer(d) {
      this.dealer = d;
      this.form = {
        ...this.getReceipt,
        ...this.form,
        ...d
      };
    }
  },
  computed: {
    ...mapGetters(["getReceipt", "getSelectedVehicle"])
  }
};
</script>

<style scoped src="./styles.css"></style>
