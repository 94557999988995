<template>
  <CpInput
    ref="inputRef"
    :value="value"
    :label="label"
    :validator="validator"
  />
</template>

<script>
import LABEL_TYPE from "../../utils/labelType";
import CpInput from "./CpInput.vue";

export default {
  name: "CpLicensePlate",
  components: {
    CpInput
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  methods: {
    validator(e) {
      const re = /(([A-Za-z]{2}\d{2}(\d{2}|[A-Za-z]{2}))|(\d{2}(\d{2}[A-Za-z]{2}|[A-Za-z]{2}\d{2})))$/gm;
      const valid = re.test(e);
      this.$emit("onChange", [e, valid]);
      if (e.length < 6) {
        return [LABEL_TYPE.INFO, this.$t("labels.info.licenseplate")];
      }
      if (valid) {
        this.$emit("onValid");
        return [LABEL_TYPE.SUCCESS, ""];
      }
      return [LABEL_TYPE.ERROR, this.$t("labels.errors.licenseplate")];
    }
  }
};
</script>
