<template>
  <div class="rate-cards">
    <div class="rate-cards--left">
      <RateCard
        :title="titleLeft"
        :filename="filenameLeft"
        :description="descriptionLeft"
        :buttonLabel="buttonLabelLeft"
        @onClick="onClickLeft"
      />
    </div>
    <!-- <div class="rate-cards--center"></div>
    <div class="rate-cards--right">
      <RateCard
        :title="titleRight"
        :filename="filenameRight"
        :description="descriptionRight"
        :buttonLabel="buttonLabelRight"
        @onClick="onClickRight"
      />
    </div> -->
  </div>
</template>

<script>
import { RateCard } from "../../organisms";
export default {
  name: "RateCards",
  components: {
    RateCard
  },
  props: {
    titleLeft: {
      type: String,
      default: "Title"
    },
    filenameLeft: {
      type: String,
      default: "car.jpg"
    },
    descriptionLeft: {
      type: String,
      default: "Description"
    },
    buttonLabelLeft: {
      type: String,
      default: "Button"
    },
    titleRight: {
      type: String,
      default: "Title"
    },
    filenameRight: {
      type: String,
      default: "hand.jpg"
    },
    descriptionRight: {
      type: String,
      default: "Description"
    },
    buttonLabelRight: {
      type: String,
      default: "Button"
    }
  },
  methods: {
    onClickLeft() {
      this.$emit("onClick", "left");
    },
    onClickRight() {
      this.$emit("onClick", "right");
    }
  }
};
</script>

<style lang="css" scoped src="./RateCards.css"></style>
