<template>
  <Panel :open="isOpenReservePanel" @onClose="closeReservePanel">
    <template slot="header">
      <Label class="header--title" size="medium" color="primary" bold="bold">{{
        title
      }}</Label>
    </template>

    <template slot="content">
      <ReserveIntro
        @onTestDrive="reserveTestDrive"
        @onContactRequest="onContactRequest"
        @onReserve="startReservation"
        @onContactVideoRequest="onContactVideoRequest"
        v-if="getReserveStatus === RESERVE_STATUS.START"
        :vehicle="getSelectedVehicle"
      />
      <ReserveTestDrive
        :vehicle="vehicle"
        :selected-vehicle="getSelectedVehicle"
        :back="back"
        @onBack="onBack"
        @onSubmit="onTestDriveSubmit"
        @onSubmitLead="onSubmitLead"
        v-if="
          getReserveStatus === RESERVE_STATUS.TEST_DRIVE ||
            getReserveStatus == RESERVE_STATUS.CONTACT ||
            getReserveStatus == RESERVE_STATUS.CONTACT_VIDEO
        "
      />
      <ReserveMultiStep
        :selected-vehicle="getSelectedVehicle"
        @onSubmit="formSubmitted"
        v-if="getReserveStatus === RESERVE_STATUS.IN_PROGRESS"
      />
      <ReserveComplete
        v-if="getReserveStatus === RESERVE_STATUS.DONE"
        @onClickButton="onClickButtonHandler"
        :title="completeTitle"
        :withFinancing="getWithFinancing"
        :withRetake="isRetakeSubmitted"
        :description="completeDescription"
        :labelButton="labelButton"
        :contactType="contactType"
      />
    </template>
  </Panel>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { Panel } from "../../molecules";
import { Label } from "../../atoms";

import ReserveTestDrive from "./ReserveTestDrive.vue";
import ReserveIntro from "./ReserveIntro.vue";
import ReserveMultiStep from "./ReserveMultiStep.vue";
import ReserveComplete from "./ReserveComplete.vue";

import { RESERVE_STATUS } from "../../../enums/reserveStatus.js";
import { PAGES } from "../../../enums/pages.js";

import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

import {
  pushNewEvent,
  GOOGLE_TAG_MANAGER_EVENT_TYPES
} from "../../../utils/GoogleTagManager";

import {
  reserveTestDrive,
  reserveContact,
  reserveContactVideo
} from "../../../api/bookVehicle.js";

export default {
  name: "ReservePanel",
  components: {
    Panel,
    ReserveIntro,
    ReserveMultiStep,
    ReserveComplete,
    ReserveTestDrive,
    Label
  },
  props: {
    back: {
      type: Boolean,
      default: true
    },
    vehicle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      RESERVE_STATUS,
      completeTitle: this.$t("panels.reserve.steps.4.completeTitle"),
      completeDescription: this.$t(
        "panels.reserve.steps.4.completeDescription"
      ),
      labelButton: this.$t("panels.reserve.steps.4.button"),
      reserveTitle: "",
      redirectPageName: PAGES.RESERVATIONS,
      contactType: false,
      footprintCloseLabel: "ReservePanel Closed"
    };
  },
  computed: {
    ...mapState(["isOpenReservePanel"]),
    ...mapGetters([
      "getSelectedVehicle",
      "getModel",
      "getIncludeRetake",
      "getWithFinancing",
      "getIsRetakeSubmitted",
      "getReserveStatus"
    ]),
    isRetakeSubmitted() {
      if (this.getIncludeRetake) {
        return !this.getIsRetakeSubmitted;
      }
      return false;
    },
    title() {
      const initialTitle = this.getWithFinancing
        ? this.$t("panels.reserve.steps.1.title1")
        : this.$t("panels.reserve.steps.1.titlePurchase");
      switch (this.getReserveStatus) {
        case RESERVE_STATUS.START:
          return (
            initialTitle +
            " " +
            this.getModel +
            " " +
            this.$t("panels.reserve.steps.1.title2")
          );
        case RESERVE_STATUS.TEST_DRIVE:
          return this.$t("panels.testdrive.steps.1.title");
        case RESERVE_STATUS.CONTACT:
          return this.$t("panels.contact.steps.1.title");
        case RESERVE_STATUS.CONTACT_VIDEO:
          return this.$t("panels.contactvideo.steps.1.title");
        case RESERVE_STATUS.IN_PROGRESS:
          return (
            initialTitle +
            " " +
            this.getModel +
            " " +
            this.$t("panels.reserve.steps.1.title2")
          );
        case RESERVE_STATUS.DONE:
          return "";
        default:
          return "";
      }
    }
  },
  methods: {
    closeReservePanel(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      // TODO: always revert?
      const currentStatus = this.getReserveStatus;

      this.$store.commit("setReserveStatus", RESERVE_STATUS.START);
      this.$store.state.isOpenReservePanel = false;

      this.closePanelFootprint();

      // emit tag manager event only if inside reserve steps
      if (currentStatus === RESERVE_STATUS.IN_PROGRESS) {
        pushNewEvent(
          GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_REMOVE_FROM_CART,
          PAGES.DETAILS,
          this.getSelectedVehicle
        );
      }
    },
    formSubmitted() {
      this.$store.commit("setReserveStatus", RESERVE_STATUS.DONE);
    },
    startReservation() {
      this.contactType = false;
      this.completeTitle = this.$t("panels.reserve.steps.4.completeTitle");
      this.completeDescription = this.$t(
        "panels.reserve.steps.4.completeDescription"
      );
      this.$store.commit("setReserveStatus", RESERVE_STATUS.IN_PROGRESS);

      pushNewEvent(
        GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_ADD_TO_CARD,
        PAGES.DETAILS,
        this.getSelectedVehicle
      );
    },
    reserveTestDrive() {
      this.completeTitle = this.$t("panels.testdrive.steps.4.completeTitle");
      this.$store.commit("setReserveStatus", RESERVE_STATUS.TEST_DRIVE);
      this.contactType = true;
    },
    onContactRequest() {
      this.completeTitle = this.$t("panels.contact.steps.4.completeTitle");
      this.$store.commit("setReserveStatus", RESERVE_STATUS.CONTACT);
      this.contactType = true;
    },
    onContactVideoRequest() {
      this.completeTitle = this.$t("panels.contactvideo.steps.4.completeTitle");
      this.$store.commit("setReserveStatus", RESERVE_STATUS.CONTACT_VIDEO);
      this.contactType = true;
    },
    onBack() {
      this.$store.commit("setReserveStatus", RESERVE_STATUS.START);
    },
    onTestDriveSubmit() {
      this.completeDescription = "";
      this.labelButton = this.$t("panels.testdrive.steps.4.button");
      this.redirectPageName = PAGES.SEARCH;
      this.$store.commit("setReserveStatus", RESERVE_STATUS.DONE);
    },
    onClickButtonHandler() {
      this.closeReservePanel();
      this.$router.push({
        name: this.redirectPageName
      });
    },
    async onSubmitLead(leadInfo) {
      this.contactType = true;
      this.completeTitle =
        this.getReserveStatus === RESERVE_STATUS.TEST_DRIVE
          ? this.$t("panels.testdrive.steps.4.completeTitle")
          : this.getReserveStatus === RESERVE_STATUS.CONTACT
          ? this.$t("panels.contact.steps.4.completeTitle")
          : this.$t("panels.contactvideo.steps.4.completeTitle");
      this.$store.commit("spinnerModule/setLoading", true, { root: true });
      const leadCall =
        this.getReserveStatus === RESERVE_STATUS.TEST_DRIVE
          ? reserveTestDrive
          : this.getReserveStatus === RESERVE_STATUS.CONTACT
          ? reserveContact
          : reserveContactVideo;
      try {
        const response = await leadCall(leadInfo);

        if (response.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event:
              this.getReserveStatus === RESERVE_STATUS.TEST_DRIVE
                ? "eec.testeDrive"
                : this.getReserveStatus === RESERVE_STATUS.CONTACT
                ? "eec.contactoInformacao"
                : "eec.contactoVideo",
            model: this.getModel
          });
          this.$toast.open({
            message: "Pedido enviado com sucesso",
            dismissible: true,
            type: "info"
          });
          this.onTestDriveSubmit();
        } else {
          this.$toast.open({
            message:
              "Lamento mas ocorreu um erro a efetuar o seu pedido, por favor tente novamente mais tarde",
            dismissible: true,
            type: "error"
          });
        }
      } catch (error) {
        this.$toast.open({
          message:
            "Lamento mas ocorreu um erro a efetuar o seu pedido, por favor tente novamente mais tarde",
          dismissible: true,
          type: "error"
        });
        console.error(error);
        if (error.response && error.response.status === 401) {
          //redirect to homepage.
          localStorage.removeItem("token");
          this.$router.push({ name: PAGES.HOME });
        }
      }
      this.$store.commit("spinnerModule/setLoading", false, { root: true });
    },
    closePanelFootprint() {
      const reserveDetails = {
        year: this.getSelectedVehicle?.year,
        model: this.getSelectedVehicle?.model,
        commercialDescription: this.getSelectedVehicle?.commercialDescription,
        fuel: this.getSelectedVehicle?.fuel,
        transmission: this.getSelectedVehicle?.transmission,
        pricePvp: this.getSelectedVehicle?.pricePvp,
        promoPrice: this.getSelectedVehicle?.promoPrice,
        vin: this.getSelectedVehicle?.vin,
        taeg: this.getSelectedVehicle?.taeg,
        monthlyPrice: this.getSelectedVehicle?.monthlyPrice,
        financeId: this.getSelectedVehicle?.financeId,
        totalPrice: this.getSelectedVehicle?.totalPrice,
        reserveType: this.getSelectedVehicle?.reserveType,
        brand: this.getSelectedVehicle?.brand,
        power: this.getSelectedVehicle?.power,
        seats: this.getSelectedVehicle?.seats,
        kilometers: this.getSelectedVehicle?.kilometers
      };

      const footprintEvent = {
        from: this.footprintCloseLabel,
        reserveDetails: reserveDetails
      };

      registerEvent(FOOTPRINT_TYPES.CLOSE_RESERVE_PANEL, footprintEvent);
    }
  }
};
</script>

<style scoped>
:deep(.panel--header) {
  margin: 1rem;
  margin-top: 30px;
  margin-left: 2rem;
  @apply font-head;
}

:deep(.header--title) {
  font-size: 24px;
}

:deep(.panel--content) {
  /* padding: 2rem; */
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

:deep(.title--content) .label {
  font-size: 21px;
}

:deep(.title--content) .price-model-description {
  @apply text-base;
}

:deep(.finance--container) .label {
  font-size: 24px;
  line-height: 24px;
}

:deep(.finance--container) .finance--wrapper .label {
  @apply text-base;
  line-height: 16px;
}

:deep(.finance--container) .taeg--margin {
  @apply text-base;
  line-height: 16px;
}

:deep(.selectCustom-options) {
  max-height: 200px;
  @apply overflow-y-auto;
  @apply z-50;
}

:deep(.finance-model-price.finance--container-title) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-modality.finance--container-subtitle) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg.finance--container-subtitle) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg.taeg--margin) {
  margin-top: 0;
}

:deep(.price-model.title--content-title) {
  font-size: 21px !important;
  line-height: 37px !important;
}

@media (max-width: 1440px) {
  :deep(.finance-model-price.finance--container-title) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-modality.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-taeg.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
}

:deep(.price-model-description.title--content-subtitle) {
  font-size: 16px !important;
  line-height: 20px !important;
}
</style>
