<template>
  <div class="search-segment-monthly">
    <div class="search-segment-monthly--monthly">
      <Label size="small" color="primary">{{
        $t("pages.homepage.sections.hero.SearchSegmentMonthly.typeOfVehicle")
      }}</Label>
      <div class="search-segment-monthly--monthly-radio">
        <RadioButtonLabel
          class="mr-8"
          :title="$t('pages.homepage.sections.hero.SearchSegmentMonthly.new')"
          radioId="new"
          radioName="carType"
          radioValue="new"
          :radioChecked="true"
          :matchColor="true"
          radioColor="primary"
          checked-color="secondary"
          @onChange="onChangeCarType"
        ></RadioButtonLabel>
        <RadioButtonLabel
          :title="$t('pages.homepage.sections.hero.SearchSegmentMonthly.used')"
          radioId="used"
          radioName="carType"
          radioValue="used"
          radioColor="primary"
          checked-color="secondary"
          :matchColor="true"
          @onChange="onChangeCarType"
        ></RadioButtonLabel>
      </div>
    </div>
    <div class="search-segment-monthly--segment">
      <Label size="small" color="primary"
        >{{ $t("pages.homepage.sections.hero.SearchSegmentMonthly.title") }}
        <span class="info">{{
          $t("pages.homepage.sections.hero.SearchSegmentMonthly.info")
        }}</span></Label
      >
      <div class="search-segment-monthly--segment-segments">
        <div class="normal-segment">
          <Segment
            v-for="(segment, index) in segmentList"
            :key="segment.title + index"
            :title="segment.title"
            :id="segment.type"
            :filename="segment.filename"
            @onChange="() => onChangeSegment(segment)"
          ></Segment>
        </div>
        <div class="swiper-segment">
          <Swiper
            customClass="experience-swiper"
            :spaceBetween="0"
            :pagination="false"
            freeMode
          >
            <SwiperSlide :key="index" v-for="(segment, index) in segmentList">
              <Segment
                :title="segment.title"
                :id="segment.type"
                :filename="segment.filename"
                @onChange="() => onChangeSegment(segment)"
              ></Segment>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
    <div class="search-segment-monthly--monthly">
      <Label size="small" color="primary">{{
        $t("pages.homepage.sections.hero.SearchSegmentMonthly.budget")
      }}</Label>
      <div class="search-segment-monthly--monthly-radio">
        <RadioButtonLabel
          class="mr-8"
          :title="
            $t('pages.homepage.sections.hero.SearchSegmentMonthly.monthly')
          "
          radioId="monthlyAmount"
          radioName="monthly"
          radioValue="monthlyAmount"
          radioColor="primary"
          checked-color="secondary"
          :matchColor="true"
          :radioChecked="true"
          @onChange="onChangeMonthly"
        ></RadioButtonLabel>
        <RadioButtonLabel
          :title="
            $t('pages.homepage.sections.hero.SearchSegmentMonthly.finalPrice')
          "
          radioId="finalPrice"
          radioName="monthly"
          radioValue="finalPrice"
          radioColor="primary"
          checked-color="secondary"
          :matchColor="true"
          @onChange="onChangeMonthly"
        ></RadioButtonLabel>
      </div>
      <div
        v-if="monthlyType === 'monthlyAmount'"
        class="search-segment-monthly--monthly-inputs"
      >
        <InputForm
          class="monthly-amount-input"
          bgColor="#F5F7F9"
          type="primary"
          inputType="number"
          :label="monthlyLabel"
          ref="monthlyAmount-input"
          @onChange="e => onChangeMonthlyAmountHandler(Number(e), 'month')"
          @keyup.enter.native="search"
        ></InputForm>

        <Dropdown
          class="initial-entry-dropdown"
          id="initial-entry"
          :uppercase="false"
          :title="
            $t(
              'pages.homepage.sections.hero.SearchSegmentMonthly.initialEntryPlaceholder',
              { initialEntry: this.dropdownInitialEntry }
            )
          "
          bgColor="white"
          @close="closeModal"
        >
          <div class="initial-entry">
            <RangeInput
              :label="
                $t(
                  'pages.homepage.sections.hero.SearchSegmentMonthly.initialEntrySlide'
                )
              "
              sliderType="secondary"
              :sliderValue="initialEntry"
              :sliderMax="maxInitialEntry"
              :sliderMin="minInitialEntry"
              :sliderInterval="500"
              @onChange="
                e => onChangeMonthlyAmountHandler(Number(e), 'deposit')
              "
            />
          </div>
        </Dropdown>
      </div>
      <div
        v-if="monthlyType === 'finalPrice'"
        class="search-segment-monthly--monthly-inputs"
      >
        <InputForm
          class="w-full"
          bgColor="#F5F7F9"
          type="primary"
          inputType="number"
          :label="
            $t(
              'pages.homepage.sections.hero.SearchSegmentMonthly.finalPricePlaceholder'
            )
          "
          ref="finalPrice-input"
          @onChange="onChangeFinalPriceHandler"
          @keyup.enter.native="search"
        ></InputForm>
      </div>
    </div>
    <div class="search-segment-monthly--button">
      <Button
        type="primary"
        :label="labelButtonHandler"
        :fill="true"
        uppercase
        @onClick="search"
      ></Button>
    </div>
  </div>
</template>

<script>
import { Label, Button, SwiperSlide } from "../../atoms";
import {
  Segment,
  RadioButtonLabel,
  InputForm,
  Dropdown,
  RangeInput,
  Swiper
} from "../../molecules";
import { PAGES } from "@/enums/pages";
import { mapGetters } from "vuex";

import { getPriceFormatted } from "../../../utils/currency";

export default {
  name: "SearchSegmentMonthly",
  components: {
    Label,
    Segment,
    RadioButtonLabel,
    InputForm,
    Button,
    Dropdown,
    RangeInput,
    SwiperSlide,
    Swiper
  },
  data() {
    return {
      tags: [],
      monthlyType: "monthlyAmount",
      monthlyAmount: 0,
      initialEntry: 500,
      maxInitialEntry: 12000,
      minInitialEntry: 500,
      stepInitialEntry: 500,
      initialEntryChanged: false,
      finalPrice: 0,
      carType: "new"
    };
  },
  methods: {
    closeModal() {},
    onChangeSegment({ title, id }) {
      const value = title == "SUV" ? "Suv" : title;
      const tag = {
        text: value,
        value,
        type: "segment",
        id
      };
      this.$store.dispatch("SearchModule/filter/addOrRemoveTag", tag);
    },
    onChangeMonthly(event) {
      this.monthlyType = event;

      if (event === "monthlyAmount") {
        this.$nextTick(function() {
          this.$refs["monthlyAmount-input"].value = null;
        });
      } else if (event === "finalPrice") {
        this.initialEntryChanged = false;
        this.$nextTick(function() {
          this.$refs["finalPrice-input"].value = null;
        });
      }

      this.finalPrice = 0;
      this.monthlyAmount = 0;
      this.initialEntry = 500;
    },
    onChangeCarType(event) {
      this.carType = event;
      this.$store.dispatch("SearchModule/dropdown/fetchDropdowns", {
        conditionId: event == "new" ? 1 : 2
      });
      this.$store.dispatch("SearchModule/filter/cleanAllTags");
    },
    search() {
      if (this.carType == "new") {
        this.$router.push({
          name: PAGES.SEARCH
        });
      } else {
        this.$router.push({
          name: PAGES.SEARCH_USED
        });
      }
    },
    onChangeMonthlyAmountHandler(event, input) {
      if (input == "month") this.monthlyAmount = event;
      if (input == "deposit") {
        this.initialEntryChanged = true;
        this.initialEntry = event;
      }
      const value = this.$t("pages.searchpage.searchform.monthlyDeposit", {
        value1: getPriceFormatted(this.monthlyAmount),
        value2: getPriceFormatted(this.initialEntry)
      });
      this.$store.dispatch("SearchModule/filter/removeTagByType", "price");
      const tag = {
        text: value,
        value,
        type: "monthlyDeposit",
        id: {
          monthlyPayment: `${this.monthlyAmount}`,
          depositValue: `${this.initialEntry}`
        }
      };
      this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
    },
    onChangeFinalPriceHandler(event) {
      const minPrice = 0;
      const value = this.$t("pages.searchpage.searchform.price", {
        value1: getPriceFormatted(minPrice),
        value2: getPriceFormatted(event)
      });
      this.$store.dispatch(
        "SearchModule/filter/removeTagByType",
        "monthlyDeposit"
      );
      this.$store.dispatch("SearchModule/filter/addOrReplaceTag", {
        id: { priceInf: `${minPrice}`, priceSup: `${event}` },
        value,
        text: value,
        type: "price"
      });
    },
    segmentToFileName(segment) {
      switch (segment) {
        case "suv":
        case "suv / tt":
          return "suv";
        case "familiar":
        case "carrinha":
          return "familiar";
        case "utilitário":
        case "comercial":
        case "citadino":
          return "utility";
        case "desportivo":
        case "hatchback":
          return "sporty";
        case "ecológico":
          return "ecological";
        default:
          return "none";
      }
    }
  },
  computed: {
    ...mapGetters({
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown",
      getTags: "SearchModule/filter/getTags",
      getDropdowns: "SearchModule/dropdown/getDropdowns"
    }),
    segmentList() {
      return this.getDropdowns["segment"]
        .map(segment => {
          const segmentImage = this.segmentToFileName(
            segment.value.toLowerCase()
          );
          return {
            title: segment.value,
            type: segment.value,
            filename: "segments/" + segmentImage + ".svg",
            id: segment.key
          };
        })
        .filter(segment => segment.filename !== "segments/none.svg");
    },

    labelButtonHandler() {
      if (this.monthlyType === "monthlyAmount") {
        if (
          this.monthlyAmount === 0 &&
          !this.initialEntryChanged &&
          !this.tags.length > 0
        ) {
          return this.$t(
            "pages.homepage.sections.hero.SearchSegmentMonthly.buttonSearchAllVehicles"
          );
        } else {
          return this.$t(
            "pages.homepage.sections.hero.SearchSegmentMonthly.buttonStartSearch"
          );
        }
      } else if (this.monthlyType === "finalPrice") {
        if (this.finalPrice === 0 && !this.tags.length > 0) {
          return this.$t(
            "pages.homepage.sections.hero.SearchSegmentMonthly.buttonSearchAllVehicles"
          );
        } else {
          return this.$t(
            "pages.homepage.sections.hero.SearchSegmentMonthly.buttonStartSearch"
          );
        }
      } else {
        return this.$t(
          "pages.homepage.sections.hero.SearchSegmentMonthly.buttonSearchAllVehicles"
        );
      }
    },
    dropdownInitialEntry() {
      return this.initialEntryChanged
        ? getPriceFormatted(this.initialEntry)
        : "";
    },
    monthlyLabel() {
      return `${this.$t(
        "pages.homepage.sections.hero.SearchSegmentMonthly.monthlyPlaceholder"
      )}`;
    }
  },
  mounted() {
    this.monthlyAmount = Math.ceil(this.getFinanceDropdown.monthlyMax);
    this.initialEntry = Math.floor(this.getFinanceDropdown.depositMin);
    this.maxInitialEntry = Math.floor(this.getFinanceDropdown.depositMax);
    this.minInitialEntry = Math.floor(this.getFinanceDropdown.depositMin);
    this.stepInitialEntry = Math.floor(this.getFinanceDropdown.depositStep);
    this.$store.dispatch("SearchModule/dropdown/fetchDropdowns", {
      conditionId: 1
    });
  }
};
</script>

<style lang="css" scoped src="./SearchSegmentMonthly.css"></style>
