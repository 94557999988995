<template>
  <label :class="['toggle--switch', customClass]" :style="customStyles">
    <input
      type="checkbox"
      @change="onToggle"
      :checked="checked"
      :disabled="disabled"
    />
    <span :class="['toggle--slider', classes, wrapperClasses]"></span>
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "primary",
      validator: type => {
        return ["primary", "secondary", "success"].includes(type);
      }
    }
  },
  computed: {
    classes() {
      return {
        "toggle--rounded": this.rounded,
        "toggle--primary": this.type === "primary",
        "toggle--success": this.type === "success",
        "toggle--secondary": this.type === "secondary"
      };
    },
    wrapperClasses() {
      return {
        "toggle--disabled": this.disabled
      };
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  methods: {
    onToggle(event) {
      this.$emit("onToggle", event.target.checked);
    }
  }
};
</script>
<style lang="css" scoped src="./Toggle.css"></style>
