const CARPLUS_PAGE_SIZE = 20;

const state = {
  current: 1,
  pages: 0,
  results: 0,
  nextPage: 0,
  prevPage: 0
};

const getters = {
  getCurrent: state => state.current,
  getPages: state => state.pages,
  getResults: state => state.results,
  getNext: state => state.nextPage,
  getPrev: state => state.prevPage
};

const mutations = {
  setCurrent(state, val) {
    state.current = val;
  },
  setPages(state, val) {
    state.pages = val;
  },
  setResults(state, val) {
    state.results = val;
  },
  setPagination(state, pagination) {
    const { totalResults, currentPage, next, prev } = pagination;
    state.results = totalResults;
    state.current = currentPage;
    state.pages = currentPage + CARPLUS_PAGE_SIZE - 1;
    state.nextPage = next;
    state.prevPage = prev;
  }
};

const actions = {
  resetPagination({ commit }) {
    commit("setPagination", {
      totalResults: 0,
      currentPage: 1,
      next: 0,
      prev: 0
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
