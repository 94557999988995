<template>
  <Section
    id="faqs-home-component"
    class="faqs-home"
    :bgColor="!retake ? '#F5F7F9' : '#fff'"
  >
    <div class="faqs-home--left" v-if="title != '' || subtitle != ''">
      <div class="faqs-home--left-title" v-if="title != ''">
        <Label size="medium" color="primary" bold="bold">{{ title }}</Label>
      </div>
      <div class="faqs-home--left-subtitle" v-if="subtitle != ''">
        <Label size="small" color="tertiary">{{ subtitle }}</Label>
      </div>
    </div>
    <div class="faqs-home--right">
      <Accordion>
        <template slot="items">
          <AccordionItem
            v-for="(accordion, index) in accordionList"
            :key="index"
            type="secondary"
            :accordionIndex="index"
            :activeIndex="activeIndex"
            @onToggle="onToggleAccordionHandler"
          >
            <template slot="accordion-trigger">
              <Heading
                class="h3-title"
                :label="accordion.title"
                tag="h3"
                weight="regular"
              />
            </template>
            <template slot="accordion-content">
              <Heading
                class="content"
                :label="accordion.content"
                tag="h4"
                weight="regular"
              />
            </template>
          </AccordionItem>
        </template>
      </Accordion>
    </div>
  </Section>
</template>

<script>
import { Section, Label, AccordionItem } from "../../atoms";
import { Accordion } from "../../molecules";
import { Heading } from "@sc/ds-ui-component-library";
export default {
  components: {
    Section,
    Label,
    Accordion,
    AccordionItem,
    Heading
  },
  data() {
    return {
      activeIndex: -1
    };
  },
  props: {
    accordionList: {
      type: Array[Object]
    },
    retake: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClickSearch() {
      this.$emit("onSearch");
    },
    // eslint-disable-next-line no-unused-vars
    onToggleAccordionHandler(event) {
      const eventData = this.accordionList[event.index];

      this.activeIndex === event.index
        ? (this.activeIndex = -1)
        : ((this.activeIndex = event.index),
          this.$emit("onActiveFootprint", eventData));
    }
  }
};
</script>

<style lang="css" scoped src="./FaqsHome.css"></style>
