import { SEGMENTS } from "../enums/segments";

export const SEO_ROUTES = [
  {
    path: "/cars/suvs",
    name: "SearchPageSuvs",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: {
      tags: [{ value: SEGMENTS.SUV, type: "segment", text: SEGMENTS.SUV }]
    }
  },
  {
    path: "/cars/desportivos",
    name: "SearchPageSports",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: {
      tags: [{ value: SEGMENTS.SPORT, type: "segment", text: SEGMENTS.SPORT }]
    }
  },
  {
    path: "/cars/familiares",
    name: "SearchPageFamily",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: {
      tags: [{ value: SEGMENTS.FAMILY, type: "segment", text: SEGMENTS.FAMILY }]
    }
  },
  {
    path: "/cars/ecologicos",
    name: "SearchPageEco",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: {
      tags: [
        { value: SEGMENTS.ECOLOGIC, type: "segment", text: SEGMENTS.ECOLOGIC }
      ]
    }
  }
];
