<template>
  <div class="comparator">
    <div class="comparator--header">
      <div class="comparator--header-title">
        <Label color="primary" bold="bold">{{
          $t("pages.ComparatorPage.title")
        }}</Label>
      </div>
      <div class="comparator--header-subtitle">
        <Label size="small" color="tertiary" bold="normal">{{
          $t("pages.ComparatorPage.subtitle", {
            number: compareCount
          })
        }}</Label>
      </div>
      <div class="comparator--header-button">
        <Button
          v-if="!empty"
          :label="differencesButtonLabel"
          uppercase
          type="primary"
          @onClick="clickShowDifferences"
        ></Button>
      </div>
    </div>
    <div class="comparator--cars">
      <div
        :class="comparatorCarCard(v.conditionId)"
        v-for="v in compareList"
        :key="v.vin"
      >
        <SearchVehicleResultCard :vehicle="v" v-if="v.conditionId == 1" />
        <UsedSearchVehicleResultCard :vehicle="v" v-else />
        <Button
          :label="$t('pages.ComparatorPage.buttons.knowMore')"
          uppercase
          type="primary"
          :fill="true"
          @onClick="clickInterest(v)"
        ></Button>
        <div v-if="v">
          <div v-if="!showDifferences" class="comparator--cars-specifications">
            <div class="comparator--specifications-title">
              <Label color="primary">{{
                $t("pages.ComparatorPage.specifications")
              }}</Label>
            </div>
            <div
              class="comparator--specifications-list-item"
              v-for="(specification, s) in v.specifications"
              :key="s"
            >
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="primary"
                >
                  {{
                    $t(
                      "pages.detailsPage.details.vehicleSpecifications." +
                        specification.title
                    )
                  }}</Label
                >
              </div>
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="tertiary"
                  >{{
                    parseDesc(specification.title, specification.description)
                  }}</Label
                >
              </div>
            </div>
          </div>
          <div v-if="!showDifferences" class="comparator--cars-equipments">
            <div class="comparator--specifications-title">
              <Label color="primary">{{
                $t("pages.ComparatorPage.equipments")
              }}</Label>
            </div>
            <div
              class="comparator--specifications-list-item"
              v-for="(equipment, e) in v.equipments"
              :key="e"
            >
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="tertiary"
                >
                  · {{ equipment }}</Label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="showDifferences && v.specificationsDifferences.length > 0"
          class="comparator--cars-specifications"
        >
          <div class="comparator--specifications-title">
            <Label color="primary">{{
              $t("pages.ComparatorPage.specifications")
            }}</Label>
          </div>
          <div
            class="comparator--specifications-list-item"
            v-for="(specification, s) in v.specificationsDifferences"
            :key="s"
          >
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="primary"
              >
                {{
                  $t(
                    "pages.detailsPage.details.vehicleSpecifications." +
                      specification.title
                  )
                }}</Label
              >
            </div>
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="tertiary"
                >{{
                  parseDesc(specification.title, specification.description)
                }}</Label
              >
            </div>
          </div>
        </div>
        <div
          v-if="showDifferences && v.equipmentsDifferences.length > 0"
          class="comparator--cars-equipments"
        >
          <div class="comparator--specifications-title">
            <Label color="primary">{{
              $t("pages.ComparatorPage.equipments")
            }}</Label>
          </div>
          <div
            class="comparator--specifications-list-item"
            v-for="(equipment, e) in v.equipmentsDifferences"
            :key="e"
          >
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="tertiary"
              >
                · {{ equipment }}</Label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../atoms";
import {
  SearchVehicleResultCard,
  UsedSearchVehicleResultCard
} from "../../organisms";
import { getPriceFormatted } from "../../../utils/currency.js";
import { PAGES } from "../../../enums/pages";
const SPECS_WITH_UNITS = ["power", "consumption"];

export default {
  name: "Comparator",
  components: {
    Label,
    Button,
    SearchVehicleResultCard,
    UsedSearchVehicleResultCard
  },
  data() {
    return {
      showDifferences: false
    };
  },
  metaInfo() {
    return {
      title: this.$t("meta.comparatorPage.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.comparatorPage.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.comparatorPage.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.comparatorPage.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.comparatorPage.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.comparatorPage.image")
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      empty: "CompareModule/empty",
      compareCount: "CompareModule/count",
      compareList: "CompareModule/list",
      numberOfDifferences: "CompareModule/numberOfDifferences"
    }),
    differencesButtonLabel() {
      return this.showDifferences
        ? this.$t("pages.ComparatorPage.buttons.showAll")
        : this.$tc(
            "pages.ComparatorPage.buttons.showDifferences",
            this.numberOfDifferences,
            {
              numberOfDifferences: this.numberOfDifferences
            }
          );
    }
  },
  methods: {
    getPriceFormatted,
    clickShowDifferences() {
      this.showDifferences = !this.showDifferences;

      if (this.showDifferences) {
        const element = document.querySelector(
          ".comparator--cars-specifications"
        );
        element?.scrollIntoView();
      }
    },
    clickInterest(vehicle) {
      this.$router.push({
        name: PAGES.DETAILS,
        query: {
          fid: vehicle?.financeId
        },
        params: {
          id: vehicle?.vin
        }
      });

      this.$store.dispatch("storeVehicle", vehicle);
    },
    parseDesc(title, desc) {
      var obj = {};
      obj[title] = desc;
      return SPECS_WITH_UNITS.includes(title)
        ? this.$t(
            `pages.detailsPage.details.vehicleSpecifications.units.${title}`,
            obj
          )
        : desc;
    },
    comparatorCarCard(conditionId) {
      return conditionId === 1
        ? "comparator--cars-car"
        : ["comparator--cars-car", "comparator--cars-car-used"];
    }
  }
};
</script>

<style lang="css" scoped src="./Comparator.css"></style>
