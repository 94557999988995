<template>
  <div class="valuation-advantages">
    <div class="valuation-advantages--title">
      <Label color="primary" uppercase>{{
        $t("pages.retake.step3.valuationAdvantages.title")
      }}</Label>
    </div>
    <div class="valuation-advantages--list">
      <ul class="grid gap-2 mt-4">
        <li
          class="valuation-advantages--list-item"
          v-for="(_, idx) in advantagesIdx"
          :key="idx"
        >
          <div class="u-icon icon" />
          <Label class="advantage" size="small" color="primary">
            {{ $t(`pages.retake.step3.valuationAdvantages.item${idx + 1}`) }}
          </Label>
        </li>
      </ul>
    </div>
    <div class="valuation-advantages--footer" v-if="false">
      <div class="valuation-advantages--footer-description">
        <Label color="primary">{{
          $t("pages.retake.step3.valuationAdvantages.footer")
        }}</Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  name: "ValuationAdvantages",
  components: {
    Label
  },
  data() {
    return {
      advantagesIdx: [1, 1, 1]
    };
  }
};
</script>

<style lang="css" scoped src="./ValuationAdvantages.css"></style>
