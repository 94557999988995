<template>
  <div class="wrapper">
    <div
      :class="['container', customClass, disabled ? 'disabled' : '', validated]"
    >
      <input
        onfocus="this.removeAttribute('readonly')"
        readonly
        v-model="input"
        @blur="handleBlur"
        @focus="showDialog = true"
        :type="showPass"
        @input="handleInput"
        @focusout="showDialog = false"
        required
      />
      <label>{{ $t("labels.password") }}</label>
      <CpIcon class="input--icon" :icon="icon" @click="handleClick" />
    </div>
    <div v-if="showDialog" class="info-block">
      <span class="title">{{ $t("labels.password-explanation") }}</span>
      <div :key="rule" class="flex" v-for="rule in rules">
        <div
          class="rule--icon"
          :class="validIconClasses[convertToContain(rule)]"
        />
        <span :class="validClasses[convertToContain(rule)]" class="rule">{{
          $t("labels.rules.password." + rule)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CpIcon from "./CpIcon.vue";
import { validateClasses, convertToContain } from "../../utils/funcs";

export default {
  name: "CpPassword",
  components: {
    CpIcon
  },
  props: {
    customClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      convertToContain,
      rules: ["size", "upper", "lower", "digit", "special"],
      showDialog: false,
      input: "",
      icon: "eyeSlash",
      showPass: "password",
      validated: "",
      validClasses: {
        hasSize: "",
        hasUpper: "",
        hasLower: "",
        hasDigit: "",
        hasSpecial: ""
      },
      validIconClasses: {
        hasSize: "",
        hasUpper: "",
        hasLower: "",
        hasDigit: "",
        hasSpecial: ""
      }
    };
  },
  methods: {
    handleClick() {
      this.icon = this.icon === "eyeSlash" ? "eye" : "eyeSlash";
      this.showPass = this.showPass === "password" ? "text" : "password";
    },
    validateInput(str) {
      return {
        hasSize: str?.length >= 8 && str?.length <= 14,
        hasUpper: /[A-Z]/.test(str),
        hasLower: /[a-z]/.test(str),
        hasDigit: /\d/.test(str),
        hasSpecial: /[- *_$!?.:;,#]/.test(str)
      };
    },
    handleValidationsAndReturn(str, valid, invalid, validIcon, invalidIcon) {
      const validations = this.validateInput(str);
      const total = Object.keys(validations).every(k => validations[k]);
      this.validClasses = validateClasses(
        this.validClasses,
        validations,
        valid,
        invalid
      );
      this.validIconClasses = validateClasses(
        this.validIconClasses,
        validations,
        validIcon,
        invalidIcon
      );
      return [validations, total];
    },
    handleBlur() {
      const [validations, total] = this.handleValidationsAndReturn(
        this.input,
        "valid",
        "invalid",
        "valid--icon",
        "invalid--icon"
      );
      console.log(validations);
      this.$emit("onValidation", [this.input, total]);
      if (total) {
        return (this.showDialog = false);
      }
      this.validated = "error-label";
    },
    handleInput(e) {
      const [validations, total] = this.handleValidationsAndReturn(
        e.target.value,
        "valid",
        "",
        "valid--icon",
        ""
      );
      console.log("Password validations", validations);
      this.$emit("onChange", e.target.value);
      if (e.target.value?.length < 8) {
        this.validated = "info-label";
        return false;
      }
      if (!total) {
        this.validated = "error-label";
        return false;
      }
      this.validated = "success-label";
      return true;
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: auto;
  background-color: #f5f7f9;
}

input,
label {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

input,
textarea {
  background-color: transparent;
}

input {
  height: 56px;
  padding-left: 16px;
  padding-top: 28px;
  padding-bottom: 12px;
  outline: none;
  color: #002c5f;
  width: 100%;
}

label {
  color: #8c909d;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

input:focus ~ label,
input:valid ~ label {
  top: 10px;
  font-size: 14px;
  line-height: 14px;
}

.container:hover,
.container:focus-within {
  box-shadow: inset 0px -2px 0px #02226f;
}

.input--icon {
  margin-right: 4px;
}

.error-label,
.error-label:hover,
.error-label:focus-within {
  box-shadow: inset 0px -2px 0px #e63312;
}

.warning-label,
.warning-label:hover,
.warning-label:focus-within {
  box-shadow: inset 0px -2px 0px #ffa300;
}
.info-label,
.info-label:hover,
.info-label:focus-within {
  box-shadow: inset 0px -2px 0px #00aad2;
}
E6 .success-label,
.success-label:hover,
.success-label:focus-within {
  box-shadow: inset 0px -2px 0px #43a047;
}
.info-block {
  z-index: 100;
  position: absolute;
  margin-top: 62px;
  border: 1px solid #eaeaea;
  background-color: white;
  background-size: cover;
  background-position: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 17px;
  width: 100%;
}

.info-block .title {
  color: #032c5f;
  font-size: 14px;
  line-height: 18px;
}

.rule {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #848484;
}

.rule--icon {
  background-image: url("../../assets/images/checkGray.svg");
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-size: contain;
  background-position: center;
}

.valid--icon {
  background-image: url("../../assets/images/checkSuccess.svg");
}

.valid {
  color: #43a047;
}

.invalid {
  color: #e63312;
}

.invalid--icon {
  background-image: url("../../assets/images/closeError.svg");
}
</style>
