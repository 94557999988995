<template>
  <SavedCard
    :labelFooterButton="
      $t('pages.personalPage.reservations.savedReservation.labelFooterButton')
    "
    :showButton="false"
    @onClick="onClick"
  >
    <template slot="header">
      <div class="header--container">
        <div class="car-img--container">
          <img
            class="car-img"
            :src="img"
            :alt="'vehicle-image-' + vehicle.model"
          />
        </div>
        <PriceModelDetails
          class="mb-6"
          :missingActions="missingActionsRetake || missingActionsPreApproval"
          :vehicle="vehicle"
          icon-name="info-circle"
          :absolutePrice="!vehicle.pricePvp"
          tooltipPosition="left"
          :product-title="vehicle.model"
          :product-description="vehicle.commercialDescription"
          :tooltip-description="tooltipDescription"
          :price-label="formattedPrice"
          :modality="formattedMonthlyPrice"
          :finance-taeg="formattedTaeg"
        />
      </div>
    </template>
    <template
      slot="footer"
      v-if="preApprovalStatus !== -1 && isPreApprovalSubmitted"
    >
      <div class="footer--container">
        <Label customClass="card-footer" color="tertiary" size="small"
          >{{
            $t(
              "pages.personalPage.reservations.savedReservation.PreApprovalFooter"
            )
          }}
          <Label customClass="card-footer" color="primary" size="small">
            {{
              $t(
                `pages.personalPage.reservations.savedReservation.PreApprovalStatus[${preApprovalStatus}]`
              )
            }}</Label
          ><Tooltip
            v-if="preApprovalStatus === 3"
            class="tooltip-footer"
            :tooltip="
              $t(
                'pages.personalPage.reservations.savedReservation.tooltipDescription'
              )
            "
          >
            <template v-slot:content>
              <Icon
                class="icon--margin"
                name="info-circle"
                size="x-small"
                color="gray"
              ></Icon>
            </template> </Tooltip
        ></Label>
      </div>
    </template>
  </SavedCard>
</template>

<script>
import { SavedCard } from "../../../organisms";
import { Label, Tooltip, Icon } from "../../../atoms";
import { PriceModelDetails } from "../../../molecules";
import { getPriceFormatted } from "../../../../utils/currency.js";
import { generateTooltipDescription } from "../../../../utils/tooltip";

export default {
  name: "SavedReservation",
  components: {
    SavedCard,
    PriceModelDetails,
    Label,
    Tooltip,
    Icon
  },
  props: {
    vehicle: {
      type: Object,
      default: () => ({})
    },
    preApprovalStatus: {
      type: Number,
      default: -1
    },
    isPreApprovalSubmitted: {
      type: Boolean,
      default: false
    },
    retake: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  computed: {
    missingActionsRetake() {
      return this.retake.plate && !this.retake.submitted;
    },
    missingActionsPreApproval() {
      return (
        !!this.vehicle?.carDetailFinance?.capitalToFinance &&
        !this.isPreApprovalSubmitted
      );
    },
    img() {
      return (
        this.vehicle?.imageUrl ||
        require("../../../../assets/images/placeholder.jpg")
      );
    },
    tooltipDescription() {
      return this.vehicle?.legalInfo || this.vehicle?.tooltipDescription
        ? generateTooltipDescription({
            ...this.vehicle?.tooltipDescription,
            pricePvp: this.vehicle?.pricePvp,
            commercialDescription: this.vehicle?.commercialDescription
          })
        : "";
    },
    formattedPrice() {
      const price = this.vehicle?.pricePvp || this.vehicle?.totalPrice;
      return getPriceFormatted(price, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    formattedTaeg() {
      return "TAEG: " + Number(this.vehicle?.taeg).toFixed(1) + " %";
    },
    formattedMonthlyPrice() {
      return Number(this.vehicle?.monthlyPrice) + " €";
    }
  }
};
</script>

<style scoped>
.footer--container {
  @apply flex;
}

.card-footer {
  font-size: 18px;
  line-height: 21px;
}

.tooltip-footer {
  @apply ml-1;
}

.header--container {
  display: flex;
  align-items: center;
}

.car-img {
  object-fit: contain;
  object-position: center;
  width: 170px;
  height: 110px;
}

.car-img--container {
  display: flex;
  margin-right: 1rem;
}

:deep(.price-model) {
  font-size: 21px;
}

:deep(.price-model-description) {
  font-size: 16px;
  @apply font-head;
  @apply font-normal;
}

:deep(.finance-model-price) {
  @apply font-head;
  @apply font-bold;
  font-size: 24px;
}

:deep(.finance-model-modality),
:deep(.finance-model-taeg) {
  @apply font-head;
  @apply font-medium;
  font-size: 16px;
}

:deep(.finance-model-price) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-modality) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

@media (max-width: 1440px) {
  :deep(.finance-model-price.finance--container-title) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-modality.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-taeg.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
}

:deep(.finance--wrapper) {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  :deep(.header--container) {
    flex-direction: column;
  }

  :deep(.car-img--container) {
    margin: 0 0 20px 0;
    width: 100%;
  }

  :deep(.car-img--container) img {
    margin: 0px;
    width: 100%;
  }
}
</style>
