<template>
  <input
    :disabled="disabled"
    type="radio"
    :id="id"
    :class="['input', customClass, classes]"
    :style="customStyles"
    :name="name"
    :value="value"
    @change="onChangeHandler"
    :checked="checked"
  />
</template>

<script>
export default {
  name: "RadioButton",
  data() {
    return {
      radioChecked: this.checked
    };
  },
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary",
      validator: value => {
        return ["primary", "secondary", "tertiary"].includes(value);
      }
    },
    size: {
      type: String,
      default: "medium",
      validator: value => {
        return ["x-small", "small", "medium", "large"].includes(value);
      }
    },
    checkedColor: {
      type: String,
      required: false,
      default: null
    },
    circular: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    matchColor: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onChangeHandler(event) {
      this.$emit("onChange", event.target.value);
    },
    externalChange() {
      if (!this.radioChecked) {
        this.radioChecked = true;
        this.$emit("onChange", this.value);
      }
    }
  },
  computed: {
    classes() {
      return {
        "radio--size-x-small": this.size === "x-small",
        "radio--size-small": this.size === "small",
        "radio--size-medium": this.size === "medium",
        "radio--size-large": this.size === "large",
        "radio--color-primary": this.color === "primary" && this.matchColor,
        "radio--color-secondary": this.color === "secondary" && this.matchColor,
        "radio--color-tertiary": this.color === "tertiary" && this.matchColor,
        "radio--color-primary--only-checked":
          this.color === "primary" && !this.matchColor,
        "radio--color-secondary--only-checked":
          this.color === "secondary" && !this.matchColor,
        "radio--color-tertiary--only-checked":
          this.color === "tertiary" && !this.matchColor,
        "radio--circle": this.circular,
        "radio--disable": this.disable,
        [this.checkedColorClass]: this.checkedColor
      };
    },
    checkedColorClass() {
      if (this.checkedColor) {
        return `radio--color-${this.checkedColor}--checked`;
      } else {
        return ""; // Return an empty string if checkedColor is not provided
      }
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  mounted() {
    this.radioChecked = this.checked;
  }
};
</script>
<style lang="css" scoped src="./RadioButton.css"></style>
