<template>
  <div class="padding-container">
    <MultiStepForm
      @onSubmit="submitForm"
      :numberSteps="numberSteps"
      @onChange="s => (step = s)"
      :prevLabel="$t('panels.reserve.prevLabel')"
      :nextLabel="$t('panels.reserve.nextLabel')"
      :submitLabel="$t('panels.reserve.submitLabel')"
    >
      <transition name="fade">
        <DetailsFormStep
          :selected-vehicle="selectedVehicle"
          v-if="step === 1"
        />
        <PersonalFormStep
          :selected-vehicle="selectedVehicle"
          v-if="step === 2"
        />
        <PriceFormStep :selected-vehicle="selectedVehicle" v-if="step === 3" />
        <PaymentFormStep
          :selected-vehicle="selectedVehicle"
          v-if="step === 4"
        />
      </transition>
    </MultiStepForm>
  </div>
</template>

<script>
import { MultiStepForm } from "../../organisms";
import DetailsFormStep from "./steps/DetailsFormStep.vue";
import PersonalFormStep from "./steps/PersonalFormStep.vue";
import PriceFormStep from "./steps/PriceFormStep.vue";
import PaymentFormStep from "./steps/PaymentFormStep.vue";

export default {
  name: "ReserveMultiStep",
  components: {
    DetailsFormStep,
    PersonalFormStep,
    PriceFormStep,
    PaymentFormStep,
    MultiStepForm
  },
  data() {
    return {
      numberSteps: 4,
      step: 1
    };
  },
  props: {
    selectedVehicle: {
      type: Object
    }
  },
  methods: {
    submitForm() {
      // FIXME: deal with backend and vuex
      this.$emit("onSubmit");
    }
  }
};
</script>

<style scoped src="./steps/styles.css"></style>
