<template>
  <div class="my-8">
    <Label class="title" size="medium" color="primary" uppercase>{{
      $t("panels.reserve.steps.1.advantages.title")
    }}</Label>
    <ul class="grid gap-2 mt-4">
      <li class="flex" v-for="(_, idx) in advantagesIdx" :key="idx">
        <div class="icon u-icon" />
        <Label class="advantage" size="small" color="primary">
          {{ $t(`panels.reserve.steps.1.advantages.list[${idx + 1}]`) }}
        </Label>
      </li>
    </ul>
  </div>
</template>

<script>
import { Label } from "../../atoms";

export default {
  name: "AdvantageList",
  components: { Label },
  data() {
    return {
      advantagesIdx: [1, 1, 1]
    };
  }
};
</script>

<style lang="css" scoped src="./AdvantageList.css"></style>
