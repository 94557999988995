<template>
  <label :class="['checkbox--container', customClass]" :style="styles">
    <input
      type="checkbox"
      :checked="currentChecked"
      @change="onChangeHandler"
      @click="fillCheckbox"
      :disabled="disabled"
    />
    <span :class="['checkbox--checkmark', classes]"></span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    circular: {
      type: Boolean,
      default: false,
      required: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "primary",
      validator: type => {
        return ["primary", "secondary", "success", "tertiary"].includes(type);
      }
    },
    fill: {
      type: Boolean,
      required: false,
      default: false
    },
    fillOnChecked: {
      type: Boolean,
      required: false,
      default: false
    },
    shadow: {
      type: String,
      default: "none",
      validator: shadow => {
        return ["none", "sm", "normal", "md", "lg", "xl", "2xl"].includes(
          shadow
        );
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFilled: this.fill
    };
  },
  methods: {
    onChangeHandler(event) {
      this.currentChecked = event.target.checked;
      this.$emit("onChange", event.target.checked);
    },
    fillCheckbox(event) {
      if (this.fillOnChecked) {
        this.isFilled = event.target.checked;
      }
    }
  },
  computed: {
    classes() {
      return {
        "checkbox--primary": this.type === "primary",
        "checkbox--secondary": this.type === "secondary",
        "checkbox--success": this.type === "success",
        "checkbox--tertiary": this.type === "tertiary",
        "checkbox--bg-success border--white":
          this.isFilled && this.type === "success",

        "checkbox--bg-primary border--white":
          this.isFilled && this.type === "primary",
        "checkbox--bg-secondary border--white":
          this.isFilled && this.type === "secondary",
        "checkbox--circular": this.circular,
        "checkbox--disabled": this.disabled,
        "checkbox--shadow-sm": this.shadow === "sm",
        "checkbox--shadow-normal": this.shadow === "normal",
        "checkbox--shadow-md": this.shadow === "md",
        "checkbox--shadow-lg": this.shadow === "lg",
        "checkbox--shadow-xl": this.shadow === "xl",
        "checkbox--shadow-2xl": this.shadow === "2xl"
      };
    },
    currentChecked: {
      get() {
        return this.checked;
      },
      set(checked) {
        this.$emit("update:checked", checked);
      }
    }
  }
};
</script>

<style lang="css" scoped src="./Checkbox.css"></style>
