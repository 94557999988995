<template>
  <ul
    class=""
    :class="['accordion', classes, customClass]"
    :styles="customStyles"
  >
    <slot name="items"></slot>
  </ul>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return { ...this.styles };
    }
  }
};
</script>

<style lang="css" scoped src="./Accordion.css"></style>
