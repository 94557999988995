<template>
  <div class="choose-type-financing">
    <div class="choose-type-financing--title">
      <Label size="small">{{ title }}</Label>
      <Link
        color="secondary"
        :label="linkLabel"
        @onClick="
          e => {
            e.preventDefault();
            openFinancingExplanation = true;
          }
        "
      />
      <Popup
        id="financing-explanation"
        v-show="openFinancingExplanation"
        :closeButton="true"
        :closeOnClickOutside="true"
        @close="openFinancingExplanation = false"
        customClass="z-30"
        :title="$t('popup.financeExplanation.title')"
      >
        <template slot="body" class="finantial-explanation--wrapper">
          <div class="finantial-explanation--body">
            <div class="section">
              <p>
                {{ $t("popup.financeExplanation.description") }}
              </p>
            </div>

            <div class="section">
              <div class="finance-explanation-container">
                <img
                  :src="
                    this.$t('resources.images.financepopup.financetodrive') ||
                      require(`../../../assets/images/finance-popup/finance-to-drive.png`)
                  "
                  width="65px"
                  height="55px"
                />
                <span style="padding-left: 3px">{{
                  $t("popup.financeExplanation.section[1].title")
                }}</span>
              </div>
              <p>
                {{ $t("popup.financeExplanation.section[1].content") }}
              </p>
            </div>

            <div class="section" v-if="hasFinancialContribution">
              <div class="finance-explanation-container">
                <img
                  :src="
                    this.$t('resources.images.financepopup.opendrive') ||
                      require(`../../../assets/images/finance-popup/open-drive.png`)
                  "
                  width="40px"
                  height="40px"
                />
                <span>{{
                  $t("popup.financeExplanation.section[2].title")
                }}</span>
              </div>
              <p>
                {{ $t("popup.financeExplanation.section[2].content") }}
              </p>
              <ul>
                <li>{{ $t("popup.financeExplanation.section[2].list[1]") }}</li>
                <li>{{ $t("popup.financeExplanation.section[2].list[2]") }}</li>
              </ul>
            </div>
          </div>
        </template>

        <template slot="footer">
          <div class="footer-btn">
            <div />
            <div />
            <div />
            <Button
              uppercase
              customClass="exist-btn"
              label="Sair"
              type="primary"
              fill
              @onClick="openFinancingExplanation = false"
            />
          </div>
        </template>
      </Popup>
    </div>
    <form>
      <div class="choose-type-financing--options">
        <div class="choose-type-financing--options-option1">
          <RadioButtonLabel
            :title="radioButtonLabel1"
            :subtitle="descriptionRadioButton1"
            :radioId="idRadioButton1"
            radioSize="small"
            radioColor="secondary"
            :radioName="nameGroup"
            radioCircular
            :radioValue="valueRadioButton1"
            titleColor="primary"
            subtitleColor="tertiary"
            :radioChecked="selected === valueRadioButton1"
            border
            @onChange="onChange"
          />
        </div>
        <div
          class="choose-type-financing--options-option2"
          v-if="hasFinancialContribution"
        >
          <RadioButtonLabel
            :title="radioButtonLabel2"
            :subtitle="descriptionRadioButton2"
            :radioId="idRadioButton2"
            radioSize="small"
            radioColor="secondary"
            :radioName="nameGroup"
            radioCircular
            :radioValue="valueRadioButton2"
            titleColor="primary"
            subtitleColor="tertiary"
            :radioChecked="selected === valueRadioButton2"
            border
            @onChange="onChange"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Label, Link, Popup } from "../../atoms";
import Button from "../../atoms/Button/Button.vue";
import { RadioButtonLabel } from "../../molecules";
export default {
  name: "ChooseTypeFinancing",
  components: {
    Label,
    Link,
    Popup,
    RadioButtonLabel,
    Button
  },
  data() {
    return {
      openFinancingExplanation: false,
      // nullRef: "javascript:void(0);"
      nullRef: "#"
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    linkLabel: {
      type: String,
      default: ""
    },
    linkURL: {
      type: String,
      default: ""
    },
    radioButtonLabel1: {
      type: String,
      default: ""
    },
    idRadioButton1: {
      type: String,
      default: ""
    },
    valueRadioButton1: {
      type: String,
      default: ""
    },
    descriptionRadioButton1: {
      type: String,
      default: ""
    },
    radioButtonLabel2: {
      type: String,
      default: ""
    },
    idRadioButton2: {
      type: String,
      default: ""
    },
    valueRadioButton2: {
      type: String,
      default: ""
    },
    descriptionRadioButton2: {
      type: String,
      default: ""
    },
    nameGroup: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      required: true
    },
    hasFinancialContribution: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event);
    }
  }
};
</script>

<style lang="css" scoped src="./ChooseTypeFinancing.css"></style>
