var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.prodBaseAuth)?_c('BaseAuth',{on:{"onValidate":_vm.clearBaseAuth}}):_vm._e(),_c('Navbar',{attrs:{"id":"navbar-page"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticStyle:{"width":"180px","height":"auto"}},[_c('img',{attrs:{"src":_vm.appLogo}})])])]},proxy:true},{key:"center",fn:function(){return [_c('LinkList',{attrs:{"list":_vm.list,"bold":"medium","display":"horizontal","size":"small","uppercase":true},on:{"onClick":_vm.closeMobileMenu}})]},proxy:true},{key:"right-1",fn:function(){return [_c('div',{staticClass:"actions-area flex flex-row justify-center sm:justify-start items-center"},[_c('a',{staticClass:"icon--wrapper",on:{"click":_vm.openCompare}},[_c('div',[(_vm.compareCount > 0)?_c('span',{staticClass:"notify-badge"},[_vm._v(" "+_vm._s(_vm.compareCount)+" ")]):_vm._e(),_c('div',{staticClass:"navbar--compare-icon",style:({
                backgroundImage: `url(${_vm.compareIcon})`
              }),on:{"click":_vm.openCompare}})])]),_c('a',{staticClass:"icon--wrapper"},[_c('div',[(_vm.favoritesCount > 0)?_c('span',{staticClass:"notify-badge"},[_vm._v(" "+_vm._s(_vm.favoritesCount)+" ")]):_vm._e(),_c('div',{staticClass:"navbar--favorite-icon",style:({
                backgroundImage: `url(${_vm.favoriteIcon})`
              }),on:{"click":_vm.openFavoritePanel}})])])])]},proxy:true},{key:"right-2",fn:function(){return [_c('div',{staticClass:"session-area flex flex-row justify-center sm:justify-start items-center"},[_c('a',{staticClass:"usericon--wrapper"},[_c('div',{staticClass:"account-image",style:({
              backgroundImage: `url(${_vm.userIcon})`
            })})]),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getToken || _vm.isGuest),expression:"!getToken || isGuest"}],attrs:{"label":_vm.$t('navbar.session.login'),"href":"#","size":"small","bold":"medium","color":"secondary","customClass":"session--link"},on:{"onClick":_vm.openLoginForm}}),_c('SessionDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.getToken && !_vm.isGuest),expression:"getToken && !isGuest"}],attrs:{"title":_vm.getWelcomeUser,"menuList":_vm.personalList},on:{"onLogout":_vm.onLogoutHandler}})],1)]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{key:_vm.$route.path})],1),_c('Footer',{attrs:{"id":"footer-page","copyright-message":_vm.footerMessage,"footer-list":_vm.footerList,"subfooterList":[
      { title: 'Termos e Condições', url: '/termos-e-condicoes' },
      { title: 'Política de Cookies', url: '/politica-de-cookies' },
      { title: 'Política de Privacidade', url: '/politica-de-privacidade' }
    ]}}),(_vm.renderComponent)?_c('RegisterForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.openPopup),expression:"openPopup"}],attrs:{"passwordSent":_vm.passwordSent,"isLogin":_vm.getIsOpenSigninPopup},on:{"close":_vm.closeAuthenticationForm,"onRegister":_vm.onRegisterHandler,"onLogin":_vm.onLoginHandler,"onLoginMyHyundai":_vm.onLoginMyHyundaiHandler,"onFacebookLogin":_vm.onFacebookLoginHandler,"onGoogleLogin":_vm.onGoogleLoginHandler,"onRecoverPassword":_vm.onRecoverPasswordHandler}}):_vm._e(),(_vm.renderComponent)?_c('VerificationAccount',{directives:[{name:"show",rawName:"v-show",value:(_vm.getIsOpenVerificationAccount),expression:"getIsOpenVerificationAccount"}],attrs:{"email":_vm.user.email,"verifiedAccount":_vm.verifiedAccount},on:{"onSendCode":_vm.onSendCodeHandler,"onResendCode":_vm.onResendCodeHandler,"close":_vm.closeVerificationAccountHandler}}):_vm._e(),_c('ReservePanel',{key:_vm.panelKey,attrs:{"contact":_vm.reserveContact,"back":_vm.reserveBack,"vehicle":_vm.reserveVehicle}}),_c('ComparePanel'),_c('FavoritePanel'),_c('Spinner',{attrs:{"loading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }