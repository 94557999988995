var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('Section',{attrs:{"id":"hero"}},[_c('HomeMainSection',{attrs:{"text-title":_vm.$t('pages.homepage.sections.hero.title'),"text-subtitle":_vm.$t('pages.homepage.sections.hero.subtitle'),"text-button-left":"Pesquisar viatura","text-button-right":"Valorizar Retoma","separator":"ou","slideshow":_vm.slideshow,"images":_vm.images},on:{"onClickLeft":_vm.onClickSearchVehicles}})],1),_c('Section',{attrs:{"id":"quicklinks"}},[_c('QuickLinks',{staticClass:"quicklink-1",style:(_vm.quicklink1),attrs:{"title":_vm.$t('pages.homepage.sections.quicklinks[0].title'),"description":_vm.$t('pages.homepage.sections.quicklinks[0].description')}},[_c('div',{staticClass:"quicklink-used-new"},[_c('div',[_c('Label',{attrs:{"size":"small","color":"primary"}},[_vm._v(_vm._s(_vm.$t( "pages.homepage.sections.hero.SearchSegmentMonthly.typeOfVehicle" )))]),_c('div',{staticClass:"quicklink-used-new-radio"},[_c('RadioButtonLabel',{staticClass:"mr-8 radio--quicklink",attrs:{"title":_vm.$t('pages.homepage.sections.hero.SearchSegmentMonthly.new'),"radioId":"new","radioName":"carTypeQuickLink","radioValue":"new","radioColor":"primary","matchColor":true,"radioChecked":true,"checked-color":"secondary"},on:{"onChange":_vm.onChangeCarType}}),_c('RadioButtonLabel',{attrs:{"title":_vm.$t('pages.homepage.sections.hero.SearchSegmentMonthly.used'),"radioId":"used","radioName":"carTypeQuickLink","matchColor":true,"radioValue":"used","radioColor":"primary","checked-color":"secondary"},on:{"onChange":_vm.onChangeCarType}})],1)],1),_c('div',{staticClass:"quicklink-autocomplete-row"},[_c('AutocompleteDropdown',{ref:"autocompleteDropdown",staticClass:"autocomplete-input",attrs:{"type":"tertiary","placeholder":_vm.$t(
                  'pages.homepage.sections.quicklinks[0].autocomplete.placeholder'
                ),"results":_vm.getAutoCompleteResults},on:{"onSearch":_vm.onStoreResults,"onEnterKey":_vm.autocompleteSearch,"onSelect":_vm.autocompleteSearch,"onChange":value => {
                  _vm.mySearch = value;
                }}}),_c('Button',{staticClass:"autocomplete-button",attrs:{"type":"primary","fill":"","uppercase":"","size":"small","icon":"search","border":false},on:{"onClick":() =>
                  _vm.autocompleteSearch({
                    type: 'needle',
                    value: _vm.mySearch,
                    id: 0
                  })}})],1)])]),_c('QuickLinks',{staticClass:"quicklink-2",style:(_vm.quicklink2),attrs:{"title":_vm.$t('pages.homepage.sections.quicklinks[1].title'),"description":_vm.$t('pages.homepage.sections.quicklinks[1].description')}},[_c('Button',{staticStyle:{"height":"60px"},attrs:{"type":"primary","fill":"","uppercase":"","label":_vm.$t('pages.homepage.sections.quicklinks[1].button')},on:{"onClick":_vm.onClickRetake}})],1),_c('QuickLinks',{staticClass:"quicklink-3",style:(_vm.quicklink3),attrs:{"title":_vm.$t('pages.homepage.sections.quicklinks[2].title'),"description":_vm.$t('pages.homepage.sections.quicklinks[2].description')}},[_c('Button',{staticStyle:{"height":"60px"},attrs:{"type":"primary","uppercase":"","label":_vm.$t('pages.homepage.sections.quicklinks[2].button')},on:{"onClick":_vm.onClickHowItWorks}})],1)],1),_c('Section',{attrs:{"id":"shopping-experience"}},[_c('div',{staticClass:"shopping-experience--header"},[_c('div',{staticClass:"w-full mb-2 shopping-experience--header-title"},[_c('Heading',{staticClass:"h1",attrs:{"label":_vm.$t('pages.homepage.sections.experience.title'),"tag":"h1","weight":"bold"}})],1),_c('div',{staticClass:"w-full shopping-experience--header-subtitle"},[_c('Label',{attrs:{"size":"small","color":"tertiary"}},[_vm._v(_vm._s(_vm.$t("pages.homepage.sections.experience.description")))])],1)]),_c('div',{staticClass:"shopping-experience--content"},[_c('Swiper',{attrs:{"customClass":"experience-swiper","spaceBetween":0,"pagination":false,"freeMode":""}},[_c('SwiperSlide',[_c('div',{staticClass:"w-full mb-6 lg:w-1/4 lg:mb-0"},[_c('ExperiencesCard',{attrs:{"title":"01.","subtitle":_vm.$t(
                    'pages.homepage.sections.experience.experiences[0].title'
                  ),"file":_vm.$t('resources.images.homepage.experiencesSwiperCard1') ||
                    require(`../../../assets/images/experience-1.webp`),"text":_vm.$t(
                    'pages.homepage.sections.experience.experiences[0].description'
                  )}})],1)]),_c('SwiperSlide',[_c('div',{staticClass:"w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0"},[_c('ExperiencesCard',{attrs:{"title":"02.","subtitle":_vm.$t(
                    'pages.homepage.sections.experience.experiences[1].title'
                  ),"file":_vm.$t('resources.images.homepage.experiencesSwiperCard2') ||
                    require(`../../../assets/images/experience-3.webp`),"text":_vm.$t(
                    'pages.homepage.sections.experience.experiences[1].description'
                  )}})],1)]),_c('SwiperSlide',[_c('div',{staticClass:"w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0"},[_c('ExperiencesCard',{attrs:{"title":"03.","subtitle":_vm.$t(
                    'pages.homepage.sections.experience.experiences[2].title'
                  ),"file":_vm.$t('resources.images.homepage.experiencesSwiperCard3') ||
                    require(`../../../assets/images/experience-4.webp`),"text":_vm.$t(
                    'pages.homepage.sections.experience.experiences[2].description'
                  )}})],1)]),_c('SwiperSlide',[_c('div',{staticClass:"w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0"},[_c('ExperiencesCard',{attrs:{"title":"04.","subtitle":_vm.$t(
                    'pages.homepage.sections.experience.experiences[3].title'
                  ),"file":_vm.$t('resources.images.homepage.experiencesSwiperCard4') ||
                    require(`../../../assets/images/experience-2.webp`),"text":_vm.$t(
                    'pages.homepage.sections.experience.experiences[3].description'
                  )}})],1)])],1)],1)]),_c('Section',{attrs:{"id":"separator300"}},[_c('Separator300',{attrs:{"numberVehicles":_vm.getTotalVehicles}})],1),_c('Section',{attrs:{"id":"process"}},[_c('div',{staticClass:"process--content"},[_c('Swiper',{attrs:{"customClass":"experience-swiper","spaceBetween":0,"pagination":false,"navigation":"","freeMode":""}},[_vm._l((_vm.$t(
              'pages.homepage.sections.process.cards'
            )),function(cardWithSegment,index){return _c('SwiperSlide',{key:index},[_c('CardWithSegment',{staticClass:"card-with-segment",attrs:{"filename":cardWithSegment.filename,"title":cardWithSegment.title,"description":cardWithSegment.description},on:{"onSearch":_vm.onSearchCardWithSegment}})],1)}),_c('SwiperSlide',[_c('Card360',{staticClass:"card-with-segment",attrs:{"numberVehicles":_vm.getTotalVehicles},on:{"onSearch":_vm.clickOnSearchNewCar}})],1)],2)],1)]),_c('Section',{attrs:{"id":"slideshow"}},[_c('Slideshow',{attrs:{"images":_vm.$t('pages.homepage.sections.slideshow')}})],1),_c('Section',{attrs:{"id":"faqs-home"}},[_c('FaqsHome',{attrs:{"title":_vm.$t('pages.homepage.sections.faqs.title'),"subtitle":_vm.$t('pages.homepage.sections.faqs.subtitle'),"accordionList":_vm.$t('pages.homepage.sections.faqs.faqs')},on:{"onActiveFootprint":e => _vm.faqsFootprint(e)}})],1),_c('Section',{attrs:{"id":"search-new-car"}},[_c('SearchNewCar',{on:{"onSearch":_vm.clickOnSearchNewCar}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }