import httpClient from "./httpClient";

const VEHICLES = "/vehicle";
const SPECIFICATIONS = "/specifications";
const IMAGES = "/images";
const FULLVIEW = "/fullview";
const COMPARE = VEHICLES + "/compare";
const TOTAL_VEHICLES = VEHICLES + "/count";
const DEALERS = "/dealers";
const SIMILAR = "/vehicle/{vin}/similar";
const companyId = 1;

function getTotalVehicles() {
  return httpClient.get(TOTAL_VEHICLES, { headers: { Authorization: "" } });
}

function getAllVehicles(page) {
  return httpClient.get(VEHICLES + "?page=" + page);
}

function getVehicle(id, fid) {
  return httpClient.get(VEHICLES + "/" + id, { params: { fid } });
}

function getVehicleSpecifications(id) {
  return httpClient.get(VEHICLES + "/" + id + SPECIFICATIONS);
}

function getVehicleImages(id) {
  return httpClient.get(VEHICLES + "/" + id + IMAGES);
}

function getVehicleImagesFullView(id) {
  return httpClient.get(VEHICLES + "/" + id + IMAGES + FULLVIEW);
}

function getVehiclesToCompare(vinsList) {
  const vins = "?vins=" + vinsList.join(",");

  return httpClient.get(COMPARE + vins);
}

function getDealersList() {
  return httpClient.get(DEALERS, {
    headers: {
      companyId: companyId
    }
  });
}

function getSimilar(vin) {
  return httpClient.get(SIMILAR.replace("{vin}", vin));
}

export {
  getAllVehicles,
  getVehicle,
  getVehicleSpecifications,
  getVehicleImages,
  getVehicleImagesFullView,
  getVehiclesToCompare,
  getTotalVehicles,
  getDealersList,
  getSimilar
};
