<template>
  <div class="step-progress">
    <div class="step-progress--title">
      <Label color="tertiary" size="small"
        >Passo {{ currentStep }} de {{ totalSteps }}</Label
      >
    </div>
    <div class="step-progress--progress">
      <progress :max="totalSteps" :value="currentStep"></progress>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  components: {
    Label
  },
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    totalSteps: {
      type: Number,
      default: 4
    }
  }
};
</script>

<style lang="css" scoped src="./StepProgress.css"></style>
