import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import { FOOTPRINT_TYPES, registerEvent } from "../api/footprint";
import { PAGES, META } from "../enums/pages";

import { getUtmParams } from "../utils/helpers";

import { SEO_ROUTES } from "./SEORoutes";
import parseJwt from "../utils/jwt";

Vue.use(VueRouter);

const getUtmParamsOrDefault = utmParams => {
  return Object.keys(utmParams).length === 0
    ? JSON.parse(sessionStorage.getItem("utms")) || {}
    : utmParams;
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../components/pages/HomePage/HomePage.vue"
      )
  },
  {
    path: "/carros-novos",
    alias: "/carros",
    name: "SearchPage",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: true
  },
  {
    path: "/images/:id",
    name: "ImageUUID",
    component: () =>
      import(
        /* webpackChunkName: "image" */ "../components/pages/Images/ImageUUID.vue"
      )
  },
  {
    path: "/carros-novos/*",
    name: "SearchPageCustomRoute",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: true
  },
  {
    path: "/carros-usados",
    alias: "/carros-usados",
    name: "SearchPageUsedCars",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPageUsedCars/SearchPageUsedCars.vue"
      ),
    props: true
  },
  {
    path: "/carros-usados/*",
    name: "SearchPageUsedCarsCustomRoute",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPageUsedCars/SearchPageUsedCars.vue"
      ),
    props: true
  },
  ...SEO_ROUTES,
  {
    path: "/comparador-carros",
    name: "Comparator",
    component: () =>
      import(
        /* webpackChunkName: "comparator" */ "../components/pages/ComparatorPage/ComparatorPage.vue"
      ),
    props: true
  },
  {
    path: "/veiculo/:id",
    name: "DetailPage",
    component: () =>
      import(
        /* webpackChunkName: "detailpage" */ "../components/pages/DetailPage/DetailPage.vue"
      )
  },
  {
    path: "/pessoal",
    name: "Personal",
    component: () =>
      import(
        /* webpackChunkName: "personalpage" */ "../components/pages/PersonalPage/PersonalPage.vue"
      ),
    props: true,
    children: [
      {
        path: "dados-pessoais",
        name: "personal-data",
        component: () =>
          import(
            /* webpackChunkName: "personaldata" */ "../components/pages/PersonalPage/PersonalData/PersonalData.vue"
          )
      },
      {
        path: "reserva",
        name: "reservations",
        component: () =>
          import(
            /* webpackChunkName: "reservations" */ "../components/pages/PersonalPage/Reservations/Reservations.vue"
          )
      },
      {
        path: "reserva/:id",
        name: "ReservationDetails",
        component: () =>
          import(
            /* webpackChunkName: "reservationdetail" */ "../components/pages/PersonalPage/Reservations/ReservationDetails.vue"
          )
      },
      {
        path: "reserva/:id/pre-aprovacao",
        name: "ReservationPreApproval",
        component: () =>
          import(
            /* webpackChunkName: "preapproval" */ "../components/pages/PersonalPage/Reservations/PreApproval.vue"
          )
      },
      {
        path: "pesquisas-guardadas",
        name: "saved-searches",
        component: () =>
          import(
            /* webpackChunkName: "savedsearches" */ "../components/pages/PersonalPage/SavedSearches/SavedSearches.vue"
          ),
        props: true
      },
      {
        path: "retoma",
        name: "returns",
        component: () =>
          import(
            /* webpackChunkName: "returns" */ "../components/pages/PersonalPage/Returns/Returns.vue"
          )
      },
      {
        path: "retoma/:id",
        name: "returnsDetails",
        component: () =>
          import(
            /* webpackChunkName: "returndetail" */ "../components/pages/PersonalPage/ReturnDetails/ReturnDetails.vue"
          )
      }
    ]
  },
  {
    path: "/retoma-carros",
    name: "Retake Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "retake" */ "../components/pages/RetakeEvaluationPage/RetakeEvaluationPage.vue"
      )
  },
  {
    path: "/como-funciona",
    name: "HowItWorksPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/pages/HowItWorksPage/HowItWorksPage.vue"
      )
  },
  {
    path: "/politica-de-cookies",
    name: "cookies-terms",
    component: () =>
      import(
        /* webpackChunkName: "cookiespolicy" */ "../components/pages/CookiePage/CookiePage.vue"
      )
  },
  {
    path: "/termos-e-condicoes",
    name: "terms-conditions",
    component: () =>
      import(
        /* webpackChunkName: "termsconditions" */ "../components/pages/TermsConditionsPage/TermsConditionsPage.vue"
      )
  },
  {
    path: "/politica-de-privacidade",
    name: "Policy",
    component: () =>
      import(
        /* webpackChunkName: "privacypolicy" */ "../components/pages/PolicyPrivacyPage/PolicyPrivacyPage.vue"
      )
  },
  {
    path: "/recuperar-password",
    name: "recover",
    component: () =>
      import(
        /* webpackChunkName: "recoverpassword" */ "../components/pages/RecoverPasswordPage/RecoverPasswordPage.vue"
      )
  },
  {
    path: "/oops",
    alias: "*",
    name: "error",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "../components/pages/ErrorPage/ErrorPage.vue"
      )
  }
];

const searchRoutes = {
  new: "/carros-novos",
  used: "/carros-usados"
};

const router = new VueRouter({
  base: "/",
  mode: "history",
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes
});

router.beforeEach(async (to, from, next) => {
  const { name: fromName, path: fromPath } = from;
  const { name: toName, path: toPath } = to;

  const footprintEvent = {
    from: fromName,
    to: toName
  };

  if (
    fromName === null ||
    Object.keys(store.getters["SearchModule/dropdown/getDropdowns"]).length ===
      0
  ) {
    await store.dispatch("SearchModule/dropdown/fetchDropdowns");
  }

  const canonicalLinks = document.head.querySelectorAll("link");
  if (canonicalLinks.length)
    canonicalLinks.forEach(link => {
      if (link.rel === "canonical") link.remove();
    });
  if (
    (toPath === searchRoutes.new && fromPath === searchRoutes.used) ||
    (toPath === searchRoutes.used && fromPath === searchRoutes.new)
  ) {
    store.dispatch("SearchModule/pagination/resetPagination");
    store.dispatch("SearchModule/filter/cleanAllTags");
    store.dispatch("SearchModule/dropdown/resetDropdowns");
    store.commit("SearchModule/filter/setNeedle", "");
  }

  let canonicalUrl = `${window.location.protocol}//${window.location.host}${toPath}`;
  if (canonicalUrl.endsWith("/")) canonicalUrl = canonicalUrl.slice(0, -1);
  const link = document.createElement("link");
  link.rel = "canonical";
  link.href = `${canonicalUrl}`;
  document.head.appendChild(link);

  const isGuest = JSON.parse(localStorage.getItem("isGuest"));
  if (toPath.startsWith("/pessoal/") && isGuest) {
    next({ name: PAGES.HOME });
  }

  // reset pagination when reaching search page from other page than details page
  if (toName === PAGES.SEARCH && fromName !== PAGES.DETAILS) {
    store.commit("resetPage");
  }

  const utmsParams = getUtmParams();
  const utms = getUtmParamsOrDefault(utmsParams);

  if (Object.keys(utms).length) {
    footprintEvent.utms = utms;
  }

  sessionStorage.setItem("utms", JSON.stringify(utms));

  const loggedInToken = localStorage.getItem("token");

  const t = loggedInToken ? parseJwt(loggedInToken) : "";
  const tokenDate = new Date(t.exp * 1000);
  const dateNow = new Date();

  if (!loggedInToken || tokenDate < dateNow) {
    await store.dispatch("fetchGuestToken");
  } else {
    store.commit("setToken", loggedInToken);
    store.commit("setIsGuest", isGuest);
  }

  registerEvent(FOOTPRINT_TYPES.PAGE_VIEW, footprintEvent);

  document.body.classList.remove("no-scroll");

  if (
    [
      PAGES.SEARCH,
      PAGES.SEARCH_USED,
      PAGES.SEARCH_PAGE_CUSTOM_ROUTE,
      PAGES.SEARCH_PAGE_USED_CUSTOM_ROUTE
    ].includes(fromName)
  ) {
    store.commit("SearchModule/setLastSearchPage", fromName);
  }

  if (
    [
      PAGES.SEARCH,
      PAGES.SEARCH_USED,
      PAGES.SEARCH_PAGE_CUSTOM_ROUTE,
      PAGES.SEARCH_PAGE_USED_CUSTOM_ROUTE
    ].includes(toName) &&
    store.getters["SearchModule/getLastSearchPage"] != toName
  ) {
    store.dispatch("SearchModule/filter/cleanAllTags");
    store.dispatch("SearchModule/dropdown/resetDropdowns");
    store.commit("SearchModule/filter/setNeedle", "");
  }

  next();
});

router.afterEach(to => {
  const { name: pageName } = to;
  document.title = META[pageName].title;
  document.head
    .querySelector('meta[name="description"]')
    ?.setAttribute("content", META[pageName].description);
});

export default router;
