<template>
  <div
    :class="['finance--product-wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="title--wrapper">
      <Label size="small" color="primary" type="primary" bold="bold">
        {{ title }}
      </Label>
    </div>
    <div class="checkbox--wrapper">
      <CheckboxLabel
        :title="leftCheckBoxLabel"
        :subtitle="leftCheckBoxDescription"
        type="primary"
        :checked.sync="checkedLeft"
        @onChange="onCheckBoxChangeHandler($event, 'left')"
      />
      <CheckboxLabel
        :title="rightCheckBoxLabel"
        :subtitle="rightCheckBoxDescription"
        type="primary"
        :checked.sync="checkedRight"
        @onChange="onCheckBoxChangeHandler($event, 'right')"
      />
    </div>
    <div class="top-range--wrapper">
      <RangeInput
        slider-type="primary"
        :label="topRangeLabel"
        :slider-interval="topRangeInterval"
        :slider-min="topRangeMinValue"
        :slider-max="topRangeMaxValue"
        :slider-value="topRangeInitialValue"
        @onChange="onRangeChangeHandler($event, 'top')"
      />
    </div>
    <div class="bottom-range--wrapper">
      <RangeInput
        slider-type="primary"
        :label="bottomRangeLabel"
        :slider-interval="bottomRangeInterval"
        :slider-min="bottomRangeMinValue"
        :slider-max="bottomRangeMaxValue"
        :slider-value="bottomRangeInitialValue"
        @onChange="onRangeChangeHandler($event, 'bottom')"
      />
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { CheckboxLabel, RangeInput } from "./../../molecules";
export default {
  name: "FinanceProduct",
  components: { RangeInput, CheckboxLabel, Label },
  data() {
    return {
      checkbox: [],
      checkedRight: false,
      checkedLeft: false
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    topRangeLabel: {
      type: String,
      default: ""
    },
    topRangeMinValue: {
      type: Number,
      default: 0
    },
    topRangeMaxValue: {
      type: Number,
      default: 1000
    },
    topRangeInitialValue: {
      type: Number,
      default: 0
    },
    bottomRangeLabel: {
      type: String,
      default: ""
    },
    bottomRangeMinValue: {
      type: Number,
      default: 0
    },
    bottomRangeMaxValue: {
      type: Number,
      default: 1000
    },
    bottomRangeInitialValue: {
      type: Number,
      default: 0
    },
    leftCheckBoxLabel: {
      type: String,
      default: ""
    },
    leftCheckBoxDescription: {
      type: String,
      default: ""
    },
    rightCheckBoxLabel: {
      type: String,
      default: ""
    },
    rightCheckBoxDescription: {
      type: String,
      default: ""
    },
    topRangeInterval: {
      type: Number,
      default: 1
    },
    bottomRangeInterval: {
      type: Number,
      default: 1
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  methods: {
    onCheckBoxChangeHandler(event, name) {
      if (event && name === "left") {
        this.checkedRight = false;
      } else {
        this.checkedLeft = false;
      }
      this.$emit("onCheckboxChange", { name, value: event });
    },
    onRangeChangeHandler(event, name) {
      this.$emit("onRangeChange", { value: event, name });
    }
  }
};
</script>

<style lang="css" scoped src="./FinanceProduct.css"></style>
