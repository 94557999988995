<template>
  <Popup
    v-if="!isVerifiedAccount"
    id="verification-account"
    :closeButton="closeButton"
    :closeOnClickOutside="closeOnClickOutside"
    @close="closeModal"
    v-show="opened"
    customClass="z-30"
  >
    <template slot="header">
      <Label color="primary" size="medium"
        >Insira o Código de Verificação</Label
      >
    </template>
    <template slot="body">
      <div class="verification-account--message">
        <Label color="tertiary"
          >Foi enviado um código de verificação para o email:
        </Label>
        <Label color="primary">{{ email }}</Label>
      </div>
      <VerificationCode
        class="verification-code"
        @onVerificationCode="onVerificationCodeHandler"
      ></VerificationCode>
      <div class="verification-account--info">
        <Label color="tertiary"
          >Se não encontrar o email, verifique o seu correio spam.
        </Label>
      </div>

      <Button
        class="verification-account--send"
        label="enviar"
        uppercase
        type="primary"
        fill
        :disabled="code.length !== 6"
        @onClick="sendCode"
      ></Button>

      <div class="verification-account--resend-code">
        <Label color="tertiary"
          >Não recebeu email com o código de verificação?
        </Label>
        <Label @click.native="resendCode" color="secondary" class="resend-code"
          >Reenviar código
        </Label>
      </div>
    </template>
  </Popup>
  <Popup
    v-else
    id="verification-account-successfully"
    :closeButton="closeButton"
    :closeOnClickOutside="closeOnClickOutside"
    @close="closeModal"
    v-show="opened"
    customClass="z-30"
  >
    <template slot="body">
      <img
        src="../../../assets/images/check-circle.png"
        alt="verification-account-successfully"
        width="64px"
        height="64px"
      />
      <div class="verified-code">
        <Label color="primary" size="medium"
          >Código verificado com sucesso!</Label
        >
      </div>

      <Button
        class="verification-account--send"
        label="sair"
        uppercase
        type="primary"
        fill
        @onClick="closeModal"
      ></Button>
    </template>
  </Popup>
</template>

<script>
import { Popup, Label, Button } from "../../atoms";
import { VerificationCode } from "../../molecules";
export default {
  name: "VerificationAccount",
  components: {
    Popup,
    Label,
    VerificationCode,
    Button
  },
  data() {
    return {
      opened: false,
      code: "",
      isVerifiedAccount: this.verifiedAccount
    };
  },
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    email: {
      type: String
    },
    verifiedAccount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.opened = false;
      this.chooseRegisterType = true;
      this.registerWithEmail = false;
      this.$emit("close");
    },
    sendCode() {
      this.$emit("onSendCode", this.code);
      this.code = "";
    },
    resendCode() {
      this.$emit("onResendCode");
    },
    onVerificationCodeHandler(event) {
      this.code = event;
    }
  }
};
</script>

<style lang="css" scoped src="./VerificationAccount.css"></style>
