const SEGMENTS = {
  SUV_PLURAL: "SUVs",
  SUV: "Suv",
  SPORT_PLURAL: "Desportivos",
  SPORT: "Desportivo",
  FAMILY_PLURAL: "Familiares",
  FAMILY: "Familiar",
  ECOLOGIC_PLURAL: "Ecológicos",
  ECOLOGIC: "Ecológico"
};

export { SEGMENTS };
