<template>
  <div class="experiences--card">
    <Label customClass="title">{{ title }}</Label>
    <Heading class="subtitle" :label="subtitle" tag="h2" />
    <img :src="file" class="image" />
    <Heading class="text" :label="text" tag="h3" />
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { Heading } from "@sc/ds-ui-component-library";
export default {
  name: "ExperiencesCard",
  components: {
    Label,
    Heading
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    file: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>
<style lang="css" scoped src="./ExperiencesCard.css"></style>
