import { MAXIMUM_VEHICLES } from "../enums/compare";

const CompareMixin = {
  methods: {
    reachedLimitToCompare() {
      return this.$store.getters["CompareModule/count"] + 1 > MAXIMUM_VEHICLES;
    },
    toggleCompared(vehicle) {
      this.$store.commit("CompareModule/openPanel");
      const existingCompare = this.$store.getters["CompareModule/exists"](
        vehicle
      );

      if (existingCompare) {
        this.$store.dispatch("CompareModule/removeFromCompare", vehicle);
      } else {
        if (!this.reachedLimitToCompare()) {
          this.$store.dispatch("CompareModule/addToCompare", vehicle);
        } else {
          this.$toast.open({
            message: this.$t("toast.compare.reachedLimit"),
            dismissible: true,
            type: "info"
          });
        }
      }
      this.$toast.open({
        message: existingCompare
          ? this.$t("toast.compare.removedFromCompare")
          : this.$t("toast.compare.addedToCompare"),
        dismissible: true,
        type: "info"
      });
    }
  }
};

export { CompareMixin };
