<template>
  <div>
    <Page id="page" :class="backgroundFilter">
      <template v-slot:content>
        <Section id="back-button">
          <Link
            :label="$t('pages.detailsPage.backToSearch')"
            :uppercase="true"
            @onClick="backHistory"
            size="x-small"
            color="tertiary"
            icon="chevron-left"
            iconPosition="left"
            bold="bold"
            sizeIcon="large"
            customClass="back-link"
          />
          <div v-if="isMobile" class="utility-buttons--container-mobile">
            <div
              class="utility-buttons--container-action"
              @click="openSharePopUp"
            >
              <div class="icon--wrapper">
                <div class="icon--share" />
              </div>
            </div>
            <div
              class="utility-buttons--container-action"
              @click="toggleFavorite(getSelectedVehicle)"
            >
              <div class="icon--wrapper">
                <div
                  class="icon--favorite"
                  :style="{ backgroundImage: `url(${favoriteImg})` }"
                />
              </div>
            </div>
            <div
              class="utility-buttons--container-action"
              @click="toggleCompared(getSelectedVehicle)"
            >
              <div class="icon--wrapper">
                <div
                  class="icon--compare"
                  :style="{ backgroundImage: `url(${compareImg})` }"
                />
              </div>
            </div>
          </div>
        </Section>
        <Section class="slide-price">
          <UsedVehicleImageViewer v-if="isUsed" />
          <VehicleImageViewer
            v-else-if="loaded"
            :isMobile="isMobile"
            :vehicle="getSelectedVehicle"
          />
          <div class="price">
            <div v-if="!isMobile" class="utility-buttons--container">
              <div
                class="utility-buttons--container-action"
                @click="openSharePopUp"
              >
                <div class="icon--wrapper">
                  <div class="icon--share" />
                </div>
                <Link
                  :label="$t('pages.detailsPage.share')"
                  uppercase
                  size="x-small"
                  color="tertiary"
                  class="mr-2 md:mr-5"
                />
              </div>
              <div
                class="utility-buttons--container-action"
                @click="toggleFavorite(getSelectedVehicle)"
              >
                <div class="icon--wrapper">
                  <div
                    class="icon--favorite"
                    :style="{ backgroundImage: `url(${favoriteImg})` }"
                  />
                </div>
                <Link
                  :label="$t('pages.detailsPage.save')"
                  uppercase
                  size="x-small"
                  color="tertiary"
                  class="mr-2 md:mr-5"
                />
              </div>
              <div
                class="utility-buttons--container-action"
                @click="toggleCompared(getSelectedVehicle)"
              >
                <div class="icon--wrapper">
                  <div
                    class="icon--compare"
                    :style="{ backgroundImage: `url(${compareImg})` }"
                  />
                </div>
                <Link
                  :label="$t('pages.detailsPage.compare')"
                  uppercase
                  size="x-small"
                  color="tertiary"
                />
              </div>
            </div>
            <VehicleInfo
              id="vehicle-info"
              :buttonClickHandler="() => {}"
              :productDetail="{
                title: getModel,
                description: getDescription,
                taeg: getFormattedTaeg,
                modality: getFormattedMonthlyPrice,
                price: getFormattedPrice
              }"
              :linkList="$t('pages.detailsPage.linkList')"
              :labelList="$t('pages.detailsPage.labelList')"
              icon="info-circle"
              :tooltipDescription="getTooltip"
              :buttonLabel="$t('pages.detailsPage.buttonInterested')"
              @onClick="openReservePanel"
              @onContactRequest="openContactPanel"
              @onTestDrive="openTestDrivePanel"
              @onSimulationRedirect="goToSimulation"
              :isMobile="isMobile"
            ></VehicleInfo>
          </div>
        </Section>
        <Section
          id="financing"
          class="financing--section"
          ref="financing"
          v-if="loaded"
        >
          <SectionHeader
            class="financing--section-header"
            :title="$t('pages.detailsPage.financing.title')"
            :subtitle="$t('pages.detailsPage.financing.subtitle')"
            type="primary"
            titleSize="large"
            subtitleSize="small"
          >
          </SectionHeader>
          <div class="financing-content">
            <Financing
              :month="month"
              :deposit="deposit"
              :minRate="getCarFinance && getCarFinance.minRate"
              :rate="getCarFinance && getCarFinance.rate"
              :pvpCetelem="
                getPriceBasedOnType({
                  hasFinancing: true,
                  isPrevious: false
                })
              "
              :pvpTotal="
                getPriceBasedOnType({
                  hasFinancing: false,
                  isPrevious: false
                })
              "
              :monthlyPrice="
                getSelectedVehicle &&
                  getSelectedVehicle.monthlyPrice &&
                  getSelectedVehicle.monthlyPrice.toFixed(2)
              "
              :taeg="getRawTaeg"
              :capitalToFinance="getFormattedCapitalToFinance"
              :car="getSelectedVehicle"
              :tooltipResult="getTooltip"
              @onClickAddResume="onClickAddResume"
              @onClickInterest="openReservePanel"
              @onClickRateCard="onClickRateCard"
              @onCalculate="onCalculate"
            ></Financing>
          </div>
        </Section>
        <Section id="details" class="details--section mt-14" ref="details">
          <div class="details-container">
            <div class="details-header">
              <SectionHeader
                :title="$t('pages.detailsPage.details.title')"
                :subtitle="$t('pages.detailsPage.details.subtitle')"
                type="primary"
                titleSize="large"
                subtitleSize="small"
              ></SectionHeader>
            </div>
            <div class="details-content">
              <div class="details-content-menu">
                <Button
                  class="desktop-view-content"
                  :label="$t('pages.detailsPage.details.buttonSpecifications')"
                  size="large"
                  :type="viewSpecifications ? 'primary' : 'secondary'"
                  icon="chevron-right"
                  iconPosition="right"
                  iconPrefix="fas"
                  uppercase
                  :border="false"
                  :fill="viewSpecifications"
                  @onClick="clickSpecifications"
                >
                </Button>
                <Button
                  class="desktop-view-content"
                  :label="$t('pages.detailsPage.details.buttonWarranty')"
                  size="large"
                  :type="viewWarranty ? 'primary' : 'secondary'"
                  icon="chevron-right"
                  iconPosition="right"
                  iconPrefix="fas"
                  uppercase
                  :border="false"
                  :fill="viewWarranty"
                  @onClick="clickWarranty"
                >
                </Button>

                <Accordion
                  class="mobile-view-content"
                  id="accordionStart"
                  :key="'toggler' + togglesRenderKey"
                >
                  <template slot="items">
                    <AccordionItem
                      :accordionIndex="0"
                      type="tertiary"
                      iconClose="chevron-down"
                      iconOpen="chevron-up"
                      accordion-index="idx"
                      :activeIndex="activeIndexSpecs"
                      @onToggle="onToggleSpecificationHandler"
                    >
                      <template slot="accordion-trigger">
                        <div class="accordion-title">
                          <Label
                            color="primary"
                            size="large"
                            bold="extra-light"
                          >
                            {{
                              $t(
                                "pages.detailsPage.details.buttonSpecifications"
                              )
                            }}
                          </Label>
                        </div>
                      </template>
                      <template slot="accordion-content">
                        <div>
                          <VehicleSpecifications
                            :specificationsList="getCarSpecificationsMapped"
                          ></VehicleSpecifications>

                          <div class="vehicle-equipments-wrapper">
                            <Label
                              class="pt-8"
                              color="primary"
                              size="medium"
                              bold="bold"
                              >{{
                                $t("pages.detailsPage.details.equipments")
                              }}</Label
                            >

                            <CollapseContainer
                              collapsedHeight="280px"
                              :expandButtonText="
                                isOpenCollapseContainer
                                  ? $t(
                                      'pages.detailsPage.details.collapse.showLess'
                                    )
                                  : $t(
                                      'pages.detailsPage.details.collapse.showAll'
                                    )
                              "
                              expandButtonColor="secondary"
                              @onOpen="onOpenCollapseContainer"
                            >
                              <div
                                class="collapse-container"
                                v-for="(value,
                                index) in getCarDetailEquipmentByCategory"
                                :key="index"
                              >
                                <Label
                                  customClass="equipment-type--title"
                                  color="primary"
                                  size="small"
                                  >{{ value.categoryName }}</Label
                                >
                                <div class="collapse-container-type">
                                  <div class="collapse-container-left">
                                    <ul>
                                      <li
                                        class="collapse-container-label"
                                        v-for="(equipment, index) in itemsHead(
                                          value.equipments
                                        )"
                                        :key="index"
                                      >
                                        <Label
                                          color="tertiary"
                                          size="small"
                                        ></Label>
                                        {{ equipment }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="collapse-container-right">
                                    <ul>
                                      <li
                                        class="collapse-container-label"
                                        v-for="(equipment, index) in itemsTail(
                                          value.equipments
                                        )"
                                        :key="index"
                                      >
                                        <Label
                                          color="tertiary"
                                          size="small"
                                        ></Label>
                                        {{ equipment }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </CollapseContainer>
                          </div>
                        </div>
                      </template>
                    </AccordionItem>
                    <AccordionItem
                      :accordionIndex="1"
                      type="tertiary"
                      iconClose="chevron-down"
                      iconOpen="chevron-up"
                      accordion-index="idx"
                      :activeIndex="activeIndexSpecs"
                      @onToggle="onToggleSpecificationHandler"
                    >
                      <template slot="accordion-trigger">
                        <div class="accordion-title">
                          <Label size="large">
                            {{ $t("pages.detailsPage.details.buttonWarranty") }}
                          </Label>
                        </div>
                      </template>
                      <template slot="accordion-content">
                        <div>
                          <WarrantyCard
                            :info="
                              $t('pages.detailsPage.details.warrantyCard.info')
                            "
                            :infoList="
                              $t(
                                'pages.detailsPage.details.warrantyCard.infoList'
                              )
                            "
                            :disclaimerInfo="
                              $t(
                                'pages.detailsPage.details.warrantyCard.disclaimer'
                              )
                            "
                          ></WarrantyCard>
                        </div>
                      </template>
                    </AccordionItem>
                  </template>
                </Accordion>

                <Button
                  :label="$t('pages.detailsPage.details.buttonBrochure')"
                  size="large"
                  type="secondary"
                  icon="chevron-right"
                  iconPosition="right"
                  iconPrefix="fas"
                  uppercase
                  :border="false"
                  v-show="false"
                >
                </Button>
              </div>
              <div class="details-content-content">
                <div v-show="viewWarranty" class="desktop-view-content">
                  <!-- <Label color="primary" size="medium" bold="bold"
                    >Garantia</Label
                  > -->
                  <WarrantyCard
                    :info="$t('pages.detailsPage.details.warrantyCard.info')"
                    :infoList="
                      $t('pages.detailsPage.details.warrantyCard.infoList')
                    "
                    :disclaimerInfo="
                      $t('pages.detailsPage.details.warrantyCard.disclaimer')
                    "
                  ></WarrantyCard>
                </div>

                <div v-show="viewSpecifications" class="desktop-view-content">
                  <VehicleSpecifications
                    :specificationsList="getCarSpecificationsMapped"
                  ></VehicleSpecifications>

                  <div class="vehicle-equipments-wrapper">
                    <div class="title-wrapper">
                      <Label color="primary" size="medium" bold="bold">{{
                        $t("pages.detailsPage.details.equipments")
                      }}</Label>
                      <Tooltip
                        v-if="isUsed"
                        :tooltip="
                          $t('pages.detailsPage.details.equipmentsInfo')
                        "
                      >
                        <template v-slot:content>
                          <img
                            width="30"
                            height="30"
                            src="../../../assets/icons/infoGray.svg"
                          />
                        </template>
                      </Tooltip>
                    </div>

                    <CollapseContainer
                      collapsedHeight="280px"
                      :expandButtonText="
                        isOpenCollapseContainer
                          ? $t('pages.detailsPage.details.collapse.showLess')
                          : $t('pages.detailsPage.details.collapse.showAll')
                      "
                      expandButtonColor="secondary"
                      @onOpen="onOpenCollapseContainer"
                    >
                      <div
                        class="collapse-container"
                        v-for="(value,
                        index) in getCarDetailEquipmentByCategory"
                        :key="index"
                      >
                        <Label
                          customClass="equipment-type--title"
                          color="primary"
                          size="small"
                          >{{ capitalize(value.categoryName) }}</Label
                        >
                        <div class="collapse-container-type">
                          <div class="collapse-container-left">
                            <ul>
                              <li
                                class="collapse-container-label"
                                v-for="(equipment, index) in itemsHead(
                                  value.equipments
                                )"
                                :key="index"
                              >
                                <Label color="tertiary" size="small"></Label>
                                {{ equipment }}
                              </li>
                            </ul>
                          </div>
                          <div class="collapse-container-right">
                            <ul>
                              <li
                                class="collapse-container-label"
                                v-for="(equipment, index) in itemsTail(
                                  value.equipments
                                )"
                                :key="index"
                              >
                                <Label color="tertiary" size="small"></Label>
                                {{ equipment }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </CollapseContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section v-if="isUsed" id="concession" class="faqs--section">
          <img
            class="concession--image"
            :src="$t('pages.detailsPage.concession.image')"
            :alt="
              concessionText +
                ' ' +
                getSelectedVehicle.dealer.designation +
                ' ' +
                getSelectedVehicle.dealer.address
            "
          />
          <div class="concession--column">
            <Label color="primary" size="large" bold="bold">
              {{ concessionText }}
            </Label>
            <Label color="primary" size="large" bold="bold">
              {{ getSelectedVehicle.dealer.designation }}
            </Label>
            <Label color="tertiary" size="small" bold="extra-light">
              {{ getSelectedVehicle.dealer.address }}
            </Label>
            <Label color="tertiary" size="small" bold="extra-light">
              {{ getSelectedVehicle.dealer.district }}
            </Label>
            <Label
              v-if="getSelectedVehicle.dealer.cellPhone"
              color="tertiary"
              size="small"
              bold="extra-light"
            >
              {{
                $t("pages.detailsPage.concession.cellPhone") +
                  getSelectedVehicle.dealer.cellPhone
              }}
            </Label>
            <Label
              v-if="getSelectedVehicle.dealer.phoneNumber"
              color="tertiary"
              size="small"
              bold="extra-light"
            >
              {{
                $t("pages.detailsPage.concession.phoneNumber") +
                  getSelectedVehicle.dealer.phoneNumber
              }}
            </Label>
            <Button
              :uppercase="isReserved || isSold ? true : false"
              type="secondary"
              :fill="true"
              :filename="isReserved || isSold ? 'Lock.svg' : ''"
              iconPosition="left"
              :custom-class="
                isReserved || isSold
                  ? 'vehicle-reserved'
                  : 'vehicle-info--button-margin'
              "
              :label="
                isReserved || isSold
                  ? filterTagLabel
                  : $t('pages.detailsPage.buttonInterested')
              "
              :disabled="isReserved || isSold"
              :border="isReserved || isSold"
              @onClick="openReservePanel"
            />
          </div>
        </Section>
        <Section id="faqs" class="faqs--section">
          <div class="info--container">
            <Label
              color="primary"
              size="large"
              bold="bold"
              custom-class="info--title"
            >
              {{ $t("pages.detailsPage.faqs.title") }}
            </Label>
            <Label
              color="tertiary"
              size="small"
              bold="extra-light"
              custom-class="info--description mt-4"
            >
              {{ $t("pages.detailsPage.faqs.description") }}
            </Label>
            <div class="questions--container">
              <div class="flex mt-4 contact-container">
                <div
                  :style="{
                    backgroundImage: `url(${require('../../../assets/icons/Question-gray.svg')})`
                  }"
                  class="faqs-icon"
                />
                <Label color="tertiary">
                  <Label
                    color="secondary"
                    custom-class="underline cursor-pointer"
                    @click.native="() => openContactPanel(true)"
                    >{{ $t("pages.detailsPage.faqs.contact1") }}</Label
                  >
                  {{ $t("pages.detailsPage.faqs.contact2") }}
                </Label>
              </div>
            </div>
          </div>
          <div class="accordion--container">
            <Accordion>
              <template slot="items">
                <AccordionItem
                  type="tertiary"
                  :accordionIndex="index"
                  v-for="(accordion, index) in productFaqs"
                  accordion-index="idx"
                  :key="accordion.id"
                  :activeIndex="activeIndex"
                  @onToggle="onToggleAccordionHandler"
                >
                  <template slot="accordion-trigger">
                    <Label color="primary" size="small" bold="normal">
                      {{ accordion.title }}
                    </Label>
                  </template>
                  <template slot="accordion-content">
                    <Label size="small" color="tertiary">
                      {{ accordion.description }}
                    </Label>
                  </template>
                </AccordionItem>
              </template>
            </Accordion>
          </div>
        </Section>
        <Section id="quality" class="quality--section" ref="quality">
          <div class="quality--container">
            <img
              class="quality-image--container"
              :src="
                $t('resources.image.detailPage.lojaMerchandise') ||
                  '../../../assets/images/loja_merchandise_cuide_do_carro.webp'
              "
              alt="detail-page-quality-image"
            />
            <div class="quality--labels">
              <Label customClass="quality--title" size="large" bold="bold">
                {{ $t("pages.detailsPage.quality.title") }}</Label
              >
              <Label
                customClass="quality--content"
                size="small"
                align="center"
                >{{ $t("pages.detailsPage.quality.description") }}</Label
              >
              <Label
                customClass="quality--content"
                size="small"
                align="center"
                >{{ $t("pages.detailsPage.quality.description2") }}</Label
              >
              <div class="quality--icon-label">
                <div
                  class="quality--icon"
                  :style="{
                    filter:
                      'invert(100%) sepia(96%) saturate(21%) hue-rotate(55deg) brightness(105%) contrast(101%)',
                    backgroundImage: `url(${require('./../../../assets/icons/map-marker.svg')})`
                  }"
                  alt=""
                />
                <Label
                  customClass="quality--label-list-item"
                  size="small"
                  align="center"
                >
                  {{ $t("pages.detailsPage.quality.benefits[0]") }}
                </Label>
              </div>
              <div class="quality--icon-label">
                <div
                  class="quality--icon"
                  :style="{
                    filter:
                      'invert(100%) sepia(96%) saturate(21%) hue-rotate(55deg) brightness(105%) contrast(101%)',
                    backgroundImage: `url(${require('./../../../assets/icons/shield-check.svg')})`
                  }"
                />
                <Label
                  customClass="quality--label-list-item"
                  size="small"
                  align="center"
                >
                  {{ $t("pages.detailsPage.quality.benefits[1]") }}
                </Label>
              </div>
              <div class="quality--icon-label">
                <div
                  class="quality--icon"
                  :style="{
                    filter:
                      'invert(100%) sepia(96%) saturate(21%) hue-rotate(55deg) brightness(105%) contrast(101%)',
                    backgroundImage: `url(${require('./../../../assets/icons/wifi-car.svg')})`
                  }"
                  alt=""
                />
                <Label
                  customClass="quality--label-list-item"
                  size="small"
                  align="center"
                >
                  {{ $t("pages.detailsPage.quality.benefits[2]") }}
                </Label>
              </div>
              <div class="quality--icon-label">
                <div
                  class="quality--icon"
                  :style="{
                    filter:
                      'invert(100%) sepia(96%) saturate(21%) hue-rotate(55deg) brightness(105%) contrast(101%)',
                    backgroundImage: `url(${require('./../../../assets/icons/spanners.svg')})`
                  }"
                  alt=""
                />
                <Label
                  customClass="quality--label-list-item"
                  size="small"
                  align="center"
                >
                  {{ $t("pages.detailsPage.quality.benefits[3]") }}
                </Label>
              </div>
            </div>
          </div>
        </Section>
        <Section
          v-if="!similarLoading"
          id="similar"
          class="similar--section mt-10"
        >
          <div class="similar-container--section">
            <SectionHeader
              type="primary"
              titleSize="large"
              :title="$t('pages.detailsPage.similar.title')"
            />
            <div class="similar--container">
              <VehicleResultCardWrapper
                class="similar-card--item"
                :vehicle="v"
                v-for="(v, index) in getCarSimilarVehicles"
                :key="`${v.vin}-${index}`"
                :id="v.vin"
                :compare="compare"
                @onFavorite="addToFavorites(v.favorite, v.vin, v.financeId)"
              >
              </VehicleResultCardWrapper>
            </div>
          </div>
        </Section>

        <Popup
          id="share-popup"
          :close-button="true"
          :closeOnClickOutside="true"
          @close="closeSharePopUp"
          v-show="isShareOpen"
          custom-class="z-30"
          :title="$t('pages.detailsPage.share')"
        >
          <template slot="body">
            <div
              class="
                flex flex-col
                items-center
                justify-center
                md:flex-row
                sm:mt-0
              "
            >
              <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{ backgroundColor: network.color }"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
                class="share-network--container"
                @open="socialNetworkFootprint(network)"
              >
                <div class="social--container">
                  <Icon
                    color="#FFFFFF"
                    icon-prefix="fab"
                    size="small"
                    :name="network.icon"
                  />
                  <Label bold="bold" custom-class="ml-2">
                    {{ network.name }}
                  </Label>
                </div>
              </ShareNetwork>
            </div>
          </template>
        </Popup>
      </template>
    </Page>
    <Spinner :loading="loading" />
    <transition name="fade" mode="out-in">
      <div id="cta-navbar" v-if="ctaNav">
        <Button
          @onClick="openTestDrive"
          :label="$t('panels.reserve.steps.1.btnLabels.testDriveBtn')"
          fill
          :style="{ height: '60px', borderColor: '#fff', borderWidth: '1px' }"
          rightArrowIcon
          iconPosition="right"
          customClass="cta-navbar--button-primary"
        />
        <Button
          @mouseover="() => (ctaHover = true)"
          @mouseleave="() => (ctaHover = false)"
          @onClick="openContactPanel"
          :label="$t('panels.reserve.steps.1.btnLabels.ContactBtn')"
          fill
          :style="{ height: '60px', borderColor: '#fff', borderWidth: '1px' }"
          :rightArrowIcon="!isMobile"
          iconPosition="right"
          customClass="cta-navbar--button-primary"
        />
        <Button
          @onClick="openReservePanel"
          :label="
            isReserved || isSold
              ? filterTagLabel
              : $t(
                  'pages.detailsPage.financing.financingResult.buttonInterested'
                )
          "
          type="secondary"
          fill
          :style="{
            height: '60px',
            borderColor: '#00aad2',
            borderWidth: '1px'
          }"
          rightArrowIcon
          iconPosition="right"
          :customClass="
            isReserved || isSold ? 'vehicle-reserved' : 'cta-navbar--button'
          "
        />
      </div>
    </transition>
  </div>
</template>

<script>
import {
  Link,
  Button,
  Icon,
  Label,
  Section,
  AccordionItem,
  Popup,
  Tooltip
} from "../../atoms";
import { Page } from "./../../templates";
import {
  SectionHeader,
  CollapseContainer,
  Accordion,
  Spinner
} from "../../molecules";
import {
  VehicleInfo,
  Financing,
  WarrantyCard,
  VehicleSpecifications,
  VehicleImageViewer,
  UsedVehicleImageViewer,
  VehicleResultCardWrapper
} from "../../organisms";
// import VehicleSpecifications from "../../organisms/VehicleSpecifications/VehicleSpecifications.vue"
import { mapGetters, mapState } from "vuex";
import { getPriceFormatted } from "../../../utils/currency";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

import { CompareMixin, FavoritesMixin } from "../../../mixins";

import { RESERVE_STATUS } from "../../../enums/reserveStatus";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

import { FAQ_TYPE } from "../../../enums/faqs";

import { PAGES } from "../../../enums/pages";

import {
  pushNewEvent,
  GOOGLE_TAG_MANAGER_EVENT_TYPES
} from "../../../utils/GoogleTagManager";

import { RESERVE_TYPE } from "../../../enums/reserveTypes.js";

export default {
  components: {
    Popup,
    AccordionItem,
    Accordion,
    Label,
    Section,
    Page,
    Link,
    VehicleInfo,
    SectionHeader,
    Button,
    VehicleSpecifications,
    CollapseContainer,
    Icon,
    Spinner,
    Financing,
    WarrantyCard,
    VehicleImageViewer,
    VehicleResultCardWrapper,
    UsedVehicleImageViewer,
    Tooltip
  },
  mixins: [CompareMixin, FavoritesMixin],
  metaInfo() {
    return {
      title: this.$t("meta.detail.title", {
        detail: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model}`
      }),
      meta: [
        {
          name: "description",
          content: this.$t("meta.detail.description", {
            description: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model} - ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`
          })
        },
        {
          name: "keywords",
          content: this.$t("meta.detail.keywords", {
            keywords: `${this.getSelectedVehicle?.carDetailCard?.brand}, ${this.getSelectedVehicle?.carDetailCard?.model}, ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`
          })
        },
        {
          property: "og:title",
          content: this.$t("meta.detail.title", {
            detail: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model}`
          })
        },
        {
          property: "og:description",
          content: this.$t("meta.detail.description", {
            description: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model} - ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`
          })
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.getSelectedVehicle?.carDetailCard?.imageUrl
        }
      ]
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      ctaHover: false,
      maxMobileSize: 750,
      isMobile: screen.width <= this.maxMobileSize,
      togglesRenderKey: 0,
      ctaNav: false,
      loaded: false,
      id: this.$route.params.id,
      financeId: this.$route.params.fid,
      isOpenCollapseContainer: false,
      vehicleDetails: {},
      productDetail: {},
      similarLoading: false,
      compare: false,
      compareRightSidebarOpened: false,
      favoriteRightSidebarOpened: false,
      isShareOpen: false,
      typeCheckboxCreateAccount: "tertiary",
      filtersList: [
        {
          name: "Caixa Manual",
          value: "manual"
        },
        {
          name: "Caixa Automática",
          value: "automatic"
        }
      ],
      imagesNormalSlide: [],
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "facebook-f",
          color: "#1877f2",
          iconColor: "#4267B2"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "twitter",
          color: "#3FB5FD",
          iconColor: "#1DA1F2"
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "whatsapp",
          color: "#25d366",
          iconColor: "#128C7E"
        }
      ],
      loading: false,
      viewSpecifications: true,
      viewWarranty: false,
      activeIndex: -1,
      activeIndexSpecs: 0,
      panelStatus: RESERVE_STATUS.START,
      renderComponent: true,
      fpSocialSharingLabel: "Social Sharing"
    };
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    updateMobileProps() {
      this.isMobile = screen.width <= this.maxMobileSize;
    },
    getPriceFormatted,
    onOpenCollapseContainer(event) {
      if (event) {
        registerEvent(FOOTPRINT_TYPES.EXPAND_CAR_DETAILS, { vin: this.id });
      }
      this.isOpenCollapseContainer = event;
      if (!event) document.getElementById("accordionStart").scrollIntoView();
    },
    onGoto(refName) {
      const element = this.$refs[refName].$el.offsetTop;
      // x = 0 and y = element
      window.scrollTo(0, element);
    },
    addToFavorites(favorite, vin, fid) {
      if (this.isGuest) {
        return (this.$store.state.isOpenSignupPopup = true);
      }

      this.$store.state.isOpenFavoritePanel = !this.$store.state
        .isOpenFavoritePanel;

      return favorite
        ? this.$store.dispatch("storeRemoveFromFavorites", vin)
        : this.$store.dispatch("storeAddToFavorites", { vin, fid });
    },
    closeRightSidebar() {
      this.favoriteRightSidebarOpened = false;
      this.compareRightSidebarOpened = false;
      this.compare = false;
    },
    backHistory() {
      const prev = JSON.parse(sessionStorage.getItem("searchPath"));
      var to = {
        params: {
          tags: JSON.parse(sessionStorage.getItem("searchFilters")) || {}
        }
      };
      if (prev) to["path"] = prev.path;
      else to["name"] = PAGES.SEARCH;
      this.$router.push(to);
    },
    openSharePopUp() {
      this.isShareOpen = true;
    },
    closeSharePopUp() {
      this.isShareOpen = false;
    },
    onClickAddResume() {},
    onPrevious() {
      this.isInterestedOpened = true;
      this.isTestDrive = false;
    },
    onClickRateCard() {},
    onCalculate(financing) {
      registerEvent(FOOTPRINT_TYPES.FINANCING_SIMULATION, {
        ...financing,
        vin: this.id
      });
    },
    clickSpecifications() {
      this.viewSpecifications = true;
      this.viewWarranty = false;
    },
    clickWarranty() {
      this.viewSpecifications = false;
      this.viewWarranty = true;
    },
    onClickBook() {
      this.isInterestedOpened = false;
      this.isTestDrive = false;
      this.isBook = true;
    },
    openReservePanel(v) {
      //console.log(v);
      this.$store.commit("setReserveStatus", RESERVE_STATUS.IN_PROGRESS);
      this.$store.commit("setReserveBack", true);
      this.$store.commit("setReserveContact", v);
      this.$store.commit("setReserveVehicle", true);
      this.$store.state.isOpenReservePanel = true;
      this.handleInterestFootprint();
    },
    openContactPanel(v) {
      this.$store.commit("setReserveBack", false);
      this.$store.commit("setReserveContact", v);
      this.$store.commit("setReserveVehicle", true);
      // open contact panel
      this.$store.commit("setReserveStatus", RESERVE_STATUS.CONTACT);
      this.$store.commit("setReservePanelKey");
      this.$store.state.isOpenReservePanel = true;
    },
    openTestDrive(v) {
      this.$store.commit("setReserveBack", false);
      this.$store.commit("setReserveContact", v);
      this.$store.commit("setReserveVehicle", true);
      // open contact panel
      this.$store.commit("setReserveStatus", RESERVE_STATUS.TEST_DRIVE);
      this.$store.commit("setReservePanelKey");
      this.$store.state.isOpenReservePanel = true;
    },
    openTestDrivePanel(v) {
      this.$store.commit("setReserveBack", false);
      this.$store.commit("setReserveContact", v);
      this.$store.commit("setReserveVehicle", true);
      // open contact panel
      this.$store.commit("setReserveStatus", RESERVE_STATUS.TEST_DRIVE);
      this.$store.commit("setReservePanelKey");
      this.$store.state.isOpenReservePanel = true;
    },
    goToSimulation() {
      document.getElementById("financing").scrollIntoView();
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: PAGES.DETAILS,
        title: e?.title
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    },
    onToggleAccordionHandler(event) {
      const eventData = this.productFaqs[event.index];
      this.activeIndex === event.index
        ? (this.activeIndex = -1)
        : ((this.activeIndex = event.index), this.faqsFootprint(eventData));
    },
    onToggleSpecificationHandler(event) {
      this.isOpenCollapseContainer = false;
      this.togglesRenderKey += 1;
      document.getElementById("accordionStart").scrollIntoView();
      this.activeIndexSpecs === event.index
        ? (this.activeIndexSpecs = -1)
        : (this.activeIndexSpecs = event.index);
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    itemsLength(equipments) {
      return Number((equipments.length / 2).toFixed(0));
    },
    itemsHead(equipments) {
      return equipments
        ? equipments.slice(0, this.itemsLength(equipments))
        : [];
    },
    itemsTail(equipments) {
      return equipments ? equipments.slice(this.itemsLength(equipments)) : [];
    },
    normalizeValue(value, offset) {
      return Math.ceil(value / offset) * offset;
    },
    handleInterestFootprint() {
      const withFinanceObj = {
        pvpValue: this.getSelectedVehicle?.pricePvp,
        monthlyPrice: this.getSelectedVehicle?.monthlyPrice,
        months: this.getSelectedVehicle?.carDetailFinance?.months,
        taeg: this.getSelectedVehicle?.taeg,
        capitalToFinance: this.getSelectedVehicle?.carDetailFinance
          ?.capitalFinance,
        initialDeposit: this.getSelectedVehicle?.carDetailFinance?.depositValue
      };
      if (this.getIsOpenDrive)
        withFinanceObj[
          "residualValue"
        ] = this.getSelectedVehicle?.carDetailFinance?.residualValue;

      const reserveDetails = {
        brand: this.getSelectedVehicle?.brand,
        model: this.getSelectedVehicle?.model,
        financeDetails: {
          withFinance: this.getWithFinancing,
          withFinanceDetails: this.getWithFinancing
            ? withFinanceObj
            : { totalPrice: this.getSelectedVehicle?.totalPrice }
        },
        retake:
          this.getIncludeRetake && !this.isGuest
            ? {
                ...this?.getReturns[0]
              }
            : {}
      };
      const footprintEvent = {
        vin: this.getSelectedVehicle?.carDetailCard?.vin,
        reserveDetails: reserveDetails
      };
      registerEvent(FOOTPRINT_TYPES.HAS_INTEREST, footprintEvent);
    },
    socialNetworkFootprint(socialNetwork) {
      const footprintEvent = {
        from: this.fpSocialSharingLabel,
        socialNetworkInfo: {
          name: socialNetwork?.name,
          network: socialNetwork?.network
        },
        vin: this.getSelectedVehicle?.vin
      };
      registerEvent(FOOTPRINT_TYPES.SOCIAL_NETWORK, footprintEvent);
    },
    isInViewport(element) {
      var top_of_element = element.offsetTop;
      var bottom_of_element =
        element.offsetTop + element.offsetHeight + element.style.marginTop;
      var bottom_of_screen = window.scrollY + window.innerHeight;
      var top_of_screen = window.scrollY;
      return (
        bottom_of_screen > top_of_element && top_of_screen < bottom_of_element
      );
    },
    handleScroll() {
      const vehicleInfo = document.getElementById("vehicle-info");
      const footer = document.getElementById("footer-page");

      var sticky = vehicleInfo.offsetHeight;
      if (
        window.innerWidth > 768 &&
        window.pageYOffset >= sticky &&
        !this.isInViewport(footer)
      ) {
        this.ctaNav = true;
      } else if (window.innerWidth <= 768 && !this.isInViewport(footer)) {
        this.ctaNav = true;
      } else {
        this.ctaNav = false;
      }
    }
  },
  computed: {
    ...mapState(["isLoading"]),
    ...mapGetters([
      "getVehicleFinanceId",
      "getVehicleDetails",
      "getVehicleById",
      "getDealers",
      "getCarFinance",
      "isGuest",
      "getSimilarVehicles",
      "getCarSimilarVehicles",
      "getCarFinance",
      "getSelectedVehicle",
      "getFormattedCapitalToFinance",
      "getRawTaeg",
      "getVin",
      "getFormattedPrice",
      "getFormattedMonthlyPrice",
      "getFormattedTaeg",
      "getDescription",
      "getModel",
      "getCarSpecificationsMapped",
      "getCarDetailEquipment",
      "getCarDetailFaqs",
      "getVehicleInitialDeposit",
      "getVehicleNumberMonths",
      "getFinanceId",
      "getIsOpenDrive",
      "getCarDetailEquipmentByCategory",
      "SearchModule/dropdown/getFinanceDropdown",
      "getCreditTypeDetails",
      "getIncludeRetake",
      "getReturns",
      "getDefaultDeposit",
      "getWithFinancing",
      "getReservationType",
      "getTooltip",
      "getPriceBasedOnType",
      "getPrice",
      "getVehicleDealerMunicipality",
      "getConditionId"
    ]),
    ...mapGetters({
      inCompareList: "CompareModule/exists",
      inFavoritesList: "FavoritesModule/exists",
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown"
    }),
    compareImg() {
      const src = this.inCompareList(this.getSelectedVehicle) ? "" : "-2";
      return require(`../../../assets/images/compare-card${src}.png`);
    },
    isUsed() {
      return this.getConditionId == 2;
    },
    concessionText() {
      return this.$t("pages.detailsPage.concession.title");
    },
    favoriteImg() {
      const img = !this.inFavoritesList(this.getSelectedVehicle)
        ? "heart-gray"
        : "heart-fill";
      return require(`../../../assets/images/${img}.svg`);
    },
    buttonEnabled() {
      return (
        !this.myPersonalName &&
        !this.myPersonalEmail &&
        !this.myPersonalPhone &&
        !this.myPersonalPassword
      );
    },
    backgroundFilter() {
      return {
        "background-filter":
          this.isAdditionalInformation ||
          this.isTestDrive ||
          this.isInterestedOpened ||
          this.compareRightSidebarOpened ||
          this.favoriteRightSidebarOpened
      };
    },
    maxRange() {
      const c =
        this.getCarFinance?.max_initial_deposit /
        this.getCarFinance?.initial_deposit_step;

      let max = Math.ceil(c);

      max = max * this.getCarFinance?.initial_deposit_step;

      return max;
    },
    productFaqs() {
      return this.getCarDetailFaqs?.filter(
        it => it.faqType == FAQ_TYPE.PRODUCT
      );
    },
    finalPvp() {
      if (this.getSelectedVehicle && this.getIsOpenDrive) {
        return this.getSelectedVehicle.totalPrice;
      }
      return this.getSelectedVehicle && this.getSelectedVehicle.pricePvp;
    },
    month() {
      const creditType = this.getIsOpenDrive ? 2 : 1;
      const creditDetail = this.getCreditTypeDetails(creditType);
      return {
        min: creditDetail.minNbrPayments || 1,
        max: creditDetail.maxNbrPayments || 3,
        step: creditDetail.nbrPaymentsStep || 1,
        value: Number(this.getCarFinance.months) || 2
      };
    },
    deposit() {
      const creditType = this.getIsOpenDrive ? 2 : 1;
      const creditDetail = this.getCreditTypeDetails(creditType);
      const totalPrice = this.getPrice;

      const max =
        this.normalizeValue(
          totalPrice * creditDetail.maxInitialDepositPc,
          Number(this["SearchModule/dropdown/getFinanceDropdown"].depositStep)
        ) || 3;
      var min =
        Number(this["SearchModule/dropdown/getFinanceDropdown"].depositStep) ||
        1;
      const retake =
        this.getReturns.length > 0
          ? this.getReturns[0].status === RETAKESTATUS.Q1_UPDATED ||
            this.getReturns[0].status === RETAKESTATUS.FINALIZED
            ? this.getReturns[0].actualValuation
            : this.getReturns[0].initialValuation
          : 0;
      var value =
        this.normalizeValue(
          this.getCarFinance.depositValue,
          Number(this.getFinanceDropdown.depositStep)
        ) || 2;

      value = value >= max ? max : value;

      if (this.getIncludeRetake && retake > min && retake < max) {
        min = this.normalizeValue(
          retake,
          Number(this.getFinanceDropdown.depositStep)
        );
        value =
          retake < max
            ? this.normalizeValue(
                retake,
                Number(this.getFinanceDropdown.depositStep)
              )
            : max;
      }

      return {
        min,
        max,
        step: Number(this.getFinanceDropdown.depositStep) || 1,
        value: value,
        retake,
        defaultValue: this.normalizeValue(
          this.getDefaultDeposit,
          Number(this.getFinanceDropdown.depositStep)
        )
      };
    },
    sharing() {
      return {
        url: window.location.href,
        title: this.$t("pages.detailsPage.sharePopup.title"),
        description: this.$t("pages.detailsPage.sharePopup.description"),
        quote: this.$t("pages.detailsPage.sharePopup.quote"),
        hashtags: "digitalstore",
        twitterUser: "DigitalStore"
      };
    },
    isReserved() {
      return this.getReservationType === RESERVE_TYPE.RESERVED;
    },
    isSold() {
      return this.getReservationType === RESERVE_TYPE.BOUGHT;
    },
    filterTagLabel() {
      return this.getReservationType === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveTypeBtnLabel.1")
        : this.$t("labels.reserveTypeBtnLabel.2");
    }
  },
  async mounted() {
    this.isMobile = screen.width <= this.maxMobileSize;
    window.addEventListener("resize", this.updateMobileProps);
    if (window.innerWidth <= 768) this.ctaNav = true;
    this.$store.commit("setIsOpenDrive", false);
    this.$store.commit("setWithFinancing", true);
    this.$store.state.isLoading = true;
    await this.$store.dispatch("storeVehicle", {
      vin: this.id,
      fid: this.$route.query.fid
    });
    const vehicle = this.getSelectedVehicle;

    if (Object.keys(vehicle).length <= 2) {
      this.$router.push({
        name: PAGES.ERROR
      });
      return;
    }
    this.similarLoading = true;
    this.$store
      .dispatch("fetchSimilarVehicles", { vin: vehicle.vin })
      .then(() => {
        this.similarLoading = false;
      });

    await this.$store.dispatch("storeDealers");
    // FIXME: Porque e que isto esta aqui??
    this.$store.dispatch("SearchModule/dropdown/fetchDropdowns");
    registerEvent(FOOTPRINT_TYPES.DETAIL_PAGE, this.getSelectedVehicle);
    this.$store.state.isLoading = false;
    this.loaded = true;
    this.$store.commit("setCarFaqs", this.$t("pages.detailsPage.faqsAnswers"));

    pushNewEvent(
      GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_VIEW,
      PAGES.DETAILS,
      vehicle
    );
  }
};
</script>

<style lang="css" scoped src="./DetailPage.css"></style>
