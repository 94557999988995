<template>
  <div
    :class="['filter-car-product--wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="filter-car-product--top">
      <div class="filter-car-product--left">
        <Label uppercase size="small" color="tertiary">
          {{ labelResults }}: {{ resultCount }}
        </Label>
      </div>
      <div class="filter-car-product--right">
        <Label
          uppercase
          color="tertiary"
          custom-class="label-order"
          size="small"
          >{{ labelOrder }}</Label
        >

        <Select
          type="secondary"
          @onChange="sort"
          id="sortList"
          :options="listOrder"
          :border="false"
          class="order-select"
        ></Select>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Select } from "./../../atoms";

export default {
  components: { Label, Select },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    labelOrder: {
      type: String,
      default: "Ordenar por"
    },
    labelResults: {
      type: String,
      default: "Resultados"
    },
    selectedOrder: {
      type: String,
      default: "Recomendado"
    },
    listOrder: {
      type: Array[String],
      default: ["Recomendado"]
    },
    resultCount: {
      //Number of searched cars
      type: Number,
      default: 0
    },
    orderBy: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  methods: {
    sort(item) {
      const selected = this.listOrder.find(i => i.value === item);
      this.$emit("onSort", selected.sort, selected.orderBy);
    }
  }
};
</script>

<style scoped src="./FilterCarProduct.css"></style>
