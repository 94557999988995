import { render, staticRenderFns } from "./FavoritePanel.vue?vue&type=template&id=1f83a8dc&scoped=true"
import script from "./FavoritePanel.vue?vue&type=script&lang=js"
export * from "./FavoritePanel.vue?vue&type=script&lang=js"
import style0 from "../styles.css?vue&type=style&index=0&id=1f83a8dc&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f83a8dc",
  null
  
)

export default component.exports