<template>
  <div class="amount-pay">
    <div class="amount-pay--info-date">
      <div class="u-icon icon" />
      <Label class="ml-4" size="small" color="primary">
        {{ $t("panels.reserve.steps.3.availableTime") }}
        <span>{{ availableTime }}</span>
        {{ $t("panels.reserve.steps.3.deliveryDate") }}
        <span>{{ deliveryDate }}</span>
      </Label>
    </div>
    <div class="amount-pay--info-pay">
      <div class="amount-pay--info-pay-today">
        <div>
          <Label class="amount-pay--title" color="primary" size="medium">
            {{ $t("panels.reserve.steps.3.amountToPay") }}
          </Label>
        </div>
        <div>
          <Label color="primary" size="medium"
            >{{ getReservationCost }} €</Label
          >
        </div>
      </div>
      <Label class="info" color="tertiary" size="small">
        {{ $t("panels.reserve.steps.3.extraInfo") }}
      </Label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import { inBusinessDaysAheadFormatted, noon } from "../../../utils/date";

export default {
  name: "AmountToPay",
  components: {
    Label
  },
  computed: {
    ...mapGetters(["getReservationCost"]),
    availableTime() {
      return noon;
    },
    deliveryDate() {
      return inBusinessDaysAheadFormatted(10);
    }
  }
};
</script>

<style lang="css" scoped src="./AmountToPay.css"></style>
