<template>
  <Page>
    <template v-slot:content>
      <div class="cookiepage--container">
        <Section id="title">
          <SectionHeader
            :title="$t('pages.cookiePage.title')"
            type="primary"
            titleSize="large"
          ></SectionHeader>
        </Section>
        <Section id="content">
          <div class="container">
            <div class="form--container">
              <p>
                {{ $t("pages.cookiePage.subtitle") }}
              </p>
              <div>
                <CheckboxLabel
                  customClass="disable--checkbox"
                  :title="$t('pages.cookiePage.checkboxLabelList[0].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[0].subtitle')
                  "
                  type="primary"
                  disabled
                  :checked.sync="necessaryCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.NECESSARY)"
                />
                <CheckboxLabel
                  :title="$t('pages.cookiePage.checkboxLabelList[1].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[1].subtitle')
                  "
                  type="primary"
                  :checked.sync="performanceCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.PERFORMANCE)"
                />
                <CheckboxLabel
                  :title="$t('pages.cookiePage.checkboxLabelList[2].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[2].subtitle')
                  "
                  type="primary"
                  :checked.sync="targetingCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.TARGETING)"
                />
                <Button
                  customClass="save--cookies"
                  type="secondary"
                  :label="$t('pages.cookiePage.button')"
                  :uppercase="true"
                  :disabled="isButtonDisable"
                  :fill="true"
                  class="button-compare"
                  :loading="loading"
                  @onClick="onClickHandler"
                ></Button>
              </div>
            </div>
            <div class="info--container">
              <div class="info--content">
                <p v-html="$t('pages.cookiePage.aboutCookies.description')"></p>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </template>
  </Page>
</template>

<script>
import { SectionHeader, CheckboxLabel } from "../../molecules";
import { Section, Button } from "../../atoms";
import { Page } from "../../templates";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";
import { COOKIE_TYPE } from "../../../enums/cookieType";
import { getCookie, setCookie } from "../../../utils/cookies";

export default {
  name: "CookiePage",
  components: { SectionHeader, Section, Page, CheckboxLabel, Button },
  data() {
    return {
      necessaryCheck: true,
      performanceCheck: false,
      targetingCheck: false,
      loading: false,
      isButtonDisable: false,
      COOKIE_TYPE
    };
  },
  methods: {
    onCheckboxChanged(event, position) {
      if (position === COOKIE_TYPE.PERFORMANCE) {
        this.performanceCheck = event;
        setCookie("dsperformance", this.performanceCheck);
      }
      if (position === COOKIE_TYPE.TARGETING) {
        this.targetingCheck = event;
        setCookie("dstargeting", this.targetingCheck);
      }
      document.cookie;
    },
    onClickHandler() {
      this.isButtonDisable = false;
      this.loading = true;
      const result = {
        necessary: this.necessaryCheck,
        performance: this.performanceCheck,
        targeting: this.targetingCheck
      };
      registerEvent(FOOTPRINT_TYPES.COOKIE_PAGE, result);
      this.loading = false;
      this.isButtonDisable = false;

      //Reload to activate GMT
      window.location.reload();

      this.$toast.open({
        message: this.$t("toast.cookies.savedSettings"),
        dismissible: true,
        type: "info"
      });
    }
  },
  mounted() {
    this.performanceCheck = JSON.parse(getCookie("dsperformance")) || false;
    this.targetingCheck = JSON.parse(getCookie("dstargeting")) || false;

    setCookie("dsshowcookiesmodal", false);
  }
};
</script>

<style scoped lang="css" src="./CookiePage.css"></style>
