import {
  getAllVehicles,
  getUsedVehicles,
  getVehicle,
  getVehicleSpecifications,
  getVehicleImages,
  getVehicleImagesFullView,
  getTotalVehicles
} from "@/api/product";
import { search } from "@/api/search";

const INITIAL_PAGE = 1;

const state = {
  vehicles: [],
  usedVehicles: [],
  filterPanelOpened: false,
  similar: [],
  pagination: {},
  // Im not sure
  vehicleDetails: {},
  vehicleSpecifications: {},
  vehicleImages: {},
  vehicleImagesFullView: {},
  totalVehicles: 300,
  // errors
  errors: [],
  // for loading spinner
  loading: false,

  // sort
  page: 1,

  sortList: [
    {
      text: "Preço Mais Baixo",
      value: "Preço Mais Baixo",
      sort: "price",
      orderBy: "asc",
      selected: true
    },
    {
      text: "Preço Mais Alto",
      value: "Preço Mais Alto",
      sort: "price",
      orderBy: "desc",
      selected: false
    },
    {
      text: "Mensalidade Mais Baixa",
      value: "Mensalidade Mais Baixa",
      sort: "monthly",
      orderBy: "asc",
      selected: false
    },
    {
      text: "Mensalidade Mais Alta",
      value: "Mensalidade Mais Alta",
      sort: "monthly",
      orderBy: "desc",
      selected: false
    },
    {
      text: "Segmento",
      value: "Segmento",
      sort: "segment",
      orderBy: "asc",
      selected: false
    },
    {
      text: "Modelo",
      value: "Modelo",
      sort: "model",
      orderBy: "asc",
      selected: false
    }
  ]
};

const getters = {
  getAllVehicles: state => state.vehicles,
  getUsedVehicles: state => state.usedVehicles,
  isFilterPanelOpened: state => state.filterPanelOpened,
  getSimilarVehicles: state => state.similar,
  getVehicleDetails: state => state.vehicleDetails,
  getVehicleSpecifications: state => state.vehicleSpecifications,
  getVehicleImages: state => state.vehicleImages,
  getVehicleImagesFullView: state => state.vehicleImagesFullView,
  getTotalVehicles: state => state.totalVehicles,
  getPagination: state => state.pagination,
  getErrors: state => state.errors,
  getLoadingState: state => state.loading,
  getSort: state => state.sort,
  getOrderBy: state => state.orderBy,
  getPage: state => state.pagination?.currentPage,
  getSortList: state => state.sortList,
  getVehicleById: state => vin => {
    const i = state.vehicles.findIndex(v => v.vin === vin);
    if (~i) {
      return state.vehicles[i];
    }
  },
  getVehicleFavoriteByVin: state => vin => {
    const i = state.vehicles.findIndex(v => v.vin === vin);
    if (~i) {
      return state.vehicles[i].favorite;
    }
  }
};

const actions = {
  // FIXME: This method is not being used!!
  async storeAllVehicles({ commit }, page) {
    commit("setLoading", true);
    try {
      const response = await getAllVehicles(page);
      commit("setVehicles", response.data.data);
      commit("setPagination", response.data.pagination);
      commit("setPage", response.data.pagination["currentPage"]);

      if (response.data.data.length >= 4) {
        commit("setSimilar", response.data.data);
      }
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  async storeUsedVehicles({ commit }, page) {
    commit("setLoading", true);
    try {
      const response = await getUsedVehicles(page);
      commit("setUsedVehicles", response.data.data);
      commit("setPagination", response.data.pagination);
      commit("setPage", response.data.pagination["currentPage"]);

      if (response.data.data.length >= 4) {
        commit("setSimilar", response.data.data);
      }
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  async storeTotalVehicles({ commit }) {
    try {
      const response = await getTotalVehicles();
      commit("setTotalVehicles", response.data.data.count);
    } catch (e) {
      commit("setErrors", e);
    }
  },
  //Fetch vehicle by id from the api
  async storeVehicleDetails({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicle(id);
      commit("setVehicleDetails", response.data.data);
    } catch (e) {
      commit("setErrors", e);
    }
    commit("setLoading", false);
  },
  //Fetch vehicle specifications by id from the api
  async storeVehicleSpecifications({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleSpecifications(id);
      commit("setVehicleSpecifications", response.data.data);
      commit("setLoading", false);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
  },
  //Fetch vehicle images by id from the api
  async storeVehicleImages({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleImages(id);
      commit("setVehicleImages", response.data.data);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  //Fetch vehicle images full view by id from the api
  async storeVehicleImagesFullView({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleImagesFullView(id);
      commit("setVehicleImagesFullView", response.data.data);
      commit("setLoading", false);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
  },
  async storeAllSearchResults(
    { commit, state },
    { mySearch, dynamic = false }
  ) {
    commit("setLoading", true);
    try {
      const selected = state.sortList.find(i => i.selected === true);

      const response = await search(
        mySearch,
        state.pagination?.currentPage,
        selected.sort,
        selected.orderBy
      );
      if (response.data.data) {
        commit("setVehicles", response.data.data.searchResult);
        commit("setPagination", response.data.pagination);
        commit("setPage", response.data.pagination["currentPage"]);
        const searchUrl = response.data.data.searchUrl;
        if (!dynamic)
          history.pushState(
            {},
            null,
            searchUrl ? response.data.data.searchUrl : "/carros-novos"
          );
        // FIXME: temp
        if (response.data.data.length > 4) {
          commit("setSimilar", response.data.data);
        }
      } else {
        commit("setPagination", {});
      }
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  storeSort({ commit, state }, sortObj) {
    const { sort, orderBy } = sortObj;

    commit("setLoading", true);

    state.sortList = state.sortList.map(i => {
      if (i.sort === sort && i.orderBy === orderBy) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      return i;
    });

    commit("setLoading", false);
  },
  storePage({ commit }, page) {
    commit("setPage", page);
  },
  storeRemoveCompareChecked(vin) {
    state.vehicles.map(i => {
      if (i.vin === vin) {
        i.checked = false;
      }
    });
  }
};

// const modules = {};

// serves to define state value
const mutations = {
  openFilterPanel: state => (state.filterPanelOpened = true),
  closeFilterPanel: state => (state.filterPanelOpened = false),
  setVehicles(state, vehicles) {
    state.vehicles = vehicles;
  },
  setSimilar(state, vehicles) {
    state.similar = vehicles;
  },
  setVehicleDetails(state, vehicle) {
    state.vehicleDetails = vehicle;
  },
  setVehicleSpecifications(state, specifications) {
    state.vehicleSpecifications = specifications;
  },
  setVehicleImages(state, images) {
    state.vehicleImages = images;
  },
  setVehicleImagesFullView(state, imagesFullView) {
    state.vehicleImagesFullView = imagesFullView;
  },
  setPagination(state, page) {
    state.pagination = page;
  },
  setTotalVehicles(state, total) {
    state.totalVehicles = total;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPage(state, page) {
    state.pagination.currentPage = page;
  },
  resetPage(state) {
    state.pagination.currentPage = INITIAL_PAGE;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
