export const GOOGLE_TAG_MANAGER_EVENT_TYPES = {
  PRODUCT_CLICK: "eec.productClick",
  PRODUCT_VIEW: "eec.productView",
  PRODUCT_ADD_TO_CARD: "eec.productAddtoCart",
  PRODUCT_REMOVE_FROM_CART: "eec.productRemoveCart",
  PRODUCT_PURCHASE: "eec.purchase"
};

const ACTIONS = {
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_CLICK]: "click",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_VIEW]: "detail",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_ADD_TO_CARD]: "add",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_REMOVE_FROM_CART]: "remove",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_PURCHASE]: "purchase"
};

export const buildGoogleTagManagerPayload = (
  eventType,
  currentPage,
  vehicle
) => {
  return {
    currencyCode: "EUR",
    [ACTIONS[eventType]]: {
      actionField: {
        list: [currentPage]
      },
      products: [
        {
          id: vehicle.vin,
          name: `${vehicle.model} + ${vehicle.commercialDescription}`,
          category: vehicle.segment,
          quantity: 1,
          price: vehicle.pricePvp
        }
      ]
    }
  };
};

export const pushNewEvent = (eventType, currentPage, vehicle) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: eventType,
    ecommerce: buildGoogleTagManagerPayload(eventType, currentPage, vehicle)
  });
};
