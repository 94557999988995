import { getPriceFormatted } from "./currency";

const formatPrice = val =>
  getPriceFormatted(val, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

const formatMonthlyPrice = val => Number(val) + " €";

export { formatPrice, formatMonthlyPrice };
