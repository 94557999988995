import httpClient from "./httpClient";

const VEHICLE_BY_PLATE = "/retake/plate/";
const GET_VALUATION = "/retake/phase1";
const SAVE_RETAKE = "/users/retake/";
const GET_ALL_BRANDS = "/brands";
const GET_ALL_MODELS = "/brands/{id}/models?year=";

const PROVIDER_HEADER = {
  headers: { provider: "BCA" }
};

function getVehicleByPlate(plate) {
  return httpClient.get(VEHICLE_BY_PLATE + plate, PROVIDER_HEADER);
}

function getBrands() {
  return httpClient.get(GET_ALL_BRANDS, PROVIDER_HEADER);
}

function getModels(brand, year) {
  return httpClient.get(
    GET_ALL_MODELS.replace("{id}", brand) + year,
    PROVIDER_HEADER
  );
}

function getValuation(valuationDetails) {
  return httpClient.post(GET_VALUATION, valuationDetails);
}

function saveValuation(retakeId) {
  return httpClient.post(SAVE_RETAKE, retakeId);
}

export { getVehicleByPlate, getBrands, getModels, getValuation, saveValuation };
