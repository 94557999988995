<template>
  <div v-if="renderComponent">
    <div class="mt-4">
      <Select
        ref="districtRef"
        @onChange="onChangeDistrict"
        id="district-test-drive"
        :options="districts"
        :disabled="isUsed"
        :value="districtProp"
      ></Select>
    </div>
    <div class="mt-4">
      <Select
        ref="dealerRef"
        @onChange="onChangeDealer"
        id="dealer-test-drive"
        :options="concessions"
        :disabled="isUsed"
        :value="dealerId"
      ></Select>
    </div>
  </div>
</template>

<script>
import { Select } from "../../atoms";
import { mapGetters } from "vuex";
const ISLANDS = "ILHA";
const emptyDistricts = [
  {
    text: "Distrito",
    value: -1,
    disabled: true
  }
];

const emptyDealers = [
  {
    text: "Concessionário",
    value: -1,
    disabled: true
  }
];

export default {
  name: "ConcessionPicker",
  components: { Select },
  props: {
    showIsland: { type: Boolean, default: false },
    isUsed: { type: Boolean, default: false },
    districtProp: { type: String, default: "" },
    dealerId: { type: String, default: "" }
  },
  data() {
    return {
      district: "",
      concession: "",
      dealer: "",
      dealerRender: 0,
      renderComponent: true
    };
  },
  methods: {
    triggerRefs({ district, dealer }) {
      return [
        this.$refs.districtRef.handleInput({ target: { value: district } }),
        this.$refs.dealerRef.handleInput({ target: { value: dealer } })
      ];
    },
    toggleToast() {
      // FIXME: unimplemented
      // open Toast
    },
    onChangeDistrict(d) {
      this.district = d;
      this.dealerCode = undefined;
      this.dealer = undefined;
      this.emitDealer();
    },
    emitDealer() {
      this.$emit("onDealer", {
        dealerCode: this.dealer,
        district: this.district,
        dealer: this.concession
      });
    },
    onChangeDealer(d) {
      this.dealer = d;
      this.concession = this.concessions.find(c => c.value === d)?.text;
      this.emitDealer();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    findDealerByCode(dealerCode) {
      return this.getDealers.data.forEach(dist => {
        dist.dealers.forEach(deal => {
          if (deal.dealerCode === dealerCode) {
            return deal;
          }
        });
      });
    }
  },
  computed: {
    ...mapGetters(["getDealersArray", "getUserDealership", "getUserDistrict"]),
    concessionInfo() {
      // FIXME: format data to show in Toast
      return "Precisa de um concessionário porque...";
    },
    districts() {
      const d = this.showIsland
        ? this.getDealersArray.filter(it => !it.district.includes(ISLANDS))
        : this.getDealersArray;
      return [
        ...emptyDistricts,
        ...Array.from(new Set(d.map(({ district }) => district))).map(it => ({
          value: it,
          text: it,
          selected: it === this.district
        }))
      ];
    },
    concessions() {
      this.forceRerender();
      return [...emptyDealers, ...this.formatDealerList];
    } /* 
    concessionUsed() {
      if(this.isUsed) {
        return this.getDealersArray.find(it => it.dealerCode === this.dealerId)?.address;
      } else {
        return this.concession;
      }
    }, */,
    getDealerByDistrict() {
      return (
        this.getDealersArray.filter(it => it.district === this.district) || []
      );
    },
    formatDealerList() {
      return this.getDealerByDistrict.map(
        ({ dealerCode, municipality, designation: name }) => ({
          value: dealerCode,
          text: name + " - " + municipality,
          selected: dealerCode == this.dealer
        })
      );
    }
  },
  mounted() {
    // Assign Value from Backend and emit to ReservePanel

    if (this.isUsed) {
      const dealer = this.getDealersArray.find(
        it => it.dealerCode == this.dealerId
      );
      this.district = dealer?.district;
      this.dealer = dealer?.dealerCode;
      this.concession = dealer?.address;
      this.$emit("onDealer", {
        dealerCode: this.dealer,
        district: this.district,
        dealer: this.concession
      });
    } else {
      const dealer = this.getDealersArray.find(
        it => it.dealerCode == this.getUserDealership
      );
      this.district = dealer?.district;
      this.dealer = dealer?.dealerCode;
      this.concession = dealer?.address;
    }

    this.emitDealer();
  }
};
</script>

<style scoped>
.book-vehicle--progress {
  @apply mb-8;
}

.book-vehicle--title {
  @apply mt-4;
}

.book-vehicle--section-item {
  @apply flex;
  @apply justify-between;
}

.accordion-item {
  @apply mt-4;
  @apply p-0;
  border: none;
  list-style-type: none;
}

.book-vehicle--section-item-title-addition {
  @apply flex;
}

.additional-checkbox input {
  position: relative;
}

.reserve {
  @apply mt-4;
  @apply lg:ml-4;
  @apply lg:mt-0;
}

:deep(.accordion-item--icon-container) svg {
  color: #757989 !important;
}

span {
  @apply text-secondary-500;
  @apply underline;
}

span:hover {
  @apply cursor-pointer;
}

.postal-code-location {
  @apply flex;
  width: 100%;
}

.postal-code {
  width: 50%;
}

.location {
  width: 50%;
}

:deep(.selectWrapper) {
  width: 100% !important;
}

:deep(.selectWrapper) .selectCustom {
  width: 100% !important;
}

:deep(.selectCustom-trigger) {
  background-color: #f5f7f9;
  border: none;
}

.info {
  width: max-content;
}

.secure-info {
  @apply flex;
}

.secure-info .label {
  font-size: 14px;
}

.secure-info2 .label {
  font-size: 14px;
}

:deep(.button--icon-container) svg {
  width: 24px !important;
  height: 24px !important;
}

:deep(.tooltip:hover) .tooltip--text {
  transform: translateX(0);
  width: fit-content;
}

:deep(.indicator) {
  color: #e63312;
  font-size: 14px;
  line-height: 14px;
  margin: 7px;
}
</style>
