import axios from "axios";
import store from "../store";

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403
};

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_DIGITAL_STORE_BASE_URL,
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
  headers: {
    Accept: "application/json",
    companyId: "1",
    "Content-Type": "application/json"
  }
});

const getAuthToken = () => localStorage.getItem("token");

const authInterceptor = config => {
  if (getAuthToken()) {
    config.headers["Authorization"] =
      config.headers && config.headers["Authorization"] !== undefined
        ? config.headers["Authorization"]
        : `Bearer ` + getAuthToken();
  }
  return config;
};

const responseInterceptor = response => {
  return response;
};

const errorResponseInterceptor = error => {
  const { status } = error.response;

  if (
    (status === HTTP_STATUS.FORBIDDEN || status === HTTP_STATUS.UNAUTHORIZED) &&
    !error.response.request.responseURL.includes("logout") &&
    !error.response.request.responseURL.includes("users/me")
  ) {
    const isGuest = () => JSON.parse(localStorage.getItem("isGuest"));

    if (isGuest()) {
      store.dispatch("fetchGuestToken");
    } else {
      if (isGuest() != null && isGuest() != undefined) {
        store.dispatch("storeLogout");
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  return error;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  responseInterceptor,
  errorResponseInterceptor
);

export default httpClient;
