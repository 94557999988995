<template>
  <div>
    <div
      :class="['container--wrapper', customClass, classes]"
      :style="customStyles"
    >
      <div class="model-information">
        <div class="model-block">
          <span class="price-model">
            {{ productTitle }}
          </span>
        </div>
        <div class="model-description-block">
          <span class="price-model-description">
            {{ productDescription }}
          </span>
        </div>
      </div>
      <div class="finance--container">
        <div class="pvp-information-header">
          <span v-if="showPreviousPrice" class="header-previous-pvp">
            {{ $t("pages.detailsPage.financingLabels.price") }}
            {{ getFormattedPreviousPrice }}
          </span>
          <div class="header-pvp">
            <Tooltip :tooltip="tooltipDescription" :position="'bottom'">
              <template v-slot:content>
                <img
                  width="30"
                  height="30"
                  src="../../../assets/icons/infoGray.svg"
                />
              </template>
            </Tooltip>
            <Label class="absolute-price">{{ getFormattedTotalPrice }}</Label>
          </div>
        </div>
      </div>
    </div>
    <div class="vehicle-details">
      <div v-if="!isMobile" class="vehicle-details-wrapper">
        <div class="vehicle-details-features">
          <div v-if="getPower" class="vehicle-details-features-block">
            <img src="../../../assets/images/engine.svg" />
            <div class="features-block-description">
              <span>{{
                this.$t(
                  `pages.detailsPage.details.vehicleSpecifications.units.power`,
                  { power: getPower }
                )
              }}</span>
            </div>
          </div>
          <div v-if="getTransmission" class="vehicle-details-features-block">
            <img src="../../../assets/images/caixa-de-veloci.svg" />
            <div class="features-block-description">
              <span>{{ getTransmission }}</span>
            </div>
          </div>
          <div v-if="getFuel" class="vehicle-details-features-block">
            <img
              :src="
                require(`../../../assets/images/${
                  getFuel === 'Eletrico' ? 'energy.svg' : 'bomba-de-gasoli.svg'
                }`)
              "
            />
            <div class="features-block-description">
              <span>{{ getFuel }}</span>
            </div>
          </div>
          <div v-if="getConsumption" class="vehicle-details-features-block">
            <img src="../../../assets/images/speedometer.svg" />
            <div class="features-block-description">
              <span>{{
                `${getFuel === "Eletrico" ? "Até " : ""}${getConsumption}${
                  getFuel === "Eletrico" ? " km" : " l/100 km"
                }`
              }}</span>
            </div>
          </div>
        </div>
        <div class="vehicle-details-features-separator"></div>
        <div class="vehicle-details-features">
          <div v-if="getPower" class="vehicle-details-features-block">
            <Icon icon="kilometers" iconHeight="36" iconWidth="33" />
            <div class="features-block-description">
              <span>{{
                this.$t(
                  `pages.detailsPage.details.vehicleSpecifications.units.kilometers`,
                  { km: getKilometers }
                )
              }}</span>
            </div>
          </div>
          <div v-if="getTransmission" class="vehicle-details-features-block">
            <Icon icon="year" iconHeight="36" iconWidth="28" />
            <div class="features-block-description">
              <span>{{ vehicleDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="vehicle-details-financing">
        <div class="vehicle-details-financing-st">
          <span id="vehicle-detail-taeg">{{
            `${$t("legalFinanceLabels.taeg")}: ${getTaeg}`
          }}</span>
          <span id="vehicle-detail-month">
            {{ `${modality}/${$t("legalFinanceLabels.monthly")}` }}</span
          >
        </div>
        <div class="vehicle-details-financing-nd">
          <div class="financial-detail">
            <span>{{ `${$t("legalFinanceLabels.entry")}:` }}</span>
            <span class="financial-value">{{
              ` ${getVehicleInitialDepositFormatted}`
            }}</span>
          </div>
          <div class="financial-detail">
            <span>{{ `${$t("legalFinanceLabels.duration")}:` }}</span>
            <span class="financial-value">{{
              ` ${getVehicleNumberMonths} ${$t("legalFinanceLabels.months")}`
            }}</span>
          </div>
          <div class="financial-detail">
            <span>{{ `${$t("legalFinanceLabels.financingValue")}:` }}</span>
            <span class="financial-value">{{
              ` ${getFormattedCapitalToFinance}`
            }}</span>
          </div>
          <div v-if="getIsOpenDrive" class="financial-detail">
            <span>{{ `${$t("legalFinanceLabels.residualValue")}:` }}</span>
            <span class="financial-value">{{
              ` ${getFormattedResidualValue}`
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "../../atoms";
import { Icon } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

export default {
  name: "UsedVehicleDetailInformation",
  components: { Tooltip, Icon },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "small",
      validator: value => {
        return ["small", "medium"].indexOf(value) !== -1;
      }
    },
    noTooltip: {
      type: Boolean,
      default: false
    },
    absolutePrice: {
      type: Boolean,
      default: false
    },
    productTitle: {
      type: String,
      default: ""
    },
    productDescription: {
      type: String,
      default: ""
    },
    priceLabel: {
      type: String,
      default: ""
    },
    financeTaeg: {
      type: String,
      default: ""
    },
    modality: {
      type: String,
      default: ""
    },
    iconName: {
      type: String,
      default: ""
    },
    tooltipDescription: {
      type: String,
      default: ""
    },
    tooltipPosition: {
      type: String,
      default: "top"
    },
    missingActions: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addLeadingZero(number) {
      if (number < 10) {
        return "0" + number;
      } else {
        return number.toString();
      }
    }
  },
  computed: {
    ...mapGetters([
      "getVehicleResidualValue",
      "getVehicleNumberMonths",
      "getFormattedMonthlyPriceSimple",
      "getWithFinancing",
      "getTaeg",
      "getVehicleInitialDepositFormatted",
      "getFormattedCapitalToFinance",
      "getFormattedPrice",
      "getFormattedPreviousPrice",
      "getPreviousPrice",
      "getFormattedTotalPrice",
      "getVehicleInitialDepositFormatted",
      "getFuel",
      "getVersion",
      "getConsumption",
      "getTransmission",
      "getPower",
      "getIsOpenDrive",
      "getFormattedResidualValue",
      "getKilometers",
      "getMonth",
      "getYear",
      "getFinancialContribution"
    ]),
    classes() {
      return {
        ...this.customClass
      };
    },
    titleSize() {
      return this.size === "medium"
        ? "medium"
        : this.size === "small"
        ? "small"
        : "";
    },
    vehicleDate() {
      return `${this.addLeadingZero(this.getMonth)}/${this.getYear}`;
    },
    smallSize() {
      return this.size === "medium"
        ? "small"
        : this.size === "small"
        ? "x-small"
        : "";
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    showPreviousPrice() {
      return (
        this.$store.getters.getPriceBasedOnType({
          hasFinancing: !this.absolutePrice && this.getFinancialContribution,
          isPrevious: true
        }) &&
        this.$store.getters.getPriceBasedOnType({
          hasFinancing: !this.absolutePrice && this.getFinancialContribution,
          isPrevious: true
        }) !=
          this.$store.getters.getPriceBasedOnType({
            hasFinancing: !this.absolutePrice && this.getFinancialContribution,
            isPrevious: false
          })
      );
    }
  }
};
</script>

<style lang="css" scoped src="./UsedVehicleDetailInformation.css"></style>
