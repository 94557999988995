import Vue from "vue";
import Vuex from "vuex";

import reserve from "./modules/reserve";
import vehicles from "./modules/vehicles.js";
import autocomplete from "./modules/autocomplete.js";
import authentication from "./modules/authentication";
import finance from "./modules/finance";
import returns from "./modules/returns";
import retake from "./modules/retake";

import responsiveness from "./modules/responsiveness";

/*
  Store refactoring - New modules
*/
import spinnerModule from "./modules/spinner";
import CompareModule from "./modules/compareModule";
import FavoritesModule from "./modules/favoritesModule";
import ConfigurationModule from "./modules/configurationModule";
import SearchModule from "./modules/searchModule";
import DropdownModule from "./modules/dropdownModule";

import { RESERVE_STATUS } from "../enums/reserveStatus.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isOpenSignupPopup: false,
    isOpenSigninPopup: false,
    isOpenVerificationAccount: false,
    isOpenReservePanel: false,
    isOpenRetakePanel: false,
    isOpenNavbar: false,
    reserveStatus: RESERVE_STATUS.START,
    reserveContact: false,
    reserveBack: true,
    reserveVehicle: true,
    reservePanelKey: 1
  },
  getters: {
    getIsOpenPopup: state => state.isOpenSigninPopup || state.isOpenSignupPopup,
    getIsOpenSigninPopup: state => state.isOpenSigninPopup,
    getIsOpenVerificationAccount: state => state.isOpenVerificationAccount,
    getReserveStatus: state => state.reserveStatus,
    getReserveContact: state => state.reserveContact,
    getReserveBack: state => state.reserveBack,
    getReserveVehicle: state => state.reserveVehicle,
    getReservePanelKey: state => state.reservePanelKey
  },

  mutations: {
    setOpenVerificationAccount(state, open) {
      state.isOpenVerificationAccount = open;
    },
    setReserveStatus(state, status) {
      state.reserveStatus = status;
    },
    setReserveContact(state, contact) {
      state.reserveContact = contact;
    },
    setReserveBack(state, back) {
      state.reserveBack = back;
    },
    setReserveVehicle(state, vehicle) {
      state.reserveVehicle = vehicle;
    },
    setReservePanelKey(state) {
      state.reservePanelKey++;
    }
  },

  modules: {
    vehicles,
    autocomplete,
    authentication,
    finance,
    reserve,
    returns,
    retake,
    responsiveness,
    // new namespaced modules
    spinnerModule,
    CompareModule,
    FavoritesModule,
    ConfigurationModule,
    SearchModule,
    DropdownModule
  }
});
