<template class="template">
  <div :class="['footer', classes, customClass]" :styles="customStyles">
    <div class="footer-container">
      <div class="footer--left">
        <img
          class="logo pointer"
          :src="require(`../../../assets/images/${filenameLogo}`)"
          width="78px"
          height="40px"
          @click="redirectTo('home')"
        />
      </div>
      <div class="footer--center">
        <div class="footer--inner-container">
          <div class="footer--navigation">
            <div
              class="footer--navigation-pages"
              v-for="(category, index) in footerList"
              :key="index"
            >
              <Link
                size="small"
                color="primary"
                bold="extra-bold"
                customClass="footer--title"
                :uppercase="true"
                :label="category[0].title"
                :href="category[0].url"
              />

              <LinkList
                v-if="false"
                class="footer--link-list"
                display="vertical"
                size="small"
                bold="extra-light"
                color="secondary"
                :list="category[0].list"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer--right">
        <div class="footer--right-title">
          <Label size="small">Siga-nos em:</Label>
        </div>
        <div class="footer--right-images">
          <div>
            <div
              class="pointer icon"
              :style="{
                backgroundImage: `url(${require('../../../assets/icons/Facebook.svg')})`
              }"
              @click="redirectTo('facebook')"
            />
          </div>
          <div>
            <div
              class="pointer icon"
              :style="{
                backgroundImage: `url(${require('../../../assets/icons/Youtube.svg')})`
              }"
              @click="redirectTo('youtube')"
            />
          </div>
          <div>
            <div
              class="pointer icon"
              :style="{
                backgroundImage: `url(${require('../../../assets/icons/Instagram.svg')})`
              }"
              @click="redirectTo('instagram')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footer--copyright-message">
      <div class="footer--copyright-message-left">
        <Label color="tertiary" size="x-small">{{ copyrightMessage }}</Label>
      </div>
      <div class="footer--copyright-message-right">
        <div
          class="subfooter-link"
          v-for="(list, index) in subfooterList"
          :key="index"
        >
          <Link
            :class="linkClass(index)"
            size="small"
            color="tertiary"
            :label="list.title"
            :href="index == 1 ? '#' : list.url"
          />
        </div>
      </div>
    </div>
    <div class="legal-mention">
      <Label color="tertiary" size="small"
        >{{ $t("footer.legalMention") }}
      </Label>
    </div>
  </div>
</template>

<script>
import { Label, Link } from "../../atoms";
import { LinkList } from "../../molecules";
export default {
  name: "Footer",
  components: {
    Label,
    LinkList,
    Link
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    copyrightMessage: {
      type: String,
      default: "Copyright © 2021. All Rights Reserved."
    },
    footerList: {
      type: Array[Object],
      default: ""
    },
    subfooterList: {
      type: Array[Object],
      default: ""
    },
    filenameLogo: {
      type: String,
      default: "logo.svg"
    },
    usernameFacebook: {
      type: String,
      default: "HyundaiPortugal"
    },
    usernameYoutube: {
      type: String,
      default: "HyundaiPortugal"
    },
    usernameInstagram: {
      type: String,
      default: "hyundaipt"
    }
  },
  methods: {
    linkClass(idx) {
      return idx === 1
        ? "js-rgc-open-cookies-settings subfooter pointer"
        : "subfooter pointer";
    },
    redirectTo(goTo) {
      console.log(goTo);
      if (goTo === "instagram") {
        window.open(
          "https://www.instagram.com/" + this.usernameInstagram,
          "_blank"
        );
      } else if (goTo === "facebook") {
        window.open(
          "https://www.facebook.com/" + this.usernameFacebook,
          "_blank"
        );
      } else if (goTo === "youtube") {
        window.open(
          "https://www.youtube.com/user/" + this.usernameYoutube,
          "_blank"
        );
      } else if (goTo === "home") {
        window.open("https://www.hyundai.pt/", "_blank");
      }
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return { ...this.styles };
    }
  }
};
</script>

<style lang="css" scoped src="./Footer.css"></style>
