<template>
  <div
    :class="['vehicleInfo--container', customClass, classes]"
    :style="customStyles"
  >
    <div class="container--wrapper" v-if="isUsed">
      <div v-if="!isSold && !isReserved" class="delivery-type">
        <span>{{ $t("pages.detailsPage.delivery") }}</span>
      </div>
      <div
        class="location-info"
        v-if="
          getSelectedVehicle.dealer.designation &&
            getSelectedVehicle.dealer.address &&
            getSelectedVehicle.dealer.municipality
        "
      >
        <div class="container--wrapper-col">
          <div class="icon-label--wrapper">
            <img
              class="location-pin"
              :src="require('../../../assets/icons/locationPin.svg')"
            />
            <Label color="tertiary" size="medium" align="right">
              {{ getSelectedVehicle.dealer.designation }}
            </Label>
          </div>
          <Label color="tertiary" size="small" align="right">
            {{ getSelectedVehicle.dealer.address }}
          </Label>
          <Label color="tertiary" size="small" align="right">
            {{ getSelectedVehicle.dealer.municipality }}
          </Label>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!isSold && !isReserved" class="delivery-type">
        <span>{{ $t("pages.detailsPage.delivery") }}</span>
      </div>
    </div>
    <div class="vehicle-info--priceModel-wrapper">
      <div class="book-vehicle--header-details" v-if="isUsed">
        <UsedVehicleDetailInformation
          size="medium"
          :absolutePrice="!getWithFinancing || getIsOpenDrive"
          :product-title="getModel"
          :product-description="getDescription"
          :finance-taeg="financeTaeg"
          :modality="getFormattedMonthlyPrice"
          :price-label="getFormattedTotalPrice"
          :tooltipDescription="tooltipDescription"
          :tooltipDescriptionPvp="tooltipDescription"
          tooltipPosition="left"
          icon-name="info-circle"
          :isMobile="isMobile"
        >
        </UsedVehicleDetailInformation>
      </div>
      <div class="book-vehicle--header-details" v-else>
        <VehicleDetailInformation
          size="medium"
          :absolutePrice="!getWithFinancing || getIsOpenDrive"
          :product-title="getModel"
          :product-description="getDescription"
          :finance-taeg="financeTaeg"
          :modality="getFormattedMonthlyPrice"
          :price-label="
            getWithFinancing ? getFormattedPrice : getFormattedTotalPrice
          "
          :tooltipDescription="tooltipDescription"
          :tooltipDescriptionPvp="tooltipDescription"
          tooltipPosition="left"
          icon-name="info-circle"
          :isMobile="isMobile"
        >
        </VehicleDetailInformation>
      </div>
    </div>
    <div
      :class="
        !anyModalOpened
          ? ['vehicle-info--confirm-wrapper']
          : [
              'vehicle-info--confirm-wrapper',
              'vehicle-info--confirm-wrapper-modal-opened'
            ]
      "
    >
      <div class="button-actions">
        <div class="redirect-section" @click="onSimulationRedirect">
          <span class="simulation-redirect">{{
            $t("pages.detailsPage.financingLabels.simulate")
          }}</span
          ><img src="../../../assets/images/goToArrow.svg" />
        </div>
        <div v-if="isMobile" class="vehicle-details">
          <div class="vehicle-details-features">
            <div v-if="getPower" class="vehicle-details-features-block">
              <img src="../../../assets/images/engine.svg" />
              <div class="features-block-description">
                <span>{{
                  $t(
                    `pages.detailsPage.details.vehicleSpecifications.units.power`,
                    { power: getPower }
                  )
                }}</span>
              </div>
            </div>
            <div v-if="getTransmission" class="vehicle-details-features-block">
              <img src="../../../assets/images/caixa-de-veloci.svg" />
              <div class="features-block-description">
                <span>{{ getTransmission }}</span>
              </div>
            </div>
            <div v-if="getFuel" class="vehicle-details-features-block">
              <img
                :src="
                  require(`../../../assets/images/${
                    getFuel === 'Eletrico'
                      ? 'energy.svg'
                      : 'bomba-de-gasoli.svg'
                  }`)
                "
              />
              <div class="features-block-description">
                <span>{{ getFuel }}</span>
              </div>
            </div>
            <div v-if="getConsumption" class="vehicle-details-features-block">
              <img src="../../../assets/images/speedometer.svg" />
              <div class="features-block-description">
                <span>{{
                  `${getFuel === "Eletrico" ? "Até " : ""}${getConsumption}${
                    getFuel === "Eletrico" ? " km" : " l/100 km"
                  }`
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <Button
          :uppercase="isReserved || isSold ? true : false"
          type="secondary"
          :fill="true"
          :filename="isReserved || isSold ? 'Lock.svg' : ''"
          :rightArrowIcon="isReserved || isSold ? false : true"
          iconPosition="left"
          :custom-class="
            isReserved || isSold
              ? 'vehicle-reserved'
              : 'vehicle-info--button-margin'
          "
          :label="isReserved || isSold ? filterTagLabel : buttonLabel"
          :disabled="isReserved || isSold"
          :border="isReserved || isSold"
          @onClick="buttonClickHandler"
        />
        <div class="reserve-button" v-if="!isReserved && !isSold && !isMobile">
          <Button
            type="primary"
            size="large"
            :rightArrowIcon="true"
            :label="$t('panels.reserve.steps.1.btnLabels.testDriveBtn')"
            @onClick="onTestDrive"
          />
          <Button
            type="primary"
            size="large"
            :rightArrowIcon="true"
            :label="$t('panels.reserve.steps.1.btnLabels.ContactBtn')"
            @onClick="onContactRequest"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Label } from "./../../atoms";
import { mapGetters } from "vuex";
import VehicleDetailInformation from "./../../refactor/VehicleDetailInformation/VehicleDetailInformation";
import UsedVehicleDetailInformation from "./../../refactor/UsedVehicleDetailInformation/UsedVehicleDetailInformation";
import { getPriceFormatted } from "../../../utils/currency.js";

import { RESERVE_TYPE } from "../../../enums/reserveTypes.js";
export default {
  name: "VehicleInfo",
  components: {
    VehicleDetailInformation,
    Button,
    Label,
    UsedVehicleDetailInformation
  },
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    },
    vehicle: {
      type: Object,
      default: () => ({})
    },
    productDetail: {
      type: Object,
      required: true
    },
    linkList: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    labelList: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    icon: {
      type: String,
      required: false,
      default: ""
    },
    tooltipDescription: {
      type: String,
      required: true,
      default: ""
    },
    buttonLabel: {
      type: String,
      required: true,
      default: ""
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "getFormattedTaeg",
      "getRawTaeg",
      "getFormattedMonthlyPrice",
      "getDescription",
      "getModel",
      "getReservationType",
      "getFormattedPrice",
      "getFormattedTotalPrice",
      "getVehicleResidualValue",
      "getVehicleNumberMonths",
      "getFormattedMonthlyPriceSimple",
      "getWithFinancing",
      "getTaeg",
      "getVehicleInitialDepositFormatted",
      "getFormattedCapitalToFinance",
      "getFormattedPrice",
      "getFormattedPreviousPrice",
      "getPreviousPrice",
      "getFormattedTotalPrice",
      "getVehicleInitialDepositFormatted",
      "getFuel",
      "getVersion",
      "getConsumption",
      "getTransmission",
      "getIsOpenDrive",
      "getPower",
      "getSelectedVehicle",
      "getConditionId"
    ]),
    ...mapGetters({
      compareModalIsOpen: "CompareModule/isPanelOpened",
      favoriteModalIsOpen: "FavoritesModule/isPanelOpened"
    }),
    anyModalOpened() {
      return this.compareModalIsOpen || this.favoriteModalIsOpen;
    },
    isReserved() {
      return this.getReservationType === RESERVE_TYPE.RESERVED;
    },
    isSold() {
      return this.getReservationType === RESERVE_TYPE.BOUGHT;
    },
    vehicleLocation() {
      /* return this.vehicle.location; */
      return "Lisboa";
    },
    vehicleLocationAddressLine1() {
      /* return this.vehicle.locationAddressLine1; */
      return "Avenida Duque de Ávila, 185";
    },
    vehicleLocationAddressLine2() {
      /* return this.vehicle.locationAddressLine2; */
      return "1888-888, Lisboa";
    },
    filterTagLabel() {
      return this.getReservationType === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveTypeBtnLabel.1")
        : this.$t("labels.reserveTypeBtnLabel.2");
    },
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    isUsed() {
      return this.getConditionId == 2;
    },
    financeTaeg() {
      return this.$t("pages.detailsPage.taeg", {
        taeg: getPriceFormatted(this.getRawTaeg, {
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).replace("€", "")
      });
    }
  },
  methods: {
    onTestDrive() {
      this.$emit("onTestDrive");
    },
    onContactRequest() {
      this.$emit("onContactRequest");
    },
    onSimulationRedirect() {
      this.$emit("onSimulationRedirect");
    },
    buttonClickHandler() {
      this.$emit("onClick");
      this.$emit("interestFootprint");
    },
    goto(event) {
      this.$emit("onGoto", event);
    }
  }
};
</script>

<style lang="css" scoped src="./VehicleInfo.css"></style>
