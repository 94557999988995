// eslint-disable-next-line no-unused-vars
import {
  getAllReturns,
  updateReturn,
  getReturnDetails,
  deleteReturn,
  addPhoto
} from "../../api/returns.js";
import { getPriceFormatted } from "../../utils/currency";
import { RETAKESTATUS } from "../../enums/retakeStatus.js";

function changeDataBasedOnBackend(arr, base) {
  const parsedArr = createDuplicatedDataForCheckboxes(arr);
  return base?.map((it, idx) => ({
    ...it,
    ...parsedArr[idx]
  }));
}

function createDuplicatedDataForCheckboxes(arr) {
  return arr?.map(({ radioGroupName, radioGroupValue }) => ({
    radioGroupName,
    radioGroupValue,
    radioGroupList: [
      {
        title: "Bom",
        radioValue: "GOOD",
        radioId: "interiors-good",
        checked: "GOOD" === radioGroupValue
      },
      {
        title: "Razoável",
        radioValue: "AVERAG",
        radioId: "interiors-reasonable",
        checked: "AVERAG" === radioGroupValue
      },
      {
        title: "Mau",
        radioValue: "POOR",
        radioId: "interiors-bad",
        checked: "POOR" === radioGroupValue
      }
    ]
  }));
}

const state = {
  returns: [],
  selectedFinancingValue: "",
  returnObj: {},
  // errors
  errorsReturns: [],
  // for loading spinner
  loadingReturn: false,

  generalStatus: [
    {
      title: "Carroçaria",
      subtitle:
        "Linhas e plásticos exteriores (incluindo Jantes mas não pneus)",
      radioGroupName: "ExteriorBodywork",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "bodywork-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "bodywork-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "bodywork-bad",
          checked: false
        }
      ]
    },
    {
      title: "Vidros",
      subtitle:
        "Vidros exteriores (incluindo espelhos). Pára-brisas, todas as janelas, os espelhos e as luzes",
      radioGroupName: "ExteriorGlass",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "glasses-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "glasses-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "glasses-bad",
          checked: false
        }
      ]
    },
    {
      title: "Interiores",
      subtitle:
        "Estofos, tapetes, forras e plásticos, tabelier e seus componentes.",
      radioGroupName: "Interior",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "interiors-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "interiors-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "interiors-bad",
          checked: false
        }
      ]
    },
    {
      title: "Items Removíveis",
      subtitle:
        "Acessórios e peças várias - isto é manuais, livros de revisão, macaco, Roda suplente (ou Kit furo), encostos de cabeça, chapeleira, assentos extras ou ferramentas etc.",
      radioGroupName: "BitsAndPieces",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "removable-items-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "removable-items-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "removable-items-bad",
          checked: false
        }
      ]
    }
  ],

  mechanicalState: [
    {
      title: "Desempenho do Motor",
      subtitle:
        "Luzes de gestão do motor, Ignição, progresso, fuma excessivamente ou anormalmente barulhento",
      radioGroupName: "EnginePerformance",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "engine-performance-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "engine-performance-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "engine-performance-bad",
          checked: false
        }
      ]
    },
    {
      title: "Desempenho na Condução",
      subtitle:
        "Embraiagem, caixa de velocidades, suspensão, direção, transmissão ou travagem",
      radioGroupName: "DrivePerformance",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "driving-performance-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "driving-performance-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "driving-performance-bad",
          checked: false
        }
      ]
    },
    {
      title: "Items Eléctricos",
      subtitle:
        "ABS, Interruptores, chaufage, ar condicionado, sistema navegação, sistemas de entretenimento (son/video/etc), tecto de abrir ou lâmpadas",
      radioGroupName: "ElectricalItems",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          title: "Bom",
          radioValue: "GOOD",
          radioId: "electrical-items-good",
          checked: false
        },
        {
          title: "Razoável",
          radioValue: "AVERAG",
          radioId: "electrical-items-reasonable",
          checked: false
        },
        {
          title: "Mau",
          radioValue: "POOR",
          radioId: "electrical-items-bad",
          checked: false
        }
      ]
    }
  ]
};

const getters = {
  getSelectedFinancingValue: state => state.selectedFinancingValue,
  getGeneralStatus: state => state.generalStatus,
  getMechanicalState: state => state.mechanicalState,
  getReturnKilometers: state => state.returnObj?.kilometers,
  getReturns: state => {
    return state.returns;
  },
  getIsRetakeSubmitted: state => state.returns[0].submitted,
  getReturn: state => {
    return state.returnObj;
  },
  getReturnId: state => state.returns[0]?.id,
  getLoadingReturns: state => state.loadingReturn,
  getReturnValue: state =>
    state.returns[0]?.status === 2
      ? state.returns[0]?.actualValuation
      : state.returns[0]?.initialValuation,
  getFormattedReturnValue: state => {
    const value =
      state.returns[0]?.status === RETAKESTATUS.Q1_UPDATED ||
      state.returns[0]?.status === RETAKESTATUS.FINALIZED
        ? state.returns[0]?.actualValuation
        : state.returns[0]?.initialValuation;

    return value === 0 || value === "0"
      ? value
      : getPriceFormatted(value, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },

  getReturnLicensePlate: state => {
    return state.returns[0]?.licensePlate;
  },
  getEquipments: state => state.returnObj?.tradeInPayload?.features?.equipments
};

const actions = {
  async storeGetReturnDetails({ commit }, id) {
    commit("setLoadingReturns", true);

    try {
      const response = await getReturnDetails(id);
      commit("setReturn", response.data.data);
      commit(
        "processMechanicalStateList",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.mechanicalStateList
      );
      commit(
        "processGeneralStatus",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.generalStatusList
      );
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("setLoadingReturns", false);
  },

  async storeUpdateReturnDetails({ commit }, returnDetails) {
    try {
      commit("spinnerModule/setLoading", true, { root: true });
      //
      // !! - required
      //
      // - additionalInformation:
      //    - vehicleOperation (financing)    !!
      //    - accessories
      // - features
      //    - airConditiong
      //    - bcaid                           !!
      //    - cabintriminlay
      //    - equipments
      //    - evaluation (adjustedValuation)  !!
      //    - externalColor                   !!
      //    - internalColor                   !!
      //    - kilometers                      !!
      //    - plate                           !!
      //    - plateDate                       !!
      //    - tradeInId                       !!
      // - mechanicalAndGeneralStatus         !!
      //    - generalStatusList
      //    - mechanicalStateList
      // - submitted
      //
      // **Extra**
      // - files (format?)
      //
      var res = await updateReturn(returnDetails);
      // commit("setReturn", res.data.data);
      if (res.response?.status && res.response?.status == 500) {
        return Promise.reject();
      }
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject();
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },

  async storeAddPhoto({ commit }, files) {
    commit("setLoadingReturns", true);
    try {
      const response = await addPhoto(files);

      if (response.status === 404 || response.status === 500) {
        commit("setErrorsReturns", response.data.message);
      } else {
        // commit("setAddPhoto", response.data.data);
        state.errorsReturns = [];
      }
    } catch (e) {
      // commit("setErrorsReturns", e);
    } finally {
      commit("setLoadingReturns", false);
    }
  },

  async storeGetAllReturns({ commit }) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const response = await getAllReturns();

      commit("setAllReturns", response.data.data);
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("spinnerModule/setLoading", false, { root: true });
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },

  async storeDeleteReturn({ commit, dispatch }, id) {
    commit("setLoadingReturns", true);

    try {
      await deleteReturn(id);
      dispatch("storeGetAllReturns");
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("setLoadingReturns", false);
  }
};

// serves to define state value
const mutations = {
  setSelectedFinancingValue(state, x) {
    state.selectedFinancingValue = x;
  },
  setMechanicalState(state, list) {
    state.mechanicalState = list;
  },
  setGeneralStatus(state, list) {
    state.generalStatus = list;
  },
  processGeneralStatus(state, list) {
    state.generalStatus = changeDataBasedOnBackend(list, state.generalStatus);
  },
  processMechanicalStateList(state, list) {
    state.mechanicalState = changeDataBasedOnBackend(
      list,
      state.mechanicalState
    );
  },
  setEquipments(state, [equipment, value]) {
    const eqs = state.returnObj?.tradeInPayload?.features?.equipments;
    if (eqs) {
      state.returnObj.tradeInPayload.features.equipments = eqs.map(e =>
        e.value !== equipment
          ? e
          : {
              ...e,
              checked: value
            }
      );
    }
  },
  setAirConditioning(state, val) {
    state.returnObj.tradeInPayload.features.airConditioning = val;
  },
  setInteriorColor(state, val) {
    state.returnObj.tradeInPayload.features.cabinTrimInlay = val;
  },
  setExteriorColor(state, val) {
    state.returnObj.tradeInPayload.features.externalColor = val;
  },
  setKilometers(state, val) {
    state.returnObj.tradeInPayload.features.kilometers = Number(val);
  },
  setReturnKilometers(state, val) {
    state.returnObj.kilometers = val;
  },
  setPlateDateFormatted(state) {
    state.returnObj.tradeInPayload.features.plateDate =
      state.returnObj.year + "-" + state.returnObj.month + "-01";
  },
  setAllReturns(state, returns) {
    state.returns = returns;
  },
  setReturn(state, returnObj) {
    if (!returnObj.tradeInPayload) {
      returnObj.tradeInPayload = {
        mechanicalAndGeneralStatus: {
          generalStatusList: [],
          mechanicalStateList: []
        },
        additionalInformation: { vehicleOperation: [] },
        features: {}
      };
    }
    state.returnObj = returnObj;
  },
  setReturnByIndex(state, index) {
    state.returnObj = state.returns[index];
  },
  setErrorsReturns(state, errors) {
    state.errorsReturns = errors;
  },
  setLoadingReturns(state, loading) {
    state.loadingReturn = loading;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
