<template>
  <Panel :open="isPanelOpened" @onClose="closePanel">
    <template slot="header">
      <div class="header-section">
        <Label size="medium" color="primary" bold="bold">{{
          $t("panels.compare.title")
        }}</Label>
        <Label color="tertiary" size="x-small" class="subtitle">{{
          subtitle
        }}</Label>
      </div>
    </template>

    <template slot="content">
      <div class="empty-message--wrapper" v-if="empty">
        <div class="empty-message">
          <span>{{ $t("panels.compare.empty.title") }}</span>
          <p>
            {{
              $t("panels.compare.empty.message1", {
                minimum: getMinimumVehicles
              })
            }}
            <img
              src="../../../assets/images/compare-filled.png"
              width="15"
              height="13"
              class="inline-icon-paragraph"
            />
            {{ $t("panels.compare.empty.message2") }}
          </p>
          <Button
            :label="$t('panels.compare.buttons.search')"
            customClass="whitespace-nowrap
          button-search"
            size="medium"
            type="primary"
            fill
            @onClick="goToSearch"
          />
        </div>
      </div>
      <div class="grid-wrapper" v-else>
        <div v-for="vehicle in compareList" :key="vehicle.vin">
          <SearchVehicleResultCard
            :vehicle="vehicle"
            v-if="vehicle.conditionId == 1"
          />
          <UsedSearchVehicleResultCard panel :vehicle="vehicle" v-else />
        </div>
        <div class="compare-btn" v-if="readyToCompare">
          <Button
            @onClick="handleClick"
            customClass="low-btn"
            :label="$t('panels.compare.buttons.compare')"
          />
        </div>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

import {
  SearchVehicleResultCard,
  UsedSearchVehicleResultCard
} from "../../organisms";
import { Panel } from "../../molecules";
import { Label, Button } from "../../atoms";

import { PAGES } from "../../../enums/pages";
import { MINIMUM_VEHICLES } from "../../../enums/compare";

export default {
  name: "ComparePanel",
  components: {
    Label,
    Panel,
    SearchVehicleResultCard,
    Button,
    UsedSearchVehicleResultCard
  },
  methods: {
    ...mapMutations({
      closePanel: "CompareModule/closePanel"
    }),
    ...mapActions({
      fetchVehiclesToCompare: "CompareModule/fetchVehicles"
    }),
    async handleClick() {
      this.closePanel();
      this.$store.commit("spinnerModule/setLoading", true, { root: true });
      await this.fetchVehiclesToCompare();
      this.$store.commit("spinnerModule/setLoading", false, { root: true });

      if (this.$route.name !== PAGES.COMPARATOR)
        this.$router.push({
          name: PAGES.COMPARATOR
        });
    },
    goToSearch() {
      this.closePanel();

      if (this.$route.name !== PAGES.SEARCH)
        this.$router.push({ name: PAGES.SEARCH });
    }
  },
  computed: {
    ...mapGetters({
      empty: "CompareModule/empty",
      readyToCompare: "CompareModule/readyToCompare",
      compareList: "CompareModule/list",
      isPanelOpened: "CompareModule/isPanelOpened"
    }),
    subtitle() {
      return !this.readyToCompare && !this.empty
        ? this.$t("panels.compare.subtitleMinimum", {
            minimum: this.getMinimumVehicles
          })
        : this.$t("panels.compare.subtitle");
    },
    getMinimumVehicles() {
      return MINIMUM_VEHICLES;
    }
  }
};
</script>

<style scoped src="../styles.css"></style>
