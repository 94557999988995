<template>
  <div class="slide">
    <div class="background-layer" v-if="exterior"></div>
    <div class="prespective-selector">
      <div class="open-gallery-side" v-if="isMobile" @click="togglePOV">
        <div v-if="!exterior" class="open-gallery">
          <span>Exterior</span>
        </div>
        <div v-else class="open-gallery">
          <span>Interior</span>
        </div>
      </div>
      <div class="open-gallery-side" v-if="isMobile" @click="openGallery">
        <div class="open-gallery">
          <span>Galeria</span>
        </div>
      </div>
    </div>

    <template v-if="!exterior">
      <VuePannellum
        v-if="panoramicImage"
        :src="
          vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url
        "
      ></VuePannellum>
      <Slide360Viewer
        key="view-interior"
        altType="view-interior"
        :modelName="vehicle.model"
        :isMobile="isMobile"
        v-else
        :path="
          getFilePath(
            vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url
          )
        "
        :fileName="
          vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url
        "
        :total="
          vehicle.carDetailImage.rotationImages.rotatingInternalImages.length -
            1
        "
      ></Slide360Viewer>
    </template>
    <template v-if="exterior">
      <Slide360Viewer
        key="view-exterior"
        altType="view-exterior"
        :modelName="vehicle.model"
        :isMobile="isMobile"
        :fileName="
          vehicle.carDetailImage.rotationImages.rotatingExternalImages[0].url
        "
        :images="
          vehicle.carDetailImage.rotationImages.rotatingExternalImages.map(
            image => image.url
          )
        "
      ></Slide360Viewer>
      <!-- <img
        :src="
          vehicle.carDetailCard && vehicle.carDetailCard.imageUrl
            ? vehicle.carDetailCard.imageUrl
            : require('./../../../assets/images/placeholder.jpg')
        "
        class="default-car-image"
      /> -->
    </template>
    <NormalSlide
      v-if="!isMobile"
      :vehicleModel="vehicle.model"
      :images="
        vehicle.carDetailImage.staticImages.externalImages
          .map(img => img.url)
          .concat(
            vehicle.carDetailImage.staticImages.internalImages.map(
              img => img.url
            )
          )
      "
    ></NormalSlide>
    <CoolLightBox
      v-if="isMobile"
      :items="
        vehicle.carDetailImage.staticImages.externalImages
          .map(img => img.url)
          .concat(
            vehicle.carDetailImage.staticImages.internalImages.map(
              img => img.url
            )
          )
      "
      :index="selectedImage"
      @close="selectedImage = null"
      :effect="'fade'"
    />
    <div v-if="!isMobile" class="controls">
      <div class="zoom-control">
        <div class="zoom-in" @click="$emit('zoomIn')"></div>
        <div class="zoom-out" @click="$emit('zoomOut')"></div>
      </div>
      <div
        class="perspective-switch"
        @click="
          exterior = !exterior;
          $emit('switchPerspective');
        "
      >
        {{ exterior ? "VER INTERIOR" : "VER EXTERIOR" }}
      </div>
    </div>
    <Label v-if="!isMobile" class="label-info" color="tertiary" size="small">{{
      $t("pages.detailsPage.imageLabel")
    }}</Label>
  </div>
</template>

<script>
import VuePannellum from "vue-pannellum";
import { Label } from "./../../atoms";
import { NormalSlide, Slide360Viewer } from "../../organisms";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    VuePannellum,
    NormalSlide,
    Slide360Viewer,
    Label,
    CoolLightBox
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {}
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      exterior: true,
      selectedImage: null
    };
  },
  methods: {
    getFilePath(url) {
      const filePath = url.slice(0, url.lastIndexOf("/") + 1);
      return filePath;
    },
    getFilenamePattern(url) {
      const filename = url.substring(url.lastIndexOf("/") + 1);

      var splitFilename = filename.split("_");
      const [rest, extension] = splitFilename[splitFilename.length - 1].split(
        "."
      );
      rest;
      const pattern =
        splitFilename.slice(0, splitFilename.length - 1).join("_") +
        `_x.` +
        extension;

      return pattern;
    },
    openGallery() {
      this.selectedImage = 0;
    },
    togglePOV() {
      this.exterior = !this.exterior;
    }
  },
  computed: {
    panoramicImage() {
      return (
        this.vehicle.carDetailImage.rotationImages.rotatingInternalImages
          .length === 1
      );
    }
  }
};
</script>

<style lang="css" scoped src="./VehicleImageViewer.css"></style>
