<template>
  <div class="interested--faqs">
    <div class="padding-container">
      <Label customClass="interested--faqs-title" size="small">{{
        $t("panels.reserve.steps.1.faqs.title")
      }}</Label>
      <Accordion>
        <template slot="items">
          <AccordionItem
            type="tertiary"
            :accordionIndex="index"
            v-for="(accordion, index) in reservationFaqs"
            accordion-index="idx"
            :key="accordion.id"
            :activeIndex="activeIndex"
            @onToggle="onToggleAccordionHandler"
          >
            <template slot="accordion-trigger">
              <Label color="tertiary" size="small" bold="extra-light">
                {{ accordion.title }}
              </Label>
            </template>
            <template slot="accordion-content">
              <Label size="small" color="tertiary">
                {{ accordion.description }}
              </Label>
            </template>
          </AccordionItem>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label, AccordionItem } from "../../atoms";
import { Accordion } from "../../molecules";

import { FAQ_TYPE } from "../../../enums/faqs";

export default {
  name: "FaqCard",
  components: { Label, Accordion, AccordionItem },
  data() {
    return {
      activeIndex: -1
    };
  },
  methods: {
    onToggleAccordionHandler(event) {
      const eventData = this.reservationFaqs[event.index];

      this.activeIndex === event.index
        ? (this.activeIndex = -1)
        : ((this.activeIndex = event.index),
          this.$emit("onActiveFootprint", eventData));
    }
  },
  computed: {
    ...mapGetters(["getVehicleFaqs"]),
    reservationFaqs() {
      return this.getVehicleFaqs?.filter(
        it => it.faqType == FAQ_TYPE.RESERVATION
      );
    }
  }
};
</script>

<style scoped>
.interested--faqs {
  background-color: #f5f7f9;
  @apply relative;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50vh;
  min-height: max-content;
}

@media (min-width: 640px) {
  .interested--faqs {
    height: 35vh;
  }
}

.interested--faqs-title {
  /* border-bottom: 1px solid gray; */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

:deep(.accordion) {
  @apply mt-4;
  border-top: 1px solid #e5ecf0;
}

:deep(.accordion-item) {
  padding-left: 0;
  border-color: #e5ecf0;
}

.padding-container {
  margin: 2rem;
  padding-top: 15px;
}
</style>
