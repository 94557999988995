<template>
  <div>
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.personalData.pageTitle")
      }}</Label>
    </div>
    <div class="content">
      <CustomerForm
        v-if="dataReady"
        :name="getUserName"
        :email="getUserEmail"
        :phone="getUserPhoneNumber"
        :address="getUserAddress"
        :dealer="getUserDealership"
        :dealers="getDealers"
        @onSavePersonalData="changePersonalInfoHandler"
        @onSavePassword="changePasswordHandler"
        @onSaveAddress="changeAddressHandler"
        @onSaveConcession="changeDistrictHandler"
      ></CustomerForm>
    </div>
  </div>
</template>

<script>
import { Label } from "../../../atoms";
import { CustomerForm } from "../../../organisms";
import { mapGetters } from "vuex";
import {
  changePersonalInfo,
  changeUserPassword,
  changeUserAddress,
  changeUserDealer
} from "./../../../../api/userMe";
import { PAGES } from "@/enums/pages";

export default {
  components: {
    CustomerForm,
    Label
  },

  data() {
    return {
      dataReady: false
    };
  },

  metaInfo() {
    return {
      title: this.$t("meta.savedDetails.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.savedDetails.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.savedDetails.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.savedDetails.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.savedDetails.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.savedDetails.image")
        }
      ]
    };
  },
  methods: {
    async changeDistrictHandler(userDealer) {
      try {
        const response = await changeUserDealer(userDealer);
        if (response.status === 200) {
          //Success changing personalInfo
          await this.$store.dispatch("storeUserInfo");
          this.$toast.open({
            message: this.$t("toast.concession.concessionChanged"),
            dismissible: true,
            type: "info"
          });
        } else {
          this.$toast.open({
            message:
              "Lamentamos mas ocorreu um erro a efetuar a alteração pretendida, por favor tente novamente mais tarde",
            dismissible: true,
            type: "error"
          });
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          //redirect to homepage.

          localStorage.removeItem("token");
          this.$router.push({
            name: PAGES.HOME
          });
        }
      }
    },
    async changePersonalInfoHandler(personalData) {
      try {
        const response = await changePersonalInfo(personalData);
        if (response.status === 200) {
          //Success changing personalInfo
          await this.$store.dispatch("storeUserInfo");
          //success token!
          this.$toast.open({
            message: this.$t("toast.personalData.personalDataChanged"),
            dismissible: true,
            type: "info"
          });
        } else {
          this.$toast.open({
            message: this.$t("toast.personalData.personalDataChangedError"),
            dismissible: true,
            type: "error"
          });
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          //redirect to homepage.

          localStorage.removeItem("token");
          this.$router.push({
            name: PAGES.HOME
          });
        }
      }
    },
    async changePasswordHandler(payload) {
      try {
        const response = await changeUserPassword(payload);
        if (response.status === 200) {
          await this.$store.dispatch("storeUserInfo");
          //success toast!
          this.$toast.open({
            message: this.$t("toast.password.passwordChanged"),
            dismissible: true,
            type: "info"
          });
        } else {
          this.$toast.open({
            message: this.$t("toast.password.passwordChangedError"),
            dismissible: true,
            type: "error"
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          //redirect to homepage.
          localStorage.removeItem("token");
          this.$router.push({
            name: PAGES.HOME
          });
        }
      }
    },
    async changeAddressHandler(newAddress) {
      try {
        const response = await changeUserAddress(newAddress);
        if (response.status === 200) {
          await this.$store.dispatch("storeUserInfo");
          //success token.
          this.$toast.open({
            message: this.$t("toast.address.addressChanged"),
            dismissible: true,
            type: "info"
          });
        } else {
          this.$toast.open({
            message: this.$t("toast.address.addressChangedError"),
            dismissible: true,
            type: "error"
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          //redirect to homepage.
          localStorage.removeItem("token");
          this.$router.push({
            name: PAGES.HOME
          });
        }
      }
    }
  },

  async mounted() {
    await this.$store.dispatch("SearchModule/dropdown/fetchDropdowns");
    await this.$store.dispatch("storeDealers");
    //await this.$store.dispatch("storeDropdowns");
    this.dataReady = true;
  },

  computed: {
    ...mapGetters([
      "getUserEmail",
      "getUserName",
      "getUserPhoneNumber",
      "getUserAddress",
      "getUserDealership",
      "getReceipt"
    ]),
    ...mapGetters({
      getDealers: "getDealersArray"
    })
  }
};
</script>

<style lang="css" scoped src="./PersonalData.css"></style>
