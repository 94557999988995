<template>
  <div>
    <FormStep :step="1" :validate="validate" />

    <PriceDetails :vehicle="selectedVehicle" />

    <div class="book-vehicle--title">
      <Label class="reserve--header" size="medium" color="primary"
        >Informação Geral</Label
      >
    </div>
    <div class="book-vehicle--section-item">
      <div class="book-vehicle--section-item-title">
        <Label size="small" color="tertiary">{{
          $t("legalFinanceLabels.pvpValue")
        }}</Label>
      </div>
      <div class="book-vehicle--section-item-description">
        <Label size="small" color="tertiary">{{
          getIsOpenDrive || !getWithFinancing
            ? getFormattedTotalPrice
            : getFormattedPrice
        }}</Label>
      </div>
    </div>
    <div class="book-vehicle--section-item">
      <div class="book-vehicle--section-item-title">
        <Label size="small" color="tertiary">{{
          $t("legalFinanceLabels.reserveSignal")
        }}</Label>
      </div>
      <div class="book-vehicle--section-item-description">
        <Label size="small" color="tertiary">{{ getReservationCost }} €</Label>
      </div>
    </div>

    <AccordionItem
      :accordionIndex="0"
      type="tertiary"
      iconClose="chevron-down"
      iconOpen="chevron-up"
      accordion-index="idx"
      :activeIndex="0"
      @onToggle="onToggleAccordionHandler"
    >
      <template slot="accordion-trigger">
        <Label
          class="reserve--header"
          color="primary"
          size="medium"
          bold="normal"
        >
          {{
            getWithFinancing
              ? "Dados Simulação Financeira"
              : "Dados Pagamento a pronto"
          }}
        </Label>
      </template>
      <template slot="accordion-content">
        <div v-if="getWithFinancing">
          <div class="book-vehicle--section-item">
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.monthValue")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <div class="label-icon">
                <Label size="small" color="tertiary">{{
                  getFormattedMonthlyPriceSimple
                }}</Label>
                <Tooltip position="left" :tooltip="getTooltip">
                  <template v-slot:content>
                    <img
                      width="16"
                      height="16"
                      class="icon--margin"
                      name="info-circle"
                      size="x-small"
                      color="#AAB1C7"
                      src="../../../../assets/images/info-toggle.png"
                    />
                  </template>
                </Tooltip>
              </div>
            </div>
          </div>
          <div class="book-vehicle--section-item">
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.numberOfMonths")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <Label size="small" color="tertiary">{{
                getNbrPaymentsValue
              }}</Label>
            </div>
          </div>
          <div class="book-vehicle--section-item">
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.taeg")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <Label size="small" color="tertiary">{{ getTaeg }}</Label>
            </div>
          </div>
          <div class="book-vehicle--section-item">
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.financingValue")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <Label size="small" color="tertiary">{{
                getFormattedCapitalToFinance
              }}</Label>
            </div>
          </div>
          <div class="book-vehicle--section-item">
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.entry")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <Label size="small" color="tertiary">{{
                getFormattedInitialDeposit
              }}</Label>
            </div>
          </div>
          <div
            v-if="getIsOpenDrive && getVehicleResidualValue"
            class="book-vehicle--section-item"
          >
            <div class="book-vehicle--section-item-title">
              <Label size="small" color="tertiary">{{
                $t("legalFinanceLabels.residualValue")
              }}</Label>
            </div>
            <div class="book-vehicle--section-item-description">
              <Label size="small" color="tertiary"
                >{{ getVehicleResidualValue }} €</Label
              >
            </div>
          </div>
        </div>

        <div class="book-vehicle--section-item">
          <div class="book-vehicle--section-item-title">
            <Label size="small" color="tertiary">Retoma</Label>
          </div>

          <div class="book-vehicle--section-item-description">
            <Label
              :class="getIncludeRetake && !isGuest ? 'underline' : ''"
              size="small"
              :color="getIncludeRetake && !isGuest ? 'secondary' : 'tertiary'"
              >{{
                getIncludeRetake && !isGuest ? getReturnLicensePlate : "Não"
              }}</Label
            >
          </div>
        </div>
        <div
          class="book-vehicle--section-item"
          v-if="getIncludeRetake && !isGuest"
        >
          <div class="book-vehicle--section-item-title">
            <Label size="small" color="tertiary">Valorização da Retoma</Label>
          </div>
          <div class="book-vehicle--section-item-description">
            <Label size="small" color="tertiary">{{
              getRetakeValuationLabel
            }}</Label>
          </div>
        </div>
      </template>
    </AccordionItem>
    <AmountToPay class="mt-4" />
  </div>
</template>

<script>
import { FormStep } from "../../../molecules";
import { AmountToPay, PriceDetails } from "../../../organisms";
import { Label, AccordionItem, Tooltip } from "../../../atoms";
import { mapGetters } from "vuex";
import { FOOTPRINT_TYPES, registerEvent } from "../../../../api/footprint";

export default {
  name: "DetailsFormStep",
  components: {
    FormStep,
    AmountToPay,
    Label,
    AccordionItem,
    PriceDetails,
    Tooltip
  },
  props: {
    selectedVehicle: {
      type: Object
    }
  },
  data() {
    return {
      retake: {},
      activeIndex: -1,
      deliveryDate: "a long string"
    };
  },
  methods: {
    validate() {
      return true;
    },
    onToggleAccordionHandler(event) {
      this.activeIndex === event.index
        ? (this.activeIndex = -1)
        : (this.activeIndex = event.index);
    },
    registerFootprint() {
      let infoObj = {};
      if (this.getWithFinancing) {
        infoObj = {
          pvpValue: this.getFormattedPrice,
          withFinancing: true,
          monthlyPrice: this.getFormattedMonthlyPriceSimple,
          nbrPaymentsValue: this.getNbrPaymentsValue,
          taeg: this.getTaeg,
          capitalToFinance: this.getFormattedCapitalToFinance,
          initialDeposit: this.getFormattedInitialDeposit
        };
        if (this.getIsOpenDrive && this.getVehicleResidualValue)
          infoObj["residualValue"] = this.getVehicleResidualValue;
      } else {
        infoObj = {
          pvpValue: this.getFormattedTotalPrice,
          withFinancing: false
        };
      }

      const retakeObj =
        this.getIncludeRetake && !this.isGuest
          ? {
              retakePlate: this.getReturnLicensePlate,
              retakeValue: this.getRetakeValuationLabel
            }
          : {};
      const result = {
        reservationCost: this.getReservationCost,
        info: infoObj,
        retakeInfo: retakeObj,
        vin: this.getSelectedVehicle?.vin
      };
      registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_1, result);
    }
  },
  computed: {
    ...mapGetters([
      "getTaeg",
      "getNbrPaymentsValue",
      "getFormattedPrice",
      "getFormattedTaeg",
      "getFormattedCapitalToFinance",
      "getFormattedInitialDeposit",
      "getFormattedMonthlyPriceSimple",
      "getWithFinancing",
      "getIsOpenDrive",
      "getVehicleResidualValue",
      "getReservationCost",
      "getFormattedReturnValue",
      "getIncludeRetake",
      "getReturnLicensePlate",
      "isGuest",
      "getSelectedVehicle",
      "getFormattedTotalPrice",
      "getTooltip"
    ]),
    getRetakeValuationLabel() {
      return this.getFormattedReturnValue === 0 ||
        this.getFormattedReturnValue === "0"
        ? this.$t("pages.retake.emptyValue")
        : this.getFormattedReturnValue;
    }
  },
  mounted() {
    this.registerFootprint();
  }
};
</script>

<style scoped src="./styles.css"></style>
