export function keyByVal(obj, val) {
  return Object.keys(obj).find(k => obj[k] === val);
}

export function convertToContain(word) {
  const first = word[0];
  return `has${first.toUpperCase()}${word.slice(1)}`;
}

export function validateClasses(obj, validations, valid, invalid) {
  return Object.fromEntries(
    Object.keys(obj).map(k => [k, validations[k] ? valid : invalid])
  );
}
