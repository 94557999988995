<template>
  <div
    :class="['container--wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="title--content">
      <div class="saved-return-label-tag">
        <Label
          class="price-model"
          color="primary"
          bold="bold"
          :size="titleSize"
          :customClass="size === 'medium' ? 'title--content-title' : ''"
        >
          {{ productTitle }}
        </Label>
        <div class="saved-return-state" v-if="missingActions">
          <StateLabel v-if="true" alertType="red">
            <div class="state-label--container">
              <CpIcon class="danger--icon" icon="alert" />
              {{ $t("missingActions.sectionTitle") }}
            </div></StateLabel
          >
        </div>
      </div>
      <Label
        class="price-model-description"
        color="tertiary"
        :size="smallSize"
        :customClass="size === 'medium' ? 'title--content-subtitle' : ''"
      >
        {{ productDescription }}
      </Label>
    </div>
    <div class="finance--container">
      <div class="finance--wrapper">
        <Label
          class="finance-model-price"
          color="secondary"
          bold="bold"
          :size="smallSize"
          align="right"
          :customClass="size === 'medium' ? 'finance--container-title' : ''"
        >
          {{ priceLabel }}
        </Label>
        <Tooltip
          v-if="!noTooltip"
          :tooltip="tooltipDescription"
          :position="tooltipPosition"
        >
          <template v-slot:content>
            <div
              class="icon--margin"
              :style="{
                backgroundImage: `url(${require('../../../assets/icons/infoGray.svg')})`
              }"
            />
          </template>
        </Tooltip>
      </div>
      <div class="finance--wrapper" v-if="!absolutePrice">
        <Label
          color="secondary"
          class="finance-model-modality modality--margin"
          :size="smallSize"
          align="right"
          :customClass="size === 'medium' ? 'finance--container-subtitle' : ''"
        >
          {{ modality + $t("pages.detailsPage.modality") }}</Label
        >
        <Tooltip
          v-if="!noTooltip"
          :tooltip="tooltipDescription"
          :position="tooltipPosition"
        >
          <template v-slot:content>
            <div
              class="icon--margin"
              :style="{
                backgroundImage: `url(${require('../../../assets/icons/infoGray.svg')})`
              }"
            />
          </template>
        </Tooltip>
      </div>
      <Label
        v-if="!absolutePrice"
        class="finance-model-taeg taeg--margin"
        color="secondary"
        bold="normal"
        :size="smallSize"
        align="right"
        :customClass="
          size === 'medium'
            ? 'finance--container-subtitle'
            : 'finance-model-taeg'
        "
      >
        {{ financeTaeg }}
      </Label>
      <div class="financing-details" v-if="hasFinancing">
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${$t("legalFinanceLabels.duration")}: `
            }}</span
            ><span class="financing-details-dark">{{ monthlyFinancing }}</span>
          </p>
        </div>
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${$t("legalFinanceLabels.entry")}: `
            }}</span
            ><span class="financing-details-dark">{{ entryFinancing }}</span>
          </p>
        </div>
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${$t("legalFinanceLabels.financingValue")}: `
            }}</span
            ><span class="financing-details-dark">{{
              totalValueFinancing
            }}</span>
          </p>
        </div>
        <div v-if="getIsOpenDrive">
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${$t("legalFinanceLabels.residualValue")}: `
            }}</span
            ><span class="financing-details-dark">{{
              getFormattedResidualValue
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Tooltip, StateLabel } from "./../../atoms";
import CpIcon from "../../refactor/CpIcon.vue";
import { getPriceFormatted } from "../../../utils/currency";
import { mapGetters } from "vuex";

export default {
  name: "PriceModelDetails",
  components: { Label, Tooltip, StateLabel, CpIcon },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "small",
      validator: value => {
        return ["small", "medium"].indexOf(value) !== -1;
      }
    },
    noTooltip: {
      type: Boolean,
      default: false
    },
    absolutePrice: {
      type: Boolean,
      default: false
    },
    productTitle: {
      type: String,
      default: ""
    },
    productDescription: {
      type: String,
      default: ""
    },
    priceLabel: {
      type: String,
      default: ""
    },
    financeTaeg: {
      type: String,
      default: ""
    },
    modality: {
      type: String,
      default: ""
    },
    iconName: {
      type: String,
      default: ""
    },
    tooltipDescription: {
      type: String,
      default: ""
    },
    tooltipPosition: {
      type: String,
      default: "top"
    },
    missingActions: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getFormattedResidualValue", "getIsOpenDrive"]),
    hasFinancing() {
      if (
        this.vehicle &&
        this.vehicle.tooltipDescription &&
        this.vehicle.tooltipDescription.depositValue &&
        this.vehicle.tooltipDescription.months &&
        this.vehicle.tooltipDescription.capitalFinance &&
        this.financeTaeg &&
        this.modality
      )
        return true;
      return false;
    },
    entryFinancing() {
      if (this.hasFinancing)
        return `${getPriceFormatted(
          this.vehicle.carDetailFinance.depositValue
        )}`;
      return "";
    },
    monthlyFinancing() {
      if (this.hasFinancing)
        return `${this.vehicle.carDetailFinance.months} ${this.$t(
          "legalFinanceLabels.months"
        )}`;
      return "";
    },
    totalValueFinancing() {
      if (this.hasFinancing)
        return `${getPriceFormatted(
          this.vehicle.carDetailFinance.capitalFinance
        )}`;
      return "";
    },
    classes() {
      return {
        ...this.customClass
      };
    },
    titleSize() {
      return this.size === "medium"
        ? "medium"
        : this.size === "small"
        ? "small"
        : "";
    },

    smallSize() {
      return this.size === "medium"
        ? "small"
        : this.size === "small"
        ? "x-small"
        : "";
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>

<style lang="css" scoped src="./PriceModelDetails.css"></style>
