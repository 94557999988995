<template>
  <div>
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.reservations.pageTitle")
      }}</Label>
    </div>
    <div class="content">
      <div v-if="getReservations.length > 0">
        <div
          class="content--list"
          v-for="(it, idx) in getReservations"
          :key="idx"
        >
          <SavedReservation
            class="saved-reservation"
            :vehicle="it.vehicle"
            :retake="it.retake"
            :preApprovalStatus="it.vehicle.preApprovalStatus"
            :isPreApprovalSubmitted="it.isPreApprovalSubmitted"
            @onClick="openReservation(it)"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          :title="$t('pages.personalPage.reservations.noResults.title')"
          :description="
            $t('pages.personalPage.reservations.noResults.description')
          "
          :labelButton="
            $t('pages.personalPage.reservations.noResults.labelButton')
          "
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../../atoms";
import { NoResults } from "../../../organisms";
import SavedReservation from "./SavedReservation.vue";
import { PAGES } from "@/enums/pages";

export default {
  name: "Reservations",
  components: {
    Label,
    SavedReservation,
    NoResults
  },
  metaInfo() {
    return {
      title: this.$t("meta.savedReserve.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.savedReserve.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.savedReserve.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.savedReserve.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.savedReserve.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.savedReserve.image")
        }
      ]
    };
  },
  methods: {
    openReservation(it) {
      this.$store.commit("setCurrentReservation", it.id);
      this.$router.push({
        name: PAGES.RESERVATION_DETAIL,
        params: { id: it.id }
      });
    },
    goToSearch() {
      this.$router.push({ name: PAGES.SEARCH });
    },
    onClickNoResultsHandler() {
      this.$router.push({
        name: PAGES.SEARCH
      });
    }
  },
  mounted() {
    this.$store.dispatch("storeReservations");
  },
  computed: {
    ...mapGetters(["getReservations"])
  }
};
</script>

<style lang="css" scoped>
.header {
  border-bottom: 2px solid #e4ecf0;
  @apply pb-5;
}

.content {
  @apply mt-5;
}

.content--list {
  margin-bottom: 1rem;
}

.page-title {
  font-size: 24px;
  @apply font-medium;
  @apply font-head;
}

.btn-container {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1rem;
}

.search-btn {
  width: 250px;
  height: 60px;
}
</style>
