<template>
  <Page>
    <template v-slot:content>
      <Section id="hero">
        <HomeMainSection
          :text-title="$t('pages.homepage.sections.hero.title')"
          :text-subtitle="$t('pages.homepage.sections.hero.subtitle')"
          text-button-left="Pesquisar viatura"
          text-button-right="Valorizar Retoma"
          separator="ou"
          :slideshow="slideshow"
          :images="images"
          @onClickLeft="onClickSearchVehicles"
        />
      </Section>
      <Section id="quicklinks">
        <QuickLinks
          class="quicklink-1"
          :title="$t('pages.homepage.sections.quicklinks[0].title')"
          :description="$t('pages.homepage.sections.quicklinks[0].description')"
          :style="quicklink1"
        >
          <div class="quicklink-used-new">
            <div>
              <Label size="small" color="primary">{{
                $t(
                  "pages.homepage.sections.hero.SearchSegmentMonthly.typeOfVehicle"
                )
              }}</Label>
              <div class="quicklink-used-new-radio">
                <RadioButtonLabel
                  class="mr-8 radio--quicklink"
                  :title="
                    $t('pages.homepage.sections.hero.SearchSegmentMonthly.new')
                  "
                  radioId="new"
                  radioName="carTypeQuickLink"
                  radioValue="new"
                  radioColor="primary"
                  :matchColor="true"
                  :radioChecked="true"
                  checked-color="secondary"
                  @onChange="onChangeCarType"
                ></RadioButtonLabel>
                <RadioButtonLabel
                  :title="
                    $t('pages.homepage.sections.hero.SearchSegmentMonthly.used')
                  "
                  radioId="used"
                  radioName="carTypeQuickLink"
                  :matchColor="true"
                  radioValue="used"
                  radioColor="primary"
                  checked-color="secondary"
                  @onChange="onChangeCarType"
                ></RadioButtonLabel>
              </div>
            </div>
            <div class="quicklink-autocomplete-row">
              <AutocompleteDropdown
                class="autocomplete-input"
                type="tertiary"
                :placeholder="
                  $t(
                    'pages.homepage.sections.quicklinks[0].autocomplete.placeholder'
                  )
                "
                :results="getAutoCompleteResults"
                @onSearch="onStoreResults"
                @onEnterKey="autocompleteSearch"
                @onSelect="autocompleteSearch"
                @onChange="
                  value => {
                    mySearch = value;
                  }
                "
                ref="autocompleteDropdown"
              ></AutocompleteDropdown>
              <Button
                type="primary"
                fill
                uppercase
                size="small"
                icon="search"
                :border="false"
                @onClick="
                  () =>
                    autocompleteSearch({
                      type: 'needle',
                      value: mySearch,
                      id: 0
                    })
                "
                class="autocomplete-button"
              />
            </div>
          </div>
        </QuickLinks>
        <QuickLinks
          class="quicklink-2"
          :title="$t('pages.homepage.sections.quicklinks[1].title')"
          :description="$t('pages.homepage.sections.quicklinks[1].description')"
          :style="quicklink2"
        >
          <Button
            style="height: 60px"
            type="primary"
            fill
            uppercase
            :label="$t('pages.homepage.sections.quicklinks[1].button')"
            @onClick="onClickRetake"
          />
        </QuickLinks>
        <QuickLinks
          class="quicklink-3"
          :title="$t('pages.homepage.sections.quicklinks[2].title')"
          :description="$t('pages.homepage.sections.quicklinks[2].description')"
          :style="quicklink3"
        >
          <Button
            style="height: 60px"
            type="primary"
            uppercase
            :label="$t('pages.homepage.sections.quicklinks[2].button')"
            @onClick="onClickHowItWorks"
          />
        </QuickLinks>
      </Section>
      <Section id="shopping-experience">
        <div class="shopping-experience--header">
          <div class="w-full mb-2 shopping-experience--header-title">
            <Heading
              class="h1"
              :label="$t('pages.homepage.sections.experience.title')"
              tag="h1"
              weight="bold"
            />
          </div>

          <div class="w-full shopping-experience--header-subtitle">
            <Label size="small" color="tertiary">{{
              $t("pages.homepage.sections.experience.description")
            }}</Label>
          </div>
        </div>
        <div class="shopping-experience--content">
          <Swiper
            customClass="experience-swiper"
            :spaceBetween="0"
            :pagination="false"
            freeMode
          >
            <SwiperSlide>
              <div class="w-full mb-6 lg:w-1/4 lg:mb-0">
                <ExperiencesCard
                  title="01."
                  :subtitle="
                    $t(
                      'pages.homepage.sections.experience.experiences[0].title'
                    )
                  "
                  :file="
                    $t('resources.images.homepage.experiencesSwiperCard1') ||
                      require(`../../../assets/images/experience-1.webp`)
                  "
                  :text="
                    $t(
                      'pages.homepage.sections.experience.experiences[0].description'
                    )
                  "
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0">
                <ExperiencesCard
                  title="02."
                  :subtitle="
                    $t(
                      'pages.homepage.sections.experience.experiences[1].title'
                    )
                  "
                  :file="
                    $t('resources.images.homepage.experiencesSwiperCard2') ||
                      require(`../../../assets/images/experience-3.webp`)
                  "
                  :text="
                    $t(
                      'pages.homepage.sections.experience.experiences[1].description'
                    )
                  "
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0">
                <ExperiencesCard
                  title="03."
                  :subtitle="
                    $t(
                      'pages.homepage.sections.experience.experiences[2].title'
                    )
                  "
                  :file="
                    $t('resources.images.homepage.experiencesSwiperCard3') ||
                      require(`../../../assets/images/experience-4.webp`)
                  "
                  :text="
                    $t(
                      'pages.homepage.sections.experience.experiences[2].description'
                    )
                  "
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="w-full mb-6 lg:w-1/4 lg:ml-6 lg:mb-0">
                <ExperiencesCard
                  title="04."
                  :subtitle="
                    $t(
                      'pages.homepage.sections.experience.experiences[3].title'
                    )
                  "
                  :file="
                    $t('resources.images.homepage.experiencesSwiperCard4') ||
                      require(`../../../assets/images/experience-2.webp`)
                  "
                  :text="
                    $t(
                      'pages.homepage.sections.experience.experiences[3].description'
                    )
                  "
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Section>
      <Section id="separator300">
        <Separator300 :numberVehicles="getTotalVehicles"></Separator300>
      </Section>

      <Section id="process">
        <div class="process--content">
          <Swiper
            customClass="experience-swiper"
            :spaceBetween="0"
            :pagination="false"
            navigation
            freeMode
          >
            <SwiperSlide
              v-for="(cardWithSegment, index) in $t(
                'pages.homepage.sections.process.cards'
              )"
              :key="index"
            >
              <CardWithSegment
                @onSearch="onSearchCardWithSegment"
                class="card-with-segment"
                :filename="cardWithSegment.filename"
                :title="cardWithSegment.title"
                :description="cardWithSegment.description"
              ></CardWithSegment>
            </SwiperSlide>
            <SwiperSlide>
              <Card360
                class="card-with-segment"
                @onSearch="clickOnSearchNewCar"
                :numberVehicles="getTotalVehicles"
              ></Card360>
            </SwiperSlide>
          </Swiper>
          <!-- <CardWithSegment
            @onSearch="onSearchCardWithSegment"
            class="card-with-segment"
            v-for="(cardWithSegment, index) in $t(
              'pages.homepage.sections.process.cards'
            )"
            :key="index"
            :filename="cardWithSegment.filename"
            :title="cardWithSegment.title"
            :description="cardWithSegment.description"
          ></CardWithSegment>
          <Card360
            class="card-with-segment"
            @onSearch="clickOnSearchNewCar"
            :numberVehicles="getTotalVehicles"
          ></Card360> -->
        </div>
      </Section>

      <Section id="slideshow">
        <Slideshow
          :images="$t('pages.homepage.sections.slideshow')"
        ></Slideshow>
      </Section>

      <Section id="faqs-home">
        <FaqsHome
          :title="$t('pages.homepage.sections.faqs.title')"
          :subtitle="$t('pages.homepage.sections.faqs.subtitle')"
          :accordionList="$t('pages.homepage.sections.faqs.faqs')"
          @onActiveFootprint="e => faqsFootprint(e)"
        ></FaqsHome>
      </Section>

      <Section id="search-new-car">
        <SearchNewCar @onSearch="clickOnSearchNewCar"></SearchNewCar>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Section, Button, Label, SwiperSlide } from "./../../atoms";
import { Heading } from "@sc/ds-ui-component-library";
import {
  HomeMainSection,
  QuickLinks,
  ExperiencesCard,
  Swiper,
  RadioButtonLabel
} from "./../../molecules";
import {
  AutocompleteDropdown,
  Separator300,
  FaqsHome,
  SearchNewCar,
  Slideshow,
  CardWithSegment,
  Card360
} from "./../../organisms";
import { Page } from "./../../templates";

import { mapGetters } from "vuex";

import { SEGMENTS } from "../../../enums/segments";

import { PAGES } from "@/enums/pages";

import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

const REPLACE_TAGS = ["needle", "price", "monthlyDeposit"];

export default {
  components: {
    QuickLinks,
    Section,
    HomeMainSection,
    Page,
    Button,
    Label,
    AutocompleteDropdown,
    Separator300,
    FaqsHome,
    SearchNewCar,
    Slideshow,
    ExperiencesCard,
    CardWithSegment,
    Card360,
    Swiper,
    SwiperSlide,
    RadioButtonLabel,
    Heading
  },
  metaInfo() {
    return {
      title: this.$t("meta.homepage.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.homepage.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.homepage.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.homepage.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.homepage.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.homepage.image")
        }
      ]
    };
  },
  props: {},
  computed: {
    ...mapGetters(["getAllResults", "getTotalVehicles"]),
    getAutoCompleteResults() {
      return this.getAllResults;
    },
    quicklink1() {
      return {
        backgroundImage: `url(${this.$t(
          "resources.images.homepage.quicklinkLeft"
        ) || require("../../../assets/images/quicklink-1.webp")})`
      };
    },
    quicklink2() {
      return {
        backgroundImage: `url(${this.$t(
          "resources.images.homepage.quicklinkCenter"
        ) || require("../../../assets/images/quicklink-2.webp")})`
      };
    },
    quicklink3() {
      return {
        backgroundImage: `url(${this.$t(
          "resources.images.homepage.quicklinkRight"
        ) || require("../../../assets/images/quicklink-3.webp")})`
      };
    }
  },
  methods: {
    importAll(r) {
      r.keys().forEach(key => this.images.push(r(key)));
    },
    onClickRetake() {
      this.$router.push({
        name: PAGES.RETAKE
      });
    },
    onClickHowItWorks() {
      this.$router.push({
        name: PAGES.HOW_IT_WORKS
      });
    },
    onClickSearchVehicles() {
      this.$router.push({ name: PAGES.SEARCH });
    },
    onStoreResults(autocompleteSearch) {
      this.$store.dispatch("storeAllResults", autocompleteSearch);
    },
    onEnterKey() {
      this.search();
    },
    autocompleteSearch(tagEvent) {
      if (tagEvent.value === "") return;
      this.$store.commit("SearchModule/filter/setNeedle", tagEvent.value);

      this.createTag(
        {
          key: tagEvent.id,
          value: tagEvent.value
        },
        tagEvent.type
      );
      this.mySearch = "";

      if (this.carType === "new") {
        this.$router.push({
          name: PAGES.SEARCH
        });
      } else {
        this.$router.push({
          name: PAGES.SEARCH_USED
        });
      }
    },
    createTag(item, type) {
      const { key, value } = item;
      const tag = {
        text: value,
        value,
        type,
        id: key
      };
      if (REPLACE_TAGS.includes(tag.type))
        this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
      else this.$store.dispatch("SearchModule/filter/addOrRemoveTag", tag);

      this.$store.dispatch("SearchModule/dropdown/updateDropdown", {
        ...tag,
        selected: true
      });
    },
    search() {
      this.$refs.autocompleteDropdown.dropdown = false;

      const tags = [];

      if (this.mySearch) {
        tags.push({
          value: this.mySearch,
          text: this.mySearch,
          type: "needle"
        });
      }

      this.$router.push({
        name: PAGES.SEARCH,
        params: { tags: tags }
      });
      this.mySearch = "";
    },
    clickOnSearchNewCar() {
      this.$router.push({
        name: PAGES.SEARCH
      });
    },
    onChangeCarType(event) {
      this.carType = event;
    },
    onSearchCardWithSegment(event) {
      switch (event) {
        case SEGMENTS.SPORT_PLURAL:
          this.createTag(
            {
              key: 29296,
              value: SEGMENTS.SPORT
            },
            "segment"
          );
          break;
        case SEGMENTS.FAMILY_PLURAL:
          this.createTag(
            {
              key: 29290,
              value: SEGMENTS.FAMILY
            },
            "segment"
          );

          break;
        case SEGMENTS.SUV_PLURAL:
          this.createTag(
            {
              key: 29287,
              value: SEGMENTS.SUV
            },
            "segment"
          );

          break;
        case SEGMENTS.ECOLOGIC_PLURAL:
          this.createTag(
            {
              key: 31102,
              value: SEGMENTS.ECOLOGIC
            },
            "segment"
          );
          break;
      }

      this.$router.push({
        name: PAGES.SEARCH
      });
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: PAGES.HOME,
        title: e?.title
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    }
  },
  data() {
    return {
      prod: true,
      carType: "new",
      slideshow: true,
      images: ["cover.webp"],
      imageList: [
        "cover.webp"
        // "https://lh5.googleusercontent.com/MwBeF89BhyGGpi3lwUM_RErXfJVI9_SM7paZe1yTkFSn_XtgoVP0-qMOC8yXj3oCxGgb5U4YM0oQQg=w1920-h913-rw"
        // "https://www.hyundai.pt/wp-content/uploads/2021/03/header-destop-campanha-com-info_1920x845-1.png",
        // "https://www.hyundai.pt/wp-content/uploads/2021/03/MicrosoftTeams-image-10-1.jpg",
        // "https://www.hyundai.pt/wp-content/uploads/2021/03/header-destop-campanha-com-info_1920x845.jpg"
      ],
      slidesPerView: 3,
      cardsInfo: [
        {
          image:
            "https://uploads-ssl.webflow.com/600a0851bb310f7342bf318c/600ac902952f1909416b78e7_car-insurance.png",
          title: "Qualidade máxima dos veículos",
          subtitle: "Todos as viaturas passaram nos nossos rigorosos testes."
        },
        {
          image:
            "https://uploads-ssl.webflow.com/600a0851bb310f7342bf318c/600ac901aad0000a5f284ad5_save-money.png",
          title: "Política de devolução em 7 dias",
          subtitle: "Todos as viaturas passaram nos nossos rigorosos testes."
        },
        {
          image:
            "https://uploads-ssl.webflow.com/600a0851bb310f7342bf318c/600ac902e00a62610139e756_shipped.png",
          title: "Entrega da viatura em sua casa",
          subtitle: "Todos as viaturas passaram nos nossos rigorosos testes."
        },
        {
          image:
            "https://uploads-ssl.webflow.com/600a0851bb310f7342bf318c/600ac901952f1952fe6b78e6_finance.png",
          title: "Transparência total nos preços apresentados",
          subtitle: "Todos as viaturas passaram nos nossos rigorosos testes."
        },
        {
          image:
            "https://uploads-ssl.webflow.com/600a0851bb310f7342bf318c/600ac9013f8362ade04cd046_accounting.png",
          title: "Ajuste os custos à medida das necessidades",
          subtitle: "Todos as viaturas passaram nos nossos rigorosos testes."
        }
      ],
      mySearch: ""
    };
  },
  mounted() {
    this.importAll(require.context("./../../../../public/assets/banner", true));
    this.images = this.images.concat(this.imageList); //concats image list from public assets and external ones.
    this.$store.dispatch("storeTotalVehicles");
  }
};
</script>

<style lang="css" scoped src="./HomePage.css"></style>
