const UTM_PREFIX = "utm_";

const getUtmParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());

  const utmParams = Object.keys(params)
    .filter(key => key.includes(UTM_PREFIX))
    .reduce((res, key) => ((res[key] = params[key]), res), {});

  return utmParams;
};

export { getUtmParams };
