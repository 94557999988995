<template>
  <fieldset class="verification-code">
    <Input
      v-for="(i, index) in numberOfDigits"
      :key="index"
      customClass="code-input"
      name="code"
      inputType="number"
      type="primary"
      :maxLength="1"
      isRequired
      @onChange="code(index, $event)"
    />
  </fieldset>
</template>

<script>
import { Input } from "../../atoms";
export default {
  name: "VerificationCode",
  components: {
    Input
  },
  data() {
    return {
      myCode: {}
    };
  },
  props: {
    numberOfDigits: {
      type: Number,
      default: 6
    }
  },
  methods: {
    code(index, event) {
      if (event.length === 1 || event.length === 0) this.myCode[index] = event;
      if (event.length > 1) this.myCode[index] = event[0];
      let code = Object.values(this.myCode).join("");
      this.$emit("onVerificationCode", code.substring(0, 6));
    },
    autoInputSelect() {
      const inputElements = [...document.querySelectorAll("input.code-input")];

      inputElements.forEach((ele, index) => {
        ele.addEventListener("keydown", e => {
          if (e.keyCode === 8 && e.target.value === "")
            inputElements[Math.max(0, index - 1)].focus();
        });
        ele.addEventListener("input", e => {
          const [first, ...rest] = e.target.value;
          e.target.value = first ?? "";
          if (index !== inputElements.length - 1 && first !== undefined) {
            inputElements[index + 1].focus();
            inputElements[index + 1].value = rest.join("");
            inputElements[index + 1].dispatchEvent(new Event("input"));
          }
        });
      });
    }
  },
  mounted() {
    this.autoInputSelect();
  }
};
</script>

<style lang="css" scoped src="./VerificationCode.css"></style>
