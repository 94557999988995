import httpClient from "./httpClient";
import QueryString from "query-string";

const RESERVATIONS = "/users/me/reservations";
const PAYMENT = "/payments";

const RESERVE_TYPE = 30;

const PRE_APPROVAL = "/reservation/financing/preapproval";

const PRE_APPROVAL_DROPDOWNS = "/reservation/preapproval/dropdowns";

const buildQueryString = type => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  utms.type = type;

  let queryString = "?";

  queryString += QueryString.stringify(utms);

  return queryString;
};

function getAllReservations() {
  return httpClient.get(RESERVATIONS);
}

function createPaymentIntent(payload) {
  const queryString = buildQueryString(RESERVE_TYPE);
  return httpClient.post(PAYMENT + queryString, payload);
}

function requestPreApproval(payload) {
  return httpClient.post(PRE_APPROVAL, payload);
}

function requestPreApprovalDropdowns() {
  return httpClient.get(PRE_APPROVAL_DROPDOWNS);
}

export {
  getAllReservations,
  createPaymentIntent,
  requestPreApproval,
  requestPreApprovalDropdowns
};
