<template>
  <div>
    <CoolLightBox
      :items="images"
      :index="selectedImage"
      @close="selectedImage = null"
      :effect="'fade'"
    >
    </CoolLightBox>
    <!-- Slider main container -->
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div
          class="swiper-slide"
          v-for="(image, index) in images"
          :key="index"
          @click="selectedImage = index"
        >
          <img
            class="vehicle-image"
            :src="image"
            :alt="'vehicle-images-' + vehicleModel + '-' + index"
          />
        </div>
      </div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

// import Swiper JS
import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    CoolLightBox
  },
  data() {
    return {
      opened: false,
      selectedImage: null,
      swiper: null
    };
  },
  props: {
    images: {
      type: Array[String],
      default: []
    },
    vehicleModel: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      centerInsufficientSlides: true,
      // Navigation arrows,
      slidesPerView: 4,
      spaceBetween: 16,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
};
</script>

<style lang="css" scoped src="./NormalSlide.css"></style>
