<template>
  <Page>
    <template v-slot:content>
      <div class="personal-page--container">
        <div class="personal-page--container-left">
          <div class="personal-page--container-left-name">
            <Avatar
              class="avatar"
              :username="username"
              :size="138"
              backgroundColor="white"
              color="#D3D9DE"
            ></Avatar>
            <Label customClass="welcome" size="medium">{{
              $t("pages.personalPage.welcome", {
                user: firstName
              })
            }}</Label>
          </div>
          <div class="personal-page--container-left-nav">
            <LinkList
              class="personal-nav"
              :list="personalList"
              bold="normal"
              display="vertical"
              size="large"
              :uppercase="true"
            />
            <Label
              @click.native="onLogoutHandler"
              class="logout"
              bold="bold"
              size="small"
              uppercase
              >{{ $t("pages.personalPage.logout") }}</Label
            >
          </div>
        </div>

        <div class="personal-page--container-right">
          <router-view></router-view>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import { LinkList } from "../../molecules";
import { Page } from "../../templates";
import { Label } from "../../atoms";
import Avatar from "vue-avatar";
import { mapGetters } from "vuex";
import { PAGES } from "@/enums/pages";

export default {
  components: {
    LinkList,
    Page,
    Avatar,
    Label
  },
  methods: {
    OpenRegisterForm() {
      this.isRegisterFormOpen = true;
    },
    onLogoutHandler() {
      this.$store.dispatch("storeLogout");
      this.$router.push({
        name: PAGES.HOME
      });
    }
  },
  computed: {
    ...mapGetters([
      "getToken",
      "getUserInfo",
      "getWelcomeUser",
      "getLoadingAuthentication",
      "isGuest"
    ]),
    firstName() {
      return this.getUserInfo?.name?.split(" ")[0];
    },
    username() {
      return this.getUserInfo?.name || "";
    },
    personalList() {
      return [
        {
          label: this.$t("pages.personalPage.personalList[0]"),
          url: "/pessoal/dados-pessoais"
        },
        {
          label: this.$t("pages.personalPage.personalList[1]"),
          url: "/pessoal/reserva"
        },
        {
          label: this.$t("pages.personalPage.personalList[2]"),
          url: "/pessoal/retoma"
        },
        {
          label: this.$t("pages.personalPage.personalList[3]"),
          url: "/pessoal/pesquisas-guardadas"
        }
      ];
    }
  }
};
</script>

<style lang="css" scoped src="./PersonalPage.css"></style>
