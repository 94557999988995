<template>
  <div :class="['icon', customIcon]" :style="{ ...iconSize }">
    <svg
      v-if="icon"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      :aria-labelledby="icon"
      role="presentation"
    >
      <g :fill="color">
        <component :is="localIcon"></component>
      </g>
    </svg>
  </div>
</template>

<script>
import icons from "../SvgIcons";
export default {
  props: {
    customIcon: {
      type: String
    },
    icon: {
      type: String
    },
    size: {
      type: Number,
      default: 24
    },
    color: {
      type: String
    }
  },
  computed: {
    localIcon() {
      return icons[this.icon];
    },
    iconSize() {
      return { width: `${this.size}px`, height: `${this.size}px` };
    }
  }
};
</script>
