<template>
  <div>
    <FormStep :step="paymentStepNumber" :validate="validate" />

    <PriceDetails :vehicle="selectedVehicle" />

    <div class="book-vehicle--title">
      <Label class="reserve--header" size="medium" color="primary">
        {{ $t("panels.reserve.steps.3.paymentMethod") }}
      </Label>
    </div>

    <div class="mt-4">
      <div class="radio-button-payment-method">
        <RadioButtonLabel
          :title="$t('panels.reserve.steps.3.paymentMethodCard')"
          titleSize="large"
          radioId="yes"
          radioSize="small"
          radioColor="secondary"
          radioName="radio"
          radioCircular
          titleColor="primary"
          subtitleColor="tertiary"
          :radioChecked="true"
        />
        <div class="payment-method-images">
          <div class="u-icon icon visa" />
          <div class="u-icon icon mastercard" />
        </div>
      </div>
      <div class="mt-4">
        <Label size="small" color="tertiary">
          {{ $t("panels.reserve.steps.3.paymentCardNumber") }}
        </Label>
        <div id="card-number" class="payment-info"></div>

        <div class="mt-2" style="width: 100%; display: table">
          <div style="display: table-row">
            <div style="width: 50%; display: table-cell">
              <Label size="small" color="tertiary">
                {{ $t("panels.reserve.steps.3.paymentCardExpiry") }}
              </Label>
              <div
                id="card-expiry"
                class="payment-info"
                style="width: 90%"
              ></div>
            </div>
            <div style="width: 50%; display: table-cell">
              <Label size="small" color="tertiary">
                {{ $t("panels.reserve.steps.3.paymentCardCVC") }}
              </Label>
              <div id="card-cvc" class="payment-info"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AmountToPay class="mt-4" />

    <div class="mt-4">
      <div class="secure-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="16"
          viewBox="0 0 11 16"
        >
          <g id="Lock" transform="translate(-875 -304)">
            <path
              id="Icon"
              d="M14.625,8.867h-7a1.017,1.017,0,0,0-1,1.032V15.06a1.017,1.017,0,0,0,1,1.032h7a1.017,1.017,0,0,0,1-1.032V9.9A1.017,1.017,0,0,0,14.625,8.867Zm-7-1.032a2.033,2.033,0,0,0-2,2.065V15.06a2.033,2.033,0,0,0,2,2.065h7a2.033,2.033,0,0,0,2-2.065V9.9a2.033,2.033,0,0,0-2-2.065Zm0-3.1a3.558,3.558,0,0,1,3.5-3.613,3.558,3.558,0,0,1,3.5,3.613v3.1h-1v-3.1a2.5,2.5,0,1,0-5,0v3.1h-1Z"
              transform="translate(869.375 302.875)"
              fill="#757989"
              fill-rule="evenodd"
            />
          </g>
        </svg>

        <Label class="ml-2" size="small" color="tertiary">
          {{ $t("panels.reserve.steps.3.paymentExtra") }}
        </Label>
      </div>
      <div class="ml-5 secure-info2">
        <Label size="small" color="tertiary">
          {{ $t("panels.reserve.steps.3.paymentDescription") }}
        </Label>
      </div>
      <div class="ml-5">
        <Label size="small" color="tertiary"> </Label>
      </div>
    </div>

    <div class="mt-5">
      <div class="mr-5">
        <Checkbox
          class="small"
          @onChange="acceptedTermsConditions($event)"
          :checked="false"
          :fill="false"
          :fillOnChecked="true"
        ></Checkbox>
      </div>
      <div class="ml-4 pl-3">
        <TextLabel
          class="label"
          color="primary"
          size="small"
          :label="$t('panels.reserve.steps.3.acceptTermsConditions')"
        />
        <Label
          v-if="showToastAcceptance"
          color="#e63312"
          bold="thin"
          size="small"
          class="error-message"
        >
          {{ this.$t("panels.reserve.steps.3.missingAcceptance") }}</Label
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FormStep } from "../../../molecules";
import { AmountToPay, PriceDetails } from "../../../organisms";
import { Label } from "../../../atoms";
import { EventBus } from "../../../../utils/eventbus";
import { inBusinessDaysAhead } from "../../../../utils/date";
import { RadioButtonLabel } from "../../../molecules";
import { FOOTPRINT_TYPES, registerEvent } from "../../../../api/footprint";
import { RESERVE_TYPE } from "../../../../enums/reserveTypes.js";
import { Checkbox } from "../../../atoms";
import { TextLabel } from "@sc/ds-ui-component-library";

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_STRIPE_PK);
const PAYMENT_STEP_NUMBER = 4;

export default {
  name: "PaymentFormStep",
  components: {
    FormStep,
    AmountToPay,
    Label,
    PriceDetails,
    RadioButtonLabel,
    Checkbox,
    TextLabel
  },
  props: {
    selectedVehicle: {
      type: Object
    },
    checkForm: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      paymentStepNumber: PAYMENT_STEP_NUMBER,
      pk: process.env.VUE_APP_STRIPE_PK,
      deliveryDate: this.getFormattedTimeLimit,
      formChanged: false.valueOf,
      isAccepted: false,
      showToastAcceptance: false
    };
  },
  methods: {
    acceptedTermsConditions(event) {
      return (this.isAccepted = event ? true : false);
    },
    showToast() {
      this.$toast.open({
        message: this.$t("panels.reserve.steps.3.missingData"),
        dismissible: true,
        type: "error"
      });
    },
    validate() {
      this.submitReserve();
      return false;
    },
    footprintPaymentResponse(paymentResponse) {
      const result = {
        paymentResult:
          paymentResponse?.paymentIntent?.status ||
          paymentResponse?.error?.message,
        vin: this.getSelectedVehicle?.vin
      };
      registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_4, result);
    },
    async submitReserve() {
      if (!this.formChanged) {
        return this.showToast();
      }
      this.showToastAcceptance = !this.isAccepted;
      if (!this.isAccepted) {
        return;
      }

      this.$store.commit("spinnerModule/setLoading", true);
      stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber
          }
        })
        .then(res => {
          if (res.error) {
            this.footprintPaymentResponse(res);
            return this.showToast();
          }
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "eec.reserva",
            model: this.getModel
          });
          // Webhook should send confirmation to backend, upload lead and save reservation
          EventBus.$emit("valid", 4);
          this.footprintPaymentResponse(res);
        })
        .catch(err => {
          this.footprintPaymentResponse(err);
          this.showToast();
          console.log(err);
          console.error("Payment failed in stripe with: ", err);
        })
        .finally(() => {
          this.$store.commit("spinnerModule/setLoading", false);
        });
    }
  },
  computed: {
    ...mapGetters([
      "getCreditType",
      "getCreditTypeDescription",
      "getVin",
      "getUser",
      "getReceipt",
      "getDealers",
      "getRawTaeg",
      "getImg",
      "getModel",
      "getFormattedPrice",
      "getPrice",
      "getFormattedMonthlyPrice",
      "getFormattedTaeg",
      "getDescription",
      "getNbrPaymentsValue",
      "getFormattedInitialDeposit",
      "getInitialDeposit",
      "getReservationCost",
      "getFormattedTimeLimit",
      "getUserName",
      "getFinanceId",
      "getUserInfoDealer",
      "getUserEmail",
      "getVehicleNumberMonths",
      "getVehiclePrice",
      "getVehicleResidualValue",
      "getVehicleFee",
      "getVehicleTan",
      "getVehicleMtic",
      "getUserPhoneNumber",
      "getIncludeRetake",
      "getReturnId",
      "getRawMonthlyPrice",
      "getRawCapitalToFinance",
      "getVehicleInitialDeposit",
      "getVehicleInitialExpenses",
      "getReceipt",
      "getBilling",
      "getWithFinancing",
      "getVehicleTotalPrice",
      "getUserInfoDifferentKeys",
      "getSelectedVehicle",
      "getCarFinance",
      "getIsOpenDrive",
      "getTooltip"
    ]),
    img() {
      return (
        this.getImg || require("../../../../assets/images/placeholder.jpg")
      );
    }
  },
  async mounted() {
    const reserveType = this.getWithFinancing
      ? RESERVE_TYPE.RESERVED
      : RESERVE_TYPE.BOUGHT;
    try {
      const res = await this.$store.dispatch("generatePaymentIntent", {
        vin: this.getVin,
        fid: this.getFinanceId,
        leadPayload: {
          dealerId: this.getUserInfoDealer,
          emailAddress: this.getUserEmail,
          billing: this.getBilling,
          noFinance: !this.getWithFinancing,
          finance: !this.getWithFinancing
            ? null
            : {
                nbrPayments: this.getVehicleNumberMonths,
                taeg: this.getRawTaeg,
                pvp: this.getIsOpenDrive
                  ? this.getVehiclePrice
                  : this.getCarFinance.pvp,
                productName: this.getCreditTypeDescription(this.getCreditType),
                residualValue: this.getVehicleResidualValue,
                fee: this.getRawMonthlyPrice,
                initialValue: this.getVehicleInitialDeposit,
                initialExpenses: this.getVehicleInitialExpenses,
                tan: this.getVehicleTan
              },
          ...(this.getIncludeRetake && { tradeInId: this.getReturnId }),
          infoCheck: this.isAccepted,
          name: this.getUserName,
          phoneNumber: this.getUserPhoneNumber,
          birthdate: this.getUserInfoDifferentKeys.birthdate,
          vin: this.getVin,
          currentUrl: window.location.href
        },
        reservationPayload: {
          reserveType: reserveType,
          reserveDate: new Date(),
          endReserveDate: inBusinessDaysAhead(10),
          pricePvp: this.getVehiclePrice,
          finance: {
            monthlyValue: this.getRawMonthlyPrice,
            depositValue: this.getVehicleInitialDeposit,
            pricePvp: this.getIsOpenDrive
              ? this.getVehiclePrice
              : this.getCarFinance.pvp,
            nbrMonths: this.getVehicleNumberMonths,
            taeg: this.getRawTaeg,
            financedValue: this.getRawCapitalToFinance,
            creditType: this.getCreditType,
            capitalFinance: this.getRawCapitalToFinance,
            residualValue: this.getVehicleResidualValue,
            mtic: this.getVehicleMtic,
            tan: this.getVehicleTan,
            initialExpenses: this.getVehicleInitialExpenses,
            legalInfo: this.getTooltip
          },
          totalPrice: this.getVehicleTotalPrice,
          receipt: this.getReceipt,
          dealershipId: this.getUserInfoDealer,
          ...(this.getIncludeRetake && { retakeId: this.getReturnId })
        }
      });

      this.clientSecret = res.data.data.client_secret;
    } catch (err) {
      this.$toast.open({
        message: this.$t("toast.reserve.startPaymentError"),
        type: "error"
      });
      // FIXME: maybe deal with error differently
      // Currently returning to previous step in multiform
      EventBus.$emit("prev");
    }

    const style = {
      base: {
        color: "#002c5f",
        fontFamily: "Hyundai Sans Head Office, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#002c5f",
        iconColor: "#002c5f"
      }
    };

    const elements = stripe.elements();

    this.cardNumber = elements.create("cardNumber", { style });
    this.cardExpiry = elements.create("cardExpiry", { style });
    this.cardCvc = elements.create("cardCvc", { style });

    this.cardNumber.mount("#card-number");
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc.mount("#card-cvc");

    this.cardNumber.on("change", () => {
      this.formChanged = true;
    });
    this.cardExpiry.on("change", () => {
      this.formChanged = true;
    });
    this.cardCvc.on("change", () => {
      this.formChanged = true;
    });
  }
};
</script>

<style scoped src="./styles.css"></style>
