import httpClient from "./httpClient";

const FINANCE = "/finance";

function getFinance(
  {
    vin,
    pvp,
    pvpFinanced,
    initialValue,
    minInitialValue,
    nrPayments,
    minRate,
    rate,
    residualValue,
    typeFinancing
  },
  download
) {
  const basePath = typeFinancing == "credit-to-drive" ? "hcd" : "hod";
  return httpClient.get(
    `${FINANCE}/${basePath}/${vin}/${pvp.toFixed(2)}/${pvpFinanced.toFixed(
      2
    )}/${minInitialValue}/${initialValue}/${nrPayments}/${minRate}/${rate}/${
      residualValue ? residualValue.toFixed(2) : ""
    }?fin=${download}`,
    { validateStatus: false }
  );
}

export { getFinance };
