<template>
  <Panel :open="isPanelOpened" @onClose="closePanel">
    <template slot="header">
      <Label size="medium" color="primary" bold="bold">{{
        $t("panels.favorites.title")
      }}</Label>
    </template>

    <template slot="content">
      <div class="empty-message--wrapper" v-if="empty">
        <div class="empty-message">
          <span>{{ $t("panels.favorites.empty.title") }}</span>
          <p>
            {{ $t("panels.favorites.empty.message1") }}
            <Icon
              class="inline-icon-paragraph"
              name="heart"
              size="small"
              color="primary"
              iconPrefix="far"
            />{{ $t("panels.favorites.empty.message2") }}
          </p>
          <Button
            :label="$t('panels.favorites.buttons.search')"
            customClass="whitespace-nowrap
          button-search"
            size="medium"
            type="primary"
            fill
            @onClick="goToSearch"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="isPriceChanged" class="my-alert">
          <MyAlert
            :inline="true"
            type="info"
            :message="$t('panels.favorites.priceChange')"
          />
        </div>
        <div class="grid-wrapper">
          <div v-for="vehicle in favoritesList" :key="vehicle.vin">
            <SearchVehicleResultCard
              :vehicle="vehicle"
              v-if="vehicle.conditionId == 1"
            />
            <UsedSearchVehicleResultCard panel :vehicle="vehicle" v-else />
          </div>
        </div>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import {
  SearchVehicleResultCard,
  UsedSearchVehicleResultCard
} from "../../organisms";
import { Panel } from "../../molecules";
import { Label, Button, Icon, MyAlert } from "../../atoms";

import { PAGES } from "../../../enums/pages";

export default {
  name: "FavoritesPanel",
  components: {
    Label,
    Panel,
    SearchVehicleResultCard,
    UsedSearchVehicleResultCard,
    Button,
    Icon,
    MyAlert
  },
  methods: {
    ...mapMutations({
      closePanel: "FavoritesModule/closePanel"
    }),
    goToSearch() {
      this.closePanel();

      if (this.$route.name !== PAGES.SEARCH)
        this.$router.push({
          name: PAGES.SEARCH
        });
    }
  },
  computed: {
    ...mapGetters({
      empty: "FavoritesModule/empty",
      count: "FavoritesModule/count",
      favoritesList: "FavoritesModule/list",
      isPanelOpened: "FavoritesModule/isPanelOpened"
    }),
    isPriceChanged() {
      const favList = this.$store.getters["FavoritesModule/list"];
      const isChanged = favList.filter(fav => fav.updateTime !== null);
      return isChanged.length !== 0;
    }
  }
};
</script>

<style scoped src="../styles.css"></style>
