import {
  getAllUserFavorites,
  addToUserFavorites,
  removeFromUserFavorites
} from "../../api/favorites";

const initialState = () => ({
  list: [],
  panelOpened: false
});

const state = initialState();

const getters = {
  count: state => state.list.length,
  empty: state => state.list.length === 0,
  list: state => state.list,
  exists: state => ({ vin, financeId }) =>
    !!state.list.find(v => v.vin === vin && v.financeId === financeId),
  isPanelOpened: state => state.panelOpened
};

const mutations = {
  set: (state, vehicles) => (state.list = vehicles),
  add: (state, vehicle) => state.list.push(vehicle),
  remove: (state, { vin, financeId }) =>
    (state.list = state.list.filter(
      vehicle => !(vehicle.vin == vin && vehicle.financeId == financeId)
    )),
  openPanel: state => (state.panelOpened = true),
  closePanel: state => (state.panelOpened = false),
  reset: state => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  }
};

const actions = {
  addToFavorites: async ({ commit }, vehicle) => {
    const { data } = await addToUserFavorites(vehicle);

    if (!data) {
      console.log("Failed to save user favorite on server");
    }

    commit("add", vehicle);
  },
  removeFromFavorites: async ({ commit }, vehicle) => {
    const { data } = await removeFromUserFavorites(vehicle);

    if (!data) {
      console.log("Failed to remove user favorite from server");
    }

    commit("remove", vehicle);
  },
  // eslint-disable-next-line no-unused-vars
  fetchFavorites: async ({ state, commit }) => {
    const { data } = await getAllUserFavorites();

    const { data: results } = data;

    const currentState = [...state.list];

    const newState = (results || []).map((favoriteVehicle, index) => {
      return { ...currentState[index], ...favoriteVehicle };
    });

    commit("set", newState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
