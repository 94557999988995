<template>
  <div class="mechanical-general-condition">
    <div class="mechanical-general-condition--title">
      <Label color="primary" size="medium" uppercase
        >Estado Mecânico e geral do Veículo</Label
      >
    </div>
    <div class="mechanical-general-condition--subtitle">
      <Label color="tertiary" size="small"
        >Por favor selecione a opção que melhor descreve o estado de cada um dos
        elementos avaliados. Isto ajuda-nos a dar uma oferta real da sua
        viatura.</Label
      >
    </div>
    <div class="mechanical-general-condition--content">
      <div class="mechanical-general-condition--content-left">
        <div class="mechanical-general-condition--content-left-title">
          <Label color="tertiary" size="small"
            >Estado Mecânico do Automóvel</Label
          >
        </div>
        <div class="mechanical-general-condition--content-left-group">
          <div
            class="radio-list"
            v-for="(item, index) in mechanicalStateList"
            :key="index"
          >
            <div class="mechanical-general-condition--content-group-title">
              <Label class="group-title" color="primary" size="small">{{
                item.title
              }}</Label>
              <Label class="group-sbtitle" color="primary" size="small">{{
                item.subtitle
              }}</Label>
            </div>
            <div class="mechanical-general-condition--content-group-radio">
              <RadioButtonLabel
                :disabled="disabled"
                v-for="(radio, index) in item.radioGroupList"
                :key="index"
                :title="radio.title"
                :radioName="item.radioGroupName"
                :radioValue="radio.radioValue"
                :radioId="radio.radioId"
                radioColor="primary"
                :radioChecked="radio.checked"
                @onChange="
                  onChangeMechanicalState(item.radioGroupName, radio, $event)
                "
              ></RadioButtonLabel>
            </div>
            <Label
              v-if="emptyMechanicalValues(index)"
              class="validate-message--label"
              color="tertiary"
              bold="thin"
              size="small"
            >
              Precisa de indicar o estado</Label
            >
          </div>
        </div>
      </div>
      <div class="mechanical-general-condition--content-right">
        <div class="mechanical-general-condition--content-right-title">
          <Label color="tertiary" size="small">Estado Geral do Automóvel</Label>
        </div>
        <div class="mechanical-general-condition--content-right-group">
          <div
            class="radio-list"
            v-for="(item, index) in generalStatusList"
            :key="index"
          >
            <div class="mechanical-general-condition--content-group-title">
              <Label class="group-title" color="primary" size="small">{{
                item.title
              }}</Label>
              <Label class="group-subtitle" color="primary" size="small">{{
                item.subtitle
              }}</Label>
            </div>
            <div class="mechanical-general-condition--content-group-radio">
              <RadioButtonLabel
                :disabled="disabled"
                v-for="(radio, index) in item.radioGroupList"
                :key="index"
                :title="radio.title"
                :radioName="item.radioGroupName"
                :radioValue="radio.radioValue"
                :radioId="radio.radioId"
                radioColor="primary"
                :radioChecked="radio.checked"
                @onChange="
                  onChangeGeneralState(item.radioGroupName, radio, $event)
                "
              ></RadioButtonLabel>
            </div>
            <Label
              v-if="emptyGeneralStatus(index)"
              class="validate-message--label"
              color="tertiary"
              bold="thin"
              size="small"
            >
              Precisa de indicar o estado</Label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import { RadioButtonLabel } from "../../molecules";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

export default {
  name: "MechanicalAndGeneralCondition",
  components: {
    Label,
    RadioButtonLabel
  },
  data() {
    return {
      mechanicalAndGeneralStatus: {
        mechanicalStateList: this.mechanicalStateList,
        generalStatusList: this.generalStatusList
      },
      retakeStatusEnum: RETAKESTATUS
    };
  },
  props: {
    mechanicalStateList: {
      type: Array[Object]
    },
    generalStatusList: {
      type: Array[Object]
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChangeMechanicalState(radioGroupName, rad, event) {
      const radio = this.mechanicalAndGeneralStatus.mechanicalStateList.findIndex(
        x => x.radioGroupName === radioGroupName
      );
      if (radio >= 0) {
        this.mechanicalAndGeneralStatus.mechanicalStateList[
          radio
        ].radioGroupValue = event;

        this.mechanicalAndGeneralStatus.mechanicalStateList[
          radio
        ].radioGroupList.forEach(element => {
          if (element.radioValue == rad.radioValue) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }
      this.$store.commit(
        "setMechanicalState",
        this.mechanicalAndGeneralStatus.mechanicalStateList
      );
    },
    onChangeGeneralState(radioGroupName, rad, event) {
      const radio = this.mechanicalAndGeneralStatus.generalStatusList.findIndex(
        x => x.radioGroupName === radioGroupName
      );
      if (radio >= 0) {
        this.mechanicalAndGeneralStatus.generalStatusList[
          radio
        ].radioGroupValue = event;

        this.mechanicalAndGeneralStatus.generalStatusList[
          radio
        ].radioGroupList.forEach(element => {
          if (element.radioValue == rad.radioValue) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }

      this.$store.commit(
        "setGeneralStatus",
        this.mechanicalAndGeneralStatus.generalStatusList
      );
    },
    emptyMechanicalValues(index) {
      return (
        !this.getMechanicalState[index].radioGroupList.some(
          elem => !!elem.checked
        ) && this.submitted
      );
    },
    emptyGeneralStatus(index) {
      return (
        !this.getGeneralStatus[index].radioGroupList.some(
          elem => !!elem.checked
        ) && this.submitted
      );
    }
  },
  computed: {
    ...mapGetters(["getReturn", "getMechanicalState", "getGeneralStatus"]),
    disabled() {
      return (
        this.getReturn?.submitted &&
        this.getReturn?.status !== this.retakeStatusEnum.Q2_REFUSED
      );
    }
  }
};
</script>

<style lang="css" scoped src="./MechanicalAndGeneralCondition.css"></style>
