<template>
  <!-- Slider main container -->
  <div :class="['swiper-container', customClass]" :style="customStyles">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <slot />
    </div>
    <!-- If we need pagination -->
    <div v-if="pagination" class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <template v-if="navigation">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </template>

    <!-- If we need scrollbar -->
    <div v-if="scrollbar" class="swiper-scrollbar"></div>
  </div>
</template>

<script>
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
// import Swiper styles
import "swiper/swiper-bundle.css";

export default {
  name: "Swiper",
  data() {
    return {
      swiper: null
    };
  },
  props: {
    customClass: {
      type: String,
      default: Math.random()
        .toString(36)
        .substring(6)
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loop: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: "horizontal",
      validator: direction => {
        return ["horizontal", "vertical"].includes(direction);
      }
    },
    navigation: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: true
    },
    scrollbar: {
      type: Boolean,
      default: false
    },
    slidesPerView: {
      type: [Number, String],
      default: "auto"
    },
    spaceBetween: {
      type: Number,
      default: 10
    },
    allowTouchMove: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayDelay: {
      type: Number,
      default: 3000
    },
    fadeEffect: {
      type: Boolean,
      default: false
    },
    freeMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    },
    swiperProps() {
      return {
        loop: this.loop,
        direction: this.direction,
        navigation: this.navigation
          ? {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev"
            }
          : false,
        scrollbar: this.scrollbar
          ? {
              el: ".swiper-scrollbar"
            }
          : false,
        pagination: this.pagination
          ? {
              el: ".swiper-pagination"
            }
          : false,
        slidesPerView: this.slidesPerView,
        spaceBetween: this.spaceBetween,
        allowTouchMove: this.allowTouchMove,
        autoplay: this.autoplay ? { delay: this.autoplayDelay } : false,
        freeMode: this.freeMode,
        ...this.effectOptions
      };
    },
    effectOptions() {
      let effect = {};

      if (this.fadeEffect) {
        effect = {
          effect: "fade",
          fadeEffect: {
            crossFade: true
          }
        };
      }

      return effect;
    }
  },
  mounted() {
    setTimeout(() => {
      this.swiper = new Swiper(
        `.${this.customClass}`,
        {
          ...this.swiperProps
        },
        2500
      );
    });
  }
};
</script>

<style lang="css" scoped src="./Swiper.css"></style>
