<template>
  <div class="financing">
    <div class="financing--options">
      <div class="financing--options-row">
        <BuyWithFinancing
          :title="$t('pages.detailsPage.financing.BuyWithFinancing.title')"
          :yesTitle="
            $t('pages.detailsPage.financing.BuyWithFinancing.yesTitle')
          "
          :noTitle="$t('pages.detailsPage.financing.BuyWithFinancing.noTitle')"
          @onChange="clickBuyWithFinancing"
        />
      </div>

      <div v-if="getWithFinancing" class="financing--options-row">
        <ChooseTypeFinancing
          :title="$t('pages.detailsPage.financing.ChooseTypeFinancing.title')"
          :linkLabel="
            $t('pages.detailsPage.financing.ChooseTypeFinancing.linkLabel')
          "
          linkURL="tipos-de-financiamentos"
          radioButtonLabel1="Credit to Drive"
          valueRadioButton1="credit-to-drive"
          :descriptionRadioButton1="
            $t(
              'pages.detailsPage.financing.ChooseTypeFinancing.descriptionRadioButton1'
            )
          "
          radioButtonLabel2="Open Drive"
          valueRadioButton2="open-drive"
          :descriptionRadioButton2="
            $t(
              'pages.detailsPage.financing.ChooseTypeFinancing.descriptionRadioButton2'
            )
          "
          @onChange="clickChooseTypeFinancing"
          idRadioButton1="credit-to-drive"
          idRadioButton2="open-drive"
          nameGroup="finance-group"
          :selected="typeFinancing"
          :hasFinancialContribution="!!getFinancialContribution"
        />
      </div>

      <div v-if="getWithFinancing" class="financing--options-row">
        <SimulationConditions
          v-if="renderComponent"
          :title="$t('pages.detailsPage.financing.SimulationConditions.title')"
          :labelRange1="
            $t('pages.detailsPage.financing.SimulationConditions.labelRange1')
          "
          :labelRange2="
            $t('pages.detailsPage.financing.SimulationConditions.labelRange2')
          "
          :month="month"
          :deposit="deposit"
          @onChangeInitialEntry="clickChangeInitialEntry"
          @onChangeNumberMonths="clickChangeNumberMonths"
        />
      </div>
      <div v-if="getWithFinancing" class="financing--options-row">
        <Button
          class="update-simulation"
          :label="$t('pages.detailsPage.financing.button')"
          type="primary"
          fill
          border
          uppercase
          :disabled="isDisabledButton"
          @onClick="calculate"
        />
      </div>
      <div class="financing--avaliation">
        <RateCard
          v-if="getReturns.length > 0 && !isGuest"
          :title="$t('pages.detailsPage.financing.rateCard.title')"
          :description="$t('pages.detailsPage.financing.rateCard.description')"
          filename="car.svg"
          :buttonLabel="$t('pages.detailsPage.financing.rateCard.buttonLabel')"
          :border="false"
          :resume="getReturns[0]"
          @onToggle="onToggleHandler"
          :checkedToggle="getIncludeRetake"
        />
        <RateCard
          v-else
          :subtitle="$t('pages.detailsPage.financing.rateCard.subtitle')"
          class="mb-5 md:mb-0"
          :border="false"
          filename="car.svg"
          :description="$t('pages.detailsPage.financing.rateCard.description')"
          :buttonLabel="$t('pages.detailsPage.financing.rateCard.buttonLabel')"
          @onClick="onClickRateCardRetakeHandler"
        />
      </div>
    </div>
    <div class="financing--result">
      <FinancingResult
        :deposit="getVehicleInitialDeposit"
        :error="getCalculateFinanceError"
        :pvp="`${pvp}`"
        :haveSimulation="getIncludeRetake"
        :resumePrice="deposit.retake"
        :tooltip="tooltipResult"
        @onAddResume="onToggleHandler"
        @onClickInterest="clickInterest"
        @getFinFile="getPdfFinFile"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Button } from "../../atoms";
import { BuyWithFinancing } from "../../molecules";
import {
  ChooseTypeFinancing,
  SimulationConditions,
  RateCard,
  FinancingResult
} from "../../organisms";
import { getPriceFormatted } from "../../../utils/currency";
import { CREDIT_TYPE } from "../../../enums/creditType";

import { PAGES } from "@/enums/pages";

const DEPOSIT_OFFSET = 500;
const OPEN_DRIVE_P = 5;
const CREDIT_TO_DRIVE_P = 4;

export default {
  components: {
    BuyWithFinancing,
    ChooseTypeFinancing,
    SimulationConditions,
    RateCard,
    FinancingResult,
    Button
  },
  data() {
    return {
      typeFinancing: "credit-to-drive",
      creditType: CREDIT_TYPE.CREDIT,
      initialEntry: undefined,
      numberMonths: this.getVehicleNumberMonths,
      turnOnButton: false,
      myMonthlyPrice: this.monthlyPrice,
      myTaeg: this.taeg,
      myCapitalToFinance: this.capitalToFinance,
      isInterestedOpened: false,
      isTestDrive: false,
      resumeObj: {
        model: "Nissan Qashqai",
        value: 5000,
        registration: "35-RO-13",
        expire: "6"
      },
      includeRetake: this.includeRetake,
      renderComponent: true,
      footprintLabel: "Finance Simulation"
    };
  },
  props: {
    haveSimulation: {
      type: Boolean,
      default: false
    },
    month: {
      type: Object
    },
    deposit: {
      type: Object
    },
    minRate: {
      type: Number
    },
    rate: {
      type: Number
    },
    pvpCetelem: {
      type: Number
    },
    pvpTotal: {
      type: Number
    },
    monthlyPrice: {
      type: String
    },
    taeg: {
      type: String
    },
    capitalToFinance: {
      type: String
    },
    car: {
      type: Array[Object],
      default: () => {
        return [];
      }
    },
    tooltipResult: {
      type: String
    }
  },
  methods: {
    getPriceFormatted,
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    normalizeValue(value, offset) {
      return Math.ceil(value / offset) * offset;
    },
    onToggleHandler(event) {
      this.$store.commit("setIncludeRetake", event);
      this.initialEntry = event
        ? this.normalizeValue(this.deposit.retake, this.deposit.step)
        : this.deposit.defaultValue;

      this.includeRetake = event;
      this.includeRetake === true
        ? sessionStorage.setItem("retake", JSON.stringify(this.resumeObj))
        : sessionStorage.removeItem("retake");

      this.calculate();
    },
    clickBuyWithFinancing(event) {
      this.$store.commit("setWithFinancing", event);
      this.turnOnButton = true;
    },
    clickChooseTypeFinancing(event) {
      this.$store.commit("setIsOpenDrive", event === "open-drive");

      if (event === "credit-to-drive") {
        // TODO: fix string logic later, might break stuff
        this.typeFinancing = "credit-to-drive";
        this.creditType = CREDIT_TYPE.CREDIT;
        if (this.numberMonths >= this.getCreditTypeDetails(1).maxNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(1).maxNbrPayments;
        }
        if (this.numberMonths <= this.getCreditTypeDetails(1).minNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(1).minNbrPayments;
        }
        this.$store.commit("setCreditType", CREDIT_TYPE.CREDIT);
      } else if (event === "open-drive") {
        this.typeFinancing = "open-drive";
        this.creditType = CREDIT_TYPE.OPEN_DRIVE;
        if (this.numberMonths >= this.getCreditTypeDetails(2).maxNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(2).maxNbrPayments;
        }
        if (this.numberMonths <= this.getCreditTypeDetails(2).minNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(2).minNbrPayments;
        }

        this.$store.commit("setCreditType", CREDIT_TYPE.OPEN_DRIVE);
        this.forceRerender();
      }
      this.turnOnButton = true;

      this.calculate();
    },
    clickChangeInitialEntry(event) {
      if (!event) return;
      this.initialEntry = event;
      this.turnOnButton = true;
    },
    clickChangeNumberMonths(event) {
      const lessThanMax = Math.min(event, this.month.max);
      const result = Math.max(lessThanMax, this.month.min);
      this.numberMonths = result || lessThanMax;
      this.turnOnButton = true;
    },
    clickAddResume(event) {
      // added retake to session storage
      if (this.getReturns.length > 0) {
        sessionStorage.setItem("retake", JSON.stringify(this.getReturns[0]));
        this.includeRetake = true;
        this.$emit("onClickAddResume", event);
      } else {
        this.$router.push({ name: PAGES.RETAKE });
      }
    },
    clickInterest(event) {
      this.$emit("onClickInterest", event);
    },
    clickRateCard(event) {
      if (event === "left") {
        this.$router.push({ name: PAGES.RETAKE });
      } else if (event === "right") {
        this.$router.push("/avaliar-taxa-risco");
      }

      this.$emit("onClickRateCard", event);
    },
    getMaxDeposit() {
      return this.getIsOpenDrive
        ? this.roundToStep(this.getVehiclePrice / OPEN_DRIVE_P, DEPOSIT_OFFSET)
        : this.roundToStep(
            this.getVehiclePrice / CREDIT_TO_DRIVE_P,
            DEPOSIT_OFFSET
          );
    },
    calculate() {
      const initialValue =
        this.initialEntry > this.deposit.max
          ? this.deposit.max
          : this.initialEntry;

      let resVal;
      if (this.getIsOpenDrive) {
        resVal = this.getVehicleResidualValueOD(
          CREDIT_TYPE.OPEN_DRIVE - 1,
          this.numberMonths || this.getVehicleNumberMonths
        );
      }

      const financing = {
        buyWithFinancing: this.getWithFinancing,
        typeFinancing: this.typeFinancing,
        initialValue: initialValue,
        nrPayments: this.numberMonths || this.getVehicleNumberMonths,
        vin: this.car.vin,
        pvp: this.getPriceBasedOnType({
          hasFinancing: !this.getIsOpenDrive && this.getFinancialContribution,
          isPrevious: true
        }),
        pvpFinanced: this.getPriceBasedOnType({
          hasFinancing: !this.getIsOpenDrive && this.getFinancialContribution,
          isPrevious: false
        }),
        rate: this.getVehicleRate(this.creditType - 1), // index in array
        minRate: this.getVehicleMinRate(this.creditType - 1),
        minInitialValue: this.deposit.min,
        residualValue: resVal
      };
      this.$store.dispatch("storeCalculateFinance", financing);
      this.turnOnButton = false;

      this.$emit("onCalculate", financing);
    },
    closeInterested() {
      this.isInterestedOpened = false;
    },
    openTestDrive() {
      this.isInterestedOpened = false;
      this.isTestDrive = true;
    },
    onPrevious() {
      this.isInterestedOpened = true;
      this.isTestDrive = false;
    },
    onClickRateCardRetakeHandler() {
      this.$router.push({ name: PAGES.RETAKE });
    },
    roundToStep(val, offset) {
      return val - (val % offset);
    },
    async getPdfFinFile() {
      const depositWithRetake = this.getIncludeRetake
        ? this.getReturnValue + this.initialEntry
        : this.initialEntry;
      const initialValue =
        depositWithRetake > this.getMaxDeposit()
          ? this.getMaxDeposit()
          : depositWithRetake;

      let resVal;
      if (this.getIsOpenDrive) {
        resVal = this.getVehicleResidualValueOD(
          CREDIT_TYPE.OPEN_DRIVE - 1,
          this.numberMonths || this.getVehicleNumberMonths
        );
      }
      const financing = {
        buyWithFinancing: this.getWithFinancing,
        typeFinancing: this.typeFinancing,
        initialValue: initialValue || this.getMinDeposit,
        carVersion: this.getVersion,
        nrPayments: this.numberMonths || this.getVehicleNumberMonths,
        vin: this.car.vin,
        pvp: this.getPriceBasedOnType({
          hasFinancing: !this.getIsOpenDrive && this.getFinancialContribution,
          isPrevious: false
        }),
        pvpFinanced:
          this.getPriceBasedOnType({
            hasFinancing: !this.getIsOpenDrive && this.getFinancialContribution,
            isPrevious: false
          }) - initialValue,
        rate: this.getVehicleRate(this.creditType - 1), // index in array
        minRate: this.getVehicleMinRate(this.creditType - 1),
        minInitialValue: this.deposit.min,
        residualValue: resVal
      };

      await this.$store.dispatch("downloadFin", financing);
      this.downloadPdf(this.getPdfFins);
    },
    downloadPdf(pdfBase64) {
      var el = document.createElement("a");
      el.href = "data:application/pdf;base64," + pdfBase64;
      el.target = "_blank";
      el.download = "Ficha_de_Informação_Normalizada";
      el.click();
    }
  },
  computed: {
    ...mapGetters([
      "getReturn",
      "getCalculateFinance",
      "getCalculateFinanceError",
      "getReturns",
      "getVehicleInitialDeposit",
      "getVehicleNumberMonths",
      "getMinDeposit",
      "getVehicleRate",
      "getVehicleMinRate",
      "getVehiclePrice",
      "getReturnValue",
      "getIncludeRetake",
      "getVehicleResidualValueOD",
      "getIsOpenDrive",
      "getToken",
      "isGuest",
      "getPdfFins",
      "getCreditTypeDetails",
      "getWithFinancing",
      "getSelectedVehicle",
      "getPriceBasedOnType",
      "getCreditDiscounts",
      "getHODDiscounts",
      "getCreditDiscountsNoFinance",
      "getHODDiscountsNoFinance",
      "getBasePVP",
      "getCondition",
      "getVersion",
      "getFinancialContribution",
      "getVehicleTotalPrice"
    ]),
    isDisabledButton() {
      return !this.turnOnButton;
    },
    pvp() {
      if (this.getFinancialContribution) {
        var discount = this.getCreditDiscounts;
        if (this.getIsOpenDrive) discount = this.getHODDiscounts;
        const totalValue = this.getBasePVP - discount;
        return totalValue + totalValue * 0.23;
      } else {
        return this.getVehicleTotalPrice;
      }
    },
    totalPvp() {
      if (this.getFinancialContribution) {
        var discount = this.getCreditDiscountsNoFinance;
        if (this.getIsOpenDrive) discount = this.getHODDiscountsNoFinance;
        const totalValue = this.getBasePVP - discount;
        return totalValue + totalValue * 0.23;
      } else {
        return this.getVehicleTotalPrice;
      }
    }
  },
  mounted() {
    this.initialEntry = this.getVehicleInitialDeposit;
    this.numberMonths = this.getVehicleNumberMonths;
  }
};
</script>

<style lang="css" scoped src="./Financing.css"></style>
