<template>
  <Block>
    <div
      :class="['quick-links--wrapper', classes, customClass]"
      :style="customStyle"
    >
      <Label
        customClass="title"
        v-html="title"
        class="quick-links--margin-bottom"
        color="primary"
        bold="semibold"
        size="medium"
      >
        {{ title }}
      </Label>
      <Label
        customClass="subtitle"
        class="quick-links--margin-bottom"
        color="tertiary"
        size="small"
      >
        {{ description }}
      </Label>
      <div class="quick-links--item-wrapper">
        <slot />
      </div>
    </div>
  </Block>
</template>

<script>
import { Label, Block } from "./../../atoms";

export default {
  name: "QuickLinks",
  components: { Block, Label },
  props: {
    customClass: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyle() {
      return {
        ...this.styles
      };
    }
  }
};
</script>

<style lang="css" scoped src="./QuickLinks.css"></style>
