<template>
  <div class="upload">
    <div class="upload-header">
      <Label class="upload-title" color="primary" size="small">{{
        title
      }}</Label>
      <Label
        class="upload-subtitle"
        v-if="!browseFile"
        color="tertiary"
        size="small"
        >{{ subtitle }}</Label
      >
      <input
        v-if="browseFile"
        type="file"
        id="file"
        ref="file"
        accept="image/*"
        @change="compress"
      />
    </div>
    <div class="upload-button">
      <Button
        v-if="
          !getReturn.submitted ||
            getReturn.status !== retakeStatusEnum.Q2_REFUSED ||
            getReturn.status !== retakeStatusEnum.FINALIZED
        "
        :disabled="disabled"
        label="upload"
        :fill="false"
        type="secondary"
        :border="true"
        uppercase
        rounded
        @onClick="submitFile"
      ></Button>
      <Button
        v-if="
          getReturn.phaseTwoDTOV3 &&
            getReturn.phaseTwoDTOV3.files &&
            getReturn.phaseTwoDTOV3.files.find(image => image.name === pov)
        "
        :disabled="false"
        label="download"
        :fill="false"
        type="secondary"
        :border="true"
        uppercase
        rounded
        @onClick="downloadFile"
      ></Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../atoms";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

// MAX 3MB
const MAX_ALLOWED = 3145728;

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });

export default {
  name: "UploadFile",
  components: {
    Label,
    Button
  },
  data() {
    return {
      browseFile: false,
      file: "",
      filename: "",
      retakeStatusEnum: RETAKESTATUS
    };
  },
  props: {
    title: {
      type: String,
      default: "Title"
    },
    subtitle: {
      type: String,
      default: "Subtitle"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pov: {
      type: String
    }
  },
  methods: {
    async handleFileUpload(rawFile) {
      this.file = await toBase64(rawFile);
      this.filename = rawFile.name;
      this.submitFile();
    },
    compress(e) {
      const file = e.target.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.invalidFileTypeError"),
          dismissible: true,
          type: "error"
        });
        return;
      }
      if (file.size < MAX_ALLOWED) {
        const fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
          const img = new Image();
          img.src = event.target.result;
          (img.onload = () => {
            const elem = document.createElement("canvas");
            elem.width = img.width * 0.9;
            elem.height = img.height * 0.9;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, elem.width, elem.height);
            ctx.canvas.toBlob(
              blob => {
                const file = new File([blob], fileName, {
                  type: "image/jpeg",
                  lastModified: Date.now()
                });
                this.handleFileUpload(file);
              },
              "image/jpeg",
              0.5
            );
          }),
            (reader.onerror = error => console.log(error));
        };
      } else {
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.maximumFileSizeError"),
          dismissible: true,
          type: "error"
        });
        return;
      }
    },
    submitFile() {
      if (this.browseFile === false) {
        this.browseFile = true;
      } else if (this.browseFile === true && this.$refs.file.files[0]) {
        this.$emit("onUpload", { content: this.file, name: this.filename });
      } else {
        this.browseFile = false;
      }
    },
    downloadFile() {
      this.$emit("onDownload", { content: this.file, name: this.filename });
    }
  },
  computed: {
    ...mapGetters(["getReturn"])
  }
};
</script>

<style lang="css" scoped src="./UploadFile.css"></style>
