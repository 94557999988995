<template>
  <div class="returns">
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.returns.title")
      }}</Label>
    </div>
    <div class="content">
      <div v-if="getReturns.length > 0">
        <div v-for="(item, index) in getReturns" :key="index">
          <SavedReturn
            class="saved-return"
            :vehicle="item"
            :retakeStatus="item.status"
            @onDelete="onDeleteHandler(item)"
            @onSearch="resumeSearch(item, index)"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          title="Upss... Nenhuma retoma adicionada."
          description="Adicione retomas através da página 'avaliar retoma'."
          labelButton="Avaliar retoma"
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../../atoms";
import { SavedReturn, NoResults } from "../../../organisms";
import { RETAKESTATUS } from "../../../../enums/retakeStatus.js";
import { PAGES } from "@/enums/pages";

export default {
  name: "Returns",
  components: {
    Label,
    SavedReturn,
    NoResults
  },
  data() {
    return {
      retakeStatusEnum: RETAKESTATUS
    };
  },
  methods: {
    resumeSearch(item, index) {
      if (item.status !== this.retakeStatusEnum.EXPIRED) {
        this.$store.commit("setReturnByIndex", index);
        this.$router.push({
          name: PAGES.RETAKE_DETAIL,
          params: { id: item.id }
        });
      } else {
        this.$router.push({ name: PAGES.RETAKE });
      }
    },
    onClickNoResultsHandler() {
      this.$router.push({
        name: PAGES.RETAKE
      });
    },
    onDeleteHandler(retake) {
      this.$store.dispatch("storeDeleteReturn", retake.id);
    }
  },
  computed: {
    ...mapGetters(["getReturns"])
  },
  mounted() {
    this.$store.dispatch("storeGetAllReturns");
  }
};
</script>

<style lang="css" scoped src="./Returns.css"></style>
