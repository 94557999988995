<template>
  <div class="no-padding panel--content-wrapper">
    <div class="padding-container">
      <PriceDetails :vehicle="vehicle" />

      <AdvantageList />

      <div class="flex flex-col">
        <Button
          class="main--btn"
          @onClick="onReserve"
          fill
          type="secondary"
          size="large"
          uppercase
          :label="buttonLabel"
        />
        <div class="flex py-2 align-baseline w-full">
          <div class="line" />
          <span class="no-underline">ou</span>
          <div class="line" />
        </div>
        <div class="reserve-button">
          <Button
            class="mr-3"
            @onClick="onTestDrive"
            uppercase
            type="primary"
            size="large"
            :label="$t('panels.reserve.steps.1.btnLabels.testDriveBtn')"
          />
          <Button
            @onClick="onContactRequest"
            uppercase
            type="primary"
            size="large"
            :label="$t('panels.reserve.steps.1.btnLabels.ContactBtn')"
          />
        </div>
        <div class="contact-video-button">
          <Button
            @onClick="onContactVideoRequest"
            uppercase
            type="primary"
            size="large"
            :label="$t('panels.reserve.steps.1.btnLabels.ContactVideoBtn')"
          />
        </div>
      </div>
    </div>

    <div class="faq-card">
      <FaqCard @onActiveFootprint="e => faqsFootprint(e)" />
    </div>
  </div>
</template>

<script>
import { PriceDetails, FaqCard } from "../../organisms";
import { AdvantageList } from "../../molecules";
import { Button } from "../../atoms";
import { mapGetters } from "vuex";

import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

export default {
  name: "ReserveIntro",
  components: {
    PriceDetails,
    Button,
    FaqCard,
    AdvantageList
  },
  props: {
    vehicle: {
      type: Object
    }
  },
  data() {
    return {
      reservePanelName: "ReservePanel Intro"
    };
  },
  methods: {
    onReserve() {
      const footprintEvent = {
        vin: this.getSelectedVehicle?.vin
      };
      registerEvent(FOOTPRINT_TYPES.START_RESERVE, footprintEvent);

      this.$emit("onReserve");
    },
    onTestDrive() {
      this.$emit("onTestDrive");
    },
    onContactRequest() {
      this.$emit("onContactRequest");
    },
    onContactVideoRequest() {
      this.$emit("onContactVideoRequest");
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: this.reservePanelName,
        title: e?.title
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    }
  },
  computed: {
    ...mapGetters(["getVehicleFaqs", "getWithFinancing", "getSelectedVehicle"]),
    buttonLabel() {
      return this.getWithFinancing
        ? this.$t("panels.reserve.steps.1.btnLabels.reserveBtn")
        : this.$t("panels.reserve.steps.1.btnLabels.purchaseBtn");
    }
  }
};
</script>

<style scoped src="./steps/styles.css"></style>
