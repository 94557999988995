<template>
  <Section id="search-new-car" customClass="search-new-car">
    <div class="search-new-car--left">
      <div class="search-new-car--left-title">
        <Heading
          class="h2-title"
          :label="$t('pages.homepage.sections.searchnewcar.title')"
          tag="h2"
        />
      </div>

      <div class="search-new-car--left-subtitle">
        <Label color="tertiary" size="small" bold="normal"
          >{{ $t("pages.homepage.sections.searchnewcar.description") }}
        </Label>
      </div>

      <div class="search-new-car--left-button-search">
        <Button
          @onClick="search"
          uppercase
          :fill="true"
          :label="$t('pages.homepage.sections.searchnewcar.button')"
          type="primary"
          :border="false"
        ></Button>
      </div>
    </div>
    <img
      class="search-new-car--right"
      :src="
        $t('resources.images.homepage.searchVehicleSection') ||
          require('../../../assets/images/Clicktobuy_carros_homepage.webp')
      "
      alt="search-new-car-image"
    />
  </Section>
</template>

<script>
import { Section, Label, Button } from "../../atoms";
import { Heading } from "@sc/ds-ui-component-library";
export default {
  components: {
    Section,
    Label,
    Button,
    Heading
  },
  methods: {
    search() {
      this.$emit("onSearch");
    }
  }
};
</script>

<style lang="css" scoped src="./SearchNewCar.css"></style>
