<template>
  <div class="page" :page-name="pageName">
    <nav id="navbar">
      <slot name="navbar" />
    </nav>
    <slot name="content" />
    <footer id="footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    pageName: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="css" scoped src="./Page.css"></style>
