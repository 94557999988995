<template>
  <div
    id="overlay"
    :class="['overlay', customClass]"
    :style="customStyles"
    @mousedown="emitClose"
  />
</template>

<script>
export default {
  name: "Overlay",
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    }
  },
  methods: {
    emitClose(e) {
      this.$emit("onClose", e);
    }
  }
};
</script>

<style scoped>
.overlay {
  @apply top-0;
  @apply left-0;
  @apply fixed;
  @apply w-screen;
  @apply h-screen;
  @apply bg-white;
  @apply opacity-50;
  z-index: 199;
}
</style>
