<template>
  <div class="simulation-conditions" v-if="renderComponent">
    <div class="simulation-conditions--title" v-if="getVehicleInitialDeposit">
      <Label size="small">{{ title }}</Label>
    </div>
    <div class="simulation-conditions--range">
      <div class="simulation-conditions--range-initial-entry">
        <RangeInput
          @onChange="onChangeInitialEntry"
          @onMouseUp="onMouseUpInitialEntry"
          :label="labelRange1"
          sliderType="secondary"
          bold="normal"
          inputType="tertiary"
          :sliderMin="deposit.min"
          :sliderMax="deposit.max"
          :sliderInterval="deposit.step"
          :sliderValue="deposit.value"
          :sliderDisabled="getIncludeRetake && deposit.retake >= deposit.max"
          :key="deposit.value"
        />
      </div>
      <div class="simulation-conditions--range-number-months">
        <RangeInput
          @onChange="onChangeNumberMonths"
          @onMouseUp="onMouseUpNumberMonths"
          :label="labelRange2"
          sliderType="secondary"
          bold="normal"
          inputType="tertiary"
          :sliderMin="month.min"
          :sliderMax="month.max"
          :sliderInterval="month.step"
          :sliderValue="months"
          icon=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { RangeInput } from "../../molecules";
import { mapGetters } from "vuex";

// Open-Drive max is 20% of price

export default {
  name: "SimulationConditions",
  components: {
    Label,
    RangeInput
  },
  data() {
    return {
      renderComponent: true,
      intialState: true
    };
  },
  props: {
    title: {
      type: String
    },
    labelRange1: {
      type: String
    },
    labelRange2: {
      type: String
    },
    deposit: {
      type: Object
    },
    month: {
      type: Object
    }
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    onChangeInitialEntry(event) {
      this.$emit("onChangeInitialEntry", event);
    },
    onChangeNumberMonths(event) {
      this.$emit("onChangeNumberMonths", event);
    },
    onMouseUpInitialEntry(event) {
      this.$emit("onMouseUpInitialEntry", event);
    },
    onMouseUpNumberMonths(event) {
      this.$emit("onMouseUpNumberMonths", event);
    }
  },
  computed: {
    ...mapGetters([
      "getVehicleNumberMonths",
      "getVehicleInitialDeposit",
      "getVehicleInitialDepositFormatted",
      "getIsOpenDrive",
      "getVehiclePrice",
      "getRetakeValue",
      "getReturnValue",
      "getIncludeRetake",
      "getIncludeRetakeChanged",
      "SearchModule/dropdown/getFinanceDropdown",
      "getCarFinance"
    ]),
    months() {
      if (this.month.value >= this.month.max) return this.month.max;
      if (this.month.value <= this.month.min) return this.month.min;
      return this.month.value;
    }
  },
  mounted() {
    this.forceRerender();
  }
};
</script>

<style lang="css" scoped src="./SimulationConditions.css"></style>
