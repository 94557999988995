import { render, staticRenderFns } from "./PhotosAndDocuments.vue?vue&type=template&id=45de8438&scoped=true"
import script from "./PhotosAndDocuments.vue?vue&type=script&lang=js"
export * from "./PhotosAndDocuments.vue?vue&type=script&lang=js"
import style0 from "./PhotosAndDocuments.css?vue&type=style&index=0&id=45de8438&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45de8438",
  null
  
)

export default component.exports