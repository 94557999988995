<template>
  <Card
    :class="'missing-action-card'"
    @click.native="redirect"
    :bgColor="backgroundColor"
    padding
  >
    <div class="card-content">
      <div class="warning-label">
        <StateLabel :alertType="warningColor"> {{ warningLabel }}</StateLabel>
      </div>
      <div class="card-content--title">
        <Label color="primary">{{ title }}</Label>
      </div>
      <div class="card-content--description">
        <Label color="tertiary">{{ description }} </Label>
      </div>
    </div>
    <div class="card--icon-container">
      <font-awesome-icon icon="chevron-right" />
    </div>
  </Card>
</template>

<script>
import { Card, Label, StateLabel } from "../../atoms";
export default {
  components: {
    Card,
    Label,
    StateLabel
  },
  props: {
    description: {
      type: String
    },
    title: {
      type: String
    },
    warningLabel: {
      type: String
    },
    warningColor: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: "white"
    }
  },
  methods: {
    redirect() {
      this.$emit("onRedirect");
    }
  },
  computed: {}
};
</script>

<style lang="css" scoped src="./CardWithAction.css"></style>
