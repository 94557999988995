import { getPriceFormatted, formatNonCurrency } from "./currency";
import i18n from "../i18n";

function getReserveTooltipPVP({
  depositValue,
  capitalFinance,
  mtic,
  tan,
  months,
  totalPrice,
  taeg,
  monthPrice,
  pricePvp,
  commercialDescription,
  consumptionCombine,
  emissions,
  model,
  initialExpenses
}) {
  return i18n.t("pages.detailsPage.tooltipPVP", {
    depositValue: getPriceFormatted(depositValue),
    capitalFinance: getPriceFormatted(capitalFinance),
    mtic: getPriceFormatted(mtic),
    tan: formatNonCurrency(tan),
    months: months,
    totalPrice: getPriceFormatted(totalPrice),
    taeg: formatNonCurrency(taeg, 1, 1),
    monthPrice: getPriceFormatted(monthPrice),
    pricePvp: getPriceFormatted(pricePvp),
    commercialDescription: commercialDescription,
    consumptionCombine: consumptionCombine,
    emissions: emissions,
    model: model,
    initialExpenses: getPriceFormatted(initialExpenses)
  });
}

function generateTooltipDescription({
  pricePvp,
  depositValue,
  capitalFinance,
  tan,
  taeg,
  mtic,
  monthPrice,
  months,
  commercialDescription,
  model,
  totalPrice,
  initialExpenses,
  dtEndOfCampaign
}) {
  return i18n.t("pages.detailsPage.tooltip", {
    pricePvp: getPriceFormatted(pricePvp),
    minDeposit: getPriceFormatted(depositValue),
    capitalToFinance: getPriceFormatted(capitalFinance),
    tan: formatNonCurrency(tan),
    taeg: formatNonCurrency(taeg, 1, 1),
    mtic: getPriceFormatted(mtic),
    monthlyValue: getPriceFormatted(monthPrice),
    numMonths: months,
    commercialDescription: commercialDescription,
    model: model,
    totalPrice: getPriceFormatted(totalPrice),
    initialExpenses: getPriceFormatted(initialExpenses),
    dtEndOfCampaign: dtEndOfCampaign
  });
}

export { generateTooltipDescription, getReserveTooltipPVP };
