import { changeReceipt } from "@/api/userMe";

function prepareZipCode(zipcode) {
  return zipcode.split("-");
}

const state = {
  receipt: {
    nif: "",
    address: "",
    zipCode: "",
    location: ""
  },
  billing: {
    vatNumber: "",
    address: "",
    zipCode: "",
    zipCodeExtension: "",
    city: ""
  }
};

const getters = {
  getReceipt: state => state.receipt,
  getBilling: state => state.billing
};

const mutations = {
  setReceipt(state, receipt) {
    state.receipt = receipt;
  },
  setBilling(state, receipt) {
    state.billing = {
      vatNumber: receipt.nif,
      address: receipt.address,
      zipCode: prepareZipCode(receipt.zipCode)[0],
      zipCodeExtension: prepareZipCode(receipt.zipCode)[1],
      city: receipt.location
    };
  }
};

const actions = {
  storeReceipt({ commit }, receipt) {
    commit("setReceipt", receipt);
    commit("setBilling", receipt);
    changeReceipt(receipt);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
