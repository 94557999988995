const state = {
  responsivenessStatus: {
    maxMobileWidth: 500,
    windowWidth: window.innerWidth,
    isMobile: window.innerWidth <= 500
  }
};

const getters = {
  isMobile: state => state.responsivenessStatus.isMobile,
  windowWidth: state => state.responsivenessStatus.windowWidth,
  maxMobileWidth: state => state.responsivenessStatus.maxMobileWidth
};

const mutations = {
  setReponsivenessStatus(state, status) {
    state.responsivenessStatus.windowWidth = status.windowWidth;
    state.responsivenessStatus.isMobile =
      status.windowWidth <= state.responsivenessStatus.maxMobileWidth;
  },
  clearResponsivenessStatus(state) {
    state.responsivenessStatus = {
      maxMobileWidth: 768,
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth <= state.maxMobileWidth
    };
  }
};

export default {
  state,
  getters,
  mutations
};
