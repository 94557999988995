<template>
  <div class="container" :style="styles" v-if="display">
    <div class="icon" :class="bgIcon" />
    <div class="type">
      <b>{{ $t(`alert.${type}`) }}</b>
      {{ message }}
    </div>
    <div class="icon-close" :class="iconClose" @click="clickAlert" />
  </div>
</template>

<script>
export default {
  name: "MyAlert",
  data() {
    return {
      typeColor: {
        error: "#E63312",
        info: "#00AAD2",
        success: "#43A047",
        warning: "#FFA300"
      },
      display: true
    };
  },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "info",
      validator: t => ["info", "error", "warning", "success"].includes(t)
    },
    message: {
      type: String,
      default: ""
    }
  },
  computed: {
    styles() {
      let bgColor;

      if (this.inline) {
        bgColor = this.typeColor[this.type];
      }

      switch (this.type + this.inline) {
        case "errortrue":
          bgColor = "rgba(230, 51, 18, 0.18)";
          break;
        case "infotrue":
          bgColor = "rgba(0, 170, 210, 0.18)";
          break;
        case "successtrue":
          bgColor = "rgba(67, 160, 71, 0.18)";
          break;
        case "warningtrue":
          bgColor = "rgba(255, 163, 0, 0.18)";
          break;
      }

      let border = "";
      if (this.inline) {
        border += `1px solid ${this.typeColor[this.type]}`;
      }

      let color = "white";
      if (this.inline) {
        color = this.typeColor[this.type];
      }

      return {
        "background-color": bgColor,
        border: border,
        color: color
      };
    },
    bgIcon() {
      return `${this.type}${this.inline ? "-inline" : ""}`;
    },
    iconClose() {
      return this.inline ? `close-${this.type}` : "close";
    }
  },
  methods: {
    clickAlert() {
      this.display = false;
    }
  }
};
</script>

<style scoped lang="css" src="./MyAlert.css"></style>
