import { render, staticRenderFns } from "./ReturnDetails.vue?vue&type=template&id=7b685a98&scoped=true"
import script from "./ReturnDetails.vue?vue&type=script&lang=js"
export * from "./ReturnDetails.vue?vue&type=script&lang=js"
import style0 from "./ReturnDetails.css?vue&type=style&index=0&id=7b685a98&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b685a98",
  null
  
)

export default component.exports