<template>
  <div
    :class="['toast--wrapper', customClass, classes]"
    @click="clickHandler"
    :style="customStyles"
  >
    <font-awesome-icon
      class="toast--icon toast--icon-status"
      v-if="icon"
      :icon="statusIcon"
      :style="iconColorStyle"
    />
    <div class="toast--label" :style="icon ? 'margin-left: 12px' : ''">
      {{ label }}
    </div>
    <font-awesome-icon
      class="toast--icon toast--icon-close"
      v-if="closeIcon"
      icon="times"
      :style="iconColorStyle"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      statusIcons: {
        normal: "bell",
        info: "info-circle",
        success: "check-circle",
        warning: "exclamation-triangle",
        danger: "exclamation-circle"
      },
      hidden: false
    };
  },
  props: {
    customClass: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: "normal",
      validator: status => {
        return (
          ["normal", "info", "success", "warning", "danger"].indexOf(status) !==
          -1
        );
      }
    },
    position: {
      type: String,
      default: "top-right",
      validator: status => {
        return (
          [
            "top-left",
            "top-center",
            "top-right",
            "bottom-left",
            "bottom-center",
            "bottom-right",
            "center"
          ].indexOf(status) !== -1
        );
      }
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 2000
    },
    closeOnClick: {
      type: Boolean,
      default: false
    },
    closeIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler(event) {
      // ignore if closeOnclick not set
      if (!this.closeOnClick) return;

      this.hidden = true;
      this.resetTimer();

      this.$emit("onClick", event);
    },
    resetTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  computed: {
    statusIcon() {
      return this.statusIcons[this.status];
    },
    classes() {
      return {
        "toast--hide": this.hidden,
        "toast--round": this.rounded,
        "toast--clickable": this.closeOnClick,
        "toast--status toast--status-info": this.status === "info",
        "toast--status toast--status-success": this.status === "success",
        "toast--status toast--status-warning": this.status === "warning",
        "toast--status toast--status-danger": this.status === "danger",
        "toast--position-top-left": this.position === "top-left",
        "toast--position-top-center": this.position === "top-center",
        "toast--position-top-right": this.position === "top-right",
        "toast--position-bottom-left": this.position === "bottom-left",
        "toast--position-bottom-center": this.position === "bottom-center",
        "toast--position-bottom-right": this.position === "bottom-right",
        "toast--position-center": this.position === "center"
      };
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    iconColorStyle() {
      return {
        color: this.iconColor
      };
    }
  },
  mounted() {
    if (this.timeout > 0) {
      this.timer = setTimeout(() => {
        this.hidden = true;
      }, this.timeout);
    }
  }
};
</script>

<style lang="css" scoped src="./Toast.css"></style>
