<template>
  <div :class="['state-label', customClass, classes]" :style="customStyles">
    <slot />
  </div>
</template>

<script>
export default {
  name: "StateLabel",
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    customClass: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary",
      validator: value => {
        return ["primary", "secondary", "tertiary"].includes(value);
      }
    },
    alertType: {
      type: String,
      default: "blue",
      validator: value => {
        return ["blue", "red", "orange"].includes(value);
      }
    }
  },
  computed: {
    classes() {
      return {
        "state-label--primary": this.color === "primary",
        "state-label--secondary": this.color === "secondary",
        "state-label--tertiary": this.color === "tertiary",
        "state-label--blue": this.alertType === "blue",
        "state-label--orange": this.alertType === "orange",
        "state-label--red": this.alertType === "red"
      };
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>

<style lang="css" scoped src="./StateLabel.css"></style>
