import httpClient from "./httpClient";

const DROPDOWNS = "/vehicle/dropdowns";

function getDropdowns(conditionId) {
  const data = conditionId
    ? httpClient.post(
        `${DROPDOWNS}?dealersAvailability=true&conditionId=${conditionId}`,
        {}
      )
    : httpClient.post(`${DROPDOWNS}?dealersAvailability=true`, {});
  return data;
}

export { getDropdowns };
