<template>
  <div class="financing-result">
    <div v-if="error === false">
      <div class="financing-result--header">
        <div v-if="withFinancing" class="financing-result--header-left">
          <div class="pvp-information-header">
            <span
              v-if="
                $store.getters.getPriceBasedOnType({
                  hasFinancing: !getIsOpenDrive && getFinancialContribution,
                  isPrevious: true
                })
              "
              class="header-previous-pvp"
            >
              {{ $t("pages.detailsPage.financingLabels.price") }}
              {{
                $store.getters.getFormattedPriceBasedOnType({
                  hasFinancing: !getIsOpenDrive && getFinancialContribution,
                  isPrevious: true
                })
              }}
            </span>
            <div class="header-pvp">
              <span class="total-price">
                {{
                  $store.getters.getFormattedPriceBasedOnType({
                    hasFinancing: !getIsOpenDrive && getFinancialContribution,
                    isPrevious: false
                  })
                }}
              </span>
              <Tooltip :tooltip="tooltip" :position="'bottom'">
                <template v-slot:content>
                  <img
                    width="22"
                    height="22"
                    class="icon--margin"
                    :src="
                      $t('resources.images.financing.info') ||
                        '../../../assets/images/infoWhite.svg'
                    "
                  />
                </template>
              </Tooltip>
            </div>
          </div>
        </div>
        <div v-if="!withFinancing" class="financing-result--header-center">
          <div
            v-if="
              $store.getters.getPriceBasedOnType({
                hasFinancing: false,
                isPrevious: true
              })
            "
          >
            <span class="header-previous-title">{{
              $store.getters.getFormattedPriceBasedOnType({
                hasFinancing: false,
                isPrevious: true
              })
            }}</span>
          </div>
          <div>
            <span class="header-title">{{
              $store.getters.getFormattedPriceBasedOnType({
                hasFinancing: false,
                isPrevious: false
              })
            }}</span>
          </div>
          <div>
            <span class="header-subtitle">{{
              $t("pages.detailsPage.financing.financingResult.amountToPay")
            }}</span>
          </div>
        </div>
        <div v-if="withFinancing" class="financing-result--header-right">
          <div v-if="withFinancing" class="pvp-information-header">
            <span class="header-financing-information--taeg">
              {{ $t("pages.detailsPage.financingLabels.taeg") }} {{ getTaeg }}
            </span>
            <span class="header-financing-information--monthly">
              {{ getFormattedMonthlyPriceSimple
              }}{{ $t("pages.detailsPage.financingLabels.monthly") }}
            </span>
          </div>
        </div>
      </div>
      <div class="financing-result--content">
        <div v-if="withFinancing" class="withFinancing">
          <div class="content-row-title">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.simulationData")
              }}</Label
            >
          </div>
          <div class="content-row">
            <div>
              <Label
                color="tertiary"
                size="small"
                customClass="content-row-label"
                >{{
                  $t("pages.detailsPage.financing.financingResult.monthlyValue")
                }}</Label
              >
            </div>

            <div class="label-icon">
              <Label
                color="tertiary"
                size="small"
                customClass="content-row-label"
              >
                {{ getFormattedMonthlyPriceSimple }}
              </Label>
              <Tooltip :tooltip="tooltip">
                <template v-slot:content>
                  <img
                    width="16"
                    height="16"
                    class="icon--margin"
                    :src="
                      $t('resources.images.financing.infotoggle') ||
                        '../../../assets/images/info-toggle.png'
                    "
                  />
                </template>
              </Tooltip>
            </div>
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.numberOfMonths")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ getVehicleNumberMonths }}</Label
            >
          </div>
          <div class="content-row">
            <Label color="tertiary" size="small" customClass="content-row-label"
              >TAEG</Label
            >
            <Label color="tertiary" size="small" customClass="content-row-label"
              >{{ getTaeg }}
            </Label>
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.financedAmunt")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ getFormattedCapitalToFinance }}</Label
            >
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.initialEntry")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ getPriceFormatted(deposit) }}</Label
            >
          </div>
          <div class="content-row" v-if="getVehicleResidualValue">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.residualValue")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ getPriceFormatted(getVehicleResidualValue) }}</Label
            >
          </div>
          <div v-if="!isGuest" class="content-row-footer">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.retake")
              }}</Label
            >
            <Label
              v-if="!haveSimulation && resumePrice"
              @click.native="addResume"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else-if="!haveSimulation && !resumePrice"
              @click.native="goToEvaluation"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else-if="haveSimulation || !resumePrice"
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ retakeEvalLabel }}
            </Label>
          </div>
        </div>
        <div v-if="!withFinancing" class="withoutFinancing">
          <div v-if="!isGuest" class="content-row-footer-alternative">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.retake")
              }}</Label
            >
            <Label
              v-if="!haveSimulation && resumePrice"
              @click.native="addResume"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else-if="!haveSimulation && !resumePrice"
              @click.native="goToEvaluation"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else-if="haveSimulation || !resumePrice"
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ retakeEvalLabel }}
            </Label>
          </div>
        </div>
      </div>
      <div class="financing-result--footer">
        <Button
          class="reserve-button"
          :uppercase="isReserved || isSold ? true : false"
          type="secondary"
          fill
          :filename="isReserved || isSold ? 'Lock.svg' : ''"
          :rightArrowIcon="isReserved || isSold ? false : true"
          iconPosition="left"
          :custom-class="isReserved || isSold ? 'vehicle-reserved' : ''"
          :label="
            isReserved || isSold
              ? filterTagLabel
              : $t(
                  'pages.detailsPage.financing.financingResult.buttonInterested'
                )
          "
          :disabled="isReserved || isSold"
          :border="isReserved || isSold"
          @onClick="clickInterest"
        />
        <div class="financing-result--footer-download" v-if="withFinancing">
          <div class="financing-result-download--wrapper">
            <Button
              filename="download-icon.svg"
              iconPosition="left"
              type="secondary"
              :border="false"
              customClass="download-fins"
              :label="
                $t(
                  'pages.detailsPage.financing.financingResult.downloadFins.title'
                )
              "
              @onClick="getFinFile"
            />
          </div>
          <Label
            class="download-fins-description"
            color="tertiary"
            size="small"
            customClass="content-row-label"
            >{{
              $t(
                "pages.detailsPage.financing.financingResult.downloadFins.description"
              )
            }}<Link
              class="download-fins-description-link"
              color="secondary"
              :label="
                $t(
                  'pages.detailsPage.financing.financingResult.downloadFins.dealersLink'
                )
              "
              @onClick="
                e => {
                  e.preventDefault();
                  openDealersPopup = true;
                }
              "
          /></Label>

          <Popup
            id="dealers-list-popup"
            v-show="openDealersPopup"
            :closeButton="true"
            :closeOnClickOutside="true"
            @close="openDealersPopup = false"
            customClass="z-30"
            :title="
              $t(
                'pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.title'
              )
            "
          >
            <template slot="body" class="dealers-list-popup--wrapper">
              <div class="main-section">
                <div class="grid sm:grid-cols-2 mb-8">
                  <Label color="primary" size="small">{{
                    $t(
                      "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.title.1"
                    )
                  }}</Label>
                  <Label color="primary" size="small">{{
                    $t(
                      "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.title.2"
                    )
                  }}</Label>
                </div>
                <div class="section-wrapper">
                  <div
                    class="grid sm:grid-cols-2 mb-5"
                    v-for="(dealer, index) in getDealers"
                    :key="index"
                  >
                    <Label
                      class="dealer-name--label"
                      color="primary"
                      size="small"
                    >
                      {{ dealer.designation }} ({{ dealer.address }}-{{
                        dealer.district
                      }})</Label
                    >
                    <Label
                      class="dealer-type--label"
                      color="tertiary"
                      size="small"
                    >
                      {{
                        $t(
                          "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.dealerTypeLabel"
                        )
                      }}</Label
                    >
                  </div>
                </div>
              </div>
            </template>

            <template slot="footer"
              ><div class="footer-btn">
                <div />
                <div />
                <div />
                <Button
                  uppercase
                  customClass="exit-btn"
                  :label="
                    $t(
                      'pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.close'
                    )
                  "
                  type="primary"
                  @onClick="openDealersPopup = false"
                />
              </div>
            </template>
          </Popup>
        </div>
      </div>
    </div>
    <div v-if="error === true">
      <div class="financing-result--header">
        <div v-if="withFinancing" class="financing-result--header-center">
          <div>
            <Label customClass="header-title">{{
              $t("pages.detailsPage.financing.financingResult.noResults")
            }}</Label>
          </div>
          <div>
            <Label customClass="header-subtitle">{{
              $t("pages.detailsPage.financing.financingResult.adjustSimulation")
            }}</Label>
          </div>
        </div>
      </div>
      <div class="financing-result--content">
        <div v-if="withFinancing" class="withFinancing">
          <div class="content-row-title mt-5">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t(
                  "pages.detailsPage.financing.financingResult.adjustInitialEntry"
                )
              }}</Label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Button, Tooltip, Link, Popup } from "../../atoms";
import { mapGetters } from "vuex";

import { RESERVE_TYPE } from "../../../enums/reserveTypes.js";
import { getPriceFormatted } from "../../../utils/currency";
import { PAGES } from "../../../enums/pages";

export default {
  name: "FinancingResult",
  components: {
    Label,
    Button,
    Link,
    Popup,
    Tooltip
  },
  data() {
    return {
      openDealersPopup: false
    };
  },
  props: {
    deposit: {
      type: Number
    },
    resumePrice: {
      type: Number,
      default: 0
    },
    haveSimulation: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: "Valor Mensal"
    },
    pvp: {
      type: String,
      default: "15000"
    },
    error: {
      type: Boolean
    }
  },
  methods: {
    getPriceFormatted,
    addResume() {
      this.$emit("onAddResume", !this.haveSimulation);
    },
    goToEvaluation() {
      this.$router.push({
        name: PAGES.RETAKE
      });
    },
    clickInterest() {
      this.$store.state.isOpenReservePanel = true;
      this.$emit("onClickInterest");
    },
    getFinFile(event) {
      this.$emit("getFinFile", event);
    }
  },
  computed: {
    ...mapGetters([
      "getIsOpenDrive",
      "getVehicleResidualValue",
      "getVehicleNumberMonths",
      "getFormattedMonthlyPriceSimple",
      "getWithFinancing",
      "getTaeg",
      "getVehicleInitialDepositFormatted",
      "getFormattedCapitalToFinance",
      "getFormattedPrice",
      "getFormattedPreviousPrice",
      "getPreviousPrice",
      "getReservationType",
      "isGuest",
      "getFormattedTotalPrice",
      "getDealers",
      "getIsOpenDrive",
      "getFinancialContribution"
    ]),
    withFinancing() {
      return this.getWithFinancing;
    },
    isReserved() {
      return this.getReservationType === RESERVE_TYPE.RESERVED;
    },
    isSold() {
      return this.getReservationType === RESERVE_TYPE.BOUGHT;
    },
    filterTagLabel() {
      return this.getReservationType === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveTypeBtnLabel.1")
        : this.$t("labels.reserveTypeBtnLabel.2");
    },
    retakeEvalLabel() {
      const value = this.resumePrice;

      return value === 0 || value === "0"
        ? this.$t("pages.retake.emptyValue")
        : getPriceFormatted(value, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    }
  }
};
</script>

<style lang="css" scoped src="./FinancingResult.css"></style>
