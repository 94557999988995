<template>
  <div :class="['card', customClass, classes]" :style="customStyles">
    <div class="card--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: String,
      validator: shadow => {
        return ["sm", "md", "lg", "xl", "2xl"].includes(shadow);
      }
    },
    padding: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: "white"
    }
  },
  computed: {
    classes() {
      return {
        "card--center card-full-width": true,
        "card--rounded": this.rounded,
        "card--no-padding": !this.padding,
        // shadow
        [`shadow-${this.shadow}`]: this.shadow
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor
      };
    }
  }
};
</script>

<style lang="css" scoped src="./Card.css"></style>
