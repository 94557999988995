<template>
  <Block :style="customStyles">
    <div class="swiper-container">
      <div class="swiper-slide hero-image" :style="heroImage"></div>
    </div>

    <div class="main-section">
      <div class="main-section-left">
        <Label
          customClass="title"
          v-html="textTitle"
          size="large"
          bold="extra-bold"
          color="primary"
        ></Label>
        <div class="2xl:w-3/5 w-full">
          <Label customClass="subtitle" size="small" color="tertiary">{{
            textSubtitle
          }}</Label>
        </div>
      </div>
      <div class="main-section-right">
        <SearchSegmentMonthly />
      </div>
    </div>
  </Block>
</template>

<script>
import { Block, Label } from "../../atoms";
import { SearchSegmentMonthly } from "../../organisms";
import { mapGetters } from "vuex";
export default {
  name: "HomeMainSection",
  components: {
    Label: Label,
    Block: Block,
    SearchSegmentMonthly
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    textButtonLeft: {
      type: String,
      require: false,
      default: "left"
    },
    textButtonRight: {
      type: String,
      require: false,
      default: "right"
    },
    textTitle: {
      type: String,
      require: false,
      default: "title"
    },
    textSubtitle: {
      type: String,
      require: false,
      default: "subtitle"
    },
    separator: {
      type: String,
      default: ""
    },
    slideshow: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: "cover.png"
    },
    images: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(["isMobile"]),
    customStyles() {
      return {
        ...this.styles
      };
    },
    heroImage() {
      if (this.isMobile) {
        return {
          backgroundImage: `url(${this.$t(
            "resources.images.homepage.heroMobile"
          ) || require("../../../assets/images/hero-mobile.webp")})`
        };
      }
      return {
        backgroundImage: `url(${this.$t("resources.images.homepage.hero") ||
          require("../../../assets/images/hero-desktop.webp")})`
      };
    }
  },
  methods: {
    onClickLeft() {
      this.$emit("onClickLeft");
    },
    onClickRight() {
      this.$emit("onClickRight");
    }
  }
};
</script>
<style lang="css" scoped src="./HomeMainSection.css"></style>
