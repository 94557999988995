<template>
  <Card
    customClass="card"
    class="cursor-pointer"
    shadow="xl"
    padding
    bgColor="#FFFFFF"
    @click.native="search"
  >
    <div class="card--header">
      <img :src="require(`../../../assets/images/cards/${filename}`)" />
    </div>
    <div class="card--content mt-6">
      <div class="card--content-title">
        <Heading class="cursor-pointer" :label="title" tag="h2" weight="bold" />
      </div>
      <div class="card--content-description">
        <Label class="cursor-pointer" color="tertiary" size="small">{{
          description
        }}</Label>
      </div>

      <div class="card--footer" @click="search">
        <Label
          class="card--footer-link"
          @click.native="search"
          color="tertiary"
          size="small"
          uppercase
          >{{
            $t("pages.homepage.sections.process.searchFor", {
              title
            })
          }}</Label
        >

        <img
          @click="search"
          :src="
            this.$t('resources.images.cardwithsegment.arrowright') ||
              require(`../../../assets/images/Arrow-right.png`)
          "
          width="21px"
          height="12px"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, Label } from "../../atoms";
import { Heading } from "@sc/ds-ui-component-library";
export default {
  components: {
    Card,
    Label,
    Heading
  },
  props: {
    numberVehicles: {
      type: Number,
      default: 300
    },
    title: {
      type: String,
      default: "Familiares"
    },
    description: {
      type: String,
      default: "Cars your family will love"
    },
    filename: {
      type: String,
      default: "segment-1.webp"
    }
  },
  methods: {
    search() {
      this.$emit("onSearch", this.title);
    }
  }
};
</script>

<style lang="css" scoped src="./CardWithSegment.css"></style>
