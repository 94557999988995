<template>
  <div class="no-results">
    <div class="no-results--title">
      <Label size="medium" color="primary">{{ title }}</Label>
    </div>
    <div v-if="description" class="no-results--description">
      <Label size="small" color="tertiary">{{ description }}</Label>
    </div>
    <div v-if="labelButton" class="no-results--button">
      <Button
        type="secondary"
        fill
        :border="false"
        uppercase
        :label="labelButton"
        @onClick="onClickHandler"
      ></Button>
    </div>
  </div>
</template>

<script>
import { Label, Button } from "../../atoms";
export default {
  name: "NoResults",
  components: {
    Label,
    Button
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    labelButton: {
      type: String
    }
  },
  methods: {
    onClickHandler() {
      this.$emit("onClick");
    }
  }
};
</script>

<style lang="css" scoped src="./NoResults.css"></style>
