<template>
  <Card
    customClass="card"
    shadow="lg"
    padding
    bgColor="#F5F7F9"
    class="cursor-pointer"
    @click.native="search"
  >
    <div class="card--header">
      <Label
        class="card--header-title"
        color="primary"
        size="medium"
        bold="bold"
      >
        {{
          $t("pages.homepage.sections.process.card360.title", {
            numberVehicles
          })
        }}
      </Label>
    </div>
    <div class="card--footer">
      <Label
        class="cursor-pointer card--footer-link"
        @click.native="search"
        color="secondary"
        size="medium"
        bold="bold"
        uppercase
        >{{ $t("pages.homepage.sections.process.card360.button") }}</Label
      >

      <div class="card--footer-icon-button" @click="search">
        <img
          :src="
            this.$t('resources.images.pricemodeldetails.arrowright') ||
              require(`../../../assets/images/arrowRightWhite.svg`)
          "
          width="32px"
          height="32px"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, Label } from "../../atoms";
export default {
  components: {
    Card,
    Label
  },
  props: {
    numberVehicles: {
      type: Number,
      default: 300
    }
  },
  methods: {
    search() {
      this.$emit("onSearch");
    }
  }
};
</script>

<style lang="css" scoped src="./Card300.css"></style>
