<template>
  <div class="container">
    <div
      class="block"
      :class="spaceBetween ? 'spacing' : ''"
      v-for="(val, key) in data"
      :key="key"
    >
      <Label color="tertiary">{{ key }}:</Label>
      <Label :customClass="spaceBetween ? 'text-left' : ''" color="primary">{{
        val
      }}</Label>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";

export default {
  name: "DisplayLabelValue",
  components: { Label },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    spaceBetween: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
:deep(.label) {
  margin-right: 0.5rem;
}

:deep(.label.text-left) {
  text-align: right;
}

.spacing {
  display: flex;
  justify-content: space-between;
}

.block {
  @apply mb-1;
}
</style>
