import { getPriceFormatted } from "../../utils/currency";
import Vue from "vue";
import {
  getVehicleByPlate,
  getBrands,
  getModels,
  getValuation,
  saveValuation
} from "../../api/retake";
import i18n from "../../i18n";

const WEEKLY_RETAKE_LIMIT = -10;

const brandInitialState = [{ text: "Marca", value: -1, disabled: true }];
const modelsInitialState = [{ text: "Modelos", value: -1, disabled: true }];
const versionsInitialState = [{ text: "Versões", value: -1, disabled: true }];

function handleError(error) {
  if (error.code === WEEKLY_RETAKE_LIMIT) {
    Vue.$toast.open({
      message: i18n.t("toast.valuation.weeklyLimit"),
      dismissible: true,
      type: "info"
    });
  } else {
    Vue.$toast.open({
      message: i18n.t("toast.valuation.error.generic"),
      dismissible: true,
      type: "error"
    });
  }
}

const setDefaults = commit => {
  commit("setBcaId", "");
  commit("setModelsResponse", []);
  commit("setModel", "");
  commit("setModels", modelsInitialState);
  commit("setVersions", versionsInitialState);
  commit("setKilometersRetake", "");
  commit("setPlateDate", "");
  commit("setIsPrefill", false);
};

const state = {
  successfullPlateCall: false,
  prefill: false,
  plate: "",
  brandObj: {},
  brandId: "",
  brand: "",
  brands: brandInitialState,
  model: "",
  models: modelsInitialState,
  modelsResponse: [],
  version: "",
  versions: versionsInitialState,
  kilometers: "",
  plateDate: "",
  bcaId: "",
  valuationRequest: {
    plate: "",
    brand: "",
    model: "",
    version: "",
    kilometers: "",
    plateDate: "",
    bcaId: ""
  },
  tradeInId: "",
  valuation: 0.0,
  allVersions: [],
  fuel: "",
  transmission: ""
};

const selectFormatted = arr =>
  arr?.length > 0
    ? arr.map(el => ({
        value: el,
        text: el
      }))
    : [];

const selectFormattedVersions = arr =>
  arr?.length > 0
    ? arr.map(el => ({
        value: el.description,
        text: el.description,
        transmission: el.transmission,
        fuel: el.fuel
      }))
    : [];

const getters = {
  getPlate: state => state.plate,
  getBrand: state => state.brand,
  getBrands: state => state.brands,
  getModell: state => state.model,
  getModels: state => state.models,
  getVrsn: state => state.version,
  getVersions: state => state.versions,
  getKilometersRetake: state => state.kilometers,
  getPlateDate: state => state.plateDate,
  getBcaId: state => state.bcaId,
  getValuationRequest: state => state.valuationRequest,
  getValuation: state => {
    return state.valuation === "0" || state.valuation === 0
      ? state.valuation
      : getPriceFormatted(Number(state.valuation), {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },
  getTradeInId: state => state.tradeInId,
  isPrefill: state => state.prefill,
  getRetakeValue: state => state.valuation,
  getRetakeObj: state => {
    const { model, valuation, plate } = state;
    return {
      model,
      value: valuation,
      registration: plate,
      expire: 2
    };
  },
  getTradeinFuel: state => state.fuel,
  getTradeinTransmission: state => state.transmission
};

const actions = {
  storeModelAndVersions({ commit, state }, model) {
    commit("setModel", model);
    // Check wether plate API call was successfull, or versions were loaded seperately
    if (state.successfullPlateCall) {
      commit("matchVersionsByModel", model);
      return;
    }
    commit("loadVersionsByModel", model);
  },
  async storeVehicleByPlate({ commit, state }) {
    try {
      commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      setDefaults(commit);
      const res = await getVehicleByPlate(state.plate);

      const resBrands = await getBrands();
      commit("setBrands", resBrands.data.data);

      if (res.data.data.version.length > 0) {
        var data;
        var dataArray;

        if (res.data.data.registrationDate) {
          data = res.data.data.registrationDate.replaceAll("-", "/");
          const [year, month, day] = data.split("/");
          dataArray = [day, month, year].join("/");
        }

        commit("setSuccessfullPlateCall", true);
        commit("setPrefillBrand", res.data.data.brand);
        commit("setModels", res.data.data.model);
      }
      if (res.data.data.version.length === 1) {
        commit("setBcaId", res.data.data.version[0].bcaId);
        commit("setTradeinFuel", res.data.data.version[0].fuel);
        commit("setTradeinTransmission", res.data.data.version[0].transmission);
      }
      commit("setVersions", res.data.data.version);
      commit("setPlateDate", dataArray);
      commit("setFormContent");
      commit("setAllVersions", res.data.data.version);
    } catch (err) {
      console.error("Could not fetch vehicle details from backend");

      const resBrands = await getBrands();
      setDefaults(commit);
      commit("setBrands", resBrands.data.data);
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeBrands({ commit }) {
    try {
      const res = await getBrands();
      setDefaults(commit);
      commit("setBrands", res.data.data);
    } catch (err) {
      console.error("Could not fetch brands from backend");
    }
  },
  async storeModels({ commit, state }) {
    try {
      const res = await getModels(
        state.brand.value,
        state.plateDate.split("/")[2]
      );
      commit("setModels", res.data.data);
      commit("setModelsResponse", res.data.data);
    } catch (err) {
      console.error("Could not fetch models from backend");
    }
  },
  storeValuation({ commit, state }, userInfo) {
    const payload = {
      ...state.valuationRequest,
      ...userInfo
    };
    return new Promise((resolve, reject) => {
      // commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      getValuation(payload)
        .then(response => {
          if (!response.data) {
            reject(response.response.data);
            handleError(response.response.data);
          } else {
            commit("setValuation", response.data.data);
            // Vue.$toast.open({
            //   message: i18n.t("toast.valuation.success.submited"),
            //   dismissible: true,
            //   type: "info"
            // });
            resolve(response.data.data);
          }
        })
        .catch(error => {
          console.error("Could not fetch valuation from backend");
          // commit("spinnerModule/setLoading", false, { root: true });
          handleError(error);
          reject(error);
        })
        .finally(() => {
          // commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
        });
    });
  },
  async assignValuationToUser({ state }) {
    try {
      await saveValuation({ tradeInId: state.tradeInId });
    } catch (err) {
      console.error("Could not assign valuation to user");
    }
  }
};

const mutations = {
  // Parse payload of models?year=x
  loadVersionsByModel(state, model) {
    const versions = state.modelsResponse.filter(obj => obj.model === model);
    state.versions = [
      ...versionsInitialState,
      ...versions.map(v => ({ text: v.version, value: v.bcaId }))
    ];
  },
  setSuccessfullPlateCall(state, val) {
    state.successfullPlateCall = val;
  },
  matchVersionsByModel(state, model) {
    state.versions = [
      ...versionsInitialState,
      ...selectFormattedVersions(
        state.allVersions.filter(v => v.description.includes(model))
      )
    ];
  },
  setAllVersions(state, versions) {
    state.allVersions = versions;
  },
  setPrefillBrand(state, [value]) {
    state.brands = state.brands
      .filter(it => it.text === value)
      .map(it => ({ ...it, selected: true }));

    state.brand = state.brands[0];
  },
  setFormContent(state) {
    state.prefill = true;
  },
  setPlate(state, plate) {
    state.plate = plate.toUpperCase();
  },
  setBrand(state, brand) {
    state.brand = brand;
  },
  setBrandId(state, brandId) {
    state.brandId = brandId;
  },
  setBrandById(state, id) {
    state.brand = state.brands.find(b => b.value == id);
  },
  setBrands(state, brands) {
    state.brands = [
      ...brandInitialState,
      ...brands.map(el => ({ value: el.id, text: el.description }))
    ];
  },
  setModel(state, model) {
    state.model = model;
  },
  setModelsResponse(state, response) {
    state.modelsResponse = response;
  },
  setModels(state, models) {
    let formattedModels;
    var uniqModel;
    if (models.length === 1) {
      if (models[0]?.value) {
        formattedModels = models;
      } else {
        formattedModels = selectFormatted(models);
      }
      state.model = formattedModels[0].value;
    } else if (models.length > 1 && typeof models[0] == "string") {
      formattedModels = [...modelsInitialState, ...selectFormatted(models)];
    } else {
      uniqModel = Array.from(new Set(models.map(it => it.model)));
      formattedModels = [
        ...modelsInitialState,
        ...uniqModel.map(el => ({ value: el, text: el }))
      ];
    }
    state.models = formattedModels;
  },
  setVersion(state, version) {
    state.version = version;
  },
  setVersions(state, versions) {
    var formattedVersions = [];
    if (
      state.modelsResponse[0] instanceof Object &&
      state.modelsResponse[0].bcaId !== undefined &&
      state.model != ""
    ) {
      formattedVersions = state.modelsResponse
        .filter(el => el.model == state.model)
        .map(el => ({ value: el.bcaId, text: el.version }));
      state.bcaId = formattedVersions[0].value;
    } else if (typeof versions[0] == "object") {
      formattedVersions = selectFormattedVersions(versions);
    }
    if (formattedVersions.length !== 1) {
      formattedVersions = [...versionsInitialState, ...formattedVersions];
    } else {
      state.fuel = formattedVersions[0].fuel;
      state.transmission = formattedVersions[0].transmission;
    }
    state.versions = formattedVersions;
    state.version = state.versions[0].text;
  },
  setPlateDate(state, plateDate) {
    state.plateDate = plateDate;
  },
  setKilometersRetake(state, kilometers) {
    state.kilometers = kilometers;
  },
  setBcaId(state, bcaId) {
    state.bcaId = bcaId;
  },
  setValuation(state, valuationResponse) {
    state.valuation = valuationResponse.valuation;
    state.tradeInId = valuationResponse.tradeInId;
  },
  setValuationRequest(state, valuationRequest) {
    state.valuationRequest = valuationRequest;
  },
  setIsPrefill(state, prefill) {
    state.prefill = prefill;
  },
  resetRetake(state) {
    state.successfullPlateCall = false;
    state.prefill = false;
    state.plate = "";
    state.brandObj = {};
    state.brandId = "";
    state.brand = "";
    state.brands = brandInitialState;
    state.model = "";
    state.models = modelsInitialState;
    state.modelsResponse = [];
    state.version = "";
    state.versions = versionsInitialState;
    state.kilometers = "";
    state.plateDate = "";
    state.bcaId = "";
    state.valuationRequest = {
      plate: "",
      brand: "",
      model: "",
      version: "",
      kilometers: "",
      plateDate: "",
      bcaId: ""
    };
    state.tradeInId = "";
    state.valuation = 0.0;
  },
  setTradeinFuel(state, fuel) {
    state.fuel = fuel;
  },
  setTradeinTransmission(state, transmission) {
    state.transmission = transmission;
  },
  setFuelTransmission(state, version) {
    var selected = state.versions.filter(v => v.value === version)[0];
    state.fuel = selected.fuel;
    state.transmission = selected.transmission;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
