function wrapInEvent(value) {
  return { target: { value } };
}

export default function formValidator(refs, ctx) {
  return refs
    .map(({ ref, nested, value }) => {
      if (!ctx) return false;
      const r = nested ? ctx[ref]?.$refs.inputRef : ctx[ref];
      if (!r) return false;
      const out = r.handleInput(wrapInEvent(value));
      r.showLabel = true;
      return out;
    })
    .filter(it => !it);
}
