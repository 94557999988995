<template>
  <div class="separator-300">
    <div class="separator-300--title">
      <Label
        v-if="numberVehicles >= 50"
        color="secondary"
        bold="bold"
        size="large"
        >+{{ multiples }}</Label
      >
      <Label v-else color="secondary" bold="bold" size="large">{{
        numberVehicles
      }}</Label>
      <Label color="primary" bold="bold" size="large">{{
        $t("pages.homepage.sections.separator300.title")
      }}</Label>
    </div>
    <div class="separator-300--description">
      <Label color="tertiary" size="medium">{{
        $t("pages.homepage.sections.separator300.description")
      }}</Label>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  components: {
    Label
  },
  props: {
    numberVehicles: {
      type: Number,
      default: 300
    }
  },
  computed: {
    multiples() {
      const trunc = Math.trunc(this.numberVehicles / 50);
      return trunc * 50;
    }
  }
};
</script>

<style lang="css" scoped src="./Separator300.css"></style>
