<template>
  <div class="autocomplete-dropdown">
    <Input
      inputType="text"
      name="search"
      :type="type"
      :placeholder="placeholder"
      :border="true"
      :value.sync="mySearch"
      @onChange="filterResults"
      :autocomplete="false"
      @keyup.enter.native="
        () => setMySearch({ type: 'needle', value: mySearch, id: 0 })
      "
    />
    <div
      v-if="results && dropdown"
      :class="['autocomplete-dropdown--results', Dropdown]"
    >
      <ul>
        <li v-if="loading">
          <Label size="small" :color="colorResults">A pesquisar...</Label>
        </li>
        <li v-else-if="!results.length">
          <Label size="small" :color="colorResults"
            >Não existem resultados.</Label
          >
        </li>
        <li
          v-else
          v-for="(r, index) in results"
          :key="index"
          @click="setMySearch(r)"
        >
          <Label size="small" :color="colorResults">{{ r.title }}</Label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Input, Label } from "../../atoms";
export default {
  name: "AutocompleteDropdown",
  components: {
    Input,
    Label
  },
  data() {
    return {
      dropdown: false,
      mySearch: ""
    };
  },
  props: {
    type: {
      type: String,
      default: "tertiary",
      validator: type => {
        return ["primary", "secondary", "tertiary"].includes(type);
      }
    },
    placeholder: {
      type: String,
      default: "Pesquise por modelo, segmento ou palavra-chave..."
    },
    results: {
      type: Array[Object],
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    Dropdown() {
      return {
        "autocomplete-dropdown--primary": this.type === "primary",
        "autocomplete-dropdown--secondary": this.type === "secondary",
        "autocomplete-dropdown--tertiary": this.type === "tertiary"
      };
    },
    colorResults() {
      return this.type === "secondary" ? "secondary" : "primary";
    }
  },
  methods: {
    filterResults() {
      this.$emit("onChange", this.mySearch);
      if (this.mySearch.length < 1) {
        this.dropdown = false;
      } else {
        this.dropdown = true;
        this.$emit("onSearch", this.mySearch);
      }
    },
    setMySearch(mySearch) {
      this.dropdown = false;
      this.$emit("onSelect", mySearch);
    }
  }
};
</script>

<style lang="css" scoped src="./AutocompleteDropdown.css"></style>
