<template>
  <div class="photos-documents">
    <div class="photos-documents--title">
      <Label color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.returnDetails.photosAndDocs.title")
      }}</Label>
    </div>
    <div class="photos-documents--subtitle">
      <Label color="tertiary" size="small">{{
        $t("pages.personalPage.returnDetails.photosAndDocs.subtitle")
      }}</Label>
    </div>
    <div class="photos-documents--content">
      <div class="photos-documents--content-left">
        <UploadFile
          pov="front"
          :disabled="disabled"
          @onUpload="onUpload('img', 'front', $event)"
          @onDownload="onDownload('front')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.front.title')
          "
          :subtitle="
            $t('pages.personalPage.returnDetails.photosAndDocs.front.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="leftSide"
          :disabled="disabled"
          @onUpload="onUpload('img', 'leftSide', $event)"
          @onDownload="onDownload('leftSide')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.leftSide.title')
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.leftSide.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="rightSide"
          :disabled="disabled"
          @onUpload="onUpload('img', 'rightSide', $event)"
          @onDownload="onDownload('rightSide')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.rightSide.title')
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.rightSide.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="rear"
          :disabled="disabled"
          @onUpload="onUpload('img', 'rear', $event)"
          @onDownload="onDownload('rear')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.rear.title')
          "
          :subtitle="
            $t('pages.personalPage.returnDetails.photosAndDocs.rear.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="rims"
          :disabled="disabled"
          @onUpload="onUpload('img', 'rims', $event)"
          @onDownload="onDownload('rims')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.rims.title')
          "
          :subtitle="
            $t('pages.personalPage.returnDetails.photosAndDocs.rims.subtitle')
          "
        ></UploadFile>
      </div>
      <div class="photos-documents--content-right">
        <UploadFile
          pov="panel"
          :disabled="disabled"
          @onUpload="onUpload('img', 'panel', $event)"
          @onDownload="onDownload('panel')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.panel.title')
          "
          :subtitle="
            $t('pages.personalPage.returnDetails.photosAndDocs.panel.subtitle')
          "
        ></UploadFile>
        <UploadFile
          pov="frontInterior"
          :disabled="disabled"
          @onUpload="onUpload('img', 'frontInterior', $event)"
          @onDownload="onDownload('frontInterior')"
          :title="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.frontInterior.title'
            )
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.frontInterior.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="rearSeat"
          :disabled="disabled"
          @onUpload="onUpload('img', 'rearSeat', $event)"
          @onDownload="onDownload('rearSeat')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.rearSeat.title')
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.rearSeat.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="suitcase"
          :disabled="disabled"
          @onUpload="onUpload('img', 'suitcase', $event)"
          @onDownload="onDownload('suitcase')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.suitcase.title')
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.suitcase.subtitle'
            )
          "
        ></UploadFile>
        <UploadFile
          pov="booklet"
          :disabled="disabled"
          @onUpload="onUpload('doc', 'booklet', $event)"
          @onDownload="onDownload('booklet')"
          :title="
            $t('pages.personalPage.returnDetails.photosAndDocs.booklet.title')
          "
          :subtitle="
            $t(
              'pages.personalPage.returnDetails.photosAndDocs.booklet.subtitle'
            )
          "
        ></UploadFile>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import { UploadFile } from "../../organisms";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

export default {
  name: "PhotosAndDocuments",
  components: {
    Label,
    UploadFile
  },
  data() {
    return {
      files: [],
      retakeStatusEnum: RETAKESTATUS
    };
  },
  methods: {
    onUpload(category, name, event) {
      const index = this.files.findIndex(x => x.name === name);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      this.files.push({
        name,
        file: event,
        category
      });
      this.$emit("onUpload", this.files);
    },
    onDownload(name) {
      var imageFile = this.getReturn.phaseTwoDTOV3.files.find(
        image => image.name === name
      );
      var el = document.createElement("a");
      el.href = "data:image/png;base64," + imageFile.file.content;
      el.target = "_blank";
      el.download = imageFile.file.name;
      el.click();
    }
  },
  computed: {
    ...mapGetters(["getReturn"]),
    disabled() {
      return (
        this.getReturn?.submitted &&
        this.getReturn?.status !== this.retakeStatusEnum.Q2_REFUSED
      );
    }
  }
};
</script>

<style lang="css" scoped src="./PhotosAndDocuments.css"></style>
