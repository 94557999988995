import { render, staticRenderFns } from "./VerificationAccount.vue?vue&type=template&id=75224452&scoped=true"
import script from "./VerificationAccount.vue?vue&type=script&lang=js"
export * from "./VerificationAccount.vue?vue&type=script&lang=js"
import style0 from "./VerificationAccount.css?vue&type=style&index=0&id=75224452&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75224452",
  null
  
)

export default component.exports