<template>
  <div>
    <Card customClass="card" rounded shadow="xl">
      <Label>Username</Label>
      <Input
        border
        type="primary"
        inputType="text"
        @onChange="val => (user = val)"
      />
      <Label>Password</Label>
      <Input border inputType="password" @onChange="val => (pass = val)" />
      <Button fill customClass="btn" label="Login" @onClick="submit" />
    </Card>
  </div>
</template>

<script>
import { Card, Input, Label, Button } from ".";

export default {
  name: "BaseAuth",
  components: {
    Card,
    Input,
    Label,
    Button
  },
  data() {
    return {
      user: "",
      pass: ""
    };
  },
  methods: {
    submit() {
      const user = process.env.VUE_APP_PROD_USER || "admin";
      const pass = process.env.VUE_APP_PROD_PASS || "admin";
      this.$emit("onValidate", this.user === user && this.pass === pass);
    }
  },
  mounted() {
    document.body.classList.add("no-scroll");
  }
};
</script>

<style scoped>
div {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  /* background-color: #002C5F; */
  display: grid;
  place-items: center;
}

.card {
  height: 400px;
  width: 600px;
}

:deep(.card--content) {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.btn {
  margin-top: 20px;
  height: 50px;
}
</style>
