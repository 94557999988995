<template>
  <Page>
    <template v-slot:content>
      <Section v-if="code" id="section-recover">
        <div v-if="!passwordUpdated" class="recover">
          <div class="recover--title">
            <Label color="primary" size="medium">{{
              $t("pages.recoverPage.title")
            }}</Label>
          </div>
          <div v-if="email" class="recover--email">
            <Label color="tertiary" size="medium"
              >{{ $t("pages.recoverPage.email") }}
            </Label>
            <Label color="primary" size="medium">{{ email }}</Label>
          </div>
          <div class="recover--input">
            <InputForm
              type="primary"
              :label="$t('placeholder.password') + ' *'"
              bgColor="#F5F7F9"
              @onFocus="validatePasswordHandler"
              icon="eye"
              @onIconClick="showPassword"
              :inputType="showNewPassword ? 'text' : 'password'"
              :inputValue="newPassword"
              @onChange="
                value => {
                  newPassword = value;
                }
              "
            />
            <div class="validate-error">
              <Label
                v-if="errorPassword"
                color="tertiary"
                bold="thin"
                size="small"
              >
                {{ errorPassword }}</Label
              >
            </div>
          </div>
          <div class="recover--button">
            <Button
              type="primary"
              fill
              uppercase
              :label="$t('pages.recoverPage.labelButtonUpdate')"
              :disabled="disabledHandler"
              @onClick="updatePassword"
            ></Button>
          </div>
        </div>
        <div v-else class="password-updated">
          <div class="password-updated--image">
            <img
              src="../../../assets/images/check-circle.svg"
              width="86px"
              height="86px"
            />
          </div>
          <div class="password-updated--title">
            <Label color="primary" size="medium">
              {{ $t("pages.recoverPage.titlePasswordChanged") }}
            </Label>
          </div>
          <div class="password-updated--button">
            <Button
              type="primary"
              fill
              uppercase
              :label="$t('pages.recoverPage.labelButtonSearch')"
              @onClick="searchVehicles"
            ></Button>
          </div>
        </div>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import { Section, Label, Button } from "../../atoms";
import { InputForm } from "../../molecules";
import v from "../../../utils/validator.js";
import { mapState } from "vuex";
import { PAGES } from "@/enums/pages";

export default {
  name: "RecoverPassword",
  components: {
    Page,
    Section,
    Label,
    InputForm,
    Button
  },
  data() {
    return {
      code: this.$route.query.code,
      email: this.$route.query.email,
      newPassword: "",
      errorPassword: null,
      passwordUpdated: false,
      showNewPassword: false
    };
  },
  methods: {
    validatePasswordHandler() {
      this.errorPassword = v("passOnly", { pass: this.newPassword }).pass;
    },
    searchVehicles() {
      this.$router.push({
        name: PAGES.SEARCH
      });
    },
    updatePassword() {
      const payload = {
        email: this.email,
        password: this.newPassword,
        code: this.code
      };
      this.$store
        .dispatch("recoverUpdatePassword", payload)
        .then(data => {
          if (data.data.data === "OK") {
            this.passwordUpdated = true;
          }
        })
        .catch(err => {
          this.passwordUpdated = false;
          console.log("err: ", err);
        });
      this.newPassword = "";
    },
    showPassword() {
      this.showNewPassword = !this.showNewPassword;
    }
  },
  computed: {
    ...mapState(["isOpenSignupPopup", "isOpenSigninPopup"]),
    disabledHandler() {
      if (this.newPassword) {
        this.validatePasswordHandler();
      }

      return !this.newPassword || !!this.errorPassword;
    }
  },
  mounted() {
    this.$store.state.isOpenSigninPopup = false;
    this.$store.state.isOpenSignupPopup = false;
  }
};
</script>

<style lang="css" scoped src="./RecoverPasswordPage.css"></style>
