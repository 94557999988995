<template>
  <div>
    <div id="jsv-holder">
      <img id="jsv-image" :alt="getAlt" :src="fileName" />
    </div>
  </div>
</template>

<script>
import { JavascriptViewer } from "@3dweb/360javascriptviewer";
export default {
  name: "Slide360Viewer",
  props: {
    altType: {
      type: String,
      default: ""
    },
    fileName: {
      type: String,
      default: ""
    },
    modelName: {
      type: String,
      default: ""
    },
    images: {
      type: Array
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewer: null
    };
  },
  methods: {},
  computed: {
    getAlt() {
      return this.altType === "view-interior"
        ? "interior-car-image-" + this.modelName
        : "exterior-car-image-" + this.modelName;
    }
  },
  mounted() {
    setTimeout(() => {
      this.viewer = new JavascriptViewer({
        mainHolderId: "jsv-holder",
        mainImageId: "jsv-image",
        speed: 100,
        reverse: true,
        defaultProgressBar: true,
        zoom: 1,
        license: process.env.VUE_APP_360JS_LICENSE || "",
        imageUrls: this.images
      });

      this.viewer.start().then(() => {
        this.viewer.rotateDegrees(180).then(() => {
          // continue with your amazing intro
        });
      });
    }, 1000);
  },
  beforeDestroy() {
    this.viewer.destroy();
    this.viewer = null;
  }
};
</script>

<style lang="css" scoped src="./Slide.css"></style>
