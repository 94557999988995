const PT_LOCALE = "pt-PT";

function getPriceFormatted(
  price,
  options = {},
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  const opts = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits,
    maximumFractionDigits,
    ...options
  };
  let formattedPrice = new Intl.NumberFormat(PT_LOCALE, opts).format(price);
  return formattedPrice.toString();
}

function formatNonCurrency(
  arg,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  return getPriceFormatted(
    arg,
    { style: "decimal" },
    minimumFractionDigits,
    maximumFractionDigits
  );
}

export { getPriceFormatted, formatNonCurrency };
