<template>
  <div class="wrapper flex">
    <span
      class="left-button"
      :class="{ selected: !isPriceSelected }"
      @click="toggle(true)"
      >{{ left }}</span
    >
    <span
      class="right-button"
      :class="{ selected: isPriceSelected }"
      @click="toggle(false)"
      >{{ right }}</span
    >
  </div>
</template>

<script>
export default {
  name: "StringToggler",
  data() {
    return {};
  },
  props: {
    left: {
      type: String,
      default: "left"
    },
    right: {
      type: String,
      default: "right"
    },
    isPriceSelected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle(e) {
      this.$emit("onSelected", e ? "left" : "right");
    }
  }
};
</script>

<style scoped>
.wrapper {
  background-color: #e4ecf0;
  border: 1px solid #abb1c5;
  border-radius: 20px;
  width: 324px;
  height: 40px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

span {
  width: 55%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #00aad2;
  box-sizing: border-box;
  line-height: 40px;
}

.selected {
  z-index: 10;
  color: black;
  background-color: white;
  border-radius: 20px;
}

span.left-button.selected {
  border-right: 1px solid #abb1c5;
}

span.right-button {
  right: 0;
}

span.right-button.selected {
  border-left: 1px solid #abb1c5;
}
</style>
