<template>
  <div class="book-vehicle-step-success--content">
    <div class="book-vehicle-step-success--content-icon">
      <svg
        id="check-circle"
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="104"
        viewBox="0 0 104 104"
      >
        <path
          id="Caminho_268"
          data-name="Caminho 268"
          d="M52,97.5A45.5,45.5,0,1,0,6.5,52,45.5,45.5,0,0,0,52,97.5Zm0,6.5A52,52,0,1,0,0,52,52,52,0,0,0,52,104Z"
          fill="#61b3d4"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_269"
          data-name="Caminho 269"
          d="M53.962,12.139a4.876,4.876,0,1,1,6.963,6.827L34.976,51.4a4.877,4.877,0,0,1-7.02.13l-17.195-17.2a4.876,4.876,0,1,1,6.89-6.89l13.612,13.6L53.838,12.283a1.536,1.536,0,0,1,.13-.142Z"
          transform="translate(17.344 20.165)"
          fill="#61b3d4"
          fill-rule="evenodd"
        />
      </svg>
    </div>

    <div class="title mb-2">
      <Label class="font-head" color="primary" size="medium">{{ title }}</Label>
    </div>

    <div v-if="description" class="subtitle mb-2">
      <TextLabel class="normal-weight" color="tertiary" :label="description" />
    </div>
    <div
      v-if="labelButton && !withFinancing && !withRetake"
      class="book-vehicle-step-success--content-reserves"
    >
      <Button
        :label="labelButton"
        uppercase
        :fill="true"
        type="primary"
        @onClick="clickToReserves"
      ></Button>
    </div>

    <div
      class="action-cards"
      v-if="(withFinancing || withRetake) && !contactType"
    >
      <div class="separator"></div>
      <div class="actions-title mb-8">
        <Label color="primary">
          {{ $t("missingActions.sectionTitle") }}
        </Label>
      </div>
      <div>
        <CardWithAction
          v-for="(item, index) in missingActions"
          :key="index"
          @onRedirect="item.redirectMethod"
          :backgroundColor="backgroundColor"
          :title="item.title"
          :description="item.description"
          :warningColor="item.warningColor"
          :warningLabel="item.warningLabel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { Label, Button } from "../../atoms";
import { CardWithAction } from "../../organisms";

import { PAGES } from "../../../enums/pages.js";
import { TextLabel } from "@sc/ds-ui-component-library";
import {
  pushNewEvent,
  GOOGLE_TAG_MANAGER_EVENT_TYPES
} from "../../../utils/GoogleTagManager";

export default {
  name: "ReserveComplete",
  components: { Label, Button, CardWithAction, TextLabel },
  methods: {
    clickToReserves() {
      this.$emit("onClickButton");
    },
    redirectQ2Form() {
      this.$router.push({ name: PAGES.RETAKES });
    },
    redirectPreApprovalForm() {
      this.$router.push({ name: PAGES.RESERVATIONS });
    }
  },
  data() {
    return {
      backgroundColor: "#f6f7f9",
      missingActionsContents: [
        {
          title: this.$t("missingActions.retake.title"),
          description: this.$t("missingActions.retake.description"),
          warningLabel: this.$t("missingActions.retake.warningLabel"),
          warningColor: this.$t("missingActions.retake.warningColor"),
          redirectMethod: this.redirectQ2Form,
          showCard: this.withRetake
        },
        {
          title: this.$t("missingActions.preApproval.title"),
          description: this.$t("missingActions.preApproval.description"),
          warningLabel: this.$t("missingActions.preApproval.warningLabel"),
          warningColor: this.$t("missingActions.preApproval.warningColor"),
          redirectMethod: this.redirectPreApprovalForm,
          showCard: this.withFinancing
        }
      ]
    };
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    labelButton: {
      type: String
    },
    withRetake: {
      type: Boolean,
      default: false
    },
    withFinancing: {
      type: Boolean,
      default: false
    },
    contactType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getSelectedVehicle"]),
    missingActions() {
      const missingActionsFiltered = this.missingActionsContents.filter(
        missingActionItem => missingActionItem.showCard
      );
      return missingActionsFiltered;
    }
  },
  mounted() {
    pushNewEvent(
      GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_REMOVE_FROM_CART,
      PAGES.DETAILS,
      this.getSelectedVehicle
    );
  }
};
</script>

<style scoped>
.separator {
  @apply mb-6;
  border-bottom: 1px solid #e4ecf0;
}
.actions-title :deep(.label) {
  @apply font-head;
  font-size: 21px;
  @apply font-normal;
  line-height: 28px;
}
.action-cards {
  text-align: start;
  @apply mt-16;
  @apply mx-4;
  @apply sm:mx-0;
  display: flex;
  flex-direction: column;
  @apply md:-ml-10;
  @apply md:-mr-10;
}
.book-vehicle-step-success--content {
  text-align: center;
  @apply md:mx-20;
}

.book-vehicle-step-success--content-icon {
  text-align: top;
}

.book-vehicle-step-success--content-icon svg {
  margin: auto;
  @apply mb-12;
}

.book-vehicle-step-success--content-reserves .button {
  width: 241px;
  height: 56px;
  margin: auto;
}

.title .label {
  font-size: 24px;
}

.subtitle .label {
  font-size: 18px;
}

.subtitle {
  --text-label-font-size: 18px;
  --text-label-font-wight: 400px;
  --text-label-line-height: 24px;
}

:deep(a) {
  color: #0053ff;
}

.normal-weight {
  font-weight: 400;
}
</style>
