<template>
  <div class="privacy-policy">
    <div :class="`privacy-policy--row ${!isPolicyValid ? 'denoted' : ''}`">
      <Checkbox
        @onChange="acceptedPrivacyPolicy"
        :type="typePrivacyPolicy"
        :fill="true"
      ></Checkbox>
      <Label class="privacy-policy--row-label" color="tertiary" size="small"
        >{{ $t("privacyPolicy.labels.1") }}
      </Label>
      <Label
        @click.native="clickPolicylinkHandler"
        class="privacy-policy--row-link"
        color="tertiary"
        label="Política de privacidade."
        size="small"
        bold="bold"
        >{{ $t("privacyPolicy.labels.2") }}</Label
      >
      <Label
        class="privacy-policy--row-label-symbol"
        color="tertiary"
        size="small"
        >*
      </Label>
    </div>
    <div class="flex" v-show="!isPolicyValid">
      <span class="message">
        {{ $t("labels.errors.required") }}
      </span>
    </div>
    <div class="privacy-policy--row mt-4">
      <Checkbox
        @onChange="acceptedEmailMarketing"
        :type="typeEmailMarketing"
        :fill="true"
      ></Checkbox>
      <Label class="privacy-policy--row-label" color="tertiary" size="small">
        {{ textMarketing }}
      </Label>
    </div>
    <div v-if="createAccount" class="privacy-policy--row mt-4">
      <Checkbox
        @onChange="acceptedCreateAccount"
        :type="typeCreateAccount"
        :fill="true"
      ></Checkbox>
      <div class="account sm:flex">
        <Label class="privacy-policy--row-label" color="tertiary" size="small"
          >{{ $t("privacyPolicy.checkBoxLabel") }}
        </Label>
        <Link
          class="privacy-policy--row-link"
          color="tertiary"
          :label="$t('privacyPolicy.checkBoxLink')"
          size="small"
          bold="bold"
          :href="createAccountLinkURL"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Label, Link } from "../../atoms";
export default {
  components: {
    Checkbox,
    Label,
    Link
  },
  data() {
    return {
      typePrivacyPolicy: "tertiary",
      typeEmailMarketing: "tertiary",
      typeCreateAccount: "tertiary",
      isCreateAccount: false
    };
  },
  props: {
    privacyPolicyLinkURL: {
      type: String,
      default: ""
    },
    createAccountLinkURL: {
      type: String,
      default: ""
    },
    textMarketing: {
      type: String,
      default: ""
    },
    createAccount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    acceptedPrivacyPolicy(event) {
      if (event === true) {
        this.typePrivacyPolicy = "secondary";
      } else {
        this.typePrivacyPolicy = "tertiary";
      }
      // if checked event is true
      // if not checkecd event is false
      this.$emit("acceptedPrivacyPolicy", event);
    },
    acceptedEmailMarketing(event) {
      if (event === true) {
        this.typeEmailMarketing = "secondary";
      } else {
        this.typeEmailMarketing = "tertiary";
      }
      // if checked event is true
      // if not checkecd event is false
      this.$emit("acceptedEmailMarketing", event);
    },
    acceptedCreateAccount(event) {
      if (event === true) {
        this.isCreateAccount = true;
        this.typeCreateAccount = "secondary";
      } else {
        this.isCreateAccount = false;
        this.typeCreateAccount = "tertiary";
      }
      // if checked event is true
      // if not checkecd event is false
      this.$emit("acceptedCreateAccount", event);
    },
    clickPolicylinkHandler() {
      this.$emit("onClickPolicyLink");
    }
  },
  computed: {
    isPolicyValid() {
      return this.typePrivacyPolicy != "tertiary";
    }
  }
};
</script>

<style lang="css" scoped src="./PrivacyPolicy.css"></style>
