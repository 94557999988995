var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photos-documents"},[_c('div',{staticClass:"photos-documents--title"},[_c('Label',{attrs:{"color":"primary","size":"medium","uppercase":""}},[_vm._v(_vm._s(_vm.$t("pages.personalPage.returnDetails.photosAndDocs.title")))])],1),_c('div',{staticClass:"photos-documents--subtitle"},[_c('Label',{attrs:{"color":"tertiary","size":"small"}},[_vm._v(_vm._s(_vm.$t("pages.personalPage.returnDetails.photosAndDocs.subtitle")))])],1),_c('div',{staticClass:"photos-documents--content"},[_c('div',{staticClass:"photos-documents--content-left"},[_c('UploadFile',{attrs:{"pov":"front","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.front.title'),"subtitle":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.front.subtitle')},on:{"onUpload":function($event){return _vm.onUpload('img', 'front', $event)},"onDownload":function($event){return _vm.onDownload('front')}}}),_c('UploadFile',{attrs:{"pov":"leftSide","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.leftSide.title'),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.leftSide.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('img', 'leftSide', $event)},"onDownload":function($event){return _vm.onDownload('leftSide')}}}),_c('UploadFile',{attrs:{"pov":"rightSide","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rightSide.title'),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.rightSide.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('img', 'rightSide', $event)},"onDownload":function($event){return _vm.onDownload('rightSide')}}}),_c('UploadFile',{attrs:{"pov":"rear","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rear.title'),"subtitle":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rear.subtitle')},on:{"onUpload":function($event){return _vm.onUpload('img', 'rear', $event)},"onDownload":function($event){return _vm.onDownload('rear')}}}),_c('UploadFile',{attrs:{"pov":"rims","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rims.title'),"subtitle":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rims.subtitle')},on:{"onUpload":function($event){return _vm.onUpload('img', 'rims', $event)},"onDownload":function($event){return _vm.onDownload('rims')}}})],1),_c('div',{staticClass:"photos-documents--content-right"},[_c('UploadFile',{attrs:{"pov":"panel","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.panel.title'),"subtitle":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.panel.subtitle')},on:{"onUpload":function($event){return _vm.onUpload('img', 'panel', $event)},"onDownload":function($event){return _vm.onDownload('panel')}}}),_c('UploadFile',{attrs:{"pov":"frontInterior","disabled":_vm.disabled,"title":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.frontInterior.title'
          ),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.frontInterior.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('img', 'frontInterior', $event)},"onDownload":function($event){return _vm.onDownload('frontInterior')}}}),_c('UploadFile',{attrs:{"pov":"rearSeat","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.rearSeat.title'),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.rearSeat.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('img', 'rearSeat', $event)},"onDownload":function($event){return _vm.onDownload('rearSeat')}}}),_c('UploadFile',{attrs:{"pov":"suitcase","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.suitcase.title'),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.suitcase.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('img', 'suitcase', $event)},"onDownload":function($event){return _vm.onDownload('suitcase')}}}),_c('UploadFile',{attrs:{"pov":"booklet","disabled":_vm.disabled,"title":_vm.$t('pages.personalPage.returnDetails.photosAndDocs.booklet.title'),"subtitle":_vm.$t(
            'pages.personalPage.returnDetails.photosAndDocs.booklet.subtitle'
          )},on:{"onUpload":function($event){return _vm.onUpload('doc', 'booklet', $event)},"onDownload":function($event){return _vm.onDownload('booklet')}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }