const name = n => (!n ? "O Nome deve ser preenchido" : "");

const privacy = p =>
  !p ? "As Políticas de Privacidade precisam de ser aceites" : "";

const email = e => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(e) ? "E-mail inválido" : "";
};

const phone = p => {
  const re = /^(\+?351|(00)?351)?\s?9[2,6,3,1]\d\s?\d{3}\s?\d{3}$/;
  return !re.test(p) ? "Número de Telemóvel inválido" : "";
};

const pass = p => {
  const re = /^(?=(.*[a-z].*){1,})(?=(.*[A-Z].*){1,})(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,14}$/;
  return !re.test(p)
    ? "A Palavra-passe deve conter entre 8 - 14 carateres e pelo menos: Uma letra maiúscula, uma letra minúscula, um número e um carater especial."
    : "";
};

const password = pass;

const birthdate = b => {
  const re = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$|^[0-9]{4}-[0-9]{2}-[0-9]{2}[T][0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}[Z]$/;
  return !b
    ? "A Data de Nascimento precisa de ser indicada"
    : !re.test(b)
    ? "A Data de Nascimento precisa de ser indicada corretamente"
    : "";
};

const nif = n => {
  const re = /^\d{9}$/;
  return !re.test(n) ? "NIF inválido" : "";
};

const address = a => (!a ? "A Morada deve ser preenchida" : "");

const zipCode = z => {
  const re = /^\d{4}-\d{3}$/;
  return !re.test(z) ? "Código Postal inválido" : "";
};

const location = l => (!l ? "A Localidade deve ser preenchida" : "");

const privacyPolicy = p =>
  !p ? "Por favor confirme a Política de Privacidade" : "";

const plate = p => {
  var re = /(([A-Za-z]{2}\d{2}(\d{2}|[A-Za-z]{2}))|(\d{2}(\d{2}[A-Za-z]{2}|[A-Za-z]{2}\d{2})))/gm;
  return !re.test(p) ? "A Matrícula deve ser preenchida (ex. 00AA00)" : "";
};

const brand = b => (!b ? "A Marca deve ser selecionada" : "");

const plateDate = pd => {
  const re = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$|^[0-9]{4}-[0-9]{2}-[0-9]{2}[T][0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}[Z]$/;
  return !pd
    ? "A Data de Registo deve ser preenchida"
    : !re.test(pd)
    ? "A Data de Registo precisa de ser indicada corretamente"
    : "";
};

const model = m => (!m ? "O Modelo deve ser selecionado" : "");

const kilometers = k =>
  isNaN(k) || !k || k < 100
    ? "O Número de Quilómetros deve ser igual ou superior a 100"
    : "";

const version = v =>
  !v || v === "Versões" ? "A Versão deve ser selecionada" : "";

const images = arr => {
  return !arr.every(Boolean) || arr.length !== 10
    ? "Precisa de submeter uma Imagem para cada secção"
    : "";
};
// TODO: Store number of files needed to submit in variable

const financing = arr =>
  !arr.some(it => it.checked)
    ? "Precisa de selecionar um Tipo de Financiamento"
    : "";

const state = arr =>
  arr.every(it => it.radioGroupList.some(elem => !!elem.checked))
    ? ""
    : "Precisa de indicar o Estado de cada parte do veículo";

const externalColor = c => (!c ? "Precisa de indicar a Cor Interior" : "");

const cabinTrimInlay = i => (!i ? "Precisa de indicar o interior" : "");

const district = d => (!d ? "Precisa de escolher um Distrito" : "");
const dealer = d => (!d ? "Precisa de escolher um Concessionário" : "");

const maritalStatus = m => {
  return (!m && m !== 0) || isNaN(m) || m === -1
    ? "O Estado Civil deve ser preenchido"
    : "";
};

const contractType = c =>
  (!c && c !== 0) || isNaN(c) || c === -1
    ? "O Estado Civil deve ser preenchido"
    : "";

const housingType = h =>
  (!h && h !== 0) || isNaN(h) || h === -1
    ? "O tipo de Habitação deve ser preenchido"
    : "";

const constraintObj = {
  emailOnly: {
    email
  },
  passOnly: {
    password,
    pass
  },
  userRegister: {
    name,
    email,
    birthdate,
    phone,
    privacy
  },
  user: {
    email,
    pass,
    birthdate,
    name,
    phone
  },
  leadRegister: {
    email,
    pass,
    name,
    phone
  },
  userLogged: {
    email,
    birthdate,
    name,
    phone
  },
  preApproval: {
    maritalStatus,
    contractType,
    housingType
  },
  receipt: {
    nif,
    address,
    zipCode,
    location,
    dealer,
    district
  },
  testDrive: {
    email,
    name,
    phone,
    privacyPolicy
  },
  retakePhase1: {
    plate,
    brand,
    plateDate,
    model,
    kilometers,
    version
  },
  retakeRegisterForm: {
    email,
    password,
    phone
  },
  retakeLoggedIn: {
    email,
    phone
  },
  returnPhase2: {
    financing,
    state,
    externalColor,
    cabinTrimInlay,
    images,
    kilometers
  }
};

/**
 * Given a key to an object with contraint functions and the data to validate
 * return an obj of errors for each field
 */
const validate = (contraints, data) =>
  Object.fromEntries(
    Object.entries(constraintObj[contraints]).map(([key, f]) => [
      key,
      f(data[key])
    ])
  );

export default validate;
