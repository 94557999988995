<template>
  <a :class="[customClass, 'separator']" :style="customStyles">
    <Card customClass="separator--card">
      <Block>
        <img class="separator--card-icon" v-if="imgURL" :src="imgURL" />
        <p v-else>Image</p>
        <Label
          align="center"
          size="medium"
          bold="semibold"
          :customClass="primary ? `title` : `title--white`"
          v-html="title"
          >{{ title }}</Label
        >
        <Label align="center" size="small" customClass="description">
          {{ description }}
        </Label>
        <Button
          v-if="buttonLabel"
          @onClick="clickHandler"
          :label="buttonLabel"
          fill
          uppercase
          :style="{ height: '60px', borderColor: '#fff', borderWidth: '1px' }"
          :filename="buttonIcon ? buttonIcon : ''"
          iconPosition="right"
        />
      </Block>
    </Card>
  </a>
</template>

<script>
import { Card, Block, Button, Label } from "../../atoms";
import { RESERVE_STATUS } from "../../../enums/reserveStatus";

export default {
  name: "CardSeparator",
  components: {
    Card,
    Block,
    Button,
    Label
  },
  props: {
    imgURL: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Title"
    },
    description: {
      type: String,
      default: "Description"
    },
    buttonLabel: {
      type: String,
      default: ""
    },
    buttonAction: {
      type: String,
      default: ""
    },
    buttonIcon: {
      type: String,
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler() {
      if (this.buttonAction == "contactPanel") {
        this.$store.commit("setReserveBack", false);
        this.$store.commit("setReserveVehicle", false);
        this.$store.commit("setReserveContact", {});
        this.$store.commit("setReserveStatus", RESERVE_STATUS.CONTACT);
        this.$store.commit("setReservePanelKey");

        this.$store.state.isOpenReservePanel = true;
      } else {
        this.$router.push({
          name: this.buttonAction
        });
      }
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>

<style lang="css" scoped src="./CardSeparator.css"></style>
