<template>
  <div class="basic-information">
    <div class="basic-information--title">
      <Label color="primary" size="medium" uppercase>Informações Básicas</Label>
    </div>
    <div class="basic-information--subtitle">
      <Label color="tertiary" size="small"
        >Para poupar o seu tempo, preenchemos algumas informações que são mais
        comuns na sua viatura. Por favor confirme que a informação
        pré-preenchida sobre a sua viatura está correta.</Label
      >
    </div>
    <div class="basic-information--content">
      <div class="basic-information--content-left">
        <Label size="small" color="primary"
          ><span>Matrícula:</span> {{ vehicle.registration }}</Label
        >
        <Label size="small" color="primary"
          ><span>Marca:</span> {{ vehicle.brand }}</Label
        >
        <Label size="small" color="primary"
          ><span>Modelo:</span> {{ vehicle.model }}</Label
        >
      </div>
      <div class="basic-information--content-right">
        <Label size="small" color="primary"
          ><span>Data da Matrícula:</span> {{ vehicle.registrationDate }}</Label
        >
        <Label size="small" color="primary"
          ><span>Versão:</span> {{ vehicle.version }}</Label
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  name: "BasicInformation",
  components: {
    Label
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="css" scoped src="./BasicInformation.css"></style>
