// /import i18n from "../i18n";

const PAGES = {
  HOME: "Home",
  SEARCH: "SearchPage",
  SEARCH_USED: "SearchPageUsedCars",
  DETAILS: "DetailPage",
  COMPARATOR: "Comparator",
  PERSONAL: "Personal",
  PERSONAL_DATA: "personal-data",
  IMPORT: "Import VIN",
  ABOUT: "About",
  RETAKE: "Retake Evaluation",
  HOW_IT_WORKS: "HowItWorksPage",
  COOKIES: "cookies-terms",
  TERMS_CONDITIONS: "terms-conditions",
  POLICY: "Policy",
  RESERVATIONS: "reservations",
  RESERVATION_DETAIL: "ReservationDetails",
  PRE_APPROVAL: "ReservationPreApproval",
  SAVED_SEARCH: "saved-searches",
  RETAKES: "returns",
  RETAKE_DETAIL: "returnsDetails",
  SEARCH_PAGE_CUSTOM_ROUTE: "SearchPageCustomRoute",
  SEARCH_PAGE_USED_CUSTOM_ROUTE: "SearchPageUsedCarsCustomRoute",
  ERROR: "error"
};

const META = {
  [PAGES.HOME]: {
    title: "Comprar automóvel online | Hyundai Click to Buy",
    description:
      "Na Hyundai Click to Buy compra o seu automóvel online, com um serviço integrado de avaliação de retoma e financiamento. Saiba tudo aqui.",
    keywords: "automóvel online"
  },
  [PAGES.SEARCH]: {
    title: "Pesquisar e comprar carro | Hyundai Click to Buy",
    description:
      "Está a pensar comprar carro? Descubra as viaturas novas da Hyundai Clik to Buy. Encontre online o Hyundai perfeito para si. Saiba como aqui.",
    keywords: "comprar carro"
  },
  [PAGES.DETAILS]: {
    title: "Detalhe da Viatura | Hyundai Click to Buy",
    description:
      "Na Click to Buy Hyundai compra o seu carro de sonho de forma 100% digital. Carros novos sem preocupações e todo o apoio que precisa. Descubra tudo aqui."
  },
  [PAGES.COMPARATOR]: {
    title: "Comparador | Hyundai Click to Buy",
    description:
      "Na Click to Buy Hyundai compra o seu carro de sonho de forma 100% digital. Carros novos sem preocupações e todo o apoio que precisa. Descubra tudo aqui."
  },
  [PAGES.PERSONAL]: {
    title: "Área Pessoal | Hyundai Click to Buy",
    description:
      "Na Click to Buy Hyundai compra o seu carro de sonho de forma 100% digital. Carros novos sem preocupações e todo o apoio que precisa. Descubra tudo aqui."
  },
  [PAGES.SAVED_SEARCH]: {
    title: "Pesquisas Guardadas | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.RETAKES]: {
    title: "Minhas Retomas | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.RETAKE_DETAIL]: {
    title: "Minhas Retomas | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.RESERVATIONS]: {
    title: "Minhas Reservas | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.RESERVATION_DETAIL]: {
    title: "Minhas Reservas | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.PERSONAL_DATA]: {
    title: "Dados Pessoais | Hyundai Click to Buy",
    description: ""
  },
  [PAGES.ABOUT]: {
    title: "Sobre | Hyundai Click to Buy",
    description:
      "Na Click to Buy Hyundai compra o seu carro de sonho de forma 100% digital. Carros novos sem preocupações e todo o apoio que precisa. Descubra tudo aqui."
  },
  [PAGES.RETAKE]: {
    title: "Retoma de carros | Hyundai Click to Buy",
    description:
      "Tem um carro para retoma? Faça uma avaliação e obtenha uma estimativa do valor de retoma. Use o valor na compra do seu novo Hyundai. Saiba como aqui.",
    keywords: "Retoma, avaliar carros"
  },
  [PAGES.HOW_IT_WORKS]: {
    title: "Como funciona? | Hyundai Click to Buy",
    description:
      "Pesquise e compre o seu Hyundai online. Usufrua de soluções de financiamento à medida e avaliação de retoma completa. Saiba mais na Hyundai Click to Buy"
  },
  [PAGES.COOKIES]: {
    title: "Política de Cookies | Hyundai Click to Buy",
    description:
      "Na Click to Buy Hyundai compra o seu carro de sonho de forma 100% digital. Carros novos sem preocupações e todo o apoio que precisa. Descubra tudo aqui."
  },
  [PAGES.TERMS_CONDITIONS]: {
    title: "Termos e Condições | Hyundai Click to Buy",
    description:
      "Conheça os termos e condições da Hyundai Click to Buy e esclareça todas as suas dúvidas sobre a nova experiência de compra online. Saiba mais."
  },
  [PAGES.POLICY]: {
    title: "Política de Privacidade | Hyundai Click to Buy",
    description:
      "*Conheça a política de privacidade da Hyundai Click to Buy. Descubra uma experiência de compra de carros cómoda, simples e transparente. Saiba mais."
  },
  [PAGES.SEARCH_PAGE_CUSTOM_ROUTE]: {
    title: "Pesquisar e comprar carro | Hyundai Click to Buy",
    description:
      "Está a pensar comprar carro? Descubra as viaturas novas da Hyundai Clik to Buy. Encontre online o Hyundai perfeito para si. Saiba como aqui.",
    keywords: "comprar carro"
  },
  [PAGES.SEARCH_PAGE_USED_CUSTOM_ROUTE]: {
    title: "Pesquisar e comprar carro | Hyundai Click to Buy",
    description:
      "Está a pensar comprar carro? Descubra as viaturas novas da Hyundai Clik to Buy. Encontre online o Hyundai perfeito para si. Saiba como aqui.",
    keywords: "comprar carro"
  },
  [PAGES.ERROR]: {
    title: "Oops! | Hyundai Click to Buy",
    description: "Página de erro"
  },
  [PAGES.SEARCH_USED]: {
    title: "Pesquisar e comprar carro | Hyundai Click to Buy",
    description:
      "Está a pensar comprar carro? Descubra as viaturas usadas da Hyundai Clik to Buy. Encontre online o Hyundai perfeito para si. Saiba como aqui.",
    keywords: "comprar carro, usado"
  }
};

export { PAGES, META };
