<template>
  <div class="session-dropdown">
    <Dropdown
      v-if="id"
      :title="title"
      :id="id"
      @close="closeModal"
      ref="closeDropdown"
    >
      <ul>
        <li
          class="item"
          v-for="(menu, index) in menuList"
          :key="index"
          @click="clickHandler(menu)"
        >
          <Label
            class="cursor-pointer"
            size="small"
            color="primary"
            bold="normal"
            >{{ menu.label }}</Label
          >
        </li>
      </ul>
    </Dropdown>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { Dropdown } from "../../molecules";

import { PAGES } from "../../../enums/pages";

export default {
  name: "SessionDropdown",
  components: {
    Dropdown,
    Label
  },
  data() {
    return {
      saveSearchModalOpened: false,
      opened: true,
      id: ""
    };
  },
  props: {
    title: {
      type: String,
      default: "Login"
    },
    menuList: {
      type: Array[Object],
      default: () => []
    }
  },
  methods: {
    clickHandler(menu) {
      this.$refs.closeDropdown.close();

      if (menu.url === "/pessoal/logout") {
        this.$emit("onLogout");
        if (this.$route.name !== PAGES.HOME)
          this.$router.push({
            name: PAGES.HOME
          });
        this.sessionStorage?.removeItem("retake");
      } else {
        this.$router.push(menu.url);
        this.$emit("onClick", menu);
      }

      this.toggleMenu();
      this.closeModal();
    },
    closeSaveSearchModal() {
      this.saveSearchModalOpened = false;
    },
    logout() {
      this.saveSearchModalOpened = false;
    },
    closeModal() {
      this.opened = false;
      this.$emit("close");
    },
    toggleMenu() {
      let menu = document.querySelector(".mobile-menu");

      if (menu.classList.contains("active")) {
        menu.classList.remove("active");
        document.body.classList.remove("no-scroll");
      }

      this.$store.state.isOpenNavbar = menu.classList.contains("active");
      this.closeModal();
    }
  },
  created() {
    this.id = Math.random()
      .toString(36)
      .substring(6);
  }
};
</script>

<style lang="css" scoped src="./SessionDropdown.css"></style>
