<template>
  <svg
    class="icon--margin"
    id="info-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path id="Path_26" data-name="Path 26" d="M0,0H16V16H0Z" fill="none" />
    <path
      class="fill"
      id="Path_27"
      data-name="Path 27"
      d="M7.833,5.241h1.3v1.3h-1.3Zm0,2.593h1.3v3.889h-1.3ZM8.482,2a6.482,6.482,0,1,0,6.482,6.482A6.484,6.484,0,0,0,8.482,2Zm0,11.667a5.185,5.185,0,1,1,5.185-5.185A5.192,5.192,0,0,1,8.482,13.667Z"
      transform="translate(-0.482 -0.482)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "AlertSVG",
  props: {
    color: {
      type: String,
      default: "#ffffff"
    }
  }
};
</script>

<style lang="css" scoped src="./InfoIconSVG.css"></style>
