var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[(_vm.exterior)?_c('div',{staticClass:"background-layer"}):_vm._e(),_c('div',{staticClass:"prespective-selector"},[(_vm.isMobile)?_c('div',{staticClass:"open-gallery-side",on:{"click":_vm.togglePOV}},[(!_vm.exterior)?_c('div',{staticClass:"open-gallery"},[_c('span',[_vm._v("Exterior")])]):_c('div',{staticClass:"open-gallery"},[_c('span',[_vm._v("Interior")])])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"open-gallery-side",on:{"click":_vm.openGallery}},[_vm._m(0)]):_vm._e()]),(!_vm.exterior)?[(_vm.panoramicImage)?_c('VuePannellum',{attrs:{"src":_vm.vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url}}):_c('Slide360Viewer',{key:"view-interior",attrs:{"altType":"view-interior","modelName":_vm.vehicle.model,"isMobile":_vm.isMobile,"path":_vm.getFilePath(
          _vm.vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url
        ),"fileName":_vm.vehicle.carDetailImage.rotationImages.rotatingInternalImages[0].url,"total":_vm.vehicle.carDetailImage.rotationImages.rotatingInternalImages.length -
          1}})]:_vm._e(),(_vm.exterior)?[_c('Slide360Viewer',{key:"view-exterior",attrs:{"altType":"view-exterior","modelName":_vm.vehicle.model,"isMobile":_vm.isMobile,"fileName":_vm.vehicle.carDetailImage.rotationImages.rotatingExternalImages[0].url,"images":_vm.vehicle.carDetailImage.rotationImages.rotatingExternalImages.map(
          image => image.url
        )}})]:_vm._e(),(!_vm.isMobile)?_c('NormalSlide',{attrs:{"vehicleModel":_vm.vehicle.model,"images":_vm.vehicle.carDetailImage.staticImages.externalImages
        .map(img => img.url)
        .concat(
          _vm.vehicle.carDetailImage.staticImages.internalImages.map(
            img => img.url
          )
        )}}):_vm._e(),(_vm.isMobile)?_c('CoolLightBox',{attrs:{"items":_vm.vehicle.carDetailImage.staticImages.externalImages
        .map(img => img.url)
        .concat(
          _vm.vehicle.carDetailImage.staticImages.internalImages.map(
            img => img.url
          )
        ),"index":_vm.selectedImage,"effect":'fade'},on:{"close":function($event){_vm.selectedImage = null}}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"controls"},[_c('div',{staticClass:"zoom-control"},[_c('div',{staticClass:"zoom-in",on:{"click":function($event){return _vm.$emit('zoomIn')}}}),_c('div',{staticClass:"zoom-out",on:{"click":function($event){return _vm.$emit('zoomOut')}}})]),_c('div',{staticClass:"perspective-switch",on:{"click":function($event){_vm.exterior = !_vm.exterior;
        _vm.$emit('switchPerspective');}}},[_vm._v(" "+_vm._s(_vm.exterior ? "VER INTERIOR" : "VER EXTERIOR")+" ")])]):_vm._e(),(!_vm.isMobile)?_c('Label',{staticClass:"label-info",attrs:{"color":"tertiary","size":"small"}},[_vm._v(_vm._s(_vm.$t("pages.detailsPage.imageLabel")))]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"open-gallery"},[_c('span',[_vm._v("Galeria")])])
}]

export { render, staticRenderFns }