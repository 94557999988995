<template>
  <div :class="isDisabled">
    <div
      :id="id"
      :style="customStyles"
      :class="['select', customClass, classes]"
    >
      <div class="selectWrapper">
        <select class="selectNative js-selectNative" aria-labelledby="jobLabel">
          <option
            v-for="(option, index) in preparedOptions"
            :key="index"
            :value="option.value"
            :disabled="option.selected"
            :selected="option.selected"
            v-html="option.text"
          ></option>
          <div class="select--icon-wrapper">
            <Icon
              v-if="
                clearable &&
                  optionChecked &&
                  optionChecked !== preparedOptions[0].value
              "
              class="select--clear-icon cursor-pointer"
              color="secondary"
              name="times"
              size="x-small"
              @click.native="clearSelect"
            />
            <Icon
              class="select--dropdown-icon ml-1 cursor-pointer"
              color="primary"
              :name="dropdownIcon"
              size="x-small"
              @click.native="onClickSelectHandler"
            />
          </div>
        </select>

        <!-- Hide the custom select from AT (e.g. SR) using aria-hidden -->
        <div class="selectCustom js-selectCustom" aria-hidden="true">
          <div class="selectCustom-trigger">
            <div
              class="
                trigger-text
                overflow-ellipsis overflow-hidden
                whitespace-nowrap
                mr-5
              "
            >
              {{ getDefaultSelected }}
            </div>
            <div class="select--icon-wrapper">
              <Icon
                v-if="
                  clearable &&
                    optionChecked &&
                    optionChecked !== preparedOptions[0].value
                "
                class="select--clear-icon select--clear-icon cursor-pointer"
                :color="type"
                name="times"
                size="x-small"
                @click.native="clearSelect"
              />
              <Icon
                class="select--dropdown-icon ml-1 cursor-pointer"
                :color="type"
                :name="dropdownIcon"
                size="x-small"
              />
            </div>
            <!--<div class="select--icon-wrapper">
              <Icon
                v-if="
                  clearable &&
                    optionChecked &&
                    optionChecked !== options[0].value
                "
                class="select--clear-icon cursor-pointer"
                color="primary"
                name="times"
                size="x-small"
                @click.native="
                  updateCustomSelectChecked(options[0].value, options[0].text)
                "
              />
              <Icon
                class="select--dropdown-icon ml-1 cursor-pointer"
                color="primary"
                :name="dropdownIcon"
                size="x-small"
              />
            </div>-->
          </div>

          <div
            class="selectCustom-options"
            :style="{ 'background-color': optionsBgColor }"
          >
            <div
              v-for="(option, index) in preparedOptions"
              :key="index"
              class="selectCustom-option"
              :data-value="option.value"
              v-html="option.text"
              v-show="!option.disabled"
            ></div>
          </div>
        </div>
      </div>
      <span v-if="showIndicator" class="indicator">Campo Obrigatório</span>
    </div>
  </div>
</template>

<script>
import { Icon } from "../../atoms";
export default {
  components: {
    Icon
  },
  props: {
    indicator: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary",
      validator: type => {
        return ["primary", "secondary"].includes(type);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    optionsBgColor: {
      type: String,
      default: "white"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showIndicator: false,
      elSelectNative: null,
      elSelectCustom: null,
      elSelectCustomBox: null,
      elSelectCustomOpts: null,
      customOptsList: null,
      optionsCount: null,
      defaultLabel: null,
      optionChecked: null,
      optionHoveredIndex: null,
      isClosed: false
    };
  },
  computed: {
    isDisabled() {
      return this.disabled ? "disabled--props" : "";
    },
    preparedOptions() {
      this.attachEvents();
      return this.options;
    },
    classes() {
      return {
        "select--no-border": !this.border,
        "select--primary": this.type === "primary",
        "select--secondary": this.type === "secondary"
      };
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    dropdownIcon() {
      return this.isClosed ? "chevron-up" : "chevron-down";
    },
    getDefaultSelected() {
      if (this.value && this.value !== "") {
        const defaultSelected = this.preparedOptions.find(
          opt => opt.value === this.value
        );
        if (defaultSelected) {
          return defaultSelected.text;
        }
      }
      const defaultSelected = this.preparedOptions.find(
        opt => opt.selected === true
      );
      if (defaultSelected) {
        return defaultSelected.text;
      }
      return this.preparedOptions[0].text;
    }
  },
  methods: {
    handleInput(e) {
      this.showIndicator = !e.target.value;
      return !!e.target.value;
    },
    onClickSelectHandler(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.isClosed = !this.elSelectCustom.classList.contains("isActive");

      if (this.isClosed) {
        this.openSelectCustom();
      } else {
        this.closeSelectCustom();
      }
    },
    openSelectCustom() {
      this.elSelectCustom.classList.add("isActive");
      // Remove aria-hidden in case this was opened by a user
      // who uses AT (e.g. Screen Reader) and a mouse at the same time.
      this.elSelectCustom.setAttribute("aria-hidden", false);

      if (this.optionChecked) {
        const optionCheckedIndex = this.customOptsList.findIndex(
          el => el.getAttribute("data-value") === this.optionChecked
        );
        this.updateCustomSelectHovered(optionCheckedIndex);
      }

      // Add related event listeners
      document.addEventListener("click", this.watchClickOutside, true);
      document.addEventListener("keydown", this.supportKeyboardNavigation);
      this.isClosed = true;
    },
    closeSelectCustom() {
      this.elSelectCustom.classList.remove("isActive");

      this.elSelectCustom.setAttribute("aria-hidden", true);

      this.updateCustomSelectHovered(-1);

      // Remove related event listeners
      document.removeEventListener("click", this.watchClickOutside, true);
      document.removeEventListener("keydown", this.supportKeyboardNavigation);
      this.isClosed = false;
    },
    updateCustomSelectHovered(newIndex) {
      const prevOption = this.elSelectCustomOpts.children[
        this.optionHoveredIndex
      ];
      const option = this.elSelectCustomOpts.children[newIndex];

      if (prevOption) {
        prevOption.classList.remove("isHover");
      }
      if (option) {
        option.classList.add("isHover");
      }

      this.optionHoveredIndex = newIndex;
    },
    updateCustomSelectChecked(value, text) {
      const prevValue = this.optionChecked;

      const elPrevOption = this.elSelectCustomOpts.querySelector(
        `[data-value="${prevValue}"`
      );
      const elOption = this.elSelectCustomOpts.querySelector(
        `[data-value="${value}"`
      );

      if (elPrevOption) {
        elPrevOption.classList.remove("isActive");
      }

      if (elOption) {
        elOption.classList.add("isActive");
      }

      this.elSelectCustomBox.children[0].textContent = text;
      this.optionChecked = value;

      this.$emit("onChange", this.optionChecked);
      this.isClosed = false;
    },
    clearSelect() {
      this.elSelectCustomBox.children[0].textContent = this.preparedOptions[0].text;
      this.optionChecked = this.preparedOptions[0].value;
      this.elSelectNative.value = this.preparedOptions[0].value;
      this.$emit("onClear");
    },
    watchClickOutside() {
      const didClickedOutside = !this.elSelectCustom.contains(event.target);
      if (didClickedOutside) {
        this.closeSelectCustom();
      }
    },
    supportKeyboardNavigation(e) {
      // press down -> go next
      if (
        event.keyCode === 40 &&
        this.optionHoveredIndex < this.optionsCount - 1
      ) {
        let index = this.optionHoveredIndex;
        e.preventDefault(); // prevent page scrolling
        this.updateCustomSelectHovered(index + 1);
      }

      // press up -> go previous
      if (event.keyCode === 38 && this.optionHoveredIndex > 0) {
        e.preventDefault(); // prevent page scrolling
        this.updateCustomSelectHovered(this.optionHoveredIndex - 1);
      }

      // press Enter or space -> select the option
      if (event.keyCode === 13 || event.keyCode === 32) {
        e.preventDefault();

        const option = this.elSelectCustomOpts.children[
          this.optionHoveredIndex
        ];
        const value = option && option.getAttribute("data-value");

        if (value) {
          this.elSelectNative.value = value;
          this.updateCustomSelectChecked(value, option.textContent);
        }
        this.closeSelectCustom();
      }

      // press ESC -> close selectCustom
      if (event.keyCode === 27) {
        this.closeSelectCustom();
      }
    },
    attachEvents() {
      /* Features needed to make the selectCustom work for mouse users.

    - Toggle custom select visibility when clicking the "box"
    - Update custom select value when clicking in a option
    - Navigate through options when using keyboard up/down
    - Pressing Enter or Space selects the current hovered option
    - Close the select when clicking outside of it
    - Sync both selects values when selecting a option. (native or custom)

    */
      setTimeout(() => {
        this.elSelectNative = document.querySelector(
          `#${this.id} .js-selectNative`
        );
        this.elSelectCustom = document.querySelector(
          `#${this.id} .js-selectCustom`
        );

        this.elSelectCustomBox = this.elSelectCustom.children[0];
        this.elSelectCustomOpts = this.elSelectCustom.children[1];
        this.customOptsList = Array.from(this.elSelectCustomOpts.children);
        this.optionsCount = this.customOptsList.length;
        this.defaultLabel = this.elSelectCustomBox.getAttribute("data-value");

        this.optionChecked = "";
        this.optionHoveredIndex = -1;

        // const selectedByDefault = this.options.find(opt => opt.selected === true);
        // if (selectedByDefault)
        //   this.updateCustomSelectChecked(
        //     selectedByDefault.value,
        //     selectedByDefault.text
        //   );

        // Toggle custom select visibility when clicking the box
        this.elSelectCustomBox.addEventListener(
          "click",
          this.onClickSelectHandler
        );

        // Update selectCustom value when selectNative is changed.
        this.elSelectNative.addEventListener("change", e => {
          const value = e.target.value;
          const elRespectiveCustomOption = this.elSelectCustomOpts.querySelectorAll(
            `[data-value="${value}"]`
          )[0];

          this.updateCustomSelectChecked(
            value,
            elRespectiveCustomOption.textContent
          );
        });

        // Update selectCustom value when an option is clicked or hovered
        this.customOptsList.forEach((elOption, index) => {
          elOption.addEventListener("click", e => {
            const value = e.target.getAttribute("data-value");
            // Sync native select to have the same value
            this.elSelectNative.value = value;
            this.updateCustomSelectChecked(value, e.target.textContent);
            this.closeSelectCustom();
          });

          elOption.addEventListener("mouseenter", () => {
            this.updateCustomSelectHovered(index);
          });
        });
      }, 500);
    }
  },
  mounted() {
    this.attachEvents();
  }
};
</script>

<style lang="css" scoped src="./Select.css">
.indicator {
  color: #e63312;
  font-size: 14px;
  line-height: 14px;
}
</style>
