<template>
  <div
    :class="['container--wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="title--content">
      <div class="saved-return-label-tag">
        <Label
          class="price-model"
          color="primary"
          bold="bold"
          :size="titleSize"
          :customClass="size === 'medium' ? 'title--content-title' : ''"
        >
          {{ productTitle }}
        </Label>
        <div class="saved-return-state" v-if="missingActions">
          <StateLabel v-if="true" alertType="red">
            <div class="state-label--container">
              <CpIcon class="danger--icon" icon="alert" />
              {{ $t("missingActions.sectionTitle") }}
            </div></StateLabel
          >
        </div>
      </div>
      <Label
        class="price-model-description"
        color="tertiary"
        :size="smallSize"
        :customClass="size === 'medium' ? 'title--content-subtitle' : ''"
      >
        {{ productDescription }}
      </Label>
      <div v-if="panel">
        <div class="tags">
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="search-vehicle-result-card-info-label"
          >
            {{ $t("pages.detailsPage.similar.usedCar") }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="tag-separator"
          >
            {{ `&#9679;` }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="search-vehicle-result-card-info-label"
          >
            {{ carDate }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="tag-separator"
          >
            {{ `&#9679;` }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="search-vehicle-result-card-info-label"
          >
            {{ kilometers }} {{ $t("pages.detailsPage.similar.km") }}
          </Label>
        </div>
        <div class="tags">
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="search-vehicle-result-card-info-label"
          >
            {{ fuel }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="tag-separator"
          >
            {{ `&#9679;` }}
          </Label>
          <Label
            size="small"
            color="tertiary"
            bold="normal"
            align="left"
            custom-class="search-vehicle-result-card-info-label"
          >
            {{ transmission }}
          </Label>
        </div>
      </div>
      <div class="tags" v-else>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="search-vehicle-result-card-info-label"
        >
          {{ $t("pages.detailsPage.similar.usedCar") }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="tag-separator"
        >
          {{ `&#9679;` }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="search-vehicle-result-card-info-label"
        >
          {{ carDate }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="tag-separator"
        >
          {{ `&#9679;` }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="search-vehicle-result-card-info-label"
        >
          {{ kilometers }} {{ $t("pages.detailsPage.similar.km") }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="tag-separator"
        >
          {{ `&#9679;` }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="search-vehicle-result-card-info-label"
        >
          {{ fuel }}
        </Label>
        <Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="tag-separator"
        >
          {{ `&#9679;` }} </Label
        ><Label
          size="small"
          color="tertiary"
          bold="normal"
          align="left"
          custom-class="search-vehicle-result-card-info-label"
        >
          {{ transmission }}
        </Label>
      </div>
    </div>
    <div class="line-separator"></div>
    <div class="finance--container">
      <div class="finance--wrapper" v-if="!absolutePrice">
        <Label
          v-if="!absolutePrice"
          class="finance-model-taeg"
          color="secondary"
          bold="bold"
          :size="smallSize"
          align="left"
          :customClass="
            size === 'medium'
              ? 'finance--container-subtitle'
              : 'finance-model-taeg'
          "
        >
          {{ financeTaeg }}
        </Label>
        <Label
          color="secondary"
          class="finance-model-modality"
          bold="bold"
          :size="smallSize"
          align="left"
          :customClass="size === 'medium' ? 'finance--container-subtitle' : ''"
          v-html="modality"
        >
          {{ modality }}</Label
        >
      </div>
      <div class="finance--wrapper">
        <div class="prices">
          <Label
            v-if="previousPrice && previousPriceLabel != priceLabel"
            class="finance-model-old-price"
            color="secondary"
            bold="bold"
            :size="smallSize"
            align="right"
            :customClass="size === 'medium' ? 'finance--container-title' : ''"
          >
            {{ `PVP: ${previousPriceLabel}` }}
          </Label>
          <div class="current-price">
            <Tooltip
              v-if="!noTooltip"
              :tooltip="tooltipDescription"
              :position="tooltipPosition"
            >
              <template v-slot:content>
                <div
                  class="icon--margin"
                  :style="{
                    backgroundImage: `url(${require('../../../assets/icons/infoGray.svg')})`
                  }"
                />
              </template>
            </Tooltip>
            <Label
              class="finance-model-price"
              color="secondary"
              bold="bold"
              :size="smallSize"
              align="right"
              :customClass="size === 'medium' ? 'finance--container-title' : ''"
            >
              {{ priceLabel }}
            </Label>
          </div>
        </div>
      </div>
    </div>
    <Label
      color="tertiary"
      class="finance-information"
      :size="smallSize"
      :customClass="size === 'medium' ? 'finance--container-subtitle' : ''"
      v-html="labelsForVehicleCard"
    >
      {{ labelsForVehicleCard }}</Label
    >

    <div class="line-separator"></div>
    <div :class="locationClass" v-if="vehicleLocation !== ''">
      <img
        class="locationPin"
        :src="require('../../../assets/icons/locationPin.svg')"
      />
      <div class="vehicle-location">
        <Label color="tertiary" :size="smallSize">
          {{ vehicleLocation }}
        </Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Tooltip, StateLabel } from "../../atoms";
import CpIcon from "../../refactor/CpIcon.vue";
import { getPriceFormatted } from "../../../utils/currency.js";
export default {
  name: "UsedVehicleCardContent",
  components: { Label, Tooltip, StateLabel, CpIcon },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "small",
      validator: value => {
        return ["small", "medium"].indexOf(value) !== -1;
      }
    },
    noTooltip: {
      type: Boolean,
      default: false
    },
    absolutePrice: {
      type: Boolean,
      default: false
    },
    productTitle: {
      type: String,
      default: ""
    },
    productDescription: {
      type: String,
      default: ""
    },
    priceLabel: {
      type: String,
      default: ""
    },
    financeTaeg: {
      type: String,
      default: ""
    },
    modality: {
      type: String,
      default: ""
    },
    iconName: {
      type: String,
      default: ""
    },
    tooltipDescription: {
      type: String,
      default: ""
    },
    tooltipPosition: {
      type: String,
      default: "top"
    },
    missingActions: {
      type: Boolean,
      default: false
    },
    fuel: {
      type: String,
      default: ""
    },
    transmission: {
      type: String,
      default: ""
    },
    months: {
      type: String,
      default: ""
    },
    capitalFinance: {
      type: String,
      default: ""
    },
    depositValue: {
      type: String,
      default: ""
    },
    previousPrice: {
      type: Number,
      default: 0.0
    },
    kilometers: {
      type: Number,
      default: 0
    },
    carDate: {
      type: String,
      default: ""
    },
    vehicleLocation: {
      type: String,
      default: ""
    },
    panel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        ...this.customClass
      };
    },
    titleSize() {
      return this.size === "medium"
        ? "medium"
        : this.size === "small"
        ? "small"
        : "";
    },

    smallSize() {
      return this.size === "medium"
        ? "small"
        : this.size === "small"
        ? "x-small"
        : "";
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    labelsForVehicleCard() {
      return `${this.depositValue} | ${this.months} | ${this.capitalFinance}`;
    },
    previousPriceLabel() {
      return getPriceFormatted(this.previousPrice, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    locationClass() {
      return this.panel ? "locationSection--panel" : "locationSection";
    }
  }
};
</script>

<style lang="css" scoped src="./UsedVehicleCardContent.css"></style>
