import { getVehiclesToCompare } from "@/api/product";
import { FOOTPRINT_TYPES, registerEvent } from "../../api/footprint";
import { MINIMUM_VEHICLES } from "../../enums/compare";

const initialState = () => ({
  list: [],
  panelOpened: false
});

const state = initialState();

const getters = {
  count: state => state.list.length,
  empty: state => state.list.length === 0,
  readyToCompare: state => state.list.length >= MINIMUM_VEHICLES,
  list: state => state.list,
  exists: state => ({ vin, financeId }) =>
    !!state.list.find(v => v.vin === vin && v.financeId === financeId),
  numberOfDifferences: state =>
    state.list[0].specificationsDifferences?.length +
      state.list[0].equipmentsDifferences?.length || "",
  isPanelOpened: state => state.panelOpened
};

const mutations = {
  set: (state, vehicles) => (state.list = vehicles),
  add: (state, vehicle) => state.list.push(vehicle),
  remove: (state, { vin, financeId }) => {
    state.list = state.list.filter(
      vehicle => !(vehicle.vin == vin && vehicle.financeId == financeId)
    );
  },
  openPanel: state => (state.panelOpened = true),
  closePanel: state => (state.panelOpened = false),
  reset: state => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
    localStorage.removeItem("comparator");
  }
};

const actions = {
  addToCompare: ({ commit, state }, vehicle) => {
    registerEvent(FOOTPRINT_TYPES.ADD_COMPARE, { Vin: vehicle.vin });
    // TODO add compared vehicle to user compared vehicles list on server
    commit("add", vehicle);
    //TODO use backend
    localStorage.setItem("comparator", JSON.stringify(state.list));
  },
  removeFromCompare: ({ commit, state }, vehicle) => {
    registerEvent(FOOTPRINT_TYPES.REMOVE_COMPARE, { Vin: vehicle.vin });
    // TODO remove compared vehicle from user compared vehicles list on server
    commit("remove", vehicle);
    //TODO use backend
    localStorage.setItem("comparator", JSON.stringify(state.list));
  },
  fetchVehicles: async ({ state, commit }) => {
    const { data } = await getVehiclesToCompare(
      state.list.map(vehicle => vehicle.vin)
    );

    const { data: results } = data;

    const currentState = [...state.list];

    const newState = results.cars.map((carDetails, index) => {
      return { ...currentState[index], ...carDetails };
    });

    commit("set", newState);
  },
  loadVehiclesFromStorage({ commit }) {
    const vehicles = JSON.parse(localStorage.getItem("comparator")) || [];
    commit("set", vehicles);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
