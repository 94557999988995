<template>
  <div class="returns">
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.returns.title")
      }}</Label>
    </div>
    <div class="content">
      <BasicInformation :vehicle="getReturnProcessed"></BasicInformation>
      <div class="separator"></div>
      <CarFeatures
        :interiorList="prepareInteriorList"
        :externalColorList="prepareExternalColorList"
        :equipmentList="prepareEquipments"
        :submitted="hasErrors"
        @onFeatures="onFeaturesHandler"
      ></CarFeatures>
      <div class="separator"></div>
      <MechanicalAndGeneralCondition
        :mechanicalStateList="getMechanicalState"
        :generalStatusList="getGeneralStatus"
        :submitted="hasErrors"
      ></MechanicalAndGeneralCondition>
      <div id="financing-separator" class="separator"></div>
      <AdditionalInformation
        :vehicleOperationList="prepareVehicleOperation"
        :accessoriesList="prepareAccessories"
        @onAdditionalInformation="onAdditionalInformationHandler"
        :key="'aikey' + renderKey"
      ></AdditionalInformation>
      <Label v-if="errs.financing" class="validate-message">{{
        errs.financing
      }}</Label>
      <div class="separator"></div>
      <PhotosAndDocuments @onUpload="onUploadHandler"></PhotosAndDocuments>
      <Label v-if="errs.images" class="validate-message">{{
        errs.images
      }}</Label>
      <div class="separator"></div>
      <EstimatedValue
        :status="getStatus"
        :initialPrice="getInitialValuation"
        :price="getCurrentValuation"
        :adjustedPrice="getActualValuation"
      ></EstimatedValue>
      <div class="footer">
        <Button
          v-if="showButtons"
          :label="$t('pages.personalPage.returns.savedReturn.saveBtn')"
          uppercase
          class="button-save"
          type="primary"
          @onClick="onClickSaveHandler(false)"
        ></Button>
        <Button
          v-if="showButtons"
          class="button-save"
          :label="$t('pages.personalPage.returns.savedReturn.submitBtn')"
          uppercase
          type="primary"
          fill
          @onClick="onClickSaveHandler(true)"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../../atoms";
import { RETAKESTATUS } from "../../../../enums/retakeStatus.js";
import {
  BasicInformation,
  CarFeatures,
  MechanicalAndGeneralCondition,
  AdditionalInformation,
  PhotosAndDocuments,
  EstimatedValue
} from "../../../organisms";
import v from "../../../../utils/validator.js";

import { PAGES } from "@/enums/pages";

const flatten = obj => {
  const {
    mechanicalAndGeneralStatus,
    images,
    additionalInformation: { vehicleOperation: financing },
    features: { externalColor, cabinTrimInlay, kilometers }
  } = obj;
  console.log("obj", obj);
  return {
    state: [
      ...mechanicalAndGeneralStatus.generalStatusList,
      ...mechanicalAndGeneralStatus.mechanicalStateList
    ],
    financing,
    images: Object.values(images),
    externalColor,
    cabinTrimInlay,
    kilometers
  };
};

export default {
  name: "Returns",
  components: {
    Label,
    Button,
    BasicInformation,
    CarFeatures,
    MechanicalAndGeneralCondition,
    AdditionalInformation,
    PhotosAndDocuments,
    EstimatedValue
  },
  data() {
    return {
      id: this.$route.params.id,
      retakeStatusEnum: RETAKESTATUS,
      returnDetails: [],
      vehicle: {},
      errorLaunch: false,
      renderKey: 0,
      externalColorList: [
        {
          value: "-1",
          text: "Cor Exterior",
          disabled: true
        },
        { value: "YELLO1", text: "Amarelo" },
        { value: "BLUE00", text: "Azul" },
        { value: "BG", text: "Bege" },
        { value: "BORDX", text: "Bordeaux" },
        { value: "WHITE0", text: "Branco" },
        { value: "BROWN", text: "Castanho" },
        { value: "CHAMP", text: "Champanhe" },
        { value: "GRAY", text: "Cinzento" },
        { value: "LGRAY", text: "Cinzento Claro" },
        { value: "DGRAY", text: "Cinzento Escuro" },
        { value: "CREAM", text: "Creme" },
        { value: "GOLD", text: "Dourado" },
        { value: "ORANGE", text: "Laranja" },
        { value: "SILVER", text: "Prateado" },
        { value: "BLACK0", text: "Preto" },
        { value: "PINK", text: "Rosa" },
        { value: "PURPL", text: "Roxo" },
        { value: "GREEN1", text: "Verde" },
        { value: "RED31", text: "Vermelho" },
        { value: "UNKWN", text: "Desconhecida" }
      ],
      interiorList: [
        {
          value: "-1",
          text: "Interior",
          disabled: true
        },
        { value: "PLAS", text: "Plástico" },
        { value: "FABRIC", text: "Tecido" },
        { value: "LEATHE", text: "Pele" },
        { value: "SYTNLE", text: "Pele sintética" }
      ],
      equipments: [
        {
          text: "ABS",
          value: "ABS",
          checked: false
        },
        {
          text: "Bancos Aquecidos",
          value: "HeatedSeats",
          checked: false
        },
        {
          text: "Espelhos Electricos",
          value: "RetractableElectricMirrors",
          checked: false
        },
        {
          text: "Radio com CD",
          value: "AutoRadioWithCD",
          checked: false
        },
        {
          text: "Vidros Electricos",
          value: "ElectricFrontGlass",
          checked: false
        },
        {
          text: "Bancos Electricos",
          value: "ElectricallyAdjustableSeats",
          checked: false
        },
        {
          text: "Cruise Control",
          value: "CruiseControl",
          checked: false
        },
        {
          text: "GPS",
          value: "NavigationSystem",
          checked: false
        },
        {
          text: "Bluetooth",
          value: "Bluetooth",
          checked: false
        },
        {
          text: "Controlo de Estabilidade (ESP)",
          value: "StabilityControl",
          checked: false
        },
        {
          text: "Farois de Xenon",
          value: "XenonHeadlamps",
          checked: false
        },
        {
          text: "Jantes em Liga Leve",
          value: "AlloyWheels",
          checked: false
        },
        {
          text: "Tecto de Abrir",
          value: "OpeningGlassRoof",
          checked: false
        },
        {
          text: "Sensores de Parqueamento Frontais",
          value: "FrontParkingSensors",
          checked: false
        },
        {
          text: "Sensores de Parqueamento Traseiros",
          value: "RearParkingSensors",
          checked: false
        }
      ],
      vehicleOperation: [
        {
          text: "Crédito Automóvel c/ Reserva ou Hipoteca",
          value: "FinancedWithReserveOfPropert",
          checked: false
        },
        {
          text: "Renting ou ALD",
          value: "renting",
          checked: false
        },
        {
          text: "Leasing",
          value: "leasing",
          checked: false
        },
        {
          text: "Nenhum (Veículo do Próprio)",
          value: "none",
          checked: false
        }
      ],
      accessories: [
        {
          text: "Livro de Instruções",
          value: "InstructionsManual",
          checked: false
        },
        {
          text: "Livro de Revisões",
          value: "ServiceBook",
          checked: false
        },
        {
          text: "Segunda Chave",
          value: "SecondaryKey",
          checked: false
        }
      ],
      features: {},
      mechanicalAndGeneralStatus: {},
      additionalInformation: {},
      files: [],
      errs: {
        images: "",
        financing: "",
        state: "",
        externalColor: "",
        cabinTrimInlay: "",
        kilometers: ""
      }
    };
  },
  methods: {
    preparePayload(submitted) {
      this.$store.commit("setPlateDateFormatted");
      return {
        tradeInId: this.id,
        features: {
          ...this.features,
          ...this.getReturn?.tradeInPayload?.features
        },
        additionalInformation: {
          ...this.getReturn?.tradeInPayload?.additionalInformation,
          ...this.additionalInformation
        },
        mechanicalAndGeneralStatus: {
          mechanicalStateList: this.getMechanicalState,
          generalStatusList: this.getGeneralStatus
        },
        images: this.fileListToObj([...this.files]),
        submitted
      };
    },
    fileListToObj(fileList) {
      fileList = this.getReturn?.phaseTwoDTOV3?.files
        ? [...this.getReturn?.phaseTwoDTOV3?.files, ...fileList]
        : fileList;
      return fileList;
    },
    onFeaturesHandler(event) {
      this.features = event;
    },
    onMechanicalAndGeneralStatusHandler(event) {
      this.mechanicalAndGeneralStatus = event;
    },
    onAdditionalInformationHandler(event) {
      this.additionalInformation = event;
    },
    onUploadHandler(event) {
      this.files = event;
    },
    onScrollToElement(errs) {
      if (
        errs.externalColor !== "" ||
        errs.cabinTrimInlay !== "" ||
        errs.kilometers !== ""
      ) {
        const element = document.querySelector(".header");
        element?.scrollIntoView();
      } else if (errs.state !== "") {
        const element = document.querySelector(".mechanical-general-condition");
        element?.scrollIntoView();
      } else if (errs.financing !== "") {
        const element = document.querySelector(".additional-information");
        element?.scrollIntoView();
      } else if (errs.images !== "") {
        const element = document.querySelector(".photos-documents");
        element?.scrollIntoView();
      }
    },
    onClickSaveHandler(submitted = true) {
      const returnDetails = this.preparePayload(submitted);
      if (submitted) {
        this.errs = v("returnPhase2", flatten(returnDetails));
        console.log("asdadasd", flatten(returnDetails));

        if (Object.values(this.errs).some(Boolean)) {
          this.errorLaunch = true;
          this.onScrollToElement(this.errs);
          return;
        }
      }

      this.errorLaunch = false;

      this.$store
        .dispatch("storeUpdateReturnDetails", returnDetails)
        .then(result => {
          console.log(result);
          // this.$toast.open({
          //   message: submitted
          //     ? this.$t("toast.valuation.success.submited")
          //     : this.$t("toast.valuation.success.saved"),
          //   dismissible: true,
          //   type: "info"
          // });
          if (submitted) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eec.retomaQ2",
              model: this.getReturnProcessed.model
            });
          }
          setTimeout(() => this.$router.push({ name: PAGES.RETAKES }), 500);
        })
        .catch(error => {
          console.log(error);
          this.$toast.open({
            message: this.$t("toast.valuation.error.generic"),
            dismissible: true,
            type: "error"
          });
        });
    },
    formatLicensePlate(plate) {
      return (
        plate.slice(0, 2) + "-" + plate.slice(2, 4) + "-" + plate.slice(4, 6)
      );
    }
  },
  computed: {
    ...mapGetters([
      "getReturn",
      "getEquipments",
      "getReturnKilometers",
      "getMechanicalState",
      "getGeneralStatus"
    ]),
    hasErrors() {
      return this.errorLaunch;
    },
    showButtons() {
      return (
        !this.getReturn?.submitted ||
        this.getReturn?.status === this.retakeStatusEnum.Q2_REFUSED
      );
    },
    getReturnProcessed() {
      return {
        ...this.getReturn,
        registration: this.getReturn.licensePlate,
        registrationDate: `${this.getReturn.month}/${this.getReturn.year}`,
        fuel: this.getReturn.fuel ?? "-",
        transmission: this.getReturn.transmission ?? "-",
        details: this.getReturn.tradeInPayload,
        version: this.getReturn.version
      };
    },
    prepareEquipments() {
      let equipmentsList = this.equipments;
      let details = this.getReturnProcessed.details;
      if (details && details.features.equipments) {
        equipmentsList = this.getReturnProcessed.details.features.equipments.map(
          equipment => {
            equipment.text = this.$t(`equipments.${equipment.value}`);

            return equipment;
          }
        );
      }

      return equipmentsList;
    },
    prepareVehicleOperation() {
      let vehicleOperation = this.vehicleOperation;
      let details = this.getReturnProcessed.details;

      if (
        details &&
        details.additionalInformation.vehicleOperation.length > 0
      ) {
        vehicleOperation = details.additionalInformation.vehicleOperation.map(
          operation => {
            operation.text = this.$t(`operations.${operation.value}`);
            if (operation.checked) {
              this.$store.commit("setSelectedFinancingValue", operation.value);
            }
            return operation;
          }
        );
      }
      return vehicleOperation;
    },
    prepareAccessories() {
      let accessoriesList = this.accessories;
      let details = this.getReturnProcessed.details;

      if (details && details.additionalInformation.accessories) {
        accessoriesList = this.getReturnProcessed.details.additionalInformation.accessories.map(
          accessory => {
            accessory.text = this.$t(`accessories.${accessory.value}`);

            return accessory;
          }
        );
      }

      return accessoriesList;
    },
    prepareInteriorList() {
      var backendTrimInLay = this.getReturnProcessed?.tradeInPayload?.features
        ?.cabinTrimInlay;
      return this.interiorList.map(it => ({
        ...it,
        selected: it.value === backendTrimInLay
      }));
    },
    prepareExternalColorList() {
      var backendExternalColor = this.getReturnProcessed?.tradeInPayload
        ?.features?.externalColor;
      return this.externalColorList.map(it => ({
        ...it,
        selected: it.value === backendExternalColor
      }));
    },
    getCurrentValuation() {
      return this.getReturn.actualValuation;
    },
    getStatus() {
      return this.getReturn.status;
    },
    getInitialValuation() {
      return this.getReturn.initialValuation;
    },
    getActualValuation() {
      return this.getReturn.actualValuation;
    }
  },
  async mounted() {
    await this.$store.dispatch("storeGetReturnDetails", this.id);
    this.renderKey += 1;
  }
};
</script>

<style lang="css" scoped src="./ReturnDetails.css"></style>
