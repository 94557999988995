<template>
  <div
    :class="['reservation-time-tag', customClass, classes]"
    :style="customStyles"
  >
    <div class="reservation-time-tag--icon">
      <Icon :name="iconName" :color="iconColor"></Icon>
    </div>
    <div class="reservation-time-tag--label-wrapper">
      <div class="reservation-time-tag--label-title">
        <Label :color="colorTitle" size="x-small">{{ title }}</Label>
      </div>
      <div class="reservation-time-tag--label-subtitle">
        <Label :type="typeSubtitle" bold="bold" size="small">{{
          subtitle
        }}</Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Label } from "../../atoms";

export default {
  components: {
    Icon,
    Label
  },
  data() {
    return {
      colorTitle: "tertiary",
      typeTitle: "description",
      typeSubtitle: "subtitle"
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "time",
      validator: type => {
        return ["time", "price"].includes(type);
      }
    },
    title: {
      type: String,
      required: true,
      default: ""
    },
    subtitle: {
      type: String,
      required: true,
      default: ""
    },
    iconName: {
      type: String,
      required: true,
      default: ""
    },
    iconColor: {
      type: String,
      require: false,
      default: ""
    }
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles
      };
    }
  }
};
</script>
<style lang="css" scoped src="./ReservationTimeTag.css"></style>
