<template>
  <div class="padding-container">
    <PriceDetails v-if="vehicle" :vehicle="selectedVehicle" />
    <div class="mt-8">
      <Label class="reserve--header">Insira os seus dados pessoais</Label>
    </div>
    <div class="mt-4">
      <CpInput
        ref="name"
        :label="$t('placeholder.name') + ' *'"
        :value="getUserInfoDifferentKeys.name"
        :validator="validateName"
      />
    </div>
    <div class="mt-4">
      <CpInput
        ref="email"
        :label="$t('placeholder.email') + ' *'"
        :value="getUserInfoDifferentKeys.email"
        :validator="validateEmail"
      />
    </div>
    <div class="mt-4">
      <CpInput
        ref="phone"
        :label="$t('placeholder.phone') + ' *'"
        :value="getUserInfoDifferentKeys.phone"
        :validator="validatePhone"
      />
    </div>
    <div class="mt-4" v-if="isGuest">
      <Label size="small" color="tertiary">
        {{ $t("validators.requiredAccountLeads") }}
        <span @click="triggerLogin" class="login-span">{{
          $t("validators.requiredAccountLogin")
        }}</span
        >.
      </Label>
    </div>
    <div class="mt-4" v-if="isGuest && form.createAccount">
      <CpPassword
        ref="password"
        @onValidation="handleOnValidation"
        :disabled="!isGuest"
      />
    </div>
    <div class="mt-4">
      <PrivacyPolicy
        @acceptedPrivacyPolicy="acceptedPrivacyPolicy"
        @acceptedEmailMarketing="acceptedEmailMarketing"
        :textMarketing="$t('privacyPolicy.textMarketing')"
        :createAccount="!getToken || isGuest"
        @acceptedCreateAccount="acceptedCreateAccount"
        @onClickPolicyLink="onOpenPrivacyPolicy"
      />
      <Label
        v-if="errs.privacyPolicy"
        color="tertiary"
        bold="thin"
        size="small"
        class="validate-message"
      >
        {{ errs.privacyPolicy }}</Label
      >
    </div>
    <ConcessionPicker
      ref="dealer"
      @onDealer="onDealer"
      showIsland
      v-if="selectedVehicle.conditionId === 1"
    />
    <ConcessionPicker
      ref="dealer"
      @onDealer="onDealer"
      isUsed
      :districtProp="selectedVehicle.dealerDistrict"
      :dealerId="selectedVehicle.dealerId"
      showIsland
      v-else
    />
    <div class="button-container">
      <div class="button-container-inner">
        <Button
          v-if="back"
          :label="$t('panels.reserve.steps.button.previous')"
          class="previous-button"
          uppercase
          type="primary"
          size="large"
          @onClick="goBack"
        />
        <Button
          fill
          :label="$t('panels.reserve.steps.button.send')"
          class="send-button"
          uppercase
          type="primary"
          size="large"
          @onClick="submit"
          :disabled="disableButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PrivacyPolicy, PriceDetails, ConcessionPicker } from "../../organisms";
import { Label, Button } from "../../atoms";
import { mapGetters } from "vuex";
import CpInput from "../../refactor/CpInput.vue";
import CpPassword from "../../refactor/CpPassword.vue";
import formValidator from "../../../utils/formValidator";
import LABEL_TYPE from "../../../utils/labelType";

export default {
  name: "ReserveTestDrive",
  components: {
    PrivacyPolicy,
    PriceDetails,
    ConcessionPicker,
    Label,
    Button,
    CpInput,
    CpPassword
  },
  data() {
    return {
      errs: {},
      form: {
        ...this.getUserInfoDifferentKeys,
        pass: "",
        privacyPolicy: false,
        emailMarketing: false,
        createAccount: false,
        sentSuccess: false
      },
      showInfoMessage: false
    };
  },
  props: {
    back: {
      type: Boolean,
      default: true
    },
    vehicle: {
      type: Boolean,
      default: true
    },
    selectedVehicle: {
      type: Object
    }
  },
  methods: {
    handleOnValidation([pass, isValid]) {
      this.form.pass = pass;
      this.validPass = isValid;
    },
    triggerLogin() {
      this.$store.state.isOpenSigninPopup = true;
    },
    validatePhone(e) {
      this.form.phone = e;
      this.$store.commit("setUserInfo", {
        ...this.getUserInfo,
        phoneNumber: e
      });
      const re = /^(\+?351|(00)?351)?\s?9[2,6,3,1]\d\s?\d{3}\s?\d{3}$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.phone")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateEmail(e) {
      this.form.email = e;
      this.$store.commit("setUserInfo", { ...this.getUserInfo, userEmail: e });
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(e)) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.email")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateName(e) {
      this.form.name = e;
      this.$store.commit("setUserInfo", { ...this.getUserInfo, name: e });
      if (!e) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.name")];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },

    async submit() {
      const refs = [
        {
          ref: "name",
          value: this.getUserInfoDifferentKeys.name
        },
        {
          ref: "email",
          value: this.getUserInfoDifferentKeys.email
        },
        {
          ref: "phone",
          value: this.getUserInfoDifferentKeys.phone
        },
        {
          ref: "password",
          value: this.form.pass
        }
      ];

      const errs = formValidator(
        this.isGuest && this.form.createAccount ? refs : refs.slice(0, -1),
        this.$refs
      );

      const [dealerErr, districtErr] = this.$refs.dealer.triggerRefs({
        dealer: this.form.dealer.dealerCode,
        district: this.form.dealer.district
      });

      if (
        errs.length > 0 ||
        !this.form.privacyPolicy ||
        !dealerErr ||
        !districtErr
      ) {
        return false;
      }

      const { pass, emailMarketing } = this.form;

      let registerError = false;
      if (this.isGuest && this.form.createAccount) {
        try {
          await this.triggerRegister({
            email: this.getUserInfoDifferentKeys.email,
            name: this.getUserInfoDifferentKeys.name,
            password: pass,
            phoneNumber: this.getUserInfoDifferentKeys.phone,
            emailMarketing: emailMarketing
          });
        } catch (e) {
          registerError = true;
        }
      }

      if (!this.isGuest) {
        // Update User Data in Store and Call Backend
        this.$store.dispatch("updateUser", {
          phoneNumber: this.form.phone,
          userEmail: this.form.email,
          name: this.form.name
        });
      }

      if (!registerError) {
        this.$emit("onSubmitLead", {
          name: this.form.name,
          dealerId: this.form.dealer?.dealerCode
            ? this.form.dealer?.dealerCode
            : this.form.dealer,
          phoneNumber: this.form.phone,
          emailAddress: this.form.email,
          vin: this.getVin,
          infoCheck: this.form.emailMarketing,
          currentUrl: window.location.href,
          finance: {
            pvp: this.getVehicleTotalPrice
          }
        });
      }

      this.$store.isOpenReservePanel = false;
    },
    async triggerRegister(payload) {
      await this.$store.dispatch("storeRegisterToken", payload);
    },
    acceptedEmailMarketing(e) {
      this.form.emailMarketing = e;
    },
    acceptedPrivacyPolicy(e) {
      this.form.privacyPolicy = e;
    },
    acceptedCreateAccount(e) {
      this.form.pass = "";
      this.form.createAccount = e;
    },
    goBack() {
      this.$emit("onBack");
    },
    onDealer(d) {
      this.form.dealer = d;
    },
    onFocusHandler() {
      this.validatePassword();
      this.showInfoMessage = true;
    },
    onBlurHandler() {
      this.showInfoMessage = false;
    },
    onOpenPrivacyPolicy() {
      window.open("/politica-de-privacidade", "_blank");
    }
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getVin",
      "getVehicleTotalPrice",
      "getToken",
      "isGuest",
      "getUserName",
      "getUserInfo",
      "getUserInfoDifferentKeys",
      "getUserPhoneNumber",
      "getUserDealership",
      "getUserEmail"
    ]),
    disableButton() {
      if (!this.isGuest) {
        if (this.form.privacyPolicy) return false;
        return true;
      } else {
        if (
          this.form.privacyPolicy &&
          this.getUserInfoDifferentKeys.name &&
          this.getUserInfoDifferentKeys.email &&
          this.getUserInfoDifferentKeys.phone
        ) {
          return false;
        }
        return true;
      }
    }
  }
};
</script>

<style scoped>
.button-container-inner .button {
  @apply mb-4;
  @apply w-full;
  height: 60px;
}

@media (min-width: 768px) {
  .footer-buttons .button {
    width: 220px;
  }
}

:deep(.selectWrapper) select {
  @apply w-full;
}

.padding-container {
  margin: 2rem;
  margin-top: 0;
}

:deep(.selectCustom-options) {
  max-height: 200px;
  @apply overflow-y-auto;
  @apply z-50;
}

.button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @apply h-auto;
  @apply sm:h-36;
  background-color: white;
  z-index: 15;
}

.button-container-inner {
  @apply sm:flex;
  @apply justify-between;
  margin: 39px;
}

.previous-button {
  @apply sm:mr-2;
}

.send-button {
  @apply sm:ml-2;
}

.validate-message {
  font-size: 12px;
  @apply font-head;
  @apply font-normal;
  line-height: 14px;
}

@media (max-width: 375px) {
  :deep(.container--wrapper) {
    flex-direction: column;
  }

  :deep(.title--content) {
    width: 100% !important;
  }

  :deep(.finance--container) {
    width: 100% !important;
    align-items: start;
  }

  :deep(.finance-model-taeg) {
    text-align: left;
  }
}

@media (min-width: 376px) {
  :deep(.finance-model-taeg) {
    text-align: right !important;
  }
}

.login-span {
  --tw-text-opacity: 1;
  color: rgba(0, 170, 210, var(--tw-text-opacity));
  text-decoration: underline;
  cursor: pointer;
}
</style>
