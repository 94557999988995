<template>
  <RightSidebar
    @onClose="closeTestDrive"
    v-if="isTestDrive"
    title="Marcar Test Drive"
    description=""
  >
    <template v-slot:content>
      <div class="interested--price-model-details">
        <PriceModelDetailsRightSidebar
          size="medium"
          :product-title="vehicleDetails.model"
          :product-description="vehicleDetails.commercialDescription"
          :finance-taeg="'TAEG: ' + vehicleDetails.taeg.toFixed(2) + '%'"
          :modality="vehicleDetails.monthlyPrice.toFixed(2) + '€/mês'"
          :price-label="
            getPriceFormatted(vehicleDetails.pricePvp, {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          "
          icon-name="info-circle"
          tooltip-description="Loren Ipsum"
        >
        </PriceModelDetailsRightSidebar>
      </div>

      <div class="mt-4">
        <Label color="tertiary">Insira os seus dados pessoais</Label>
      </div>

      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Nome *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              myPersonalName = value;
            }
          "
        />
        <Label v-if="errorName" color="tertiary" bold="thin" size="small">
          {{ errorName }}</Label
        >
      </div>

      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Email *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              myPersonalEmail = value;
            }
          "
        />
        <Label v-if="errorEmail" color="tertiary" bold="thin" size="small">
          {{ errorEmail }}</Label
        >
      </div>

      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Telémovel *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              myPersonalPhone = value;
            }
          "
        />
        <Label v-if="errorPhone" color="tertiary" bold="thin" size="small">
          {{ errorPhone }}</Label
        >
      </div>
      <div class="mt-4">
        <PrivacyPolicy
          privacyPolicyLinkURL="/politica-de-privacidade"
          @acceptedPrivacyPolicy="acceptedPrivacyPolicy"
          @acceptedEmailMarketing="acceptedEmailMarketing"
          :textMarketing="$t('privacyPolicy.textMarketing')"
          :createAccount="true"
          @acceptedCreateAccount="acceptedCreateAccount"
          @onClickPolicyLink="onOpenPrivacyPolicy"
        />
        <Label
          v-if="errorPrivacyPolicy"
          color="tertiary"
          bold="thin"
          size="small"
        >
          {{ errorPrivacyPolicy }}</Label
        >
      </div>
      <div v-if="isCreateAccount" class="mt-4">
        <InputForm
          type="primary"
          inputType="password"
          label="Palavra-passe *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              myPersonalPassword = value;
            }
          "
        />
        <Label v-if="errorPassword" color="tertiary" bold="thin" size="small">
          {{ errorPassword }}</Label
        >
      </div>
      <div class="mt-4">
        <Label color="tertiary"
          >Escolha um concessionário para intermediar o negócio.</Label
        >
        <Link
          class="concessionario"
          color="secondary"
          size="small"
          label="Porque preciso de um concessionário?"
        />
      </div>
      <div class="mt-4">
        <Select
          @onChange="onChangeDistrict"
          id="district-test-drive"
          :options="districtList"
        ></Select>
      </div>
      <div class="mt-4">
        <Select
          @onChange="onChangeDealer"
          id="dealer-test-drive"
          :options="concessionList"
        ></Select>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        label="anterior"
        @onClick="previous"
        uppercase
        type="primary"
      ></Button>

      <Button
        class="reserve"
        label="marcar test drive"
        uppercase
        type="primary"
        :fill="true"
        :disabled="buttonEnabled"
        @onClick="clickTestDrive"
      ></Button>
    </template>
  </RightSidebar>
</template>

<script>
import { getPriceFormatted } from "../../../utils/currency";
import { mapGetters } from "vuex";
import { RightSidebar, PrivacyPolicy } from "../../organisms";
import { PriceModelDetailsRightSidebar, InputForm } from "../../molecules";
import { Label, Link, Select, Button } from "../../atoms";
export default {
  name: "TestDrive",
  components: {
    RightSidebar,
    PriceModelDetailsRightSidebar,
    Label,
    InputForm,
    PrivacyPolicy,
    Link,
    Select,
    Button
  },
  data() {
    return {
      isTestDrive: true,
      isCreateAccount: false,
      myPersonalEmail: "",
      myPersonalName: "",
      myPersonalPhone: "",
      myPersonalPassword: "",
      myPrivacyPolicy: "",
      myEmailMarketing: "",
      myPersonalDealer: "",
      errorEmail: "",
      errorName: "",
      errorPhone: "",
      errorPassword: null,
      errorPrivacyPolicy: "",
      district: "PORTO"
    };
  },
  props: {
    vehicleDetails: {
      type: Object
    }
  },
  methods: {
    getPriceFormatted,
    closeTestDrive() {
      this.isTestDrive = false;
      this.$emit("onClose", true);
    },
    openInterested() {
      this.isTestDrive = false;

      // this.$emit("onInterestedOpened", true);
    },
    clickTestDrive() {
      if (!this.myPersonalEmail) {
        this.errorEmail = "O email é obrigatório.";
      } else if (!this.validEmail(this.myPersonalEmail)) {
        this.errorEmail = "Introduza um email válido.";
      } else {
        this.errorEmail = null;
      }

      if (!this.myPersonalName) {
        this.errorName = "O Nome é obrigatório.";
      } else {
        this.errorName = null;
      }

      if (!this.myPersonalPhone) {
        this.errorPhone = "O Telemóvel é obrigatório.";
      } else {
        this.errorPhone = null;
      }

      if (this.isCreateAccount) {
        if (!this.myPersonalPassword) {
          this.errorPassword = "A password é obrigatório.";
        } else if (!this.validPassword(this.myPersonalPassword)) {
          this.errorPassword =
            "Inclua entre 8 - 14 carateres e pelo menos: Uma letra maiúscula, uma letra minúscula, um número e um carater especial.";
        } else {
          this.errorPassword = null;
        }
      }

      if (!this.myPrivacyPolicy) {
        this.errorPrivacyPolicy = "Tem de aceitar a política de privacidade.";
      } else {
        this.errorPrivacyPolicy = null;
      }

      if (
        this.errorEmail === null &&
        this.errorName === null &&
        this.errorPrivacyPolicy === null &&
        this.errorPhone === null &&
        this.errorPassword === null
      ) {
        const testDrive = {
          emailAddress: this.myPersonalEmail,
          name: this.myPersonalName,
          phoneNumber: this.myPersonalPhone,
          dealerId: this.myPersonalDealer,
          // password: this.myPersonalPassword,
          infoCheck: this.myEmailMarketing
        };

        this.$store.dispatch("storeAddTestDrive", testDrive);

        if (this.isCreateAccount) {
          const createAccount = {
            email: this.myPersonalEmail,
            name: this.myPersonalName,
            password: this.myPersonalPassword,
            emailMarketing: !!this.myEmailMarketing
          };

          this.$store.dispatch("storeRegisterToken", createAccount);
        }
      }
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPassword: function(password) {
      let re = /^(?=(.*[a-z].*){1,})(?=(.*[A-Z].*){1,})(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,14}$/;
      return re.test(password);
    },
    onChangeDistrict(event) {
      this.district = event;
      this.concessionList;
    },
    acceptedPrivacyPolicy(event) {
      this.myPrivacyPolicy = event;
    },
    acceptedEmailMarketing(event) {
      this.myEmailMarketing = event;
    },
    acceptedCreateAccount(event) {
      if (event) {
        this.isCreateAccount = true;
      } else {
        this.isCreateAccount = false;
      }
    },
    onChangeDealer(event) {
      this.myPersonalDealer = event;
    },
    previous(event) {
      this.$emit("onPrevious", event);
    },
    onOpenPrivacyPolicy() {
      window.open("/politica-de-privacidade", "_blank");
    }
  },
  computed: {
    ...mapGetters(["getDealers"]),
    buttonEnabled() {
      return (
        !this.myPersonalName &&
        !this.myPersonalEmail &&
        !this.myPersonalPhone &&
        !this.myPersonalPassword
      );
    },
    districtList() {
      const dealers = this.getDealers;

      const unique = [...new Set(dealers.map(item => item.district))];

      const uniqueDistricts = unique.map(item => {
        return {
          value: item,
          text: item
        };
      });

      return uniqueDistricts;
    },
    concessionList() {
      const dealers = this.getDealers;

      const arrayDealers = [];

      const localDistrict = this.district;

      dealers.forEach(function(entry) {
        if (localDistrict === entry.district) {
          arrayDealers.push({
            address: entry.address,
            dealerCode: entry.dealerCode
          });
        }
      });

      const uniqueDealers = arrayDealers.map(item => {
        return {
          value: item.dealerCode,
          text: item.address
        };
      });

      return uniqueDealers;
    }
  }
};
</script>

<style lang="css" scoped src="./TestDrive.css"></style>
