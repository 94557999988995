import httpClient from "./httpClient";
import axios from "axios";

const RETURNS = "/users/me/retake";
const UPLOAD = RETURNS + "/upload";
const PHASE_2 = "/retake/phase2";

function getAllReturns() {
  return httpClient.get(RETURNS);
}

function getReturnDetails(id) {
  return httpClient.get(RETURNS + "/" + id);
}

function addPhoto(file) {
  axios.post(UPLOAD, file, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function updateReturn(details) {
  return new Promise(resolve => {
    resolve(httpClient.post(PHASE_2, details));
  });
}

function deleteReturn(id) {
  return httpClient.delete(RETURNS + "/" + id);
}

export {
  getAllReturns,
  addPhoto,
  getReturnDetails,
  deleteReturn,
  updateReturn
};
