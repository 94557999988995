<template>
  <div class="step-progress">
    <div class="step-progress--title">
      <Label color="tertiary" size="small"
        >Passo {{ currentStep }} de {{ totalSteps }}</Label
      >
    </div>
    <div class="step-progress--progress">
      <progress :max="totalSteps" :value="currentStep"></progress>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  name: "ProgressBar",
  components: {
    Label
  },
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    totalSteps: {
      type: Number,
      default: 4
    }
  }
};
</script>

<style scoped>
.step-progress {
  @apply w-full;
}

.step-progress--title {
  @apply mb-2;
}

.step-progress--title .label {
  font-size: 16px !important;
}

progress::-moz-progress-bar {
  @apply bg-secondary-500;
  height: 3px;
}

progress::-webkit-progress-value {
  @apply bg-secondary-500;
  height: 3px;
}

progress::-webkit-progress-bar {
  background-color: #e5ecf0;
  height: 3px;
  width: 100%;
}

progress {
  background-color: #e5ecf0;
  height: 3px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  @apply mb-2;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
}
</style>
