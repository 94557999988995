<template>
  <div>
    <UsedSearchVehicleResultCard
      v-if="used"
      :showUsedTag="true"
      :vehicle="vehicle"
      :id="vehicle.vin"
      :compare="compare"
    ></UsedSearchVehicleResultCard>
    <SearchVehicleResultCard
      v-else
      :vehicle="vehicle"
      :id="vehicle.vin"
      :compare="compare"
    ></SearchVehicleResultCard>
  </div>
</template>

<script>
import {
  SearchVehicleResultCard,
  UsedSearchVehicleResultCard
} from "../../organisms";
export default {
  components: { SearchVehicleResultCard, UsedSearchVehicleResultCard },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    vehicle: {
      type: Object
    },
    tooltipIcon: {
      type: String,
      default: "info-circle"
    },
    id: {
      type: String,
      default: ""
    },
    compare: {
      type: Boolean,
      default: false
    } /* 
    index: {
      type: Number,
      required: true
    } */
  },
  computed: {
    used() {
      return this.vehicle?.condition && this.vehicle?.condition != "Novo";
    }
  }
};
</script>

<style lang="css" scoped src="./VehicleResultCardWrapper.css"></style>
