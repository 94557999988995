const FavoritesMixin = {
  methods: {
    toggleFavorite(vehicle) {
      if (this.$store.getters.isGuest) {
        return (this.$store.state.isOpenSigninPopup = true);
      }

      this.$store.commit("FavoritesModule/openPanel");

      const existingFavorite = this.$store.getters["FavoritesModule/exists"](
        vehicle
      );

      existingFavorite
        ? this.$store.dispatch("FavoritesModule/removeFromFavorites", vehicle)
        : this.$store.dispatch("FavoritesModule/addToFavorites", vehicle);

      this.$toast.open({
        message: existingFavorite
          ? this.$t("toast.favorites.removedFavorite")
          : this.$t("toast.favorites.addedFavorite"),
        dismissible: true,
        type: "info"
      });
    }
  }
};

export { FavoritesMixin };
