<template>
  <div class="slideshow">
    <div class="slideshow--header">
      <div class="slideshow--header-relative">
        <div class="slideshow--header-title">
          <Heading
            class="h2-title"
            :label="images[currentPosition].title"
            tag="h2"
            weight="bold"
          />
        </div>

        <div class="slideshow--header-content">
          <div class="slideshow--header-content-button">
            <Button
              class="button-left"
              @onClick="previous"
              icon="chevron-left"
              :fill="true"
              bgColor="white"
              type="primary"
              :border="false"
            ></Button>
          </div>

          <div class="slideshow--header-content-center">
            <div class="slideshow--header-content-title">
              <Label size="medium" color="primary" bold="bold">{{
                images[currentPosition].subtitle
              }}</Label>
            </div>
            <div class="slideshow--header-content-subtitle">
              <Label size="small" color="tertiary">{{
                images[currentPosition].description
              }}</Label>
            </div>
          </div>

          <div class="slideshow--header-content-button">
            <Button
              class="button-right"
              @onClick="next"
              icon="chevron-right"
              :fill="true"
              bgColor="white"
              type="primary"
              :border="false"
            ></Button>
          </div>
        </div>
      </div>
    </div>
    <div class="slideshow--wrapper"></div>
  </div>
</template>

<script>
import { Label, Button } from "../../atoms";
import { Heading } from "@sc/ds-ui-component-library";
export default {
  name: "Slideshow",
  components: {
    Label,
    Button,
    Heading
  },
  data() {
    return {
      currentPosition: 0
    };
  },
  props: {
    images: {
      type: Array[String],
      default: []
    }
  },
  methods: {
    next() {
      if (this.currentPosition < this.images.length - 1) {
        this.currentPosition++;
      } else {
        this.currentPosition = 0;
      }
    },
    previous() {
      if (this.currentPosition > 0) {
        this.currentPosition--;
      } else {
        this.currentPosition = this.images.length - 1;
      }
    }
  },
  computed: {
    filename() {
      return (
        this.$t("resources.images.homepage.slideshowCover") ||
        require("../../../assets/images/slideshow-cover.webp")
      );
    }
  }
};
</script>

<style lang="css" scoped src="./Slideshow.css"></style>
