import httpClient from "./httpClient";

const USER_REGISTER = "/users/register";

const USER_LOGIN = "/users/login";

const USER_ME = "/users/me";

const USER_LOGOUT = "/users/logout";

const USER_GUEST = "/users/guest";

const USER_UPDATE = "/users/me/personal";

const USER_ACTIVATION_CODE = "/users/activation-code";

function registerUser(userObj) {
  // const payload = {
  //   email: userObj.email,
  //   name: userObj.name,
  //   phoneNumber: userObj.phone,
  //   password: userObj.password
  // };

  return httpClient.post(USER_REGISTER, userObj, { validateStatus: false });
}

function loginUser(userObj) {
  return httpClient.post(USER_LOGIN, userObj, {
    headers: { Authorization: "" }
  });
}

function logoutUser() {
  return httpClient.get(USER_LOGOUT);
}

function getUserInfo() {
  return httpClient.get(USER_ME);
}

function getGuestToken() {
  return httpClient.get(USER_GUEST, {
    headers: { Authorization: "" }
  });
}

function updatePersonalData(payload) {
  return httpClient.put(USER_UPDATE, payload);
}

function resendActivationCode(payload) {
  return httpClient.post(USER_ACTIVATION_CODE, payload);
}

export {
  registerUser,
  loginUser,
  getUserInfo,
  logoutUser,
  getGuestToken,
  updatePersonalData,
  resendActivationCode
};
