var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['footer', _vm.classes, _vm.customClass],attrs:{"styles":_vm.customStyles}},[_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"footer--left"},[_c('img',{staticClass:"logo pointer",attrs:{"src":require(`../../../assets/images/${_vm.filenameLogo}`),"width":"78px","height":"40px"},on:{"click":function($event){return _vm.redirectTo('home')}}})]),_c('div',{staticClass:"footer--center"},[_c('div',{staticClass:"footer--inner-container"},[_c('div',{staticClass:"footer--navigation"},_vm._l((_vm.footerList),function(category,index){return _c('div',{key:index,staticClass:"footer--navigation-pages"},[_c('Link',{attrs:{"size":"small","color":"primary","bold":"extra-bold","customClass":"footer--title","uppercase":true,"label":category[0].title,"href":category[0].url}}),(false)?_c('LinkList',{staticClass:"footer--link-list",attrs:{"display":"vertical","size":"small","bold":"extra-light","color":"secondary","list":category[0].list}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"footer--right"},[_c('div',{staticClass:"footer--right-title"},[_c('Label',{attrs:{"size":"small"}},[_vm._v("Siga-nos em:")])],1),_c('div',{staticClass:"footer--right-images"},[_c('div',[_c('div',{staticClass:"pointer icon",style:({
              backgroundImage: `url(${require('../../../assets/icons/Facebook.svg')})`
            }),on:{"click":function($event){return _vm.redirectTo('facebook')}}})]),_c('div',[_c('div',{staticClass:"pointer icon",style:({
              backgroundImage: `url(${require('../../../assets/icons/Youtube.svg')})`
            }),on:{"click":function($event){return _vm.redirectTo('youtube')}}})]),_c('div',[_c('div',{staticClass:"pointer icon",style:({
              backgroundImage: `url(${require('../../../assets/icons/Instagram.svg')})`
            }),on:{"click":function($event){return _vm.redirectTo('instagram')}}})])])])]),_c('div',{staticClass:"footer--copyright-message"},[_c('div',{staticClass:"footer--copyright-message-left"},[_c('Label',{attrs:{"color":"tertiary","size":"x-small"}},[_vm._v(_vm._s(_vm.copyrightMessage))])],1),_c('div',{staticClass:"footer--copyright-message-right"},_vm._l((_vm.subfooterList),function(list,index){return _c('div',{key:index,staticClass:"subfooter-link"},[_c('Link',{class:_vm.linkClass(index),attrs:{"size":"small","color":"tertiary","label":list.title,"href":index == 1 ? '#' : list.url}})],1)}),0)]),_c('div',{staticClass:"legal-mention"},[_c('Label',{attrs:{"color":"tertiary","size":"small"}},[_vm._v(_vm._s(_vm.$t("footer.legalMention"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }