<template>
  <div class="saved-card">
    <div class="saved-card--header">
      <div class="saved-card--header-content">
        <slot name="header"></slot>
      </div>
      <div class="saved-card--header-close">
        <Button
          v-if="showButton"
          :border="false"
          icon="times"
          size="small"
          @onClick="onDelete"
        ></Button>
      </div>
    </div>
    <div class="saved-card--header-footer">
      <div class="saved-card--header-footer-left">
        <slot name="footer"></slot>
      </div>
      <div class="saved-card--header-footer-button">
        <Button
          :label="labelFooterButton"
          uppercase
          type="primary"
          :fill="false"
          @onClick="onClick"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "../../atoms";
export default {
  name: "SavedCard",
  components: {
    Button
  },
  props: {
    labelFooterButton: {
      type: String,
      default: "Button"
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
    onDelete() {
      this.$emit("onDelete");
    }
  }
};
</script>

<style lang="css" scoped src="./SavedCard.css"></style>
