<template>
  <div class="price-search-details">
    <div
      class="price-search-details--button-container"
      :class="{ 'selected-left': !isPriceSelected }"
    >
      <StringToggler
        :isPriceSelected="isPriceSelected"
        :left="
          $t('pages.searchpage.searchform.dropdownMonthlyAndPrice.monthly')
        "
        :right="$t('pages.searchpage.searchform.dropdownMonthlyAndPrice.price')"
        @onSelected="switchClickHandler"
      />
    </div>
    <div class="price-search-details--content">
      <div class="price-search--container" v-if="!isPriceSelected">
        <div class="price-search-details--content-left">
          <RangeInput
            class="mb-5"
            :label="topRangeLabel"
            sliderType="secondary"
            :sliderValue="topRangeValue"
            :sliderMax="topRangeMax"
            :sliderMin="topRangeMin"
            :sliderInterval="topRangeStep"
            @onChange="onChangeRangeHandler($event, 'top')"
          />
          <RangeInput
            class="mb-5 show-on-mobile"
            :label="bottomRangeLabel"
            sliderType="secondary"
            :sliderValue="bottomRangeValue"
            :sliderMax="bottomRangeMax"
            :sliderMin="bottomRangeMin"
            :sliderInterval="bottomRangeStep"
            @onChange="onChangeRangeHandler($event, 'bottom')"
            :sliderDisabled="disableInitialEntrySlider"
          />
          <RateCard
            v-if="getReturns.length > 0 && !isGuest"
            class="mb-5 md:mb-0"
            :checkedToggle="checkedToggle"
            @onToggle="onToggleHandler"
            :resume="getReturns[0]"
            :border="true"
            filename="car.svg"
          ></RateCard>
          <RateCard
            v-else
            :subtitle="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.subtitle'
              )
            "
            class="mb-5 md:mb-0"
            :border="true"
            filename="car.svg"
            :description="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.description'
              )
            "
            :buttonLabel="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.buttonLabel'
              )
            "
            @onClick="onClickRateCardRetakeHandler"
          ></RateCard>
        </div>
        <div class="price-search-details--content-right">
          <RangeInput
            class="mb-5 hide-on-mobile"
            :label="bottomRangeLabel"
            sliderType="secondary"
            :sliderValue="bottomRangeValue"
            :sliderMax="bottomRangeMax"
            :sliderMin="bottomRangeMin"
            :sliderInterval="bottomRangeStep"
            @onChange="onChangeRangeHandler($event, 'bottom')"
            :sliderDisabled="disableInitialEntrySlider"
          />
        </div>
      </div>
      <div class="price-search-details--content-left-justify" v-else>
        <div class="price-search-details--price mb-5 md:mb-0">
          <div class="price-search-details--price-label">
            <Label size="small" color="primary" bold="normal" :uppercase="true"
              >{{ rightButtonLabel }}
            </Label>
          </div>

          <div class="price-search-details--input-wrapper">
            <Input
              customClass="price-search-details--input"
              name="startPrice"
              icon="euro-sign"
              type="secondary"
              placeholder="0"
              :value="priceMin"
              @onChange="onChangeInputHandler($event, 'left')"
            />
            <span class="price-search-details--hifen"> - </span>
            <Input
              customClass="price-search-details--input"
              name="endPrice"
              icon="euro-sign"
              type="secondary"
              placeholder="0"
              :value="priceMax"
              @onChange="onChangeInputHandler($event, 'right')"
            />
          </div>
        </div>

        <div class="price-search-details--rate-card">
          <RateCard
            v-if="getReturns.length > 0 && !isGuest"
            :checkedToggle="checkedToggle"
            @onToggle="onToggleHandler"
            :buttonLabel="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCard.buttonLabel'
              )
            "
            :resume="getReturns[0]"
            :border="true"
            filename="car.svg"
          ></RateCard>
          <RateCard
            v-else
            :subtitle="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.subtitle'
              )
            "
            class="mb-5 md:mb-0"
            :border="true"
            filename="car.svg"
            :description="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.description'
              )
            "
            :buttonLabel="
              $t(
                'pages.searchpage.searchform.dropdownMonthlyAndPrice.rateCardRetakeAsk.buttonLabel'
              )
            "
            @onClick="onClickRateCardRetakeHandler"
          ></RateCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Input, StringToggler } from "../../atoms";
import { mapGetters } from "vuex";
import { RangeInput } from "../../molecules";
import { RateCard } from "../../organisms";
import { PAGES } from "@/enums/pages";

function debounce(f, delay) {
  let timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    const args = arguments;
    const ctx = this;
    timeoutID = setTimeout(function() {
      f.apply(ctx, args);
    }, delay);
  };
}

export default {
  data() {
    return {
      includeRetake: false,
      priceMin: 0,
      priceMax: 0
    };
  },
  components: {
    RangeInput,
    Label,
    Input,
    RateCard,
    StringToggler
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    leftButtonLabel: {
      type: String,
      default: ""
    },
    rightButtonLabel: {
      type: String,
      default: ""
    },
    topRangeLabel: {
      type: String,
      default: ""
    },
    topRangeValue: {
      type: Number,
      default: 0
    },
    topRangeMax: {
      type: Number
    },
    topRangeMin: {
      type: Number
    },
    topRangeStep: {
      type: Number
    },
    bottomRangeLabel: {
      type: String,
      default: ""
    },
    bottomRangeValue: {
      type: Number
    },
    bottomRangeMax: {
      type: Number
    },
    bottomRangeMin: {
      type: Number
    },
    bottomRangeStep: {
      type: Number
    },
    leftInputPlaceholder: {
      type: String,
      default: "Placeholder"
    },
    rightInputPlaceholder: {
      type: String,
      default: "Placeholder"
    },
    tipLabel: {
      type: String,
      default: ""
    },
    tipLinkLabel: {
      type: String,
      default: ""
    },
    tipLink: {
      type: String,
      default: "#"
    },
    boxTitle: {
      type: String,
      default: ""
    },
    boxText: {
      type: String,
      default: ""
    },
    boxButtonLabel: {
      type: String,
      default: ""
    },
    resumeObj: {
      type: Object
    },
    checkedToggle: {
      type: Boolean
    }
  },
  methods: {
    switchClickHandler() {
      this.$store.dispatch("SearchModule/filter/togglePriceSelected");
    },
    onChangeRangeHandler: debounce(function(event, rangeUsed) {
      // this.$store.commit("setIncludeRetake", false);
      this.$store.commit("setLastDepositValue", event);
      this.$emit("onChangeRange", { range: rangeUsed, value: event });
    }, 200),
    onChangeInputHandler: debounce(function(event, inputUsed) {
      if (inputUsed === "left") this.priceMin = event;
      else this.priceMax = event;
      this.$emit("onChangeInput", {
        min: this.priceMin,
        max: this.priceMax
      });
    }, 200),
    onClickButtonHandler() {
      this.$emit("onClickButton");
    },
    onToggleHandler(event) {
      this.$store.commit("setIncludeRetake", event);

      // Add or Sub Retake value from depositValue in finance store
      const val = this.getIncludeRetake
        ? this.getReturnValue
        : this.getLastDepositValue;

      this.$store.commit("setDepositValue", val);

      // FIXME: all this is probably not needed
      /*      this.includeRetake = event;
      this.includeRetake === true
        ? sessionStorage.setItem("retake", JSON.stringify(this.resumeObj))
        : sessionStorage.removeItem("retake");*/
      this.$emit("onToggle", event);
    },
    onClickRateCardRetakeHandler() {
      this.$router.push({ name: PAGES.RETAKE });
    },
    multiples(price) {
      // multiples of getMonthlyStep
      const trunc = Math.trunc(price / this.getMonthlyStep);
      return trunc * this.getMonthlyStep;
    }
  },
  computed: {
    ...mapGetters([
      "getReturns",
      "getReturnValue",
      "getDepositValue",
      "getLastDepositValue",
      "getIncludeRetake",
      "getPvpMin",
      "getPvpMax",
      "isGuest",
      "getMonthlyStep"
    ]),
    ...mapGetters({
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown",
      isPriceSelected: "SearchModule/filter/isPriceSelected"
    }),
    classes() {
      return {};
    },
    disableInitialEntrySlider() {
      let flag = false;
      if (this.getIncludeRetake) {
        flag = this.getReturnValue > this.bottomRangeMax ? true : false;
      }
      return flag;
    }
  }
};
</script>

<style lang="css" scoped src="./PriceSearchDetails.css"></style>
