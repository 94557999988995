<template>
  <div class="segment">
    <input @change="onChange" type="checkbox" :id="id" />
    <div class="segment--content">
      <div class="segment--image">
        <img
          :src="require(`../../../assets/images/${filename}`)"
          width="56px"
        />
      </div>
      <div class="segment--title">
        <Label size="small" color="primary">{{ title }}</Label>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  name: "Segment",
  components: {
    Label
  },
  props: {
    id: {
      type: String,
      default: "segment"
    },
    title: {
      type: String,
      default: "SUVs"
    },
    filename: {
      type: String,
      default: "sport-utilities.png"
    }
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event.target.checked);
    }
  }
};
</script>

<style lang="css" scoped src="./Segment.css"></style>
