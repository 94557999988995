<template>
  <div
    id="right-sidebar"
    :class="['right-sidebar', customClass]"
    :style="customStyles"
  >
    <div class="right-sidebar--header">
      <svg
        @click="closeRightSideBar"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <g id="Close" transform="translate(-325 -89)">
          <rect
            id="Bg"
            width="40"
            height="40"
            transform="translate(325 89)"
            fill="#fff"
            opacity="0"
          />
          <path
            id="Icon"
            d="M9.286,7.207A1.47,1.47,0,1,0,7.207,9.286l8.491,8.49-8.491,8.49a1.47,1.47,0,1,0,2.079,2.079l8.491-8.49,8.49,8.49a1.47,1.47,0,1,0,2.079-2.079l-8.491-8.49,8.49-8.49a1.47,1.47,0,1,0-2.079-2.079L17.776,15.7,9.286,7.206Z"
            transform="translate(327.224 91.224)"
            fill="#032c5f"
          />
        </g>
      </svg>

      <div class="right-sidebar--header-title">
        <Label
          customClass="right-sidebar--title"
          color="primary"
          size="small"
          bold="bold"
          >{{ title }}</Label
        >
        <Label color="tertiary" size="x-small" bold="normal">{{
          description
        }}</Label>
      </div>
    </div>
    <div class="right-sidebar--content">
      <slot name="content"></slot>
    </div>
    <!-- FIXME: content content slot is overriding css class.  -->
    <!-- .right-sidebar--content uses grid instead of children being a grid -->
    <div class="right-sidebar--no-content">
      <slot name="no-content"></slot>
    </div>
    <div class="right-sidebar--footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  name: "RightSidebar",
  components: {
    Label
  },
  data() {
    return {};
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Title"
    },
    description: {
      type: String,
      default: "Description"
    },
    opened: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeRightSideBar() {
      // document.getElementById("page").classList.remove("background-filter");
      // document
      //   .getElementById("navbar-page")
      //   .classList.remove("background-filter");
      // document
      //   .getElementById("footer-page")
      //   .classList.remove("background-filter");
      // document.getElementById("right-sidebar").classList.remove("no-filter");

      // this.opened = false;
      this.$emit("onClose");
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    },
    isOpened() {
      // if (this.opened) {
      //   document.getElementById("page").classList.add("background-filter");
      //   document
      //     .getElementById("navbar-page")
      //     .classList.add("background-filter");
      //   document
      //     .getElementById("footer-page")
      //     .classList.add("background-filter");
      //   document.getElementById("page").classList.add("background-filter");

      //   document.getElementById("right-sidebar").classList.add("no-filter");
      // } else {
      //   document.getElementById("page").classList.remove("background-filter");
      //   document
      //     .getElementById("navbar-page")
      //     .classList.remove("background-filter");
      //   document
      //     .getElementById("footer-page")
      //     .classList.remove("background-filter");
      //   document.getElementById("page").classList.remove("background-filter");
      //   document.getElementById("right-sidebar").classList.remove("no-filter");
      // }
      return this.opened;
    }
  },
  mounted() {
    // document.getElementById("page").classList.remove("background-filter");
    // document
    //   .getElementById("navbar-page")
    //   .classList.remove("background-filter");
    // document
    //   .getElementById("footer-page")
    //   .classList.remove("background-filter");
    // document.getElementById("page").classList.remove("background-filter");
    // document.getElementById("right-sidebar").classList.remove("no-filter");
  }
  // watch: {
  //   opened: {
  //     immediate: true,
  //     handler(value) {
  //       if (value === true) {
  //         document.getElementById("page").classList.add("background-filter");
  //         document
  //           .getElementById("navbar-page")
  //           .classList.add("background-filter");
  //         document
  //           .getElementById("footer-page")
  //           .classList.add("background-filter");
  //         document.getElementById("page").classList.add("background-filter");

  //         document.getElementById("right-sidebar").classList.add("no-filter");
  //       } else {
  //         document.getElementById("page").classList.remove("background-filter");
  //         document
  //           .getElementById("navbar-page")
  //           .classList.remove("background-filter");
  //         document
  //           .getElementById("footer-page")
  //           .classList.remove("background-filter");
  //         document.getElementById("page").classList.remove("background-filter");
  //         document
  //           .getElementById("right-sidebar")
  //           .classList.remove("no-filter");
  //       }
  //     }
  //   }
  // }
};
</script>

<style lang="css" scoped src="./RightSidebar.css"></style>
