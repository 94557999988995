<template>
  <CpInput
    ref="inputRef"
    :value="parsedValue"
    icon="calendar"
    :label="defaultLabel"
    :validator="validator"
    :disabled="disabled"
  />
</template>

<script>
import dayjs from "dayjs";
import LABEL_TYPE from "../../utils/labelType";
import CpInput from "./CpInput.vue";

export default {
  name: "CpDatePicker",
  components: { CpInput },
  props: {
    value: {
      type: [String, Date, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validator(e) {
      const re = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/gm;
      if (re.test(e)) {
        this.$emit("onChange", e);
        return [LABEL_TYPE.SUCCESS, ""];
      }
      return [LABEL_TYPE.ERROR, this.$t("labels.errors.datepicker")];
    }
  },
  computed: {
    defaultLabel() {
      return this.label || this.$t("labels.datepicker");
    },
    parsedValue() {
      const utcRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
      const alreadyInDateFormat = utcRegex.test(this.value);
      if (alreadyInDateFormat) {
        const correctDateString = dayjs(this.value).format("DD/MM/YYYY");
        this.$emit("onChange", correctDateString);
        return correctDateString;
      }
      return this.value;
    }
  }
};
</script>

<style scoped>
:deep(.container) {
  min-width: 300px;
}

:deep(input[type="date"]) {
  appearance: none;
}
</style>
