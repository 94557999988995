<template>
  <CpInput
    ref="inputRef"
    :value="value"
    :label="$t('labels.zipcode')"
    :validator="validator"
  />
</template>

<script>
import LABEL_TYPE from "../../utils/labelType";
import CpInput from "./CpInput.vue";

export default {
  name: "CpZipCode",
  components: { CpInput },
  props: ["value"],
  methods: {
    validator(e) {
      const re = /^\d{4}-\d{3}$/;
      this.$emit("onChange", e);
      if (re.test(e)) return [LABEL_TYPE.SUCCESS, ""];
      return [LABEL_TYPE.ERROR, this.$t("labels.errors.zipcode")];
    }
  }
};
</script>
