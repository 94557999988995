<template>
  <div>
    <Spinner :loading="isLoading"></Spinner>
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase
        >Pesquisas Guardadas</Label
      >
    </div>
    <div class="content" v-if="loaded">
      <div v-if="getFormattedSearch.length > 0">
        <div v-for="(search, index) in formattedSearch" :key="index">
          <SavedSearch
            class="saved-search"
            :search="search"
            @onDelete="remove(search.id)"
            @onSearch="resumeSearch"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          title="Upss... Nenhuma pesquisa guardada."
          description="Guarde pesquisas através da página 'pesquisar viaturas'."
          labelButton="Pesquisar Viaturas"
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../../atoms";
import { Spinner } from "../../../molecules";
import { SavedSearch, NoResults } from "../../../organisms";
import { mapGetters } from "vuex";
import { PAGES } from "@/enums/pages";

export default {
  components: {
    SavedSearch,
    Label,
    NoResults,
    Spinner
  },
  data() {
    return {
      loaded: false
    };
  },
  metaInfo() {
    return {
      title: this.$t("meta.savedSearch.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.savedSearch.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.savedSearch.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.savedSearch.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.savedSearch.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.savedSearch.image")
        }
      ]
    };
  },
  methods: {
    remove(id) {
      this.$store.dispatch("SearchModule/deleteSearch", id);
    },
    resumeSearch(tags) {
      var conditionTag = tags.find(tag => tag.type == "condition");
      this.$store.commit("SearchModule/filter/setTags", tags);
      if (conditionTag) {
        if (Number(conditionTag.id) == 1) {
          this.$router.push({
            name: PAGES.SEARCH
          });
        } else {
          this.$router.push({
            name: PAGES.SEARCH_USED
          });
        }
      } else {
        this.$router.push({
          name: PAGES.SEARCH
        });
      }
    },
    onClickNoResultsHandler() {
      this.$router.push({
        name: PAGES.SEARCH
      });
    }
  },
  computed: {
    ...mapGetters({
      getFormattedSearch: "SearchModule/getFormattedSearch",
      isLoading: "spinnerModule/isLoading"
    }),
    formattedSearch() {
      return this.getFormattedSearch.filter(search => search.tags.length > 0);
    }
  },
  async mounted() {
    var dropdownFetched,
      searchesFetched = false;
    this.$store.commit("spinnerModule/setLoading", true, { root: true });
    this.$store.dispatch("SearchModule/dropdown/fetchDropdowns").finally(() => {
      dropdownFetched = true;
      if (dropdownFetched && searchesFetched) {
        this.$store.commit("spinnerModule/setLoading", false, { root: true });
        this.loaded = true;
      }
    });
    this.$store.dispatch("SearchModule/getSearches").finally(() => {
      searchesFetched = true;
      if (dropdownFetched && searchesFetched) {
        this.$store.commit("spinnerModule/setLoading", false, { root: true });
        this.loaded = true;
      }
    });
  }
};
</script>

<style lang="css" scoped>
.saved-search {
  @apply mb-3;
}

.header {
  border-bottom: 2px solid #e4ecf0;
  @apply pb-5;
}

.content {
  @apply mt-5;
}

.page-title {
  font-size: 24px;
  @apply font-medium;
  @apply font-head;
}
</style>
