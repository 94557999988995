import { render, staticRenderFns } from "./VehicleDetailInformation.vue?vue&type=template&id=52f190c4&scoped=true"
import script from "./VehicleDetailInformation.vue?vue&type=script&lang=js"
export * from "./VehicleDetailInformation.vue?vue&type=script&lang=js"
import style0 from "./VehicleDetailInformation.css?vue&type=style&index=0&id=52f190c4&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f190c4",
  null
  
)

export default component.exports