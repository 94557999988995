import { render, staticRenderFns } from "./SavedCard.vue?vue&type=template&id=775f7156&scoped=true"
import script from "./SavedCard.vue?vue&type=script&lang=js"
export * from "./SavedCard.vue?vue&type=script&lang=js"
import style0 from "./SavedCard.css?vue&type=style&index=0&id=775f7156&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775f7156",
  null
  
)

export default component.exports