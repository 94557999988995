<template>
  <div class="additional-information">
    <div class="additional-information--title">
      <Label color="primary" size="medium" uppercase
        >Informações adicionais sobre o veículo</Label
      >
    </div>
    <div class="additional-information--subtitle">
      <Label color="tertiary" size="small"
        >Estes dados são de extrema importância para a avaliação do veículo. A
        não disponibilização dos mesmos condicionará a avaliação final.</Label
      >
    </div>
    <div class="additional-information--content">
      <div class="additional-information--content-left">
        <div class="additional-information--content-title">
          <Label color="tertiary" size="small">Financiamento de veículo</Label>
        </div>
        <div class="additional-information--content-list">
          <div
            class="additional-information--content-list-input"
            v-for="(item, index) in vehicleOperationList"
            :key="index"
          >
            <CheckboxLabel
              :title="item.text"
              :class="{ unchecked: !isItemChecked(index) }"
              :checked="isItemChecked(index)"
              type="primary"
              checkboxType="secondary"
              :fill="false"
              :fillOnChecked="true"
              @onChange="onChangeVehicleOperation(item.value, $event)"
            ></CheckboxLabel>
          </div>
        </div>
      </div>
      <div class="additional-information--content-right">
        <div class="additional-information--content-title">
          <Label color="tertiary" size="small">Acessórios Disponíveis</Label>
        </div>
        <div class="additional-information--content-list">
          <div
            class="additional-information--content-list-input"
            v-for="(item, index) in accessoriesList"
            :key="index"
          >
            <CheckboxLabel
              :disabled="disabled"
              :title="item.text"
              :checked="item.checked"
              type="primary"
              checkboxType="secondary"
              :fill="false"
              :fillOnChecked="true"
              @onChange="onChangeAccessories(item.value, $event)"
            ></CheckboxLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { CheckboxLabel } from "../../molecules";
import { mapGetters } from "vuex";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

export default {
  name: "AdditionalInformation",
  components: {
    Label,
    CheckboxLabel
  },
  data() {
    return {
      additionalInformations: {
        vehicleOperation: this.vehicleOperationList,
        accessories: this.accessoriesList
      },
      vehicleOperationSelected: this.getSelectedFinancingValue,
      retakeStatusEnum: RETAKESTATUS,
      changed: false
    };
  },
  props: {
    vehicleOperationList: {
      type: Array[Object]
    },
    accessoriesList: {
      type: Array[Object]
    }
  },
  methods: {
    isItemChecked(idx) {
      return this.additionalInformations.vehicleOperation[idx].checked;
    },
    onChangeVehicleOperation(value, event) {
      this.changed = true;
      this.additionalInformations.vehicleOperation = this.additionalInformations.vehicleOperation.map(
        op => {
          let check = false;
          if (op.value === value) check = event;
          return { ...op, checked: check };
        }
      );

      this.getSelectedValue === value
        ? this.$store.commit("setSelectedFinancingValue", "")
        : this.$store.commit("setSelectedFinancingValue", value);

      this.emitAdditionalInformation();
    },
    onChangeAccessories(value, event) {
      const eq = this.additionalInformations.accessories.findIndex(
        x => x.value === value
      );
      if (eq >= 0) {
        this.additionalInformations.accessories[eq].checked = event;
      }

      this.emitAdditionalInformation();
    },
    emitAdditionalInformation() {
      if (!this.changed)
        this.additionalInformations = this.getReturn?.tradeInPayload?.additionalInformation;
      console.log(this.additionalInformations);
      this.$emit("onAdditionalInformation", this.additionalInformations);
    },
    vehicleOperationChecked(item) {
      return !!this.getSelectedValue && item.value !== this.getSelectedValue;
    }
  },
  computed: {
    ...mapGetters(["getReturn", "getSelectedFinancingValue"]),
    disabled() {
      return (
        this.getReturn?.submitted &&
        this.getReturn?.status !== this.retakeStatusEnum.Q2_REFUSED
      );
    },
    getSelectedValue() {
      return this.getSelectedFinancingValue;
    }
  }
};
</script>

<style lang="css" scoped src="./AdditionalInformation.css"></style>
