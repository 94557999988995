<template>
  <SavedCard
    @onClick="onSearch"
    @onDelete="onDelete"
    :labelFooterButton="labelFooterButton"
  >
    <template v-slot:header>
      <div class="saved-return--header">
        <div class="saved-return--header-left">
          <div class="saved-return--header-state-plate">
            <div class="saved-return-plate" style="padding-bottom: 9px">
              <Label class="registration-plate" size="small" color="primary"
                ><span>{{
                  $t("pages.personalPage.returns.savedReturn.registrationPlate")
                }}</span>
                {{ formatePlate }}
              </Label>
            </div>

            <div class="saved-return-label-tag">
              <div
                class="saved-return-state"
                v-if="vehicle.actualValuation && vehicle.initialValuation"
              >
                <StateLabel
                  v-if="vehicle.status !== retakeStatusEnum.Q2_REFUSED"
                  :alertType="
                    retakeStatusMap[
                      retakeStatus == 5 &&
                      !vehicle.actualValuation &&
                      !vehicle.initialValuation
                        ? retakeStatus + 1
                        : retakeStatus
                    ].color
                  "
                >
                  {{
                    $t(
                      `pages.personalPage.returns.savedReturn.retakeState[${
                        retakeStatus == 5 &&
                        !vehicle.actualValuation &&
                        !vehicle.initialValuation
                          ? retakeStatus + 1
                          : retakeStatus
                      }]`
                    )
                  }}</StateLabel
                >
                <Tooltip
                  v-else
                  position="right"
                  :tooltip="
                    $t(
                      `pages.personalPage.returns.savedReturn.toolTipStatusDenied`
                    )
                  "
                >
                  <template v-slot:content>
                    <StateLabel alertType="red">
                      {{
                        $t(
                          `pages.personalPage.returns.savedReturn.retakeState[${retakeStatus}]`
                        )
                      }}</StateLabel
                    >
                  </template>
                </Tooltip>
              </div>
              <div
                v-if="expirationDateFlag && vehicle.actualValuation"
                class="saved-return-state-expiration-date"
              >
                <StateLabel alertType="orange">
                  {{
                    $t("pages.personalPage.returns.savedReturn.expirationLabel")
                  }}
                  {{ expirationDate }}</StateLabel
                >
              </div>
            </div>
          </div>
          <Label size="small" color="primary"
            ><span>{{
              $t("pages.personalPage.returns.savedReturn.brand")
            }}</span>
            {{ vehicle.brand }}</Label
          >
          <Label size="small" color="primary"
            ><span>{{
              $t("pages.personalPage.returns.savedReturn.model")
            }}</span>
            {{ vehicle.model }}</Label
          >
        </div>
        <div class="saved-return--header-right">
          <div class="saved-return--header-right-close">
            <div class="saved-return--header-right-close-value">
              <Label class="mb-2 vehicle-value" size="small" color="primary">{{
                $t("pages.personalPage.returns.savedReturn.value")
              }}</Label>
              <div class="saved-return--header-right-close-prices">
                <Label
                  v-if="showOldValue"
                  class="old-value"
                  size="medium"
                  color="tertiary"
                  bold="bold"
                  :lineThrough="true"
                  >{{ initialPriceLabel }}</Label
                >
                <Label
                  class="price"
                  style="text-align: right"
                  size="medium"
                  color="secondary"
                  bold="bold"
                  >{{ priceLabel }}</Label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="saved-return--footer-left">
        <Label size="small" color="primary"
          ><span>{{
            $t("pages.personalPage.returns.savedReturn.registrationDate")
          }}</span>
          {{ vehicle.month }} / {{ vehicle.year }}</Label
        >
        <Label v-if="vehicle.fuel" size="small" color="primary"
          ><span>{{ $t("pages.personalPage.returns.savedReturn.fuel") }}</span>
          {{ vehicle.fuel }}</Label
        >
        <Label v-if="vehicle.transmission" size="small" color="primary"
          ><span>{{
            $t("pages.personalPage.returns.savedReturn.transmission")
          }}</span>
          {{ vehicle.transmission }}</Label
        >
        <Label size="small" color="primary"
          ><span>{{ $t("pages.personalPage.returns.savedReturn.km") }}</span>
          {{ vehicle.kilometers }}</Label
        >
      </div>
    </template>
  </SavedCard>
</template>

<script>
import { SavedCard } from "../../organisms";
import { Label, Tooltip, StateLabel } from "../../atoms";
import { getPriceFormatted } from "../../../utils/currency.js";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

export default {
  name: "SavedReturn",
  data: function() {
    return {
      retakeStatusEnum: RETAKESTATUS
    };
  },
  metaInfo() {
    return {
      title: this.$t("meta.savedReturn.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.savedReturn.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.savedReturn.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.savedReturn.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.savedReturn.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.savedReturn.image")
        }
      ]
    };
  },
  components: {
    SavedCard,
    Label,
    Tooltip,
    StateLabel
  },
  props: {
    vehicle: {
      type: Object
    },
    retakeStatus: {
      type: Number,
      default: -1
    }
  },
  methods: {
    onSearch() {
      this.$emit("onSearch");
    },
    onDelete() {
      this.$emit("onDelete");
    }
  },
  computed: {
    expirationDateFlag() {
      return (
        this.vehicle.status !== this.retakeStatusEnum.EXPIRED &&
        this.vehicle.status !== this.retakeStatusEnum.Q2_REFUSED &&
        this.vehicle.status !== this.retakeStatusEnum.FINALIZED
      );
    },
    expirationDate() {
      var createdDate = new Date(this.vehicle.createTime);
      const daysToExpire = 30;
      createdDate.setDate(createdDate.getDate() + daysToExpire);
      var expirationDate = new Date(createdDate);
      const expirationDateStr =
        expirationDate.getDate() +
        "/" +
        (expirationDate.getMonth() + 1) +
        "/" +
        expirationDate.getFullYear();

      return expirationDateStr;
    },
    showOldValue() {
      return (
        (this.vehicle.status === this.retakeStatusEnum.Q1_UPDATED ||
          this.vehicle.status === this.retakeStatusEnum.FINALIZED ||
          this.vehicle.status === this.retakeStatusEnum.Q2_PENDING ||
          this.vehicle.status === this.retakeStatusEnum.Q2_REFUSED) &&
        !this.equalValuations &&
        !!this.vehicle?.initialValuation
      );
    },
    equalValuations() {
      return (
        this.vehicle?.initialValuation === this.vehicle?.actualValuation ||
        (this.vehicle.status === this.retakeStatusEnum.FINALIZED &&
          this.vehicle.actualValuation === this.vehicle.actualValuation)
      );
    },
    initialPriceLabel() {
      return getPriceFormatted(
        this.vehicle?.status === this.retakeStatusEnum.FINALIZED
          ? this.vehicle?.actualValuation
          : this.vehicle?.initialValuation,
        {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      );
    },
    priceLabel() {
      const value =
        this.vehicle?.actualValuation || this.vehicle?.initialValuation;

      return value === "0" || value === 0
        ? this.$t("pages.retake.emptyValue")
        : getPriceFormatted(this.vehicle?.actualValuation, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    },
    formatePlate() {
      return (
        this.vehicle.licensePlate.substring(0, 2) +
        "-" +
        this.vehicle.licensePlate.substring(2, 4) +
        "-" +
        this.vehicle.licensePlate.substring(4, 6)
      );
    },
    labelFooterButton() {
      return this.retakeStatus === RETAKESTATUS.EXPIRED
        ? this.$t(
            "pages.personalPage.returns.savedReturn.retakeDetailsBtn.reevaluate"
          )
        : this.$t(
            "pages.personalPage.returns.savedReturn.retakeDetailsBtn.retakeEval"
          );
    },
    retakeStatusMap() {
      return {
        1: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[1]"
          ),
          color: "blue"
        },
        2: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[2]"
          ),
          color: "red"
        },
        3: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[3]"
          ),
          color: "blue"
        },
        4: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[4]"
          ),
          color: "blue"
        },
        5: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[5]"
          ),
          color: "red"
        },
        6: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[6]"
          ),
          color: "red"
        },
        7: {
          label: this.$t(
            "pages.personalPage.returns.savedReturn.retakeState[7]"
          ),
          color: "red"
        }
      };
    }
  }
};
</script>

<style lang="css" scoped src="./SavedReturn.css"></style>
