<template>
  <div />
</template>

<script>
import { EventBus } from "../../../utils/eventbus.js";

export default {
  name: "FormStep",
  props: {
    step: {
      type: Number,
      default: 0
    },
    validate: {
      type: Function,
      default: () => []
    }
  },
  methods: {
    runValidation(step) {
      if (step !== this.step) return;
      if (!this.validate()) {
        return console.log("validation failed");
      }
      EventBus.$emit("valid", step);
    }
  },
  mounted() {
    EventBus.$on("validate", this.runValidation);
  },
  beforeDestroy() {
    EventBus.$off("validate");
  }
};
</script>
