<template>
  <div class="rate-card" :class="['rate-card', classes]">
    <div v-if="title" class="rate-card--title">
      <Label color="primary" size="small">{{ title }}</Label>
    </div>
    <div class="rate-card--content">
      <div class="rate-card--content-image">
        <div
          :style="{
            backgroundImage: `url(${require('../../../assets/images/' +
              filename)})`
          }"
          class="icon"
        />
      </div>
      <div v-if="!resume" class="rate-card--content-description">
        <Label v-if="subtitle" class="subtitle" color="primary">{{
          subtitle
        }}</Label>
        <Label class="description" color="tertiary"> {{ description }}</Label>
      </div>

      <div v-if="resume" class="rate-card--content-resume">
        <div class="rate-card--content-resume-include">
          <div class="resume--title">
            <Label color="primary" size="small">Oferta de Retoma</Label>
          </div>
          <div class="resume--include">
            <Label class="include" color="tertiary" size="small">Incluir</Label>
            <Toggle
              :checked="checkedToggle"
              rounded
              :disabled="!resume.actualValuation"
              type="success"
              @onToggle="onToggle"
            ></Toggle>
          </div>
        </div>
        <div class="rate-card--content-resume-price mt-2">
          <div class="resume--title">
            <Label color="primary" size="small">{{ resume.model }}</Label>
          </div>
          <div class="resume--include">
            <Label color="secondary" size="small" bold="bold">{{
              formatePrice
            }}</Label>
          </div>
        </div>
        <div class="rate-card--content-resume-registration">
          <div class="resume--title mr-1">
            <Label color="primary" size="small">Matrícula: </Label>
          </div>
          <div class="resume--include">
            <Label color="tertiary" size="small">{{ formatePlate }}</Label>
          </div>
        </div>
        <div class="resume--expire-info">
          <Label color="tertiary" size="small">
            {{
              expirationDays > 0
                ? $t("pages.detailsPage.financing.rateCard.expireMessage", {
                    days: expirationDays
                  })
                : $t("pages.detailsPage.financing.rateCard.expiredMessage")
            }}
          </Label>
        </div>
      </div>
    </div>
    <div v-if="!resume" class="rate-card--button">
      <Button @onClick="onClick" uppercase :label="buttonLabel"></Button>
    </div>
  </div>
</template>

<script>
import { Label, Button, Toggle } from "../../atoms";
import { getPriceFormatted } from "../../../utils/currency";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";
export default {
  components: {
    Label,
    Button,
    Toggle
  },
  data() {
    return {
      retakeStatusEnum: RETAKESTATUS
    };
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    filename: {
      type: String,
      default: "car.jpg"
    },
    description: {
      type: String,
      default: "Description"
    },
    buttonLabel: {
      type: String,
      default: "Avaliar taxa de risco"
    },
    resume: {
      type: Object
    },
    checkedToggle: {
      type: Boolean
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPriceFormatted,
    onClick(event) {
      this.$emit("onClick", event);
    },
    onToggle(event) {
      this.$emit("onToggle", event);
    },
    getExpirationDate(date) {
      {
        date = new Date(date);
        const daysToExpire = 30;
        date.setDate(date.getDate() + daysToExpire);

        const expirationDate = new Date(date);
        const currentDate = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.round(
          Math.abs((expirationDate - currentDate) / oneDay)
        );

        return diffDays;
      }
    }
  },
  computed: {
    classes() {
      return {
        "card--border": this.border
      };
    },
    formatePlate() {
      return (
        this.resume.licensePlate.substring(0, 2) +
        "-" +
        this.resume.licensePlate.substring(2, 4) +
        "-" +
        this.resume.licensePlate.substring(4, 6)
      );
    },
    formatePrice() {
      const value =
        this.resume.status === this.retakeStatusEnum.Q1_UPDATED ||
        this.resume.status === this.retakeStatusEnum.FINALIZED
          ? this.resume.actualValuation
          : this.resume.initialValuation;

      return value === 0 || value === "0"
        ? this.$t("pages.retake.emptyValue")
        : getPriceFormatted(value, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    },
    expirationDays() {
      return this.resume.updateTime
        ? this.getExpirationDate(this.resume.updateTime)
        : this.getExpirationDate(this.resume.createTime);
    }
  }
};
</script>

<style lang="css" scoped src="./RateCard.css"></style>
