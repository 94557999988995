<template>
  <div class="customer-form">
    <div class="customer-form--section">
      <div class="customer-form--section-header">
        <Label color="primary" bold="thin" size="small">{{
          $t("pages.personalPage.personalData.customerForm.title")
        }}</Label>
        <div
          v-if="editPersonalData"
          class="customer-form--section-header-buttons"
        >
          <Button
            @onClick="cancelEditPersonalData"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonCancel')
            "
            size="small"
          ></Button>
          <Button
            @onClick="saveEditPersonalData"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonSave')
            "
            :fill="true"
            size="small"
          ></Button>
        </div>

        <Link
          v-else
          @onClick="clickHandlerPersonalData"
          :label="$t('pages.personalPage.personalData.customerForm.buttonEdit')"
          color="secondary"
          bold="thin"
          size="medium"
        />
      </div>
      <div class="customer-form--section-content">
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.name")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <CpInput
              customClass="custom--password"
              v-if="editPersonalData"
              :label="$t('pages.personalPage.personalData.customerForm.name')"
              :value="myPersonalName"
              :isRequired="true"
              name="name"
              inputType="text"
              :border="true"
              type="primary"
              @onChange="handleNameChange"
            />
            <Label v-else color="tertiary" bold="thin" size="small">{{
              name
            }}</Label>
            <div class="errors">
              <Label
                v-if="editPersonalData && errorName"
                color="secondary"
                bold="thin"
                size="small"
              >
                {{ errorName }}
              </Label>
            </div>
          </div>
        </div>
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.email")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <CpInput
              disabled
              custom-class="input-disable"
              v-if="editPersonalData"
              :value="email"
              @onChange="val => (email = val)"
              :isRequired="true"
              name="email"
              :label="$t('pages.personalPage.personalData.customerForm.email')"
              inputType="email"
              :border="true"
              type="primary"
            />
            <Label v-else color="tertiary" bold="thin" size="small">{{
              email
            }}</Label>
            <div
              style="
    margin-top: 5px;
"
            ></div>
          </div>
        </div>
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.phone")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <div class="phone">
              <InputField
                ref="phoneInput"
                mode="phone"
                v-if="editPersonalData"
                :value="phoneValue"
                :label="
                  $t('pages.personalPage.personalData.customerForm.phone')
                "
                @onInput="updatePhoneNumber"
              />
            </div>
            <Label
              v-if="!editPersonalData"
              color="tertiary"
              bold="thin"
              size="small"
              >{{ phone }}</Label
            >
            <div class="errors">
              <Label
                v-if="editPersonalData && errorPhone"
                color="secondary"
                bold="thin"
                size="small"
              >
                {{ errorPhone }}
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-form--section">
      <div class="customer-form--section-header">
        <Label color="primary" bold="thin" size="small">{{
          $t("pages.personalPage.personalData.customerForm.safety")
        }}</Label>
        <div v-if="editPassword" class="customer-form--section-header-buttons">
          <Button
            @onClick="cancelEditPassword"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonCancel')
            "
            size="small"
          ></Button>
          <Button
            @onClick="saveEditPassword"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonSave')
            "
            :fill="true"
            size="small"
          ></Button>
        </div>
        <Link
          v-else
          @onClick="clickHandlerPassword"
          :label="$t('pages.personalPage.personalData.customerForm.buttonEdit')"
          color="secondary"
          bold="thin"
          size="medium"
        />
      </div>
      <div
        :key="'passwordeditorkey' + passwordEditorKey"
        class="customer-form--section-content"
      >
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label
              v-if="!editPassword"
              color="primary"
              bold="thin"
              size="small"
              >{{
                $t("pages.personalPage.personalData.customerForm.password")
              }}</Label
            >
            <Label
              v-if="editPassword"
              color="primary"
              bold="thin"
              size="small"
              >{{
                $t("pages.personalPage.personalData.customerForm.atualPassword")
              }}</Label
            >
          </div>
          <div class="customer-form--section-content-input-input">
            <CpPassword
              customClass="custom--password"
              v-if="editPassword"
              :isRequired="true"
              icon="eye"
              @onIconClick="showPassword"
              name="password"
              :border="true"
              type="primary"
              :value.sync="myPassword"
              :inputType="myShowPassword ? 'text' : 'password'"
              @onChange="
                value => {
                  myPassword = value;
                }
              "
            />
          </div>
        </div>
        <div v-if="editPassword" class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.newPassword")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <CpPassword
              customClass="custom--password"
              v-if="editPassword"
              :isRequired="true"
              @onIconClick="showNewPassword"
              icon="eye"
              name="newPassword"
              :value.sync="myNewPassword"
              :inputType="myShowNewPassword ? 'text' : 'password'"
              :border="true"
              type="primary"
              @onChange="
                value => {
                  myNewPassword = value;
                }
              "
            />
          </div>
        </div>
        <div v-if="editPassword" class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t(
                "pages.personalPage.personalData.customerForm.confirmNewPassword"
              )
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <CpPassword
              v-if="editPassword"
              :isRequired="true"
              icon="eye"
              name="newPasswordConfirm"
              :value.sync="myNewPasswordConfirm"
              :inputType="myShowNewPasswordConfirm ? 'text' : 'password'"
              :border="true"
              type="primary"
              customClass="custom--password"
              @onIconClick="showNewPasswordConfirm"
              @onChange="
                value => {
                  myNewPasswordConfirm = value;
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="customer-form--section">
      <div class="customer-form--section-header">
        <Label color="primary" bold="thin" size="small">{{
          $t("pages.personalPage.personalData.customerForm.deliveryPlace")
        }}</Label>
        <div v-if="editAddress" class="customer-form--section-header-buttons">
          <Button
            @onClick="cancelEditAddress"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonCancel')
            "
            size="small"
          ></Button>
          <Button
            @onClick="saveEditAddress"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonSave')
            "
            :fill="true"
            size="small"
          ></Button>
        </div>
        <Link
          v-else
          @onClick="clickHandlerAddress"
          :label="$t('pages.personalPage.personalData.customerForm.buttonEdit')"
          color="secondary"
          bold="thin"
          size="medium"
        />
      </div>
      <div class="customer-form--section-content">
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.address")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <CpInput
              customClass="custom--password"
              v-if="editAddress"
              name="address"
              inputType="text"
              :border="true"
              label="Morada"
              type="primary"
              :value.sync="myAddress"
              @onChange="
                value => {
                  myAddress = value;
                }
              "
            />
            <Label v-else color="tertiary" bold="thin" size="small">{{
              address
            }}</Label>
            <div class="errors">
              <Label
                v-if="editAddress && errorAddress"
                color="secondary"
                bold="thin"
                size="small"
              >
                {{ errorAddress }}
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="customer-form--section">
      <div class="customer-form--section-header">
        <Label color="primary" bold="thin" size="small">{{
          $t("pages.personalPage.personalData.customerForm.favoriteConcession")
        }}</Label>
        <div
          v-if="editConcession"
          class="customer-form--section-header-buttons"
        >
          <Button
            @onClick="cancelEditConcession"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonCancel')
            "
            size="small"
          ></Button>
          <Button
            @onClick="saveEditConcession"
            type="primary"
            :label="
              $t('pages.personalPage.personalData.customerForm.buttonSave')
            "
            :fill="true"
            size="small"
          ></Button>
        </div>
        <Link
          v-else
          @onClick="clickHandlerConcession"
          :label="$t('pages.personalPage.personalData.customerForm.buttonEdit')"
          color="secondary"
          bold="thin"
          size="medium"
        />
      </div>
      <div class="customer-form--section-content">
        <div class="customer-form--section-content-input">
          <div class="customer-form--section-content-input-label">
            <Label color="primary" bold="thin" size="small">{{
              $t("pages.personalPage.personalData.customerForm.concession")
            }}</Label>
          </div>
          <div class="customer-form--section-content-input-input">
            <Select
              v-if="editConcession"
              id="district"
              :options="districtList"
              @onChange="onChangeDistrict"
            ></Select>
            <Select
              v-if="editConcession"
              id="concession"
              :options="concessionList"
              @onChange="onChangeDealer"
            ></Select>
            <Label v-else color="tertiary" bold="thin" size="small">{{
              getUserDealerMunicipality
            }}</Label>
            <div class="errors">
              <Label
                v-if="editConcession && errorConcession"
                color="secondary"
                bold="thin"
                size="small"
              >
                {{ errorConcession }}
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Link, Button, Select } from "../../atoms";
import CpPassword from "../../refactor/CpPassword.vue";
import CpInput from "../../refactor/CpInput.vue";
import { mapGetters } from "vuex";
import { InputField } from "@sc/ds-ui-component-library";

export default {
  components: {
    Label,
    Link,
    Button,
    Select,
    CpPassword,
    CpInput,
    InputField
  },
  data() {
    return {
      passwordEditorKey: 0,
      //
      editPersonalData: false,
      editPassword: false,
      editAddress: false,
      editConcession: false,
      //
      myShowPassword: false,
      myShowNewPassword: false,
      myShowNewPasswordConfirm: false,
      //
      myPersonalName: this.name,
      myPersonalEmail: this.email,
      phoneValue: "",
      isValidPhone: false,
      formatInternationalPhone: this.phone,
      //
      myPassword: "",
      myNewPassword: "",
      myNewPasswordConfirm: "",
      myAddress: this.address,

      dealersList: [],
      districtList: [],
      concessionList: [],
      myConcession: "",
      myDistrict: "",

      //
      errorName: "",
      errorPassword: "",
      errorNewPassword: "",
      errorNewPasswordConfirm: "",
      errorPhone: "",
      errorAddress: "",
      errorConcession: ""
    };
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    phone: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    },
    dealer: {
      type: String,
      default: ""
    },
    dealers: {
      type: Array[Object],
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["getReceipt", "getUserDealership", "getUserAddress"]),
    getUserDealerMunicipality() {
      const got = this.dealers.find(
        d => d.dealerCode == this.getUserDealership
      );
      return got
        ? `${got.designation} - ${got.municipality}`
        : this.$t("validators.noFavoriteConcession");
    }
  },
  methods: {
    handleNameChange(e) {
      this.myPersonalName = e;
    },
    clickHandlerPersonalData(event) {
      event.preventDefault();
      this.editPersonalData = true;
      this.clearVariables();
    },
    clickHandlerPassword(event) {
      event.preventDefault();
      this.editPassword = true;
      this.clearVariables();
    },
    clickHandlerAddress(event) {
      event.preventDefault();
      this.editAddress = true;
      this.clearVariables();
    },
    clickHandlerConcession(event) {
      event.preventDefault();
      this.editConcession = true;
      this.clearVariablesConcession();
    },
    cancelEditPersonalData() {
      this.editPersonalData = false;
      this.errorName = "";
      this.errorPhone = "";
      this.clearVariables();
    },
    cancelEditPassword() {
      this.editPassword = false;
      this.errorPassword = "";
      this.clearVariables();
    },
    cancelEditAddress() {
      this.editAddress = false;
      this.errorAddress = "";
      this.clearVariables();
    },
    cancelEditConcession() {
      this.editConcession = false;
      this.errorConcession = "";
      this.clearVariablesConcession();
    },
    saveEditPersonalData() {
      if (!this.myPersonalName) {
        this.errorName = this.$t("validators.requiredName");
        return;
      }

      this.errorName = "";

      if (!this.formatInternationalPhone) {
        this.errorPhone = this.$t("validators.requiredPhone");
        return;
      }

      if (!this.isValidPhone) {
        this.errorPhone = this.$t("validators.invalidPhone");
        return;
      }

      this.errorPhone = "";

      const personalData = {
        name: this.myPersonalName,
        phoneNumber: this.formatInternationalPhone
      };

      this.$emit("onSavePersonalData", personalData);
      this.editPersonalData = false;
      this.clearVariables();
    },
    saveEditPassword() {
      if (!this.myPassword) {
        this.errorPassword = this.$t("validators.requiredPassword");
      } else {
        this.errorPassword = "";
      }

      if (!this.validPassword(this.myPassword)) {
        this.errorPassword = this.$t("validators.validPassword");
        return;
      } else {
        this.errorPassword = "";
      }

      if (!this.validPassword(this.myNewPassword)) {
        this.errorNewPassword = this.$t("validators.validPassword");
        return;
      } else {
        this.errorNewPassword = "";
      }

      if (!this.myNewPasswordConfirm) {
        this.errorNewPasswordConfirm = this.$t(
          "validators.requiredPasswordConfirm"
        );
        return;
      } else if (
        !this.validMatchPasswords(this.myNewPassword, this.myNewPasswordConfirm)
      ) {
        this.errorNewPasswordConfirm = this.$t(
          "validators.invalidPasswordConfirm"
        );
        return;
      } else {
        this.errorNewPasswordConfirm = "";
      }

      const payload = {
        newPassword: this.myNewPassword,
        oldPassword: this.myPassword
      };

      this.$emit("onSavePassword", payload);
      this.editPassword = false;
      this.clearVariables();

      this.passwordEditorKey += 1;
    },
    saveEditAddress() {
      if (!this.myAddress) {
        this.errorAddress = this.$t("validators.requiredDeliveryPlace");
      }
      const payload = {
        address: this.myAddress
      };

      this.$emit("onSaveAddress", payload);
      this.editAddress = false;
      this.clearVariables();
    },
    saveEditConcession() {
      if (!this.myConcession) {
        this.errorConcession = this.$t("validators.requiredConcession");
      }

      let payload = {
        dealerCode: this.myConcession,
        district: this.myDistrict
      };

      this.$emit("onSaveConcession", payload);
      this.editConcession = false;
      this.clearVariablesConcession();
    },
    validMatchPasswords: function(p1, p2) {
      return p1 === p2;
    },
    validPassword: function(password) {
      let re = /^(?=(.*[a-z].*){1,})(?=(.*[A-Z].*){1,})(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,14}$/;
      return re.test(password);
    },
    showNewPasswordConfirm() {
      this.myShowNewPasswordConfirm = !this.myShowNewPasswordConfirm;
    },
    showNewPassword() {
      this.myShowNewPassword = !this.myShowNewPassword;
    },
    showPassword() {
      this.myShowPassword = !this.myShowPassword;
    },
    onChangeDistrict(event) {
      this.myDistrict = event;
      this.concessionList = this.dealers
        .filter(deal => deal.district === event)
        .map((fltVals, idx) => {
          return {
            text: fltVals.designation + " - " + fltVals.municipality,
            value: fltVals.dealerCode,
            selected: idx === 0
          };
        });
      this.myConcession = this.concessionList[0].value;
    },
    onChangeDealer(event) {
      this.myConcession = event;
    },
    updatePhoneNumber(phone) {
      this.formatInternationalPhone = phone[0];
      this.isValidPhone = phone[1];

      if (this.isValidPhone) {
        this.errorPhone = "";
      }
    },
    districtListMap() {
      this.dealersList = this.dealers;

      let userDealerShipObj;

      if (this.dealer) {
        userDealerShipObj = this.dealersList.find(value => {
          return value.dealerCode === this.dealer;
        });
      }

      const distinctDistricts = [
        ...new Set(this.dealersList.map(x => x.district))
      ];

      if (!userDealerShipObj) {
        this.districtList = distinctDistricts.map((d, idx) => {
          return {
            text: d,
            value: d,
            selected: idx === 0
          };
        });
      } else {
        this.districtList = distinctDistricts.map(d => {
          return {
            text: d,
            value: d,
            selected: d === userDealerShipObj.district
          };
        });
        this.myConcessionAddress = userDealerShipObj.address;
      }
    },
    concessionListMap() {
      let selectedDistrict = this.districtList.find(value => {
        return value.selected === true;
      });

      this.concessionList = this.dealersList
        .filter(val => {
          return val.district === selectedDistrict.value;
        })
        .map((val, idx) => {
          return {
            text: val.designation + " - " + val.municipality,
            value: val.dealerCode,
            selected: idx === 0
          };
        });
    },
    clearVariables() {
      this.myPersonalName = this.name;
      this.phoneValue = this.phone;
      this.myAddress = this.getUserAddress;
      this.myPassword = "";
      this.myNewPassword = "";
      this.myNewPasswordConfirm = "";
      this.isValidPhone = this.validatePhone(this.phoneValue);
    },
    clearVariablesConcession() {
      this.districtListMap();
      this.concessionListMap();
    },
    validatePhone(phone) {
      const re = /^(\+?351|(00)?351)?\s?9[2,6,3,1]\d\s?\d{3}\s?\d{3}$/;
      const valid = re.test(phone);
      return valid;
    }
  },
  beforeMount() {
    if (!this.dealers) {
      this.$store.dispatch("SearchModule/dropdown/fetchDropdowns");
    }
  },
  mounted() {
    this.districtListMap();
    this.concessionListMap();
    this.isValidPhone = this.validatePhone(this.phoneValue);
  }
};
</script>

<style lang="css" scoped src="./CustomerForm.css"></style>
