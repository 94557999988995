<template>
  <div class="multistep-form">
    <div class="form--progress">
      <ProgressBar
        :currentStep="currStep"
        :totalSteps="numberSteps"
      ></ProgressBar>
    </div>
    <div class="step-content">
      <slot />
    </div>
    <div class="button-container">
      <div class="button-container-inner">
        <div class="button-right" v-if="currStep != numberSteps">
          <Button
            :label="nextLabel"
            uppercase
            fill
            type="primary"
            size="large"
            @onClick="clickNext"
          />
        </div>
        <div class="button-right" v-else>
          <Button
            :label="submitLabel"
            uppercase
            fill
            type="primary"
            size="large"
            @onClick="onSubmit"
          />
        </div>
        <div class="button-left" v-if="currStep > 1">
          <Button
            :label="prevLabel"
            uppercase
            type="primary"
            size="large"
            @onClick="clickPrevious"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, ProgressBar } from "../../atoms";
import { EventBus } from "../../../utils/eventbus.js";

export default {
  name: "MultiStepForm",
  components: {
    Button,
    ProgressBar
  },
  props: {
    opened: {
      type: Boolean,
      default: true
    },
    numberSteps: {
      type: Number,
      default: 1
    },
    nextLabel: {
      type: String,
      default: "next"
    },
    prevLabel: {
      type: String,
      default: "previous"
    },
    submitLabel: {
      type: String,
      default: "submit"
    }
  },
  data() {
    return {
      currStep: 1
    };
  },
  methods: {
    clickPrevious() {
      this.currStep -= 1;
      this.$emit("onChange", this.currStep);
    },
    clickNext() {
      EventBus.$emit("validate", this.currStep);
    },
    incStep(step) {
      if (step !== this.currStep) return;
      this.currStep += 1;
      this.$emit("onChange", this.currStep);
    },
    onSubmit() {
      EventBus.$emit("validate", this.currStep);
    },
    submit() {
      this.$emit("onSubmit");
    },
    validateSubmit() {
      EventBus.$emit("validate", this.currStep);
    },
    triggerMethod(event) {
      return event === this.numberSteps ? this.submit() : this.incStep(event);
    }
  },
  mounted() {
    // EventBus.$on("valid", this.incStep);
    EventBus.$on("valid", this.triggerMethod);
    EventBus.$on("prev", this.clickPrevious);
  },
  beforeDestroy() {
    EventBus.$off("valid");
    EventBus.$off("prev");
  }
};
</script>

<style lang="css" scoped src="./MultiStepForm.css"></style>
