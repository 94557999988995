<template>
  <div class="spinner-wrapper" :class="show ? 'visible' : ''">
    <div class="spinner-overlay"></div>
    <div class="spinner">
      <breeding-rhombus-spinner
        :animation-duration="2000"
        :size="65"
        color="#00aad2"
      />
    </div>
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from "epic-spinners";
export default {
  components: {
    BreedingRhombusSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show() {
      if (this.loading) {
        // When the modal is shown, we want a fixed body
        document.body.style.overflow = "hidden";
        document.body.style.top = `-${window.scrollY}px`;
      } else {
        // When the modal is hidden...
        const scrollY = document.body.style.top;
        document.body.style.overflow = "scroll";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }

      return this.loading;
    }
  }
};
</script>

<style lang="css" scoped src="./Spinner.css"></style>
