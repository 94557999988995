import httpClient from "./httpClient";

const FOOTPRINT = "/footprint";

const FOOTPRINT_TYPES = {
  PAGE_VIEW: 1,
  SEARCH: 2,
  SAVED_SEARCH: 3,
  DETAIL_PAGE: 14,
  COOKIE_PAGE: 15,
  ADD_COMPARE: 7,
  REMOVE_COMPARE: 8,
  HAS_INTEREST: 23,
  EXPAND_CAR_DETAILS: 25,
  FAQS: 22,
  CLOSE_RESERVE_PANEL: 26,
  FINANCING_SIMULATION: 27,
  SEARCH_RESULT_CLICK: 28,
  SOCIAL_NETWORK: 29,
  RESERVE_STEP_1: 34,
  RESERVE_STEP_2: 35,
  RESERVE_STEP_3: 36,
  RESERVE_STEP_4: 37,
  START_RESERVE: 40,
  RETAKE_STEP_1: 41,
  RETAKE_STEP_2: 42,
  RETAKE_EVAL: 44
};

function registerEvent(footprintType, payload) {
  const footprintEvent = {
    type: footprintType,
    data: payload
  };

  return httpClient.post(FOOTPRINT, footprintEvent);
}

export { FOOTPRINT_TYPES, registerEvent };
