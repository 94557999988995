import { render, staticRenderFns } from "./RadioButtonLabel.vue?vue&type=template&id=5d27e6d2&scoped=true"
import script from "./RadioButtonLabel.vue?vue&type=script&lang=js"
export * from "./RadioButtonLabel.vue?vue&type=script&lang=js"
import style0 from "./RadioButtonLabel.css?vue&type=style&index=0&id=5d27e6d2&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d27e6d2",
  null
  
)

export default component.exports