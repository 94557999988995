<template>
  <div class="ds-viewer">
    <div v-if="!getAOS" class="ds-viewer--no-overflow">
      <img class="main-image" :src="getMainImage" alt="" />
      <NormalSlide :images="getExternalImages"></NormalSlide>
    </div>

    <div v-else id="aos-box">
      <div v-html="getAOS"></div>
    </div>
  </div>
</template>

<script>
import { NormalSlide } from "../../organisms";
import { mapGetters } from "vuex";

export default {
  components: {
    NormalSlide
  },
  data() {
    return {
      aosLoaded: false
    };
  },
  computed: {
    ...mapGetters(["getMainImage", "getExternalImages", "getAOS"])
  },
  mounted() {
    let script = document.createElement("script");
    script.defer = "defer";
    script.src = "https://media-player.aos.tv/app.js?cbb1164a0797f1ecd59f";
    document.head.append(script);

    if (this.getAOS) {
      setTimeout(() => {
        this.aosLoaded = true;
      }, 1000);
    }
  },
  beforeDestroy() {
    const scripts = document.querySelectorAll(
      `script[src^='https://media-player.aos.tv/app.js?cbb1164a0797f1ecd59f']`
    );
    if (scripts.length) scripts.forEach(script => script.remove());
  }
};
</script>

<style lang="css" scoped src="./UsedVehicleImageViewer.css"></style>
