<template>
  <div>
    <!-- Full Screen with Opacity in Background -->
    <Overlay v-if="isOpen" @onClose="close" />
    <!-- Transition Wrapper -->
    <transition name="slide">
      <!-- Actual Right Panel -->
      <div
        id="panel"
        v-if="isOpen"
        class="panel"
        :class="['panel', customClass, left ? 'left' : '']"
        :style="customStyles"
      >
        <!-- Content comes here -->
        <div class="panel--header">
          <slot name="header" />
          <div @click="close" class="panel--btn-wrapper">
            <div @click="close" class="panel--close-btn" />
          </div>
        </div>

        <div class="panel--content">
          <slot name="content" />
        </div>
        <div v-if="$slots.footer" class="panel--footer">
          <slot name="footer" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Overlay } from "../../atoms";

export default {
  name: "Panel",
  components: { Overlay },
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    customClass: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    clickOutside: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close(e) {
      this.$emit("onClose", e);
    },
    closeFromOverlay() {
      if (this.clickOutside) return;
      this.close();
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    },
    isOpen() {
      if (this.open) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
      return this.open;
    }
  }
};
</script>

<style lang="css" scoped src="./Panel.css"></style>
