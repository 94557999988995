<template>
  <div :class="['content-block', customClass]" :style="customStyles">
    <span v-html="content" v-if="html && content"></span>
    <div v-else-if="checkContent && !html">
      <Heading
        v-for="(element, index) in handleContent"
        :key="index"
        :label="element.innerHTML"
        :tag="checkTag(element)"
        :weight="checkWeight(element)"
      />
    </div>
    <div v-else>
      <Heading
        :label="$t('pages.searchpage.contentBlock.h1.0')"
        tag="h1"
        weight="bold"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h2.0')"
        tag="h2"
        weight="regular"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h3.0')"
        tag="h3"
        weight="regular"
      />
      <br />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h2.1')"
        tag="h2"
        weight="regular"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h3.1')"
        tag="h3"
        weight="regular"
      />
    </div>
  </div>
</template>

<script>
import { Heading } from "@sc/ds-ui-component-library";

export default {
  name: "ContentBlock",
  components: { Heading },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    html: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isEmpty(str) {
      return !str || str.length === 0;
    },
    checkTag(htmlElement) {
      return htmlElement.localName;
    },
    checkWeight(htmlElement) {
      return htmlElement.localName === "h1" ? "bold" : "regular";
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles
      };
    },
    headingContent() {
      return {
        h1: this.isEmpty(this.h1Content),
        h2: this.isEmpty(this.h2Content),
        h3: this.isEmpty(this.h3Content)
      };
    },
    checkContent() {
      const doc = new DOMParser().parseFromString(this.content, "text/html");
      const elementH1 = doc.querySelectorAll(["h1", "h2", "h3"]);
      return elementH1.length > 0;
    },
    handleContent() {
      const doc = new DOMParser().parseFromString(this.content, "text/html");
      const elements = doc.querySelectorAll(["h1", "h2", "h3"]);
      return elements;
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped src="./ContentBlock.css"></style>
