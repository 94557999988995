<template>
  <div class="book-vehicle--header">
    <div class="book-vehicle--header-image">
      <img :src="img" />
    </div>
    <div class="book-vehicle--header-details">
      <PriceModelDetails
        size="medium"
        :vehicle="vehicle"
        :absolutePrice="!getWithFinancing"
        :product-title="getModel"
        :product-description="getDescription"
        :finance-taeg="financeTaeg"
        :modality="getFormattedMonthlyPrice"
        :price-label="
          getIsOpenDrive || !getWithFinancing
            ? getFormattedTotalPrice
            : getFormattedPrice
        "
        :tooltipDescription="getTooltip"
        :tooltipDescriptionPvp="getTooltip"
        :tooltipPosition="tooltipPosition"
        icon-name="info-circle"
      >
      </PriceModelDetails>
    </div>
  </div>
</template>

<script>
import { PriceModelDetails } from "../../molecules";
import { mapGetters } from "vuex";
import { getPriceFormatted } from "../../../utils/currency.js";

export default {
  name: "PriceDetails",
  components: { PriceModelDetails },
  props: {
    tooltipPosition: {
      type: String,
      default: "left"
    },
    vehicle: {
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      "getWithFinancing",
      "getImg",
      "getModel",
      "getDescription",
      "getFormattedPrice",
      "getRawTaeg",
      "getFormattedTaeg",
      "getFormattedMonthlyPrice",
      "getFormattedTotalPrice",
      "getIsOpenDrive",
      "getTooltip"
    ]),
    img() {
      return this.getImg || require("../../../assets/images/placeholder.jpg");
    },
    financeTaeg() {
      return this.$t("pages.detailsPage.taeg", {
        taeg: getPriceFormatted(this.getRawTaeg, {
          currency: "EUR",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).replace("€", "")
      });
    }
  }
};
</script>

<style scoped>
.book-vehicle--header {
  @apply flex;
  border-bottom: 1px solid #e5ecf0;
  @apply pb-4;
}

.book-vehicle--header-image {
  background-color: #f5f7f9;
  width: 30%;
  display: flex;
  align-items: center;
}

.book-vehicle--header-details {
  @apply ml-4;
  width: 70%;
}

:deep(.tooltip--left) {
  transform: translateY(95%);
}

:deep(.finance-model-modality.finance--container-subtitle) {
  font-size: 16px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg.taeg--margin) {
  font-size: 16px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-price.finance--container-title) {
  font-weight: 700 !important;
  font-size: 20px !important;
}

:deep(.financing-details) div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

:deep(.financing-details-block) {
  width: max-content;
}

@media (max-width: 768px) {
  .book-vehicle--header {
    flex-direction: column;
    gap: 20px;
  }
  .book-vehicle--header-image {
    width: 100%;
  }
  .book-vehicle--header-details {
    margin: 0;
    width: 100%;
  }
  :deep(.finance-model-taeg.taeg--margin) {
    text-align: left !important;
  }
  :deep(.financing-details) div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
</style>
