var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload"},[_c('div',{staticClass:"upload-header"},[_c('Label',{staticClass:"upload-title",attrs:{"color":"primary","size":"small"}},[_vm._v(_vm._s(_vm.title))]),(!_vm.browseFile)?_c('Label',{staticClass:"upload-subtitle",attrs:{"color":"tertiary","size":"small"}},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.browseFile)?_c('input',{ref:"file",attrs:{"type":"file","id":"file","accept":"image/*"},on:{"change":_vm.compress}}):_vm._e()],1),_c('div',{staticClass:"upload-button"},[(
        !_vm.getReturn.submitted ||
          _vm.getReturn.status !== _vm.retakeStatusEnum.Q2_REFUSED ||
          _vm.getReturn.status !== _vm.retakeStatusEnum.FINALIZED
      )?_c('Button',{attrs:{"disabled":_vm.disabled,"label":"upload","fill":false,"type":"secondary","border":true,"uppercase":"","rounded":""},on:{"onClick":_vm.submitFile}}):_vm._e(),(
        _vm.getReturn.phaseTwoDTOV3 &&
          _vm.getReturn.phaseTwoDTOV3.files &&
          _vm.getReturn.phaseTwoDTOV3.files.find(image => image.name === _vm.pov)
      )?_c('Button',{attrs:{"disabled":false,"label":"download","fill":false,"type":"secondary","border":true,"uppercase":"","rounded":""},on:{"onClick":_vm.downloadFile}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }