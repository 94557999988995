import httpClient from "./httpClient";
import QueryString from "query-string";

const LEAD_URL = "/lead";

const LEAD_TYPES = {
  TEST_DRIVE: 28,
  CONTACT: 29,
  CONTACT_VIDEO: 31
};

const buildQueryString = type => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  utms.type = type;

  let queryString = "?";

  queryString += QueryString.stringify(utms);

  return queryString;
};

function addReserve(payload) {
  const queryString = buildQueryString(LEAD_TYPES.RESERVE);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveTestDrive(payload) {
  const queryString = buildQueryString(LEAD_TYPES.TEST_DRIVE);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveContact(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CONTACT);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveContactVideo(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CONTACT_VIDEO);
  return httpClient.post(LEAD_URL + queryString, payload);
}

export { addReserve, reserveTestDrive, reserveContact, reserveContactVideo };
