<template>
  <a @click="onCardClickHandler" class="search-vehicle-result-card-link">
    <Card
      :class="['search-vehicle-result-card', customClass, classes]"
      :styles="customStyles"
    >
      <Block class="image--wrapper">
        <img
          class="image"
          :class="img.indexOf('placeholder') > -1 ? 'no-image-placeholder' : ''"
          :src="img"
          :alt="'vehicle-card-image-' + productTitle"
        />
        <div v-if="isReserved || isSold" class="reserved--container">
          <FilterTag customClass="reserved" :label="filterTagLabel" />
        </div>
      </Block>
      <Block class="info--wrapper">
        <VehicleCardContent
          :product-title="productTitle"
          :product-description="productDescription"
          :icon-name="tooltipIcon"
          :tooltip-description="vehicle.legalInfo"
          :price-label="priceLabel"
          :modality="modality"
          :finance-taeg="financeTaeg"
          :fuel="fuel"
          :transmission="transmission"
          :months="months"
          :capitalFinance="capitalToFinance"
          :depositValue="depositValue"
          :previousPrice="previousPrice"
        />
      </Block>
      <div class="icons--wrapper">
        <a class="icon--wrapper" @click="onFavoriteHandler">
          <div
            :style="{ backgroundImage: `url('${favoriteImg}')` }"
            class="icon--favorite"
          />
        </a>
        <a class="icon--wrapper" @click="onCompareHandler">
          <div
            :style="{ backgroundImage: `url('${compareImg}')` }"
            class="icon--compare"
          />
        </a>
      </div>
    </Card>
  </a>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { Card, Block } from "../../../components";
import { VehicleCardContent } from "../../../components/organisms";
import { FilterTag } from "@sc/ds-ui-component-library";
import { getPriceFormatted } from "../../../utils/currency.js";

import { CompareMixin, FavoritesMixin } from "../../../mixins";

import { PAGES } from "../../../enums/pages";

import { RESERVE_TYPE } from "../../../enums/reserveTypes.js";

import {
  pushNewEvent,
  GOOGLE_TAG_MANAGER_EVENT_TYPES
} from "../../../utils/GoogleTagManager";

export default {
  name: "SearchVehicleResultCard",
  components: {
    Card,
    VehicleCardContent,
    Block,
    FilterTag
  },
  mixins: [CompareMixin, FavoritesMixin],
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    vehicle: {
      type: Object
    },
    tooltipIcon: {
      type: String,
      default: "info-circle"
    },
    id: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      inCompareList: "CompareModule/exists",
      inFavoritesList: "FavoritesModule/exists"
    }),
    isReserved() {
      return this.vehicle?.reserveType === RESERVE_TYPE.RESERVED;
    },
    isSold() {
      return this.vehicle?.reserveType === RESERVE_TYPE.BOUGHT;
    },
    filterTagLabel() {
      return this.vehicle?.reserveType === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveType.1")
        : this.$t("labels.reserveType.2");
    },
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles
      };
    },
    financeInfo() {
      return {
        months: this.vehicle.tooltipDescription.months,
        capitalFinance: getPriceFormatted(
          this.vehicle.tooltipDescription.capitalFinance
        ),
        depositValue: getPriceFormatted(
          this.vehicle.tooltipDescription.depositValue
        )
      };
    },
    img() {
      return (
        this.vehicle?.imageUrl ||
        require("../../../assets/images/placeholder.jpg")
      );
    },
    compareImg() {
      const src = this.inCompareList(this.vehicle) ? "" : "-gray";
      return require(`../../../assets/images/compare${src}.svg`);
    },
    favoriteImg() {
      const src = this.inFavoritesList(this.vehicle) ? "fill" : "gray";
      return require(`../../../assets/images/heart-${src}.svg`);
    },
    priceLabel() {
      const financialDiscPrice = this.vehicle?.prices?.find(
        v => v.priceType == "FinancialDiscountPrice"
      );
      if (financialDiscPrice) {
        return getPriceFormatted(financialDiscPrice.actualValue, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return getPriceFormatted(
          this.vehicle?.prices?.find(v => v.priceType == "TotalPrice")
            ?.actualValue,
          {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        );
      }
      /* return getPriceFormatted(
        this.vehicle?.prices?.find(v => v.priceType == "FinancialDiscountPrice")
          ?.actualValue,
        {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      ); */
    },
    previousPrice() {
      return this.vehicle?.prices?.find(
        price => price.priceType == "FinancialDiscountPrice"
      )?.previousValue;
    },
    modality() {
      return this.$t("pages.searchpage.SearchVehicleResultCard.monthlyPrice", {
        monthlyPrice: getPriceFormatted(this.vehicle?.monthlyPrice, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });
    },
    depositValue() {
      return this.$t("pages.searchpage.SearchVehicleResultCard.depositValue", {
        depositValue: getPriceFormatted(
          this.vehicle?.tooltipDescription?.depositValue,
          {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        )
      });
    },
    months() {
      return this.$t("pages.searchpage.SearchVehicleResultCard.months", {
        months: this.vehicle?.tooltipDescription?.months
      });
    },
    capitalToFinance() {
      return this.$t(
        "pages.searchpage.SearchVehicleResultCard.capitalToFinance",
        {
          capitalToFinance: getPriceFormatted(
            this.vehicle?.tooltipDescription?.capitalFinance,
            {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )
        }
      );
    },
    productDescription() {
      return this.vehicle?.commercialDescription;
    },
    financeTaeg() {
      return this.$t("pages.detailsPage.taeg", {
        taeg: getPriceFormatted(this.vehicle?.taeg, {
          currency: "EUR",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).replace("€", "")
      });
    },
    fuel() {
      const f = this.vehicle?.fuel;
      if (!f) return "";
      return `${f[0]}${f.slice(1).toLowerCase()}`;
    },
    transmission() {
      let transmission = this.vehicle?.transmission;
      if (transmission) {
        transmission = `${transmission}`;
      }
      return transmission;
    },
    productTitle() {
      return this.vehicle?.model;
    }
  },
  methods: {
    ...mapMutations({
      closeComparePanel: "CompareModule/closePanel",
      closeFavoritesPanel: "FavoritesModule/closePanel"
    }),
    onFavoriteHandler(event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();

      this.toggleFavorite(this.vehicle);
    },
    onCompareHandler(event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();

      this.toggleCompared(this.vehicle);
    },
    onCardClickHandler(event) {
      this.$emit("onClickResult");
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();

      this.closeComparePanel();
      this.closeFavoritesPanel();

      pushNewEvent(
        GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_CLICK,
        PAGES.SEARCH,
        this.vehicle
      );
      console.log(this.$router.currentRoute.path);
      sessionStorage.setItem(
        "searchPath",
        JSON.stringify({
          path: this.$router.currentRoute.path,
          name: this.$route.name
        })
      );
      this.$router.push({
        name: PAGES.DETAILS,
        query: {
          fid: this.vehicle?.financeId
        },
        params: {
          id: this.vehicle?.vin
        }
      });
    }
  }
};
</script>
<style lang="css" scoped src="./SearchVehicleResultCard.css"></style>
