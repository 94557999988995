<template>
  <div class="estimated-value">
    <div class="estimated-value--left">
      <div class="estimated-value--title">
        <Label color="primary" size="medium" uppercase>{{
          $t("pages.personalPage.returnDetails.estimatedValue.title")
        }}</Label>
      </div>
      <div class="estimated-value--subtitle">
        <Label color="tertiary" size="small">{{
          $t("pages.personalPage.returnDetails.estimatedValue.subtitle")
        }}</Label>
      </div>
    </div>
    <div class="estimated-value--right">
      <div class="estimated-value--right-title">
        <Label color="primary" size="small">{{
          $t("pages.personalPage.returnDetails.estimatedValue.label")
        }}</Label>
      </div>
      <div class="estimated-value--right-price">
        <div class="saved-return--header-right-close-prices">
          <Label
            v-if="showOldValue"
            class="old-value"
            size="medium"
            color="tertiary"
            bold="bold"
            :lineThrough="true"
            >{{ initialPriceLabel }}</Label
          >
          <Label
            v-if="status !== retakeStatusEnum.Q2_PENDING"
            color="secondary"
            size="medium"
            bold="bold"
            align="right"
            >{{ priceLabel }}</Label
          >

          <Label
            v-else
            color="secondary"
            size="medium"
            bold="bold"
            align="right"
            >{{
              $t(
                `pages.personalPage.returns.savedReturn.retakeState[${status}]`
              )
            }}</Label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { getPriceFormatted } from "../../../utils/currency.js";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";
export default {
  name: "EstimatedValue",
  data: function() {
    return {
      retakeStatusEnum: RETAKESTATUS
    };
  },
  components: {
    Label
  },
  props: {
    price: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
      default: -1
    },
    initialPrice: {
      type: Number,
      default: 0
    },
    adjustedPrice: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showOneValue() {
      return (
        this.status === -1 ||
        this.status === this.retakeStatusEnum.Q1_ESTIMATED ||
        (this.status === this.retakeStatusEnum.Q2_REFUSED &&
          this.equalValuations) ||
        this.status === this.retakeStatusEnum.Q1_ERROR ||
        (this.status === this.retakeStatusEnum.FINALIZED &&
          this.equalValuations) ||
        (this.status === this.retakeStatusEnum.Q1_UPDATED &&
          this.equalValuations) ||
        (this.status === this.retakeStatusEnum.FINALIZED &&
          this.equalValuations)
      );
    },

    showOldValue() {
      return (
        (this.status === this.retakeStatusEnum.Q1_UPDATED ||
          this.status === this.retakeStatusEnum.FINALIZED ||
          this.status === this.retakeStatusEnum.Q2_PENDING ||
          this.status === this.retakeStatusEnum.Q2_REFUSED) &&
        !this.equalValuations &&
        !!this.initialPrice
      );
    },
    equalValuations() {
      return (
        this.initialPrice === this.price ||
        (this.status === this.retakeStatusEnum.FINALIZED &&
          this.adjustedPrice === this.price)
      );
    },
    priceLabel() {
      return this.price === "0" || this.price === 0
        ? this.$t("pages.retake.emptyValue")
        : getPriceFormatted(this.price, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    },
    initialPriceLabel() {
      return getPriceFormatted(
        this.status === this.retakeStatusEnum.FINALIZED
          ? this.adjustedPrice
          : this.initialPrice,
        {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      );
    }
  },
  mounted() {
    console.log(this.status);
  }
};
</script>

<style lang="css" scoped src="./EstimatedValue.css"></style>
