<template>
  <div class="touch-wrapper" @click="e => $emit('click', e)">
    <div
      class="icon"
      :style="{
        'background-image': `url(${require('../../assets/images/' +
          icon +
          '.svg')})`
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.touch-wrapper {
  height: 44px;
  width: 44px;
  display: grid;
  place-items: center;
}

.icon {
  height: 24px;
  width: 24px;
  background-size: contain;
  background-position: center;
}
</style>
