<template>
  <div :class="['label-float', customClass, classes]" :style="customStyles">
    <Input
      @onFocus="onFocus"
      @onBlur="onBlur"
      @onChange="onChange"
      @onIconClick="onIconClick"
      :placeholder="isFocused || fixed ? label : ''"
      :bgColor="bgColor"
      :icon="icon"
      :inputType="inputType"
      :value.sync="value"
      :type="type"
      :disabled="isDisabled"
    />
    <label :class="[floatingLabel, floatingLabelwithIcon]">{{ label }}</label>
  </div>
</template>

<script>
import { Input } from "../../atoms";
export default {
  name: "InputForm",
  components: {
    Input
  },
  data() {
    return {
      value: this.inputValue,
      isFocused: false
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary",
      validator: type => {
        return ["primary", "secondary", "tertiary"].includes(type);
      }
    },
    inputType: {
      type: String,
      default: "text",
      validator: inputType => {
        return ["text", "email", "number", "password", "tel", "date"].includes(
          inputType
        );
      }
    },
    label: {
      type: String,
      required: true
    },
    bgColor: {
      type: String
    },
    fixed: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    inputValue: {
      type: [String, Number]
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
      this.$emit("onFocus");
    },
    onBlur() {
      this.isFocused = false;
      this.$emit("onBlur");
    },
    onChange(value) {
      this.$emit("onChange", value);
    },
    onIconClick(event) {
      this.$emit("onIconClick", event);
    }
  },
  computed: {
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor
      };
    },
    classes() {
      return {
        "label-float--primary": this.type === "primary",
        "label-float--secondary": this.type === "secondary",
        "label-float--tertiary": this.type === "tertiary"
      };
    },
    floatingLabel() {
      return this.isFocused || this.value || this.fixed ? "floating" : "";
    },
    floatingLabelwithIcon() {
      return this.icon ? "label--with-icon" : "";
    }
  }
};
</script>

<style lang="css" scoped src="./InputForm.css"></style>
