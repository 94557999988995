<template>
  <div class="car-features">
    <div class="car-features--title">
      <Label color="primary" size="medium" uppercase>{{
        $t("pages.personalPage.returnDetails.carFeatures.title")
      }}</Label>
    </div>
    <div class="car-features--content">
      <div class="car-features--general">
        <div class="car-features--general-title">
          <Label color="tertiary" size="small">{{
            $t("pages.personalPage.returnDetails.carFeatures.generalFeatures")
          }}</Label>
        </div>
        <div class="car-features--general-input">
          <CpInput
            customClass="custom--input"
            v-if="renderComponent"
            :validator="validateNumber"
            :disabled="disabled"
            label="Quilómetros"
            type="tertiary"
            :value="getReturnKilometers"
            @onChange="onChangeKmHandler"
          />
        </div>
        <div class="car-features--general-input">
          <Select
            :disabled="disabled"
            id="external-color"
            type="primary"
            :border="false"
            :options="exteriorColor"
            @onChange="onChangeExternalColorHandler"
          ></Select>
          <div class="validate-message">
            <Label
              class="validate-message--label"
              v-if="emptyColor"
              color="tertiary"
              bold="thin"
              size="small"
            >
              Precisa de indicar a cor exterior</Label
            >
          </div>
        </div>
        <div class="car-features--general-input">
          <Select
            :disabled="disabled"
            id="interior"
            type="primary"
            :border="false"
            :options="cabinTrimInlay"
            @onChange="onChangeInteriorHandler"
          ></Select>
          <div class="validate-message">
            <Label
              v-if="emptyInterior"
              color="tertiary"
              bold="thin"
              size="small"
              class="validate-message--label"
            >
              Precisa de indicar o interior do veículo</Label
            >
          </div>
        </div>
      </div>
      <div class="car-features--equipment">
        <div class="car-features--equipment-title">
          <Label color="tertiary" size="small">{{
            $t("pages.personalPage.returnDetails.carFeatures.equipment")
          }}</Label>
        </div>
        <div class="car-features--equipment-list">
          <div class="car-features--equipment-left">
            <div
              class="car-features--general-input"
              v-for="(item, index) in itemsHead"
              :key="index"
            >
              <CheckboxLabel
                :disabled="disabled"
                :title="item.text"
                :checked="item.checked"
                type="primary"
                checkboxType="secondary"
                :fill="false"
                :fillOnChecked="true"
                @onChange="onChangeEquipment(item.value, $event)"
              ></CheckboxLabel>
            </div>
          </div>
          <div class="car-features--equipment-right">
            <div
              class="car-features--general-input"
              v-for="(item, index) in itemsTail"
              :key="index"
            >
              <CheckboxLabel
                :disabled="disabled"
                :title="item.text"
                :checked="item.checked"
                type="primary"
                checkboxType="secondary"
                :fill="false"
                :fillOnChecked="true"
                @onChange="onChangeEquipment(item.value, $event)"
              ></CheckboxLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Select } from "../../atoms";
import { CheckboxLabel } from "../../molecules";
import { mapGetters } from "vuex";
import CpInput from "../../refactor/CpInput.vue";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

import LABEL_TYPE from "../../../utils/labelType";

const selectArgsWithSelected = (arr, val) =>
  arr.map(it => ({
    ...it,
    selected: it.value === val
  }));

export default {
  name: "CarFeatures",
  components: {
    Label,
    CpInput,
    Select,
    CheckboxLabel
  },
  data() {
    return {
      features: {
        kilometers: this.getReturnKilometers,
        externalColor: this.externalColorList[0].value,
        cabinTrimInlay: this.interiorList[0].value,
        equipments: this.equipmentList
      },
      retakeStatusEnum: RETAKESTATUS,
      renderComponent: true
    };
  },
  props: {
    returs: {
      type: Object,
      default: () => ({})
    },
    externalColorList: {
      type: Array[Object]
    },
    interiorList: {
      type: Array[Object]
    },
    equipmentList: {
      type: Array[Object]
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      // If you like promises better you can
      // also use nextTick this way
      this.$nextTick().then(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    onChangeEquipment(value, event) {
      const eq = this.features.equipments.findIndex(x => x.value === value);
      if (eq >= 0) {
        this.features.equipments[eq].checked = event;
      }

      this.$store.commit("setEquipments", [value, event]);

      this.emitFeatures();
    },
    validateNumber(e) {
      if (!e) {
        return [LABEL_TYPE.ERROR, "Campo obrigatório"];
      }
      if (!Number(e)) {
        return [LABEL_TYPE.ERROR, "Só são aceites valores numéricos"];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    onChangeInteriorHandler(event) {
      this.features.cabinTrimInlay = event;
      this.$store.commit("setInteriorColor", event);
      this.emitFeatures();
    },
    onChangeExternalColorHandler(event) {
      this.features.externalColor = event;
      this.$store.commit("setExteriorColor", event);
      this.emitFeatures();
    },
    onChangeKmHandler(event) {
      this.$store.commit("setReturnKilometers", event);
      this.emitFeatures();
    },
    emitFeatures() {
      this.setFeatures();
      this.$emit("onFeatures", this.features);
    },
    setFeatures() {
      this.features.kilometers = this.kilometers;
      this.features.plateDate =
        this.getReturn.year + "-" + this.getReturn.month + "-01";
      this.features.plate = this.getReturn.licensePlate;
      this.features.bcaId = this.getReturn.bcaId;
      this.features.tradeInId = this.getReturn.id;
      this.features.evaluation =
        this.getReturn.status === this.retakeStatusEnum.Q1_UPDATED ||
        this.getReturn.status === this.retakeStatusEnum.FINALIZED
          ? this.getReturn.actualValuation
          : this.getReturn.initialValuation;
    },
    checkUndefinedOrNull(x) {
      return x === undefined || x === null;
    }
  },
  computed: {
    ...mapGetters(["getReturn", "getReturnKilometers"]),
    itemsLength() {
      return Number((this.equipmentList.length / 2).toFixed(0));
    },
    emptyInterior() {
      return this.checkUndefinedOrNull(this.features.cabinTrimInlay) &&
        this.checkUndefinedOrNull(
          this.getReturn.tradeInPayload.features.cabinTrimInlay
        ) &&
        this.submitted
        ? true
        : false;
    },
    emptyColor() {
      return this.checkUndefinedOrNull(this.features.externalColor) &&
        this.checkUndefinedOrNull(
          this.getReturn.tradeInPayload.features.externalColor
        ) &&
        this.submitted
        ? true
        : false;
    },
    itemsHead() {
      return this.equipmentList
        ? this.equipmentList.slice(0, this.itemsLength)
        : [];
    },
    itemsTail() {
      return this.equipmentList
        ? this.equipmentList.slice(this.itemsLength)
        : [];
    },
    exteriorColor() {
      return selectArgsWithSelected(
        this.externalColorList,
        this.getReturn?.tradeInPayload?.features?.externalColor
      );
    },
    cabinTrimInlay() {
      return selectArgsWithSelected(
        this.interiorList,
        this.getReturn?.tradeInPayload?.features?.cabinTrimInlay
      );
    },
    kilometers() {
      return this.getReturnKilometers;
    },
    disabled() {
      return (
        this.getReturn?.submitted &&
        this.getReturn?.status !== this.retakeStatusEnum.Q2_REFUSED
      );
    }
  },
  mounted() {
    this.$store.commit("spinnerModule/setLoading", true);
    setTimeout(() => {
      this.emitFeatures();
      this.$store.commit("spinnerModule/setLoading", false);
    }, 1500);
    this.features.cabinTrimInlay = this.interiorList.find(
      it => it.selected
    )?.value;
    this.features.externalColor = this.externalColorList.find(
      it => it.selected
    )?.value;
  }
};
</script>

<style lang="css" scoped src="./CarFeatures.css"></style>
