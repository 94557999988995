<template>
  <div class="range--input-wrapper" @change="rangeSliderChanged">
    <div class="label--input-wrapper">
      <Label variant="subtitle" uppercase :bold="bold">{{ label }}</Label>
      <Input
        :type="inputType"
        name="input"
        :value.sync="currentValue"
        textColor="secondary"
        :icon="icon"
        @onChange="changedInput"
      />
    </div>
    <div class="slider--wrapper">
      <Slider
        :value.sync="currentValue"
        :min="sliderMin"
        :max="sliderMax"
        :interval="sliderInterval"
        :dotSize="sliderDotSize"
        @onChange="changedRange"
        @onMouseUp="mouseUpHandler"
        :type="sliderType"
        :disabled="sliderDisabled"
      ></Slider>
    </div>
  </div>
</template>

<script>
import { Slider, Label, Input } from "../../atoms";

function debounce(f, delay) {
  let timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    const args = arguments;
    const ctx = this;
    timeoutID = setTimeout(function() {
      f.apply(ctx, args);
    }, delay);
  };
}

export default {
  name: "RangeInput",
  data() {
    return {
      currentValue: 0,
      componentIndex: 0
    };
  },
  components: {
    Slider,
    Label,
    Input
  },
  props: {
    label: {
      type: String,
      required: true,
      default: ""
    },
    icon: {
      type: String,
      required: false,
      default: "euro-sign"
    },
    bold: {
      type: String,
      required: false,
      default: "bold"
    },
    inputType: {
      type: String,
      default: "primary"
    },
    sliderValue: {
      type: Number,
      required: false
    },
    sliderMin: {
      type: Number,
      required: false
    },
    sliderMax: {
      type: Number,
      required: false
    },
    sliderInterval: {
      type: Number,
      required: false
    },
    sliderDotSize: {
      type: Number,
      required: false
    },
    sliderType: {
      type: String,
      default: "primary",
      validator: sliderType => {
        return ["primary", "secondary"].includes(sliderType);
      }
    },
    sliderDisabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    changedRange: debounce(function(value) {
      this.currentValue = value;
      this.rangeSliderChanged();
    }, 200),
    changedInput(value) {
      // value is a string must convert
      const intValue = parseInt(value);
      intValue ? (this.currentValue = intValue) : (this.currentValue = 0);
      this.rangeSliderChanged();
    },
    rangeSliderChanged() {
      this.$emit("onChange", this.currentValue);
    },
    mouseUpHandler() {
      this.$emit("onMouseUp");
    }
  },
  created() {
    if (this.sliderValue) this.currentValue = this.sliderValue;
  }
};
</script>
<style lang="css" scoped src="./RangeInput.css"></style>
