import { render, staticRenderFns } from "./PriceFormStep.vue?vue&type=template&id=19ed216e&scoped=true"
import script from "./PriceFormStep.vue?vue&type=script&lang=js"
export * from "./PriceFormStep.vue?vue&type=script&lang=js"
import style0 from "./styles.css?vue&type=style&index=0&id=19ed216e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ed216e",
  null
  
)

export default component.exports