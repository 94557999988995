import { PublicClientApplication } from "@azure/msal-browser";
import { LOGIN_PROVIDERS } from "../enums/loginProviders";

const MyHyundaiMixin = {
  data() {
    return {
      msalInstance: {}
    };
  },
  async created() {
    this.msalInstance = new PublicClientApplication({
      auth: {
        clientId: "de567c09-9bf8-4c62-9249-979dc4bb0618",
        authority:
          "https://hyundaib2c.b2clogin.com/hyundaib2c.onmicrosoft.com/B2C_1A_B2CMIGRATESSPR_SIGNUPORSIGNIN", //Login
        knownAuthorities: ["hyundaib2c.b2clogin.com"]
      },
      cache: {
        cacheLocation: "localStorage"
      }
    });
  },
  mounted() {
    const accounts = this.msalInstance?.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
  },
  methods: {
    async signIn() {
      try {
        await this.msalInstance.loginPopup({
          redirectUri: window?.location?.origin
        });
        const myAccounts = this.msalInstance.getAllAccounts();
        this.account = myAccounts[0];

        const userObj = {
          token: this.account.localAccountId,
          providerId: LOGIN_PROVIDERS.MYHYUNDAI
        };

        await this.$store.dispatch("storeLogin", userObj);

        this.$router.push("/");
      } catch (error) {
        console.error(`error during authentication: ${error}`);
        this.$emit("login-error", error);
      }
    },
    async signOut() {
      await this.msalInstance
        .logout({})
        .then(() => {})
        .catch(error => {
          console.error(error);
        });
    }
  }
};

export { MyHyundaiMixin };
