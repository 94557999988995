<template>
  <div class="buy-with-financing">
    <div class="buy-with-financing--title">
      <Label size="small">{{ title }}</Label>
    </div>
    <form>
      <div class="buy-with-financing--radio">
        <div class="buy-with-financing--radio-option1">
          <RadioButtonLabel
            :title="yesTitle"
            radioId="yes"
            radioSize="small"
            radioColor="secondary"
            radioName="radio"
            radioCircular
            radioValue="yes"
            titleColor="primary"
            titleFor="yes"
            subtitleColor="tertiary"
            :radioChecked="true"
            @onChange="onChange"
          />
        </div>
        <div class="buy-with-financing--radio-option2">
          <RadioButtonLabel
            :title="noTitle"
            radioId="no"
            radioSize="small"
            radioColor="secondary"
            radioName="radio"
            radioCircular
            radioValue="no"
            titleColor="primary"
            titleFor="no"
            subtitleColor="tertiary"
            @onChange="onChange"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { RadioButtonLabel } from "../../molecules";
export default {
  name: "BuyWithFinancing",
  components: {
    Label,
    RadioButtonLabel
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    yesTitle: {
      type: String,
      default: ""
    },
    noTitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event === "yes");
    }
  }
};
</script>

<style lang="css" scoped src="./BuyWithFinancing.css"></style>
